import { useState, useLayoutEffect, useRef } from 'react';
import Tour from 'features/introJS-tour';
import TourQuickStatsData from 'features/introJS-tour/tour-data/page-analytics-quickstats';
import {
  getInitialStep,
  hasStepExpired,
  onCompleteSetTourState,
  TourEnum,
  tourStepHandler
} from 'features/introJS-tour/tour-helper';
import TourModal from 'pages/portal/dashboard/tourModal';
import storage from 'helpers/storage';
import FeatureTourModal from 'pages/portal/dashboard/featureTourModal';
import { toast } from 'react-toastify';
import { errors } from 'helpers/variables';

const TourWrapper = ({
  tourModalShow,
  setModalShow,
  toggleTour,
  toggelTourHandler,
  onTourChange
}) => {
  const [initTourStep, setInitTourStep] = useState(0);
  const [isTourCompleted, setTourCompleted] = useState(false);
  const getStepsData = TourQuickStatsData({ isTourCompleted });
  const [showFeature, setShowFeature] = useState(false);
  const [currentRelease, setCurrentRelease] = useState(false);
  const target = useRef(null);

  useLayoutEffect(() => {
    filterStepsData(getStepsData);
  }, []);

  const handleShowFeature = state => {
    setCurrentRelease(state);
    setShowFeature(state);
  };

  const filterStepsData = stepsData => {
    // TODO: check if Tour all pages are viewed true and has new feature in stepData
    // add feature data in stepData
    // then show tour for new feature data
    // then add to localstorage to not show everytime if has viewed true
    let tour = JSON.parse(storage.getItem('Tour'));
    if (tour?.tourModeEnabled) {
      // show new feature tour here
      let { pages } = tour;
      let newSteps = stepsData.filter(
        step =>
          step.isNew &&
          !pages[TourEnum[step.key]] &&
          !hasStepExpired(step.expiry)
      );
      if (newSteps.length) {
        onTourChange(0);
        setTimeout(() => {
          toggelTourHandler(true);
        }, 1000);
      }
    }

    setTourInitialStep();
  };

  const setTourInitialStep = () => {
    let tour = JSON.parse(storage.getItem('Tour'));
    let initStep = getInitialStep(tour, getStepsData);
    setInitTourStep(initStep);
  };

  const onTourExitHandler = stepIndex => {
    toggelTourHandler(false);
    if (stepIndex >= 0 && !isTourCompleted) {
      setTimeout(() => {
        setInitTourStep(prevStep =>
          // prevStep < stepIndex ? stepIndex + 1 : prevStep
          prevStep < stepIndex ? stepIndex : prevStep
        );
      }, 1000);
    }
  };

  const onChangeHandler = stepIndex => {
    tourStepHandler(getStepsData[stepIndex]?.key);
    onTourChange(stepIndex);
  };

  const onTourComplete = ev => {
    setInitTourStep(0);
    setTourCompleted(true);
    setTimeout(() => {
      onCompleteSetTourState(true, getStepsData);
      // setTourInitialStep();
    }, 100);
  };
  const checkDataAndOpenModal = () => {
    if (target?.current?.dataArray) {
      handleShowFeature(true);
    } else {
      toast.error(errors.failure);
    }
  };

  const onTourStart = stepIndex => {
    setTourCompleted(false);
  };
  return (
    <>
      <Tour
        toggleTour={toggleTour}
        stepData={getStepsData}
        initialStep={initTourStep}
        onStart={onTourStart}
        onChange={onChangeHandler}
        onExit={onTourExitHandler}
        onComplete={onTourComplete}
      />
      <TourModal
        show={tourModalShow}
        onHide={() => setModalShow(false)}
        toggelTourHandler={toggelTourHandler}
        startFeatureTour={checkDataAndOpenModal}
      />
      <FeatureTourModal
        show={showFeature}
        currentRelease={currentRelease ? global.AppInfo.version : ''}
        onHide={handleShowFeature}
        ref={target}
        // toggelTourHandler={toggelTourHandler}
      />
    </>
  );
};

export default TourWrapper;
