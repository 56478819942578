import React, { useState, useEffect } from 'react';
import { UilListUl } from '@iconscout/react-unicons';
import { isMobile } from 'react-device-detect';

const ToggleMenuButton = ({ heading, clickHandlerMenu }) => {
  useEffect(() => {
    if (isMobile) {
      clickHandlerMenu();
    }
  }, []);
  return (
    <>
      <h4
        className="sub-head"
        style={{
          display: 'inline-block',
          verticalAlign: 'sub',
          width: 'calc(100% - 25px)'
        }}
      >
        {heading}
      </h4>
      {isMobile && <UilListUl onClick={clickHandlerMenu} />}
    </>
  );
};

export default ToggleMenuButton;
