import { useEffect, useState } from 'react';
import { Slide } from '@material-ui/core';
import { Col } from 'react-bootstrap';

// Responsive Mobile Images
import MobileTablet from 'static/images/MD-coming-soon-form/responsive-mobile/tabletScreen.png';
import MobileDesktop from 'static/images/MD-coming-soon-form/responsive-mobile/desktopScreen.png';
import MobileLargeScreen from 'static/images/MD-coming-soon-form/responsive-mobile/largeScreen.png';
// Responsive Web Images
import WebTablet from 'static/images/MD-coming-soon-form/responsive-web/webTabletScreen.png';
import WebDesktop from 'static/images/MD-coming-soon-form/responsive-web/webDesktopScreen.png';
import WebLargeScreen from 'static/images/MD-coming-soon-form/responsive-web/webLargeScreen.png';
import MobileAppContent from './mobile-app-content';
import useViewport from 'helpers/viewport-hook';
import WhatsNewSection from './whats-new-section';
const ImageSection = ({ isGrid }) => {
  const screen = useViewport();
  const [desktop, setDesktop] = useState(false);
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    setMobile(true);
    setTimeout(() => {
      setDesktop(true);
    }, 200);
  }, []);

  return (
    <Col
      xs={12}
      md={!isGrid ? 12 : 8}
      className={`blux-text d-md-block ${isGrid ? 'd-none pl-1' : ''}`}
    >
      <div
        className={`MD-signup-image flex-column ${
          !isGrid && 'MD-signup-image-no-form position-relative'
        } ${
          screen.xs &&
          'justify-content-center align-items-center image-section-on-mobile'
        }`}
      >
        {!screen.xs && (
          <Slide
            direction="right"
            in={desktop}
            mountOnEnter
            unmountOnExit
            timeout={1900}
          >
            <picture className="desktop-img image-rendering">
              <source srcSet={WebTablet} media="(max-width: 768px)" />
              <source
                srcSet={WebLargeScreen}
                media="(min-width: 769px) and (max-width: 1440px)"
              />
              <source srcSet={WebLargeScreen} media="(min-width: 1444px)" />
              <img src={WebDesktop} alt="MD-web-screen" width={'100%'} />
            </picture>
          </Slide>
        )}
        <Slide
          direction="right"
          in={mobile}
          mountOnEnter
          unmountOnExit
          timeout={1600}
        >
          <picture className={`${!screen.xs && 'mobile-img'} image-rendering`}>
            <source srcSet={MobileLargeScreen} media="(max-width: 768px)" />
            <source
              srcSet={MobileDesktop}
              media="(min-width: 769px) and (max-width: 1440px)"
            />
            <source srcSet={MobileLargeScreen} media="(min-width: 1444px)" />
            <img src={MobileTablet} alt="MD-mobile-screen" width={'100%'} />
          </picture>
        </Slide>

        {isGrid && (
          <Slide
            direction="up"
            in={mobile}
            mountOnEnter
            unmountOnExit
            timeout={1600}
          >
            <div
              className="mx-5"
              style={{ position: 'absolute', bottom: '0.5rem' }}
            >
              <WhatsNewSection />
            </div>
          </Slide>
        )}
      </div>

      {!isGrid && (
        <div>
          <Slide
            direction="up"
            in={desktop}
            mountOnEnter
            unmountOnExit
            timeout={1900}
          >
            <div>
              <MobileAppContent
                showText={false}
                linkStyles={{
                  maxWidth: screen.xs ? '100%' : '30%',
                  margin: '0 auto'
                }}
              />
            </div>
          </Slide>
        </div>
      )}
    </Col>
  );
};
export default ImageSection;
