import React, { useEffect, useState } from 'react';
import './dashboard.scss';
import { getActivationStats } from 'features/analytics/analyticSlice';
import LineChart from 'components/Charts/LineChart';
import {
  camelCaseStringToNormalText,
  getCookie,
  setCookieForSpecificMinutes
} from 'helpers/generic.methods';
import { LoadingComponent } from './loadingListComp';
import { GraphColors } from './filters/constants';
const AnalyticsGraph = ({
  graphData,
  conversion = false,
  loading,
  removeTextFromLabel = '',
  appendTextToLabel = ''
}) => {
  const [accActivation, setAccActivation] = useState({});
  const colors = ['#00D0AA', '#B83C7A', '#009FFF'];
  // const colors = ['#00D0AA', '#B83C7A', '#009FFF'];
  const months = [
    '---',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];
  useEffect(() => {
    getAccActivations();
  }, [graphData]);

  const getAccActivations = () => {
    let obj = {
      labels: [],
      data: []
    };
    if (graphData && Object.keys(graphData).length) {
      Object.keys(graphData).forEach((key, index) => {
        let labels = [],
          values = [];
        if (graphData[key] && graphData[key].length) {
          graphData[key].forEach((dt, ind) => {
            labels.push(
              months[dt.Month] +
                `${ind === 0 || dt.Month === 1 ? ` ${dt.Year}` : ''}`
            );
            values.push(dt.Count);
          });
          obj['labels'] =
            obj['labels'].length > labels.length ? obj['labels'] : labels;
          obj['data'].push({
            values,
            color: GraphColors[key],
            label: camelCaseStringToNormalText(
              key.replace(removeTextFromLabel, appendTextToLabel),
              'heading'
            )
          });
        }
      });
      if (obj.data.length) setAccActivation(obj);
      else {
        setAccActivation({});
      }
    } else {
      setAccActivation({});
    }
  };
  return (
    <div className="chart-wrapper text-center">
      {/* {loading && (
        
      )} */}
      {!loading && Object.keys(accActivation).length ? (
        <LineChart
          graphData={accActivation}
          conversionInTooltip={conversion}
          loading={loading}
        />
      ) : null}
      <LoadingComponent
        loading={loading}
        dataLength={Object.keys(accActivation).length}
        isList={false}
      />
    </div>
  );
};

export default AnalyticsGraph;
