import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ForgotPasswordForm from 'features/auth/ForgotPasswordForm';
import { queryStringToJSONObject } from 'helpers/generic.methods';
import storage from 'helpers/storage';

const ForgotPassword = () => {
  const location = useLocation();
  const [forgotPasswordSuccess, setForgotPasswordSuccess] = useState('');
  let _hostKey = JSON.parse(storage.getItem('AppMetaInfo'))?.['host'];

  useEffect(() => {
    if (location.search && queryStringToJSONObject(location.search).email) {
      setForgotPasswordSuccess(queryStringToJSONObject(location.search).email);
    }
  }, [location]);

  return (
    <div className="form-container">
      <div className="text-darkgray-1 mb-lg-48 mb-32">
        <h2 className="login-title">
          {forgotPasswordSuccess ? 'Reset' : 'Forgot'} Password
          {forgotPasswordSuccess ? '' : '?'}
        </h2>
      </div>
      <ForgotPasswordForm />
      {!forgotPasswordSuccess && (
        <div className="back-to-login text-center fz-15">
          <Link to={_hostKey ? `/login/${_hostKey}` : '/login'}>
            <i className="uil uil-arrow-left"></i> Back to login
          </Link>
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;
