import React, { useEffect, useState } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { UilComments } from '@iconscout/react-unicons';
import { useSelector } from 'react-redux';
import { Badge, OverlayTrigger } from 'react-bootstrap';
import {
  dayAgo,
  // getStringInitials,
  truncateText
} from 'helpers/generic.methods';
import {
  getChatInfoById,
  getSelectedChatId,
  getSelectedChatLastMessage,
  getSelectedChatStarted,
  getSelectedChatTypingIndicator,
  getVisitorInfoName
} from 'features/messagingChats/messagingChats';
import CopyButton from 'CellRenderer/CopyButton';
import { getPlainStringFromHTMLElementAsString } from 'helpers/validate';
import renderToolTip from 'helpers/ui-controls/renderToolTip';

const ChatItem = ({
  chat,
  onChatClickHandler,
  showActionBtns,
  isOperator = false,
  isAgent = false
}) => {
  const lastMessage = useSelector(getSelectedChatLastMessage);
  const isTyping = useSelector(getSelectedChatTypingIndicator);
  const isChatStarted = useSelector(getSelectedChatStarted);
  const getVisitorName = useSelector(getVisitorInfoName);
  const selectedChatId = useSelector(getSelectedChatId);
  const { data: chatInfo } = useSelector(getChatInfoById(chat.chatId));

  return (
    <ListGroup.Item
      className={`chat-item m-2 ${
        selectedChatId === chat.chatId ? 'active-list' : 'non-active-list'
      }`}
      key={chat.chatId}
      onClick={() =>
        !showActionBtns || isChatStarted(chat.chatId)
          ? onChatClickHandler(chat)
          : null
      }
    >
      <div className="left-section p-2">
        <div className="chatname d-flex justify-content-between">
          <span className="d-flex align-items-center position-relative">
            <UilComments className="chat-icon" />
            &nbsp;
            {/* InitiatedBy Badge  */}
            {/* <span
              className="text-left language"
              title={`InitiatedBy:${chat.getChatInitiatedBy()}`}
            >
              <Badge bg="secondary" className="lang-pill">
                {getStringInitials(chat.getChatInitiatedBy())}
              </Badge>
            </span>
            &nbsp; */}
            {/* Language Badge  */}
            {/* <span className="text-left language" title="Language">
              <Badge bg="secondary" className="lang-pill">
                {chat.threeLetterISOLanguageName.toUpperCase()}
              </Badge>
            </span> */}
            <OverlayTrigger
              // show={isAgent}
              placement={'auto'}
              overlay={renderToolTip(
                isAgent
                  ? chat.companyName
                  : isOperator
                  ? getVisitorName(chat) || 'Chat'
                  : ''
              )}
            >
              <span className="chatname-title">
                {isAgent
                  ? truncateText(chat.companyName, 12)
                  : isOperator
                  ? truncateText(getVisitorName(chat), 12) || 'Chat'
                  : ''}
              </span>
            </OverlayTrigger>
            &nbsp;-
            {/* Id with copy button */}
            <span className="copy-button">
              <CopyButton
                copySpecificText={`${
                  chat.chatId
                } - ${chat.threeLetterISOLanguageName.toUpperCase()} - ${
                  chat.companyName
                }`}
                value={chat.chatId}
              />
            </span>
          </span>
          {/* lead email not sent badge */}
          {!chatInfo?.sendingLead &&
          chatInfo?.leadSubmitMessage &&
          chatInfo?.leadSubmitMessage === 'Chat lead has been submitted' ? (
            <span
              className="language"
              title="Email of this lead has not been sent"
            >
              <Badge bg="secondary" className="badge-warning">
                Lead email is pending
              </Badge>
            </span>
          ) : null}
        </div>
        <div className="w-100 d-flex justify-content-between align-items-center">
          <span className="text-left  message">
            {truncateText(
              getPlainStringFromHTMLElementAsString(
                lastMessage(chat.chatId).text
              ),
              25
            )}
          </span>
          <span className="text-right date-time">{dayAgo(chat.createdOn)}</span>
        </div>
      </div>
    </ListGroup.Item>
  );
};

export default ChatItem;
