import { Paper, MenuItem, Slide } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { UilTimesCircle } from '@iconscout/react-unicons';
import { getQTInitials } from '../Helper';
import BadgeWithIcon from 'components/BadgeWithIcon';
import { getSuggestionIcon } from 'helpers/messaging/message.methods';
const useStyle = makeStyles(theme => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'var(--primary-4)',
    // color: 'var(--darkgray-3)',
    borderRadius: '8px 8px 0px 0px',
    padding: '4px',
    paddingLeft: '16px'
  },
  closeIcon: {
    cursor: 'pointer'
  },
  paperContainer: {
    width: '100%',
    position: 'absolute',
    bottom: '100%',
    height: 'auto'
    // backgroundColor: 'var(--primary-4)'
  },
  badgeCustomStyle: {
    borderRadius: '4px !important',
    marginRight: '4px',
    marginLeft: '0 !important',
    padding: '4px !important'
  },
  visiblityHidden: {
    visibility: 'hidden !important'
    // width: '28px !important'
  },
  listItem: {
    whiteSpace: 'break-spaces'
  },
  customScroll: {
    height: 'auto',
    maxHeight: '250px',
    minHeight: '36px',
    position: 'relative',
    overflowY: 'auto !important',
    '&::-webkit-scrollbar': {
      width: '6px'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '3px',
      backgroundColor: 'var(--darkgray-9)'
    },
    '& div': {
      position: 'relative !important',
      overflow: 'hidden !important'
    }
  }
}));

const AutoCompleteSuggestion = ({
  suggestions,
  onClickSuggestion,
  onClose
}) => {
  const classes = useStyle();
  return (
    !!suggestions?.length && (
      <Slide
        direction="up"
        in={!!suggestions.length}
        mountOnEnter
        unmountOnExit
      >
        <Paper elevation={3} className={classes.paperContainer}>
          <span className={classes.header}>
            <span>Quick Pick</span>
            <span>
              <UilTimesCircle className={classes.closeIcon} onClick={onClose} />
            </span>
          </span>
          <div className={classes.customScroll}>
            {suggestions?.map((opt, index) => (
              <MenuItem
                key={index}
                onClick={() => onClickSuggestion(opt)}
                className={classes.listItem}
              >
                {/* <BadgeWithIcon
                  showIcon={false}
                  text={getQTInitials(opt?.questionType).initials}
                  toolTipText={
                    opt?.questionType == 0
                      ? ''
                      : getQTInitials(opt?.questionType).qt
                  }
                  badgeClass={classes.badgeCustomStyle}
                /> */}
                {opt?.questionType == 0 ? (
                  // <BadgeWithIcon badgeClass={classes.visiblityHidden} />
                  ''
                ) : (
                  <BadgeWithIcon
                    showIcon={true}
                    badgeClass={classes.badgeCustomStyle}
                    icon={
                      <img
                        src={getSuggestionIcon(opt?.questionType)}
                        style={{
                          height: '14px',
                          width: '14px',
                          margin: '0px'
                        }}
                      />
                    }
                    toolTipText={getQTInitials(opt?.questionType).qt}
                  />
                )}
                {
                  <span
                    dangerouslySetInnerHTML={{
                      __html: opt.text
                    }}
                  ></span>
                }
              </MenuItem>
            ))}
          </div>
        </Paper>
      </Slide>
    )
  );
};

export default AutoCompleteSuggestion;
