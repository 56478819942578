import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import LeadItem from './LeadItem';

const LeadNotesBox = ({ title, leadNotes = [] }) => {
  const leadRenderer = leadNotes.map((lead, index) => (
    <LeadItem key={index} lead={lead} />
  ));
  return (
    <>
      <div>
        <h5 className="text-darkgray-2 fz-15 fw-700 mt-20">{title}</h5>
        <div className="chat-container mb-20">
          <Scrollbars>
            <div className="chat-snippet">{leadRenderer}</div>
          </Scrollbars>
        </div>
      </div>
    </>
  );
};

export default LeadNotesBox;
