import { logIt } from 'helpers/logger';

export const callConnectCallbacks = {
  onSuccess: args => {
    logIt('log', 'CallConnectStatus callback:: onSuccess::', args);
  },
  onReceive: args => {
    logIt('log', 'CallConnectStatus callback:: onReceive::', args);
    args.onStatusArrived(args.data);
  },
  onError: args => {
    logIt('Error', 'CallConnectStatus callback:: onError::', args);
  }
};
