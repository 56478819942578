// import React from 'react';
import { Enums } from 'helpers/dropdown-enums';

const _checkGoogleTrackingConditions = trackingType => {
  return (
    Enums.AnalyticsTracking.GoogleUniversal === trackingType ||
    Enums.AnalyticsTracking.GoogleAnalyticsClientId === trackingType ||
    Enums.AnalyticsTracking.GoogleAnalyticsCrossDomainTracking === trackingType
  );
};
const _checkGoogleServerSideTrackingConditions = _company => {
  return (
    _company.googleAnalyticsEnableUniversal ||
    _company.googleAnalyticsPagesEnabled ||
    _company.googleAnalyticsEventsEnabled
  );
};
export const renderCompanyTrackingTypes = _company => {
  let _isGoogleAnalyticsTrackingEnabled = false;
  let _isFacebookPixelTrackingEnabled = false;
  let _isCustomTrackingEnabled = false;
  let _isGoogleServerSideTrackingEnabled = false;
  let _sendClientIdToGoogleAnalytics = false;
  let _googleAnalyticsCrossDomainTracking = false;
  // Fetching the company trackings list
  if (
    _company.companyTrackingTypes &&
    _company.companyTrackingTypes.length > 0
  ) {
    // Setting the company trackings data
    _company.companyTrackingTypes.forEach(data => {
      const trackingType = data.trackingType;

      if (_checkGoogleTrackingConditions(trackingType)) {
        _isGoogleAnalyticsTrackingEnabled = true;
        if (Enums.AnalyticsTracking.GoogleAnalyticsClientId === trackingType) {
          _sendClientIdToGoogleAnalytics = true;
        } else if (
          Enums.AnalyticsTracking.GoogleAnalyticsCrossDomainTracking ===
          trackingType
        ) {
          _googleAnalyticsCrossDomainTracking = true;
        }
      } else if (Enums.AnalyticsTracking.FacebookPixel === trackingType) {
        /*checking FacebookPixelTrackingEnabled */
        _isFacebookPixelTrackingEnabled = true;
      } else if (
        /* Custom tracking: Lead/Chats javascript based */
        Enums.AnalyticsTracking.Custom === trackingType
      ) {
        _isCustomTrackingEnabled = true;
      }
    });
  }

  _isGoogleServerSideTrackingEnabled = _checkGoogleServerSideTrackingConditions(
    _company
  );

  const getTrackingInfo = {
    googleAnalyticsTrackingEnabled:
      _isGoogleAnalyticsTrackingEnabled ||
      _company.sendChatsAndLeadsToGaViaDashboard ||
      _isGoogleServerSideTrackingEnabled,
    serverSideGoogleTracking: _isGoogleServerSideTrackingEnabled,
    clientSideGoogleTracking: _isGoogleAnalyticsTrackingEnabled,
    facebookPixelTracking: _isFacebookPixelTrackingEnabled,
    customTrackingEnabled: _isCustomTrackingEnabled,
    sendClientIdToGoogleAnalytics: _sendClientIdToGoogleAnalytics,
    googleAnalyticsCrossDomainTracking: _googleAnalyticsCrossDomainTracking
  };

  //   return {
  //     isGoogleAnalyticsTrackingEnabled: _isGoogleAnalyticsTrackingEnabled,
  //     isFacebookPixelTrackingEnabled: _isFacebookPixelTrackingEnabled,
  //     isCustomTrackingEnabled: _isCustomTrackingEnabled,
  //     isGoogleServerSideTrackingEnabled: _isGoogleServerSideTrackingEnabled,
  //     sendClientIdToGoogleAnalytics: _sendClientIdToGoogleAnalytics,
  //     googleAnalyticsCrossDomainTracking: _googleAnalyticsCrossDomainTracking
  //   };

  return getTrackingInfo;
};
