import React from 'react';

const LeadItem = ({ lead }) => {
  return (
    <div>
      {lead.includes('href') ? (
        <span
          className="agent-chat"
          dangerouslySetInnerHTML={{ __html: lead }}
        ></span>
      ) : (
        <span className="agent-chat">{lead}</span>
      )}
    </div>
  );
};

export default LeadItem;
