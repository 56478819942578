export const en_facebookIntegration = {
  heading1: 'Facebook',
  verificationToken: {
    label: 'Verification Token',
    info: 'A token used to setup the facebook integration application.'
  },
  pageAccessToken: {
    label: 'Page Access Token',
    info:
      'A token generated by the facebook application that gives permission to communicate on behalf of the facebook page.'
  },
  callbackURL: {
    label: 'Callback URL',
    info: 'The Endpoint URL used to setup the facebook integration application.'
  },
  enableGetStartedButton: {
    label: 'Enable Get Started Button',
    info:
      'If set, the get started button will only appear when the user interacts with the page for the first time.'
  },
  greetingText: {
    label: 'Greeting Text',
    info: 'Shows greeting text when the Get Started Button is enabled.'
  },
  enableGreetingText: {
    label: 'Enable Greeting Text',
    info:
      'If set, the get started button will also show greeting text for the first time.'
  },
  facebookChatGreetingText: {
    label: 'Facebook Chat Greeting Text',
    info:
      'This message will appear immediately after the Get Started button is pressed.'
  },
  companyFacebookPageName: {
    label: 'Company Facebook Page Name',
    info:
      'E.g: If company facebook page url is https://www.facebook.com/Gearbestshopping , so, Gearbestshopping is the Facebook page name.'
  },
  facebookPageID: {
    label: 'Facebook Page ID',
    info: 'You can find Facebook Page Id by right clicking on Display Picture.'
  }
};
export default en_facebookIntegration;
export { default as fr_facebookIntegration } from '../fr-FR-Dictionary/fr-facebookIntegration';
export { default as sp_facebookIntegration } from '../sp-SP-Dictionary/sp-facebookIntegration';
