import React from 'react';

const FieldError = ({ errorMessage, error, titleText, position }) => {
  return (
    <div
      className={`field-error tooltip-error tooltip-${
        position ? position : 'right'
      }`}
      aria-labelledby={titleText}
    >
      {errorMessage ? errorMessage : error}
    </div>
  );
};

export default FieldError;
