import React, { useState } from 'react';
// Testing
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { UilEnvelopeSend, UilHistory } from '@iconscout/react-unicons';
import BottomPaper from '../BottomSheet/bottomPaper';
import { makeStyles } from '@material-ui/core/styles';
import { Badge } from 'react-bootstrap';
import CustomBadge from 'components/CustomBadge';
const useStyle = makeStyles(theme => ({
  selected: {
    color: 'var(--primary) !important'
  }
}));
const BottomNavigationComponent = ({
  onChangeNavMenu,
  children,
  unSentLeadCount = 0
}) => {
  // Testing
  const [value, setValue] = useState(0);
  const [bottomSheetOpen, setBottomSheet] = useState(false);
  const classes = useStyle();

  const handleBottomSheet = activeTab => {
    setValue(activeTab);
    onChangeNavMenu(activeTab);
    if (activeTab == value && bottomSheetOpen) {
      setBottomSheet(false);
    } else {
      setBottomSheet(true);
    }
  };

  const closeBotomSheet = () => {
    setBottomSheet(false);
  };

  // Testing end
  return (
    <>
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          handleBottomSheet(newValue);
        }}
        showLabels
        className="bottom-sheet-main"
      >
        <BottomNavigationAction
          label="Unsent Leads"
          classes={classes}
          icon={
            <div>
              <UilEnvelopeSend size={25} />
              {unSentLeadCount ? (
                <CustomBadge
                  style={{ top: '2px' }}
                  count={unSentLeadCount}
                  toolTipLabel={'Total unsent leads'}
                />
              ) : null}
            </div>
          }
        />
        <BottomNavigationAction
          label="History"
          classes={classes}
          // style={{ paddingTop: '15px' }}
          icon={<UilHistory size={18} />}
        />
      </BottomNavigation>
      <BottomPaper open={bottomSheetOpen} closeBotomSheet={closeBotomSheet}>
        {children}
      </BottomPaper>
    </>
  );
};

export default BottomNavigationComponent;
