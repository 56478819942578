import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const SubMenuActiveLink = ({ children, activeMenu = false, ...props }) => {
  const [active, setActive] = useState(false);
  const child = React.Children.only(children);

  useEffect(() => {
    if (activeMenu) setActive(true);
    else setActive(false);
  }, [activeMenu]);
  return (
    <Link {...props} to={props.href} className={active ? 'active' : ''}>
      {React.cloneElement(child)}
    </Link>
  );
};

SubMenuActiveLink.propTypes = {
  activeMenu: PropTypes.bool
};

export { SubMenuActiveLink };
