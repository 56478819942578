import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import ProfileNav from './ProfileNav';
import RedirectComp from './RedirectComp';
import { stopPropogation } from 'features/general/generalSlice';
import { UilQuestionCircle } from '@iconscout/react-unicons';
import UilSetting from '@iconscout/react-unicons/icons/uil-setting';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectLoadedScript,
  setScript
} from 'features/script-load/scriptLoadSlice';
import { selectRoles } from 'features/roles/rolesSlice';
import storage from 'helpers/storage';
import config from 'config';
import AppInfoModal from 'pages/portal/modal/appInfoModal';
import { PreloadImages } from '../PreloadImages';
import dropdownEnums from 'helpers/dropdown-enums';
import { isMobile } from 'react-device-detect';
import RenderSidebarTooltip from 'helpers/ui-controls/renderSidebarTooltip';
import { getUserFromViewedUser } from 'helpers/generic.methods';
import TourWrapper from './TourWrapper';
import PopMenu, { PopMenuItem } from './popmenu';

const MainDivChatWidget = 'apexchat_prechat_invitation_wrapper';
const MainDivChatWindow = 'apexchat_dompopup_chatwindow_wrapper';
const IFramIdWidget = 'apexchat_prechat_invitation_frame';
const IFramIdChatBox = 'apexchat_dompopup_chatwindow_frame';
const classNameChatBoxCloseButton = 'livechat_close';
const ElementIdWidgetButton = 'apexchat_prechat_main_bg';
const ElementIdPreChat = 'apexchat_prechat_message_popup_container';

const SideBarBottom = ({ user, showSideBar, switchTo, setShowSidebar }) => {
  const dispatch = useDispatch();
  const { scriptLoad } = useSelector(selectLoadedScript);
  const [showChat, setShowChat] = useState(false);
  const [infoModal, setInfoModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [menuShow, setMenuShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [loginDetails, setLoginDetails] = useState(null);
  const history = useHistory();
  const { roles } = useSelector(selectRoles);

  useEffect(() => {
    if (JSON.parse(scriptLoad) && window.navigator.onLine) {
      loadScriptFunc();
    }
  }, []);

  const showWidget = () => {
    setShowChat(true);
    widgetVisibility(MainDivChatWidget, true);
    widgetVisibility(ElementIdWidgetButton, true);
    widgetVisibility(ElementIdPreChat, true);
  };

  const initializeChatCloseOnClick = () => {
    let iframeChat = document.getElementById(IFramIdChatBox);
    if (iframeChat) {
      let iframeDoc = (iframeChat.contentWindow || iframeChat.contentDocument)
        .document;
      let chatCloseButtons = iframeDoc.getElementsByClassName(
        classNameChatBoxCloseButton
      );
      if (chatCloseButtons.length) {
        chatCloseButtons[0].onclick = chatCloseFunction;
      }
    }
  };

  const loadScriptFunc = () => {
    dispatch(
      setScript({
        isLoaded: true,
        src: config.scriptBotLink
      })
    );
    showWidget();
    setLoading(true);
    let inter = setInterval(() => {
      console.log('connecting...');
      if (window.ApexChat) {
        initializeWidgetOnClick();
        setLoading(false);
        setMenuShow(false);
        clearInterval(inter);
      } else if (!window.navigator.onLine) clearInterval(inter);
    }, 1000);
  };

  const chatCloseFunction = () => {
    widgetVisibility(ElementIdWidgetButton, false);
    widgetVisibility(ElementIdPreChat, false);
    setShowChat(false);
    storage.setItem('ScriptLoad', false);
  };

  const initializeWidgetOnClick = () => {
    initializeChatCloseOnClick();
    let iframeWidget = document.getElementById(IFramIdWidget);
    if (iframeWidget) {
      let iframeDoc = (
        iframeWidget.contentWindow || iframeWidget.contentDocument
      ).document;
      let widgetButton = null;
      let inter2 = setInterval(() => {
        console.log('initializing...');
        widgetButton = iframeDoc.getElementById(ElementIdWidgetButton);
        if (widgetButton) {
          widgetButton.onclick = onWidgetClickFunction;
          clearInterval(inter2);
        } else if (!window.navigator.onLine) clearInterval(inter2);
      }, 1000);
    }
  };

  const onWidgetClickFunction = () => {
    setTimeout(() => {
      initializeChatCloseOnClick();
    }, 200);
  };

  const addScript = () => {
    if (!showChat) {
      if (scriptLoad) {
        initializeWidgetOnClick();
        showWidget();
        storage.setItem('ScriptLoad', true);
        toggleDropdownHandler();
      } else if (window.navigator.onLine) {
        loadScriptFunc();
      }
    } else {
      setShowChat(false);
      toggleDropdownHandler();
    }
  };

  // TODO - if required
  // const hideWidget = (id, condition) => {
  //   setTimeout(() => {
  //     let doc = document;
  //     if (!doc.getElementById(id)) {
  //       let iframeWidget = document.getElementById(IFramIdWidget);
  //       if (iframeWidget) {
  //         let iframeDoc = (
  //           iframeWidget.contentWindow || iframeWidget.contentDocument
  //         ).document;
  //         doc = iframeDoc;
  //       }
  //     }
  //     if (doc.getElementById(id))
  //       doc.getElementById(id).style.display = condition ? 'block' : 'none';
  //   }, 200);
  // };

  const widgetVisibility = (id, condition) => {
    setTimeout(() => {
      let doc = document;
      if (!doc.getElementById(id)) {
        let iframeWidget = document.getElementById(IFramIdWidget);
        if (iframeWidget) {
          let iframeDoc = (
            iframeWidget.contentWindow || iframeWidget.contentDocument
          ).document;
          doc = iframeDoc;
        }
      }
      if (doc.getElementById(id))
        doc.getElementById(id).style.visibility = condition
          ? 'visible'
          : 'hidden';
    }, 50);
  };

  const useCloseOnClickedOutside = async ref => {
    await dispatch(stopPropogation(ref)).then(res => {
      if (res) setMenuShow(false);
    });
  };
  // use ref on wrapper element
  const wrapperRef = useRef(null);
  // calling the method to check if the clicked event
  // happened inside / outside the wrapper
  // and pass the wrapper ref
  useCloseOnClickedOutside(wrapperRef);

  const toggleDropdownHandler = e => {
    let show = !menuShow;
    setMenuShow(show);
  };

  useEffect(() => {
    let loggedInUser = getUserFromViewedUser();
    if (loggedInUser) {
      setLoginDetails(loggedInUser);
    }
  }, [showChat]);
  const onLogOutRemoveChat = () => {
    widgetVisibility(MainDivChatWindow, false);
    widgetVisibility(IFramIdChatBox, false);
    widgetVisibility(ElementIdPreChat, false);
    setShowChat(false);
  };

  const [toggleTour, setToggleTour] = useState(false);

  const toggelTourHandler = value => {
    setToggleTour(value);
  };

  const launchHandler = () => {
    setShowSidebar(true);
    setTimeout(function () {
      setModalShow(true);
    }, 1000);
    setMenuShow(false);
  };

  const launchInfoHandler = () => {
    setInfoModal(true);
    setMenuShow(false);
  };

  const closeModal = () => {
    setInfoModal(false);
  };

  const onChangeHandler = stepIndex => {
    if (roles.analytics.view && isMobile && stepIndex === 0) {
      setShowSidebar(false);
    } else {
      setShowSidebar(true);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (loginDetails && !loginDetails?.isLogginBefore) {
        setModalShow(true);
        setShowSidebar(true);
      } else {
        setModalShow(false);
      }
    }, 3000);
  }, [loginDetails]);

  useEffect(() => {
    if (toggleTour && isMobile) {
      document.body.className = 'body-wrapper';
    } else {
      document.body.className = '';
    }
  });

  return (
    <div className="sidebar-bottom-wrap" style={{ width: '70%' }}>
      <PreloadImages imgSource={dropdownEnums.enum_ImageSource} />
      <TourWrapper
        tourModalShow={modalShow}
        setModalShow={setModalShow}
        toggelTourHandler={toggelTourHandler}
        toggleTour={toggleTour}
        onTourChange={onChangeHandler}
      />
      {roles.settings.view && (
        <PopMenu
          showSideBar={showSideBar}
          title={'Settings'}
          icon={<UilSetting size="24" className="sidebar-link" />}
          clickHandler={e => history.push('/portal/settings/widget-editor')}
        />
      )}

      <div className="profile-nav" ref={wrapperRef}>
        <div
          id="introJS-item-help"
          className="avatar-nav d-block"
          onClick={e => toggleDropdownHandler(e)}
        >
          {/* conditional toggle avtar/usericon */}
          <div className="userIcon d-flex align-items-center userTextPrimary">
            <div className="m-4">
              <RenderSidebarTooltip
                showSidebar={showSideBar}
                title={'Help'}
                icon={<UilQuestionCircle size="24" className="sidebar-link" />}
              />
            </div>
            {showSideBar ? <span className="fw-600 ml-2">Help</span> : null}
          </div>
        </div>
        {/* toggle the menu on condition check */}
        {menuShow && (
          <div
            // style={{ bottom: '5.3rem' }}
            className={`sidebar-bottom-menu${showSideBar ? '-open' : ''}`}
          >
            <ul className="dropdow-list">
              <li className="notification-list-item heading cursor-pointer p-0">
                <div
                  className="d-flex justify-content-between px-3 py-2 align-items-center"
                  style={{
                    background: 'var(--lightgray-2)',
                    boxShadow: '0px 4px var(--lightgray-5)'
                  }}
                >
                  <p
                    className="m-0"
                    style={{ fontWeight: 700, color: 'var(--black)' }}
                  >
                    Help
                  </p>
                  <UilQuestionCircle size="18" className="sidebar-link" />
                </div>
              </li>
              {/*  || config.masterCompany === user.CompanyKey */}
              <li
                className="notification-list-item option cursor-pointer"
                onClick={addScript}
              >
                Support Bot
                {loading && <i className="uil uil-spinner spinner ml-2"></i>}
              </li>
              <li
                className="notification-list-item option cursor-pointer"
                onClick={launchHandler}
              >
                Launch Tour
              </li>
              <li
                className="notification-list-item option cursor-pointer"
                onClick={launchInfoHandler}
              >
                App Info
              </li>
            </ul>
          </div>
        )}
      </div>
      {switchTo.allowPortal ? <RedirectComp showSideBar={showSideBar} /> : null}
      <ProfileNav
        showSideBar={showSideBar}
        onLogOutRemoveChat={onLogOutRemoveChat}
      />
      <AppInfoModal showModal={infoModal} eventHideModal={closeModal} />
    </div>
  );
};

export default SideBarBottom;
