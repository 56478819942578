export const fr_basicDetails = {
  heading1: 'Détail de base',
  heading2: 'Détails primaires',
  heading3: 'Support linguistique',
  companyId: {
    label: "Identifiant de l'entreprise",
    info:
      "L'ID de la société est un nombre numérique à référence lorsque le compte a été créé dans notre système"
  },
  hostName: {
    label: 'Hôtesse',
    info: 'Le nom du revendeur le compte est sous.'
  },
  relationship: {
    label: 'Relation',
    info: 'Ceci indique le type de compte (partenaire ou client).'
  },
  CDN: {
    label: 'CDN',
    info:
      "Cette option vous permet de charger le code de chat à partir d'un CDN au lieu de nos serveurs."
  },
  website: {
    label: 'Site Internet',
    info: 'Ce nom de domaine associé à ce compte.'
  },
  languages: {
    label: 'Langages',
    info: 'Langues prises en charge par cette société'
  }
};
export default fr_basicDetails;
