import React from 'react';
import Form from 'react-bootstrap/Form';
import { UilBellSlash, UilBell } from '@iconscout/react-unicons';
import DropDownMenu from 'helpers/ui-controls/ui-components/dropdownMenu';
import UILSetting from '@iconscout/react-unicons/icons/uil-setting';
import { makeStyles } from '@material-ui/core/styles';
import ToggleSwitch from 'helpers/ui-controls/ui-components/toggleSwitch';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAutoSuggestEnable,
  getOpenAddSuggestionModalState,
  setAutoSuggestEnable,
  setOpenSuggestionModal
} from 'features/messagingChats/suggestions';
import StatementModal from '../StatementModal';

const useStyles = makeStyles(theme => ({
  settingMenu: {
    padding: '0',
    backgroundColor: 'transparent !important',
    boxShadow: 'unset',
    '&:hover': {
      boxShadow: 'unset !important',
      backgroundColor: 'transparent !important'
    },
    '&:focus': {
      backgroundColor: 'transparent !important'
    }
  },
  menuContainer: {
    '& .MuiListItem-root': {
      paddingTop: '0 !important',
      color: 'var(--darkgray-1)',
      '&:hover': {
        backgroundColor: 'transparent !important'
      }
    }
  },
  menuIcon: {
    width: '22px !important',
    height: '22px !important'
  },
  smallSwitch: {
    marginRight: '0 !important',
    '& .custom-control-label': {
      '&:before': {
        width: '2.2rem',
        height: '1.1rem'
      },
      '&:after': {
        width: '0.9rem',
        height: '0.9rem'
      }
    }
  }
}));

const Header = ({
  totalCount,
  notifiySoundEnable,
  setNotificationSoundEnable,
  toggleStatus,
  status,
  statusLoading,
  isAgent
}) => {
  const classes = useStyles();
  const getAutoSuggestStatus = useSelector(getAutoSuggestEnable);
  const openStatementModal = useSelector(getOpenAddSuggestionModalState);
  const dispatch = useDispatch();
  const handleChange = menuKey => {
    console.log('handleChange', menuKey);
    if (menuKey === 'suggestion') {
      dispatch(setOpenSuggestionModal(true));
    }
  };

  const ToggleAutoCompleteSuggestionComponent = () => (
    <ToggleSwitch
      label={'Auto Suggestions'}
      id={'auto-suggest-switch'}
      selectedValue={getAutoSuggestStatus}
      classes={`d-flex justify-content-between align-items-center w-100`}
      switchClass={classes.smallSwitch}
      handleChange={e => dispatch(setAutoSuggestEnable(!getAutoSuggestStatus))}
    />
  );
  const ToggleSoundComponent = () => (
    <ToggleSwitch
      label={'Sound'}
      id={'sound-switch'}
      selectedValue={notifiySoundEnable}
      classes="d-flex justify-content-between align-items-center w-100"
      switchClass={classes.smallSwitch}
      handleChange={e => setNotificationSoundEnable(!notifiySoundEnable)}
    />
  );

  const menuOptions = [
    {
      key: 'sound',
      value: <ToggleSoundComponent />,
      disableClick: true,
      isVisible: true
    },
    {
      key: 'autoComplete',
      value: <ToggleAutoCompleteSuggestionComponent />,
      disableClick: true,
      isVisible: true
    },
    {
      key: 'suggestion',
      value: 'Add Statement',
      disableClick: false,
      isVisible: !isAgent
    }
  ];
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <h5 className="title">Chats</h5>
      <div className="pt-3 d-flex">
        <Form.Group controlId="isActiveStatus " className="d-flex mb-0">
          <Form.Label className="d-flex align-items-center">
            <span>{status ? 'Active' : 'Inactive'}</span>
          </Form.Label>
          <Form.Check
            type="switch"
            id={'isActiveStatus'}
            checked={!!status}
            onChange={e => toggleStatus(e)}
            inline={true}
            disabled={statusLoading}
          />
        </Form.Group>
        <DropDownMenu
          title={<UILSetting className={classes.menuIcon} />}
          classes={`btn btn-sm btn-flex mr-1 btn-lightgray-3 ${classes.settingMenu}`}
          menuClass={classes.menuContainer}
          onOptionSelect={handleChange}
          options={menuOptions}
          disabled={false}
          isOnlyIcon={true}
          // disableClick={true}
        />
      </div>
      <StatementModal
        showModal={openStatementModal}
        title="Add New Statement"
      />
    </div>
  );
};

export default Header;
