import config from 'config';
import { addSubdomainInURL, getCookie } from 'helpers/generic.methods';
import storage from 'helpers/storage';
import { toast } from 'react-toastify';

const SSO_Redirection = (arg = {}) => {
  const {
    subDomain = '',
    returnUrl = '',
    authToken = '',
    shouldOpenInSameWindow = false,
    profile
  } = arg;
  const user = profile || JSON.parse(storage.getItem('Profile'));
  let token = getCookie('Auth') || authToken;

  if (!!token) {
    let host = (user && user.HostDomain) || config.portalURL;
    // Add subdomain "dashboard." before host url in case of Production/Beta Only
    if (config.environment === 'production' || config.environment === 'beta') {
      host =
        subDomain === 'dashboard.'
          ? addSubdomainInURL(
              (user && user.HostDomain) || config.portalURL,
              'dashboard'
            )
          : (user && user.HostDomain) || config.portalURL;
    }

    let redirectionWithToken =
      host +
      '/handlers/sso.ashx?' + // Slash before handlers is intentional in case if any host has no slash in host Domain
      (returnUrl ? `ReturnUrl=${returnUrl}&` : '') +
      'token=' +
      token;
    // redirect to another link
    if (shouldOpenInSameWindow) {
      toast.success('Redirecting to the Portal...');
      window.setTimeout(() => {
        window.location.assign(redirectionWithToken);
      }, 2000);
    } else {
      window.open(redirectionWithToken, `_newtab_${returnUrl}`);
    }
  }
};
export default SSO_Redirection;
