import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { camelize, abbrNum, toolTipPlacement } from 'helpers/generic.methods';
const FilterPanelInfoTooltip = ({ toolTipQuickStats, toolTipGraphs }) => {
  const { t } = useTranslation();
  const translate = str => {
    console.log(str);
    let translation = t(`company.analytics.${str}`);
    return translation;
  };
  return (
    <>
      <div style={{ textAlign: 'left' }}>
        <div
          style={{
            fontWeight: '700',
            whiteSpace: 'break-spaces',
            width: '280px',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
          className="mb-3"
          title={t('company.analytics.filtersTooltip1')}
        >
          {t('company.analytics.filtersTooltip1')}
        </div>
        <div style={{ fontWeight: '700' }}>
          {t('company.analytics.quickStats')} :{' '}
        </div>
        <ul className="mb-2">
          {toolTipQuickStats.split(',').map((ttq, index) => (
            <li key={index}>{translate(ttq)}</li>
          ))}
        </ul>
        <div style={{ fontWeight: '700' }}>
          {' '}
          {t('company.analytics.charts')} :{' '}
        </div>
        <ul className="mb-2">
          {toolTipGraphs.split(',').map((ttg, index) => (
            <li key={index}>{translate(ttg)}</li>
          ))}
        </ul>
      </div>
    </>
  );
};
export default FilterPanelInfoTooltip;
