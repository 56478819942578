import Scrollbars from 'react-custom-scrollbars';

const ScrollBar = ({
  children,
  styles = {},
  verticalScroll = true,
  horizontalScroll = true,
  ...rest
}) => {
  const renderThumb = ({ style, ...props }) => {
    console.log('1235');
    const thumbStyle = {
      backgroundColor: `rgb(236 0 0)`,
      background: `rgb(236 0 0)`
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };

  return horizontalScroll ? (
    <Scrollbars
      renderThumbHorizontal={renderThumb}
      renderThumbVertical={renderThumb}
      {...rest}
    >
      {children}
    </Scrollbars>
  ) : (
    <Scrollbars
      renderTrackHorizontal={rest => (
        <div
          {...rest}
          className="track-horizontal"
          style={{
            display: horizontalScroll ? 'block' : 'none'
          }}
        />
      )}
      renderThumbHorizontal={rest => (
        <div
          {...rest}
          className="thumb-horizontal"
          style={{
            display: horizontalScroll ? 'block' : 'none',
            backgroundColor: '#a8acb0cc'
          }}
        />
      )}
      renderThumbVertical={rest => (
        <div
          {...rest}
          className="thumb-vertical"
          style={{
            ...rest.style,
            borderRadius: '10px',
            backgroundColor: '#a8acb0cc'
          }}
        />
      )}
      {...rest}
    >
      {children}
    </Scrollbars>
  );
};

export default ScrollBar;
