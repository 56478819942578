import React, { useRef, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import ScrollBar from 'components/Scrollbars';
import { formatTime } from 'helpers/generic.methods';
import MessageContainer from './MessageContainer';
import { Enums } from 'helpers/dropdown-enums';
import { responseTypeUserIds } from '../Helper';
import useViewport from 'helpers/viewport-hook';

const AllMessages = ({
  selectedChat,
  chatById,
  isChatEnded,
  userImage,
  inputMessage
}) => {
  const [height, setHeight] = useState(0);
  const messageListEndRef = useRef(null);
  const screen = useViewport();

  const scrollToBottom = () => {
    messageListEndRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: screen.xs ? 'center' : 'end',
      inline: screen.xs ? 'center' : 'end'
    });
  };

  useEffect(() => {
    //  if new messages updated then scroll to last message
    scrollToBottom();
  }, [chatById?.messages?.length]);

  useEffect(() => {
    let inputElem = document.getElementById('msg-input');
    let inputScrollHeight = inputElem.scrollHeight;
    // if inputScrollHeight is 76 or above, means textarea have 4 or above rows
    // when textarea have 4 or above rows we need to add height on messages container to scroll properly
    // and messages should not be visible from behind textarea
    if (inputScrollHeight >= 76) {
      setHeight(screen.xs ? 45 : 19);
    } else {
      setHeight(0);
    }
  }, [chatById?.messages?.length, inputMessage]);

  return (
    <div className="col-md-12 h-100">
      <ScrollBar
        horizontalScroll={false}
        style={{
          height: `calc(100% - ${screen.xs ? 60 + height : 130 + height}px)`,
          paddingRight: '10px'
        }}
      >
        <div
          className="chat_box touchscroll chat_box_colors_a mr-3"
          style={{ padding: isMobile ? '0px' : '0px 30px' }}
        >
          <div className="chat-date-time">
            {formatTime(selectedChat?.data?.createdOn, 'dddd DD, MMMM')}
          </div>
          {chatById &&
            Object.keys(chatById).length &&
            chatById.messages.length > 0 &&
            chatById.messages.map((msg, i) => (
              <MessageContainer
                // showAvatar={
                //   msg.userId !== chatById?.messages[i - 1]?.userId &&
                //   msg.userId > 0
                // }
                showAvatar={
                  (msg.userId > 0 ||
                    responseTypeUserIds.includes(msg.userId)) &&
                  msg.userId !== chatById?.messages[i - 1]?.userId
                }
                alertMsg={
                  msg.userId < 0 && !responseTypeUserIds.includes(msg.userId)
                }
                key={msg.id}
                msg={msg}
                index={i}
                userImage={userImage}
                isChatEnded={isChatEnded}
                messagesLength={chatById.messages.length}
                nextMessage={chatById?.messages[i + 1]}
              />
            ))}
          <div ref={messageListEndRef}></div>
        </div>
      </ScrollBar>
    </div>
  );
};

export default AllMessages;
