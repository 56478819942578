import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';

import Form from 'react-bootstrap/Form';
import UILInfoCircle from '@iconscout/react-unicons/icons/uil-info-circle';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import UILSave from '@iconscout/react-unicons/icons/uil-save';
import dropdownEnums from 'helpers/dropdown-enums';
import ToggleText from 'helpers/ui-controls/toggleText';
import SimpleLabelTextBox from 'helpers/ui-controls/ui-components/SimpleLabelTextbox';
import renderToolTip from 'helpers/ui-controls/renderToolTip';

const History = ({ company }) => {
  const [companyObj, setCompanyObj] = useState({});
  const updateData = (key, value) => {
    let _tempCompanyObj = {};
    _tempCompanyObj[key] = value;
    setCompanyObj({ ...companyObj, ..._tempCompanyObj });
  };
  return (
    <>
      <Form>
        <div className="page-section">
          <h4 className="sub-head">History</h4>
          <section>
            <header className="section-head d-flex align-items-center justify-content-between">
              <h5 className="fw-600 mb-0">Primary details</h5>
              <Button className="btn-md btn-flex" variant="primary">
                <UILSave />
                <span>Save</span>
              </Button>
            </header>
            <div className="section-body">
              <ul className="editable-fields-list">
                <li>Company ID</li>
                <li>
                  <span>{company.id}</span>
                  <OverlayTrigger
                    placement="right"
                    overlay={renderToolTip('Company ID')}
                  >
                    <UILInfoCircle className="list-icon-after-text" />
                  </OverlayTrigger>
                </li>
                <li>
                  <Form.Label>Host</Form.Label>
                </li>
                <li className="is-valid">
                  <Form.Label>{company.hostName}</Form.Label>
                  <OverlayTrigger
                    placement="right"
                    overlay={renderToolTip('Host that company uses...')}
                  >
                    <UILInfoCircle className="list-icon-after-text" />
                  </OverlayTrigger>
                  {/* <div className="field-error tooltip-error">
                    Incorrect website
                  </div> */}
                </li>
                <li>
                  <Form.Label>Relationship</Form.Label>
                </li>
                <li>
                  <Form.Label>
                    {dropdownEnums.enum_Relationship.map(e => {
                      if (e.value === company.relationship) {
                        return e.name;
                      }
                      return '';
                    })}
                  </Form.Label>
                  <OverlayTrigger
                    placement="right"
                    overlay={renderToolTip(
                      'Determines access to reporting data & portals'
                    )}
                  >
                    <UILInfoCircle className="list-icon-after-text" />
                  </OverlayTrigger>
                  {/* <Form.Control as="select" value={company.relationship}>
                    {dropdownEnums.enum_Relationship.map((e, key) => {
                      return (
                        <option key={key} value={e.value}>
                          {e.name}
                        </option>
                      );
                    })}
                  </Form.Control> */}
                </li>
                <li>CDN</li>
                <li>
                  {/* UI-Control: toggleText takes flag as an argument and renders the state */}
                  <ToggleText flag={company.useCdn} />
                </li>
                <SimpleLabelTextBox
                  heading="Website"
                  field="domain"
                  dataProperty={company['domain']}
                  titleText="Used when sending google analytics leads"
                  onChange={updateData}
                />
              </ul>
            </div>
          </section>
        </div>
        <div className="page-section">
          <section>
            <header className="section-head d-flex align-items-center justify-content-between">
              <h5 className="fw-600 mb-0">Language Support</h5>
            </header>
            <div className="section-body">
              <ul className="editable-fields-list">
                <li>
                  <Form.Label>Languages</Form.Label>
                </li>
                <li>
                  <Form.Label>
                    {company.languageSupport &&
                      company.languageSupport.join(', ')}
                  </Form.Label>
                  <OverlayTrigger
                    placement="right"
                    overlay={renderToolTip('Supported')}
                  >
                    <UILInfoCircle className="list-icon-after-text" />
                  </OverlayTrigger>
                </li>
              </ul>
            </div>
          </section>
        </div>
      </Form>
    </>
  );
};

export default History;
