import React, { useState, useEffect, useRef } from 'react';
import * as _ from 'lodash.debounce';
import { Button, Card, OverlayTrigger } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import ListGroup from 'react-bootstrap/ListGroup';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import {
  UilCommentAlt,
  UilFolder,
  UilSearch,
  UilTrashAlt
} from '@iconscout/react-unicons';
import { useDispatch, useSelector } from 'react-redux';
import { saveInputMessage } from 'features/messagingChats/messagingChats';

import {
  getSuggestionAsync,
  getSuggestionsByCompany,
  setSuggestion,
  setLoading,
  removeSuggestionAsync,
  setOpenSuggestionModal
} from 'features/messagingChats/suggestions';
import { toggleChatDetailsRightPane } from 'features/chat/chatSlice';
import { isMobile } from 'react-device-detect';
import BadgeWithIcon from 'components/BadgeWithIcon';
import { Enums, getObjectKeyByValue } from 'helpers/dropdown-enums';
import {
  camelCaseStringToNormalText,
  getStringInitials
} from 'helpers/generic.methods';
import { makeStyles } from '@material-ui/core/styles';
import './Suggestions.scss';
import { searchSuggestion, getQTInitials } from './Helper';
import {
  parseScriptText,
  getSuggestionIcon,
  getSuggestionsByRole
} from 'helpers/messaging/message.methods';
import { selectRoles } from 'features/roles/rolesSlice';
import { selectLogin } from 'features/auth/authSlice';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import UilPlus from '@iconscout/react-unicons/icons/uil-plus';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import renderToolTip from 'helpers/ui-controls/renderToolTip';
import DeleteModal from '../modal/deleteModal';

const useStyles = makeStyles(theme => ({
  badgeCustomStyle: {
    borderRadius: '4px !important',
    marginRight: '4px',
    padding: '4px !important'
  },
  visiblityHidden: {
    visibility: 'hidden !important',
    width: '28px !important'
  },
  statementListItem: {
    '&:hover': {
      '& .statement-delete-icon': {
        display: 'block'
      }
    },
    '& .statement-delete-icon': { display: 'none' }
  },
  fabButtonRoot: {
    backgroundColor: 'var(--primary-2)',
    position: 'fixed',
    bottom: '28px',
    right: '28px',
    textTransform: 'capitalize',
    height: '35px',
    padding: '0 14px',
    '& .MuiFab-label': {
      fontSize: '13px !important',
      marginRight: '3px',
      '& svg': {
        width: '0.8em !important'
      }
    },
    '&:hover': {
      backgroundColor: 'var(--primary)'
    }
  },
  bgColorLightGray: {
    backgroundColor: 'var(--lightgray-3)'
  }
  // disabledDelete: {
  //   pointerEvents: 'none',
  //   color: 'var(--darkgray-3)'
  // }
}));

const Suggestions = ({
  setDefaultMessageText,
  setToggleChatDetail,
  setToggleChatSuggestion,
  selectedChat,
  isActiveTab
}) => {
  const classes = useStyles();
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const getSuggestionPayload = () => {
    let payload = {
      companyId: selectedChat?.getCompanyId(),
      threeLetterISOLanguageName: selectedChat?.getLanguage()
    };
    return payload;
  };
  const allSuggestions = useSelector(
    getSuggestionsByCompany(getSuggestionPayload())
  );
  const [_allSuggestions, setAllSuggestions] = useState(allSuggestions);
  const [activeKeys, setActiveKey] = useState(
    _allSuggestions?.questions?.map((item, index) => '') || []
  );
  const [searchValue, setSearchValue] = useState('');
  const [loading, setSearching] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedStatement, setSelectedStatement] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const { roles } = useSelector(selectRoles);
  const { profile } = useSelector(selectLogin);

  useEffect(() => {
    if (!allSuggestions) {
      fetchSuggestions(getSuggestionPayload());
    } else {
      setAllSuggestions(allSuggestions);
    }

    setActiveKey([]);
    setSearchValue('');
    if (inputRef && inputRef.current) {
      inputRef.current.value = '';
    }
  }, [selectedChat.getId()]);

  const fetchSuggestions = payload => {
    dispatch(getSuggestionAsync(payload)).then(data => {
      let modifiedData = getSuggestionsByRole(data);
      dispatch(setSuggestion(modifiedData));
      dispatch(setLoading(false));
      setAllSuggestions(modifiedData);
    });
  };

  const onDeleteSuggestion = () => {
    console.log('suggestionId', selectedStatement);
    setDeleteLoading(true);
    dispatch(removeSuggestionAsync({ id: selectedStatement })).then(data => {
      if (data) {
        fetchSuggestions(getSuggestionPayload());
        setDeleteLoading(false);
        setOpenDeleteModal(false);
        setSelectedStatement(null);
      }
    });
  };

  const onSuggestionClickHandler = suggestion => {
    let inputElem = document.getElementById('msg-input');
    // let inputValue = inputElem.value;
    let text = parseScriptText(suggestion.text);
    let QTDetails = suggestion;
    dispatch(saveInputMessage({ id: selectedChat?.getId(), text, QTDetails }));
    if (isMobile) {
      // on mobile screen, close sidebar on suggestion click
      dispatch(
        toggleChatDetailsRightPane({
          show: false
        })
      );
    }
    inputElem.focus();
  };

  const searchText = e => {
    let text = e.target.value;
    if (text && text.trim()) {
      text = text.toLowerCase();
      // setSearching(true);
      handleSearch(text);
      // setSearchValue(text);
    } else {
      setAllSuggestions(allSuggestions);
      setActiveKey([]);
      setSearchValue('');
      handleSearch.cancel();
    }
  };

  const handleSearch = _(value => {
    let newData = searchSuggestion(allSuggestions?.questions, value);
    let tempSuggestions = { ..._allSuggestions, questions: newData };
    setAllSuggestions(tempSuggestions);
    setSearchValue(value);
    // setSearching(false);
    if (newData && newData.length) {
      let openKeys = newData.map(
        (item, index) => item.expanded && item.id.toString()
      );
      setActiveKey(openKeys);
    }
  }, 1500);

  //  toggle accordion W.R.T suggestion ids
  const toggleActive = (index, id) => {
    let tempKeys = [...activeKeys];
    if (tempKeys[index] === id.toString()) {
      tempKeys[index] = '';
    } else {
      tempKeys[index] = id.toString();
    }
    setActiveKey(tempKeys);

    // scroll parent div if last question is toggled
    let accordionContainer = document.getElementById('accordion-container');
    let lastQuestionElemenIndex = accordionContainer.childNodes.length - 1;
    if (lastQuestionElemenIndex === index) {
      setTimeout(() => {
        var objDiv = document.getElementById('suggestion-scroll-div');
        objDiv.firstChild.scrollTop = objDiv.firstChild.scrollHeight;
      }, 500);
    }
  };

  // mark bg yellow if search string matches in any suggestion
  const markText = text => {
    let value = text;
    if (text && searchValue && text.toLowerCase().includes(searchValue)) {
      let re =
        searchValue.length > 1
          ? new RegExp(searchValue, 'gi')
          : new RegExp('[' + searchValue + ']', 'gi');
      let result = value.replace(re, match => {
        return `<mark>${match}</mark>`;
      });
      // old logic for match which was throwing error on searching '?'
      // let result = value.replace(new RegExp(searchValue, 'gi'), match => {
      //   return `<mark>${match}</mark>`;
      // });
      return result;
    }
    return value;
  };

  const showDeleteButton = answer => {
    return answer?.createdBy === profile?.Username ? true : false;
  };

  const openAddStatementModal = () => {
    dispatch(setOpenSuggestionModal(true));
  };

  const openConfirmDeleteModal = (e, statementId) => {
    e.stopPropagation();
    setSelectedStatement(statementId);
    setOpenDeleteModal(true);
  };

  return (
    <>
      <div className="suggestion-input">
        <InputGroup className="mb-3">
          <InputGroup.Prepend>
            <InputGroup.Text id="basic-addon1">
              <UilSearch />
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            placeholder="Search suggestions"
            aria-label="Suggestions"
            aria-describedby="basic-addon1"
            onChange={searchText}
            // value={searchValue}
            ref={inputRef}
          />
        </InputGroup>
      </div>
      <div className="suggestions-questions">
        <Card id="accordion-container">
          {_allSuggestions?.questions?.length ? (
            _allSuggestions?.questions
              .filter(sugg => sugg.answers.length)
              .map((question, que) => {
                return question.answers.length ? (
                  <Accordion
                    key={question.id}
                    // className="suggestions-questions"
                    defaultActiveKey={
                      question?.expanded ? question.id.toString() : '-1'
                    }
                    activeKey={activeKeys[que]}
                    flush="true"
                  >
                    <div>
                      <Accordion.Toggle
                        as={Card.Header}
                        className="according-text"
                        eventKey={question.id.toString()}
                        // eventKey={que.toString()}
                        onClick={e => toggleActive(que, question.id)}
                      >
                        <span className="tab-icon">
                          <UilFolder />
                        </span>
                        <span className="tab-title">
                          {
                            <span
                              dangerouslySetInnerHTML={{
                                __html: markText(question.text)
                              }}
                            ></span>
                          }
                        </span>
                      </Accordion.Toggle>
                      <Accordion.Collapse
                        eventKey={question.id.toString()}
                        // eventKey={que.toString()}
                      >
                        <Card.Body>
                          <List
                            component="nav"
                            aria-label="main mailbox folders"
                          >
                            {question.answers &&
                              question.answers.map((answer, ans) => {
                                return (
                                  <ListItem
                                    key={answer.id}
                                    button
                                    // className="statement-list-item"
                                    classes={{
                                      container: classes.statementListItem
                                    }}
                                    onClick={e =>
                                      onSuggestionClickHandler(answer)
                                    }
                                  >
                                    <ListItemIcon>
                                      <BadgeWithIcon
                                        showIcon={true}
                                        badgeClass={classes.badgeCustomStyle}
                                        icon={
                                          <img
                                            src={getSuggestionIcon(
                                              answer?.questionType ===
                                                Enums.ChatQuestionType.None
                                                ? Enums.ChatQuestionType.Text
                                                : answer?.questionType
                                            )}
                                            style={{
                                              height: '14px',
                                              width: '14px',
                                              margin: '0px'
                                            }}
                                          />
                                        }
                                        toolTipText={
                                          answer?.questionType !==
                                            Enums.ChatQuestionType.None &&
                                          getQTInitials(answer?.questionType).qt
                                        }
                                      />
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={
                                        <span className="chatname-title">
                                          <span
                                            dangerouslySetInnerHTML={{
                                              __html: markText(answer.text)
                                            }}
                                          ></span>
                                        </span>
                                      }
                                    />
                                    <ListItemSecondaryAction>
                                      {/* UIUX-1356 hide delete icon from statements, which are not deletable */}
                                      {/* <OverlayTrigger
                                      placement="auto"
                                      overlay={renderToolTip(
                                        'Cannot delete statement'
                                      )}
                                      show={
                                        showDeleteButton(answer)
                                          ? undefined
                                          : false
                                      }
                                    > */}
                                      {showDeleteButton(answer) && (
                                        <span className="ml-2 statement-delete-icon">
                                          <UilTrashAlt
                                            size={18}
                                            className={`cursor-pointer`}
                                            onClick={e =>
                                              openConfirmDeleteModal(
                                                e,
                                                answer?.id
                                              )
                                            }
                                          />
                                        </span>
                                      )}
                                      {/* </OverlayTrigger> */}
                                    </ListItemSecondaryAction>
                                  </ListItem>
                                );
                              })}
                          </List>
                        </Card.Body>
                      </Accordion.Collapse>
                    </div>
                  </Accordion>
                ) : null;
              })
          ) : (
            <p className={`text-center ${classes.bgColorLightGray}`}>
              No Suggestions Found
            </p>
          )}
        </Card>

        <DeleteModal
          onSubmit={onDeleteSuggestion}
          eventHideModal={e => setOpenDeleteModal(false)}
          showModal={openDeleteModal}
          loading={deleteLoading}
          title="Delete Statement"
          infoText="Are you sure you want to delete this statement?"
          submitButtonTitle="Delete"
        />

        {isActiveTab && !roles.isAgent && (
          <Fab
            variant="extended"
            color="primary"
            classes={{ root: classes.fabButtonRoot }}
            onClick={openAddStatementModal}
          >
            <AddIcon />
            Statement
          </Fab>
        )}
      </div>
    </>
  );
};

export default Suggestions;
