export const sp_pacingLeadsAndTraffic = {
  heading1: 'La estimulación',
  heading2: 'Dirige',
  heading3: 'Tráfico',
  campaignPaused: {
    label: 'Campaña detenida',
    info: 'Sea o no esta campaña está en pausa.'
  },
  recycleBudgetAfterItEnds: {
    label: 'Presupuesto de reciclaje después de que termine',
    info: 'Sea o no el reinicio del presupuesto después del período total (días) se ha completado.'
  },
  resetBudget: {
    label: 'Restablecer Presupuesto - 1 de cada mes',
    info: 'Si selecciona esta opción para restablecer un presupuesto de la compañía de forma automática en la primera cita de cada mes.'
  },
  totalPeriod: {
    label: 'Periodo Total (Días)',
    info: 'El número de días para ejecutar la herramienta de presupuesto para.'
  },
  pacingType: {
    label: 'Tipo de estimulación',
    info: 'El parámetro de basar el presupuesto en; Las opciones son o bien de Tráfico o plomo.'
  },
  numberofleads: {
    label: 'Número de clientes potenciales',
    info: 'Número de clientes potenciales envió a cabo durante el presupuesto actual.'
  },
  sendNotification: {
    label:
      'Enviar notificación (en el primer cliente potencial cuando comienza un presupuesto)',
    info: 'Esta notificación se envía cuando la primera ventaja es enviado en un ciclo presupuestario.'
  },
  emailAddresses: {
    label:
      'Las direcciones de correo electrónico (para enviar el mensaje cuando se envía primero el plomo)',
    info: 'Notificación de alerta de plomo primera presupuestado será enviado a estas direcciones. Si este campo se deja en blanco, la notificación será enviada al contacto principal para la empresa.'
  },
  warningMessageEmailAddresses: {
    label: 'Direcciones de correo electrónico (para mensajes de advertencia)',
    info: 'Se enviará una advertencia de notificación de primera oportunidad de venta presupuestada a estas direcciones. Si este campo se deja en blanco, la notificación se enviará al contacto principal de la empresa.'
  },
  sendingMessageEmailAddresses: {
    label:
      'Direcciones de correo electrónico (para enviar mensajes cuando el presupuesto está en pausa)',
    info: 'Se enviará una advertencia de presupuesto detenido a estas direcciones. Si este campo se deja en blanco, se enviará un mensaje de presupuesto en pausa al contacto principal de la empresa.'
  },
  hasAWarningBeenSent: {
    label:
      'Tiene una advertencia sido enviado (por primera ventaja presupuestados)',
    info: 'Una advertencia se enviará una vez sólo hasta el período presupuesto total se pone a cero.'
  },
  enableSubBudget: {
    label: 'Habilitar Sub Presupuesto (promedio de días de estimulación)',
    info: 'Permite un mini presupuesto dentro del presupuesto definido anteriormente. Ejemplo: Si el presupuesto principal se establece durante un mes, utilice esta opción para establecer un límite diario.'
  },
  totalTrafficAllowed: {
    label: 'Total de tráfico permitido',
    info: 'El número de veces que la invitación de chat se mostrará durante el período de presupuesto actual.'
  }
};
export default sp_pacingLeadsAndTraffic;
