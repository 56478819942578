import { ListGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export const LoadingComponent = ({
  loading = false,
  dataLength = 1,
  isList = true
}) => {
  const { t } = useTranslation();
  return (
    <>
      {loading ? (
        <div className="heading text-center">
          <span className="ml-2 mr-2 mb-5">
            <i className="uil uil-spinner spinner mr-2 fz-24"></i>
            <span className="fz-14 text-darkgray-2">
              {t('company.analytics.dataBeingFetched')}
            </span>
          </span>
        </div>
      ) : !dataLength ? (
        isList ? (
          <ListGroup.Item>
            <div className="heading text-center">
              {t('company.analytics.noData_Available')}
            </div>
          </ListGroup.Item>
        ) : (
          <div className="heading text-center">
            {t('company.analytics.noData_Available')}
          </div>
        )
      ) : null}
    </>
  );
};
