import storage from 'helpers/storage';

export const filterTourBasedOnPermission = tour => {
  let fileteredTour = tour;
  fileteredTour['features'] = fileteredTour['features'].filter(
    item => !item.roles || checkForRoles(item.roles)
  );
  return fileteredTour;
};

const checkForRoles = tourItemRoles => {
  let keys = Object.keys(tourItemRoles);
  let shouldHide = tourItemRoles.shouldHide;
  let result = keys.every(key =>
    verifyRoles({ type: key, value: tourItemRoles[key] })
  );
  console.log('result', result);
  return (result && shouldHide) || (!result && !shouldHide) ? false : true;
};

const verifyRoles = action => {
  let userInfo = JSON.parse(storage.getItem('Profile'));
  switch (action.type) {
    case 'userTypes': // userTypes is an array
      return action.value.includes(userInfo.UserType);
    case 'ownerCompanies': // ownerCompanies is an array
      return action.value.includes(userInfo?.OwnerCompanyKey);
    default:
      return true;
  }
};

//  change tour json structure
// {
//   "en-US":{
//     "heading": "Export",
//     "text": [
//       "Export the records that are currently present within the grid into an Excel sheet."
//     ]
//   },
//   "sp-SP":{
//     "heading": "Export",
//     "text": [
//       "Export the records that are currently present within the grid into an Excel sheet."
//     ]
//   },
//   "fr-FR":{
//     "heading": "Export",
//     "text": [
//       "Export the records that are currently present within the grid into an Excel sheet."
//     ]
//   },
//   "image": "/tour/3.5.0/export.gif",
//   "roles":{
//     "shouldHide":true,
//     "userTypes":[1,2],
//     "application":2
//     }
// },
