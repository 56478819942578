import FilterWrapper from './filter-wrapper';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { FeaturesOptionSelector } from './constants';
import './styles.scss';
import FilterPanelInfoTooltip from './filter-panel-info-tooltip';
const FeaturesComp = ({
  allowedOptions = {},
  title = '',
  onChangefilters,
  dataArray,
  setFunction,
  categoryButtons = false,
  isHours = false,
  // toolTipQuickStats = 'Total Chats, Total Leads, Total Billable Leads, Blank Chats, Active Chats',
  // toolTipGraphs = 'Features Usage, Chat Stats By, Referral Chart, Agent CTR, Chat vs Lead'
  toolTipQuickStats = 'totalChats,totalVisits,totalBillableLeads,blankChats,activeChats,ctr,lcr',
  toolTipGraphs = 'featuresUsage,chatStatsBy,chatReferrals,agentCTR,chatsLeads'
}) => {
  const checkSelected = type => {
    let checkboxCategory = dataArray.filter(da => da.selectorType === type);
    let selectedCheckboxCategory = dataArray.filter(
      da => da.selectorType === type && da.value
    );
    if (checkboxCategory.length === selectedCheckboxCategory.length)
      return true;
    else return false;
  };
  const handleOnChange = (e, ind) => {
    let temp = dataArray.map((t, index) => {
      let obj = { ...t };
      if (index === ind) obj.value = e.target.checked;
      return obj;
    });
    setFunction(temp);
    onChangefilters(temp.filter(t => t.value));
  };
  const handleOnCatChange = type => {
    let value = !checkSelected(type);
    let data = dataArray.map(da =>
      da.selectorType === type
        ? {
            ...da,
            value
          }
        : {
            ...da
          }
    );
    setFunction(data);
    onChangefilters(data.filter(t => t.value));
  };

  const onFilterClear = (pushBackData = true) => {
    let temp = dataArray.map(t => {
      return { ...t, value: false };
    });
    setFunction(temp);
    onChangefilters([]);
  };

  return (
    <FilterWrapper
      title={title}
      onFilterClear={onFilterClear}
      tooltipLabel={
        <FilterPanelInfoTooltip
          toolTipQuickStats={toolTipQuickStats}
          toolTipGraphs={toolTipGraphs}
        />
      }
    >
      <div className="w-100">
        <div>
          {categoryButtons &&
            FeaturesOptionSelector.map((element, index) => {
              if (dataArray.some(da => da.selectorType == element.key))
                return (
                  <Button
                    key={index}
                    className="btn-md btn-flex custom-btn"
                    type="button"
                    variant={
                      checkSelected(element.key) ? 'primary' : 'lightgray-3'
                    }
                    onClick={e => {
                      e.preventDefault();
                      handleOnCatChange(element.key);
                    }}
                  >
                    <span>{element.label}</span>
                  </Button>
                );
            })}
        </div>
      </div>
      <div className={!isHours ? 'features-container' : 'features-hours'}>
        {dataArray.length ? (
          dataArray.map(
            (feature, index) => (
              // allowedOptions[feature.name] ? (
              <Form.Check
                key={index}
                custom
                type="checkbox"
                name="checkbox"
                id={feature.key + index}
                label={feature.key}
                onChange={e => handleOnChange(e, index)}
                checked={feature.value}
                style={{
                  paddingTop: '10px',
                  fontSize: '0.8125rem',
                  fontWeight: '600'
                }}
              />
            )
            // ) : null
          )
        ) : (
          <p>No features are available. </p>
        )}
      </div>
    </FilterWrapper>
  );
};

export default FeaturesComp;
