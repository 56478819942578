import FilterWrapper from './filter-wrapper';
// import { UilPlus, UilTrashAlt } from '@iconscout/react-unicons';
// import { Form } from 'react-bootstrap';
// import SimpleSelectBox from '../../../../helpers/ui-controls/ui-components/SimpleSelectBox';
import AutoCompeleteComp from './auto-complete';
import FilterPanelInfoTooltip from './filter-panel-info-tooltip';

const CustomAutoCompleteFilter = ({
  title = '',
  setFunction,
  onChangefilters = e => {
    // console.log('logged', e);
  },
  variant = 'outlined',
  handleTextChange,
  loading,
  selected,
  liStyles = '',
  options = [
    { value: 0, text: 'None', name: 'None' },
    { value: 3, text: 'Vendor Max', name: 'vendormax' },
    { value: 2, text: 'Frozen Mountain', name: 'frozenmountain' },
    { value: 13, text: 'Reach Local', name: 'reachlocal' },
    { value: 2054, text: 'Abdul Samad', name: 'Abdul Samad' }
  ],
  toolTipQuickStats = 'Apply to All Quick Stats',
  toolTipGraphs = 'Apply to All Charts',
  isFilterWrapper = true,
  styles = {},
  isMultiSelectEnable = true,
  editable,
  error,
  handleBlur,
  name,
  freeSolo,
  placeholder,

  //for textarea prop
  multiline = false,
  maxRows,
  minRows,
  inputClass,
  errorClass
}) => {
  const onFilterClear = () => {
    let temp = [];
    setFunction(temp);
    onChangefilters([]);
  };
  const handleChange = (data, e) => {
    setFunction(data);
    onChangefilters(data);
  };

  return isFilterWrapper ? (
    <FilterWrapper
      title={title}
      onFilterClear={onFilterClear}
      tooltipLabel={
        <FilterPanelInfoTooltip
          toolTipQuickStats={toolTipQuickStats}
          toolTipGraphs={toolTipGraphs}
        />
      }
    >
      <div className="items-container">
        <AutoCompeleteComp
          handleChange={handleChange}
          handleTextChange={handleTextChange}
          options={options}
          loading={loading}
          selected={selected || []}
          variant={variant}
          styles={styles}
          isMultiSelectEnable={isMultiSelectEnable}
          handleBlur={handleBlur}
          name={name}
          error={error}
        />
      </div>
    </FilterWrapper>
  ) : (
    <li className="custom-label-styles" style={liStyles}>
      <AutoCompeleteComp
        title={title}
        handleChange={handleChange}
        handleTextChange={handleTextChange}
        options={options}
        loading={loading}
        selected={selected}
        variant={variant}
        styles={styles}
        editable={editable}
        isMultiSelectEnable={isMultiSelectEnable}
        handleBlur={handleBlur}
        name={name}
        error={error}
        freeSolo={freeSolo}
        placeholder={placeholder}
        multiline={multiline}
        maxRows={maxRows}
        minRows={minRows}
        inputClass={inputClass}
        errorClass={errorClass}
      />
    </li>
  );
};

export default CustomAutoCompleteFilter;
