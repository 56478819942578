import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { Row, Col, Card } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { useSelector, useDispatch } from 'react-redux';

import { toast } from 'react-toastify';
import UnsentLeads from '../UnsentLeadChats';
import ChatDetail from '../ChatDetail';
import History from '../History';

import {
  getActiveChatList,
  getAllChatList,
  getIncomingChatList,
  getSelectedChatId,
  setEnableNotificationSound,
  setSelectedChat,
  setUnreadMessage,
  saveChatMessage,
  changeMessagingDashboardStatusAsync,
  getUnsentLeads,
  getChatRoomData,
  getShelvedChatList,
  getEnableNotificationSound,
  setMessagesRecived,
  onTransferChatUpdate,
  removeFromIncomingSection,
  isActiveChatUnsubscribed,
  onChatReject,
  getAssignedGroups,
  addRejectLoadingInIncomingChat,
  addRejectedChatId,
  setChatStack
} from 'features/messagingChats/messagingChats';
import storage from 'helpers/storage';
import ChatList from '../ChatList';
import './Chats.scss';
import {
  resetLeadFormUserCredentials,
  setLeadFormReason,
  setLeadFormUserCredentials
} from 'features/lead/leadSlice';
import {
  selectChat,
  toggleChatDetailsRightPane
} from 'features/chat/chatSlice';
import { errors, success } from 'helpers/variables';
import { selectLogin, setProfileToState } from 'features/auth/authSlice';
import Header from './Header';
import { ChatsType, UnsentChatList } from './Constants';
import { selectRoles } from 'features/roles/rolesSlice';
import ScrollBar from 'components/Scrollbars';
import { Enums } from 'helpers/dropdown-enums';
import {
  RemoveChannelsFromRTCCleint,
  UnSubscribeChat
} from 'app/communication/services/chat/pub_sub_chat';
import { chatCallbacks } from 'app/communication/services/chat/callbacks/chat_default_callbacks';
import Initiate_Chat from 'app/communication/services/chat/initiate_chat';
import BottomNavigationComponent from '../BottomNavigationComponent';
import { logIt } from 'helpers/logger';

const Chats = ({
  setToggleChatDetail,
  toggleChatDetail,
  toggleActiveChats,
  setToggleActiveChats,
  setToggleChatSuggestion,
  setDefaultSuggestionTab,
  defaultMessageText
}) => {
  // const { toggle, incomingChatSound, clearSound } = useNotification();
  let enableNotificationSound = useSelector(getEnableNotificationSound);
  const [statusLoading, setStatusLoading] = useState(false);
  const [notifiySoundEnable, setNotifiySoundEnable] = useState(
    enableNotificationSound
  );

  const dispatch = useDispatch();

  let { profile } = useSelector(selectLogin);
  let { roles } = useSelector(selectRoles);
  let chats = {
    incomingChatList: useSelector(getIncomingChatList),
    activeChatList: useSelector(getActiveChatList),
    allChatList: useSelector(getAllChatList),
    shelvedChatList: useSelector(getShelvedChatList),
    unsentLeadList: useSelector(getUnsentLeads)
  };
  const [selectedBottomMenu, setSelectedBottomMenu] = useState(0);

  const onChangeNavMenu = value => {
    setSelectedBottomMenu(value);
  };

  let getActiveChatUnsubscribedStatus = useSelector(isActiveChatUnsubscribed);
  let selectedChatId = useSelector(getSelectedChatId);
  const user = useSelector(selectLogin);

  // auto subscribe active chats if they are not subscribed after refresh
  const _onSuccessMessageArrived = data => {
    data['preventNotification'] = true;
    _onMessageArrived(data);
  };
  const _onMessageArrived = data => {
    if (data.userId !== user?.profile?.UserID && !data.preventNotification) {
      reviceMsg();
      dispatch(setUnreadMessage(data.chatId));
    }

    // only run below statement if chat-detail section is opened
    if (
      data.chatId === selectedChatId &&
      toggleChatDetail &&
      data.userId === Enums.ChatMessageType.Visitor
    ) {
      dispatch(setLeadFormUserCredentials(data.text));
      dispatch(setLeadFormReason(data.text));
    }

    dispatch(saveChatMessage(data));
  };

  useEffect(() => {
    if (chats.activeChatList.length) {
      // re-subscribe active chats after reload
      chats.activeChatList.map(chat => {
        if (!getActiveChatUnsubscribedStatus(chat.getId())) {
          let callbacks = chatCallbacks({
            _onSuccessMessageArrived,
            _onMessageArrived,
            chatId: chat.getId()
          });
          Initiate_Chat({
            chatId: chat.getId(),
            companyId: chat.getCompanyId(),
            callbacks
          });
        }
      });
    }
  }, [chats.activeChatList.length]);

  const onChatClickHandler = roomType => chat => {
    let isFromIncomingSection =
      roomType === Enums.RoomType.IncomingChat ? true : false;
    if (!chat.getId) {
      if (chat.chatId !== selectedChatId) {
        dispatch(getChatRoomData(chat.chatId)).then(res => {
          if (res && typeof res !== 'string') {
            if ('response' in chat) {
              dispatch(removeFromIncomingSection(chat.chatId));
            }
            setChatRoomForSelectedChat({ ...res, isFromIncomingSection });
          }
        });
      }
    } else {
      if (isFromIncomingSection) {
        let isRouted = window.RTC.subscribedChannels.some(item =>
          item.includes(chat.getId())
        );
        if (isRouted) {
          //unsubscribe chat;
          UnSubscribeChat(chat.getId());
          // RemoveChannelsFromRTCCleint(chat.getId());
        }
      }

      setChatRoomForSelectedChat({ ...chat, isFromIncomingSection });
    }
  };

  const setChatRoomForSelectedChat = chat => {
    dispatch(setLeadFormUserCredentials({ reason: '' }));
    if (chat?.getId() !== selectedChatId) {
      dispatch(
        toggleChatDetailsRightPane({
          show: false
        })
      );
      dispatch(resetLeadFormUserCredentials());
      dispatch(setChatStack(chat.getId()));
    }
    dispatch(setSelectedChat(chat));
    setToggleChatDetail(true);
    if (!isMobile) {
      setToggleChatSuggestion(true);
    }
    if (isMobile) {
      setToggleActiveChats(true);
    }
  };

  const setNotificationSoundEnable = status => {
    setNotifiySoundEnable(status);
    dispatch(setEnableNotificationSound(status));
    storage.setItem('enableSound', status);
  };

  const reviceMsg = () => {
    dispatch(setMessagesRecived(true));
    setTimeout(() => {
      dispatch(setMessagesRecived(false));
    }, 1000);
  };

  const toggleStatus = async e => {
    let value = e.target.checked;
    setStatusLoading(true);
    let statusChanged = await dispatch(
      changeMessagingDashboardStatusAsync(+value)
    );

    if (statusChanged) {
      toast.success(success.statusChange);
      setStatusLoading(false);
      let profile = JSON.parse(storage.getItem('Profile'));
      profile['Active'] = value;
      storage.setItem('Profile', profile);
      dispatch(setProfileToState());
    } else {
      toast.error(errors.failure);
      setStatusLoading(false);
    }
  };

  // on Transfer chat click
  // response: 1/0 as = accept/ignore
  const selectTranferedChat = response => chat => {
    // let response = ApexChat.ChatTransferResponse.rejected;
    let data = {
      chatId: chat?.getId(),
      userId: profile?.UserID,
      message: 'test',
      response
    };
    dispatch(onTransferChatUpdate(data)).then(res => {
      if (typeof res !== 'string' && res) {
        if (response) {
          let chatHandler = onChatClickHandler(Enums.RoomType.IncomingChat);
          chatHandler(data);
          // dispatch(removeFromIncomingSection(data.chatId));
        } else {
          dispatch(removeFromIncomingSection(data.chatId));
        }
        // TODO: remove from incoming section and if accepted then add to active section
      }
    });
  };

  // on Reject chat click
  const selectRejectChat = chat => {
    let payload = {
      chatId: chat?.getId()
    };
    // add loading on reject click
    dispatch(
      addRejectLoadingInIncomingChat({ id: payload.chatId, loading: true })
    );

    // get assignedGroup in order to get activeAgentsCount
    // if activeAgentsCount is greater than 1, then user can reject chat else not
    getAssignedGroups()
      .then(res => {
        if (res && res.data.success && res.data.data) {
          let groups = res.data.data;
          let canReject = groups.every(item => item.activeAgentsCount > 1);
          logIt('log', 'canReject--', canReject);
          // if activeAgentsCount is greater than 1 call reject api
          // else show warning that your the only active user
          if (canReject) {
            dispatch(onChatReject(payload.chatId))
              .then(res => {
                if (res) {
                  toast.success(success.chatRejected);
                  dispatch(addRejectedChatId(payload.chatId));
                  dispatch(removeFromIncomingSection(payload.chatId));
                }
              })
              .catch(err => {
                console.log('onError', err);
                toast.error(errors.failure);
                dispatch(
                  addRejectLoadingInIncomingChat({
                    id: payload.chatId,
                    loading: false
                  })
                );
              });
          } else {
            toast.warn(
              'You are the last active user for one of your groups. You cannot reject the chat.'
            );
            dispatch(
              addRejectLoadingInIncomingChat({
                id: payload.chatId,
                loading: false
              })
            );
          }
        }
      })
      .catch(err => {
        console.log('onError', err);
        toast.error(errors.failure);
        dispatch(
          addRejectLoadingInIncomingChat({
            id: payload.chatId,
            loading: false
          })
        );
      });
  };

  //History chat click handler
  // remove chatId from RTC list to subscribe again in order
  // to get messages again after closed previously
  const historyChatClickHandler = chat => {
    RemoveChannelsFromRTCCleint(chat.chatId);
    onChatClickHandler(6)(chat);
  };

  return (
    <>
      <Col className="mb-3 col-chat-wrap">
        <Card border="white" className="card-rounded all-chat-section">
          <Row className="all-chat-row no-gutters">
            {!toggleActiveChats && (
              <div className="chat-list-wrapper border-right px-8">
                <Header
                  totalCount={
                    chats.activeChatList.length +
                    chats.allChatList.length +
                    chats.incomingChatList.length +
                    chats.shelvedChatList.length
                  }
                  notifiySoundEnable={notifiySoundEnable}
                  setNotificationSoundEnable={setNotificationSoundEnable}
                  toggleStatus={toggleStatus}
                  status={profile.Active}
                  statusLoading={statusLoading}
                  isAgent={roles.isAgent}
                />
                <ScrollBar
                  horizontalScroll={false}
                  style={{
                    // height: 'calc(100vh - 121px)'
                    height: 'calc(100% - 102px)'
                    // maxHeight: '300px'
                  }}
                >
                  {ChatsType.map(
                    type =>
                      (type.key !== 'SHL' ||
                        (type.key === 'SHL' && roles.isAgent)) && (
                        <Accordion
                          key={type.key}
                          className="messaging-chats"
                          defaultActiveKey={type.defaultActiveKey}
                          flush="true"
                        >
                          <ChatList
                            title={type.title}
                            chats={chats[type.chats]}
                            showActionBtns={type.showActionBtns}
                            onChatClickHandler={onChatClickHandler(
                              type.roomType
                            )}
                            onAccept={selectTranferedChat(1)}
                            onIgnore={selectTranferedChat(0)}
                            onReject={selectRejectChat}
                            eventKey={type.eventKey}
                            defaultActiveKey={type.defaultActiveKey}
                            roomType={type.roomType}
                          />
                        </Accordion>
                      )
                  )}
                </ScrollBar>
                {/* <Accordion
                  className="messaging-chats"
                  defaultActiveKey={UnsentChatList.defaultActiveKey}
                  flush="true"
                >
                  <UnsentLeads
                    title={UnsentChatList.title}
                    chats={chats[UnsentChatList.chats]}
                    showActionBtns={UnsentChatList.showActionBtns}
                    onChatClickHandler={onChatClickHandler(
                      UnsentChatList.roomType
                    )}
                    eventKey={UnsentChatList.eventKey}
                  />
                </Accordion> */}

                <div
                  className="messaging-chats bottom-sheet-container"
                  style={{
                    marginLeft: '-9px',
                    marginRight: '-8px'
                  }}
                >
                  <BottomNavigationComponent
                    onChangeNavMenu={value => onChangeNavMenu(value)}
                    unSentLeadCount={chats[UnsentChatList.chats].length}
                  >
                    {selectedBottomMenu == 0 ? (
                      <UnsentLeads
                        title={UnsentChatList.title}
                        chats={chats[UnsentChatList.chats]}
                        showActionBtns={UnsentChatList.showActionBtns}
                        onChatClickHandler={onChatClickHandler(
                          UnsentChatList.roomType
                        )}
                        eventKey={UnsentChatList.eventKey}
                      />
                    ) : (
                      <History onChatClickHandler={historyChatClickHandler} />
                    )}
                  </BottomNavigationComponent>
                </div>
              </div>
            )}
            {(toggleChatDetail || selectedChatId) && (
              <Col className="card-rounded chat-details">
                <ChatDetail
                  setToggleChatDetail={setToggleChatDetail}
                  setToggleActiveChats={setToggleActiveChats}
                  setToggleChatSuggestion={setToggleChatSuggestion}
                  setDefaultSuggestionTab={setDefaultSuggestionTab}
                  defaultMessageText={defaultMessageText}
                  notifiySound={reviceMsg}
                  _onSuccessMessageArrived={_onSuccessMessageArrived}
                  _onMessageArrived={_onMessageArrived}
                />
              </Col>
            )}
          </Row>
        </Card>
      </Col>
    </>
  );
};

export default Chats;
