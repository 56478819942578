import 'static/scss/main.scss';
import 'intro.js/introjs.css';
import React, { Fragment, useEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import Pages from 'layouts/Auth';
import PortalLayout from 'layouts/Portal';
import { ToastContainer, Slide } from 'react-toastify';
import NotFound from 'pages/errors/404';
import ProtectedRoute from 'routes/ProtectedRoute';
import authRoutes from 'routes/authRoutes';
import { useDispatch, useSelector } from 'react-redux';
import { setProfileToState } from 'features/auth/authSlice';
import { duration } from 'helpers/variables';
import { getCookie } from 'helpers/generic.methods';
import './i18n';
import { useTranslation } from 'react-i18next';
import { selectLangauge } from 'features/general/generalSlice';
import CacheBuster from '../CacheBuster';
import portalRoutes from 'routes/portalRoutes';
import { selectRoles } from 'features/roles/rolesSlice';
import storage from 'helpers/storage';
import generalRoutes from 'routes/generalRoutes';
const App = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const language = useSelector(selectLangauge);
  const isAuthenticated = !!getCookie('Auth');
  const { roles } = useSelector(selectRoles);
  const history = useHistory();
  let _hostKey = JSON.parse(storage.getItem('AppMetaInfo'))?.['host'];

  /** set the profile object from local storage to redux store state */
  useEffect(() => {
    dispatch(setProfileToState());
  }, [dispatch, i18n, language]);

  /* Map all the auth related routes */
  const authPagesRoutes = authRoutes.map((route, key) => {
    return (
      <Route
        path={route.path}
        exact={route.exact}
        component={
          route.path === '/not-found' || route.path === '/access-denied'
            ? route.component
            : props => <Pages childComp={route.component} {...props} />
        }
        key={key}
      />
    );
  });

  const checkRoles = (path, roles) => {
    if (roles && Object.keys(roles).length) {
      let currentRoute = [...portalRoutes, ...generalRoutes].filter(
        r => r.path === path
      );
      if (currentRoute.length) {
        let key = currentRoute[0].name;
        let secondKey = currentRoute[0].secondKey || 'view';
        if (roles[key][secondKey]) return true;
        else return false;
      }
    }
    return true;
  };
  /* Map all the portal related protected route with auth guard */
  return (
    <CacheBuster key={1}>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          // You can decide how and when you want to force reload
          refreshCacheAndReload();
        }
        return (
          <Fragment>
            <Switch>
              {authPagesRoutes}
              {!checkRoles(history.location.pathname, roles) && (
                <Redirect to={'/access-denied'} />
              )}
              <ProtectedRoute
                path={'/'}
                component={PortalLayout}
                key={'parent-' + 0}
              />

              {/* 404 route */}

              {isAuthenticated ? (
                <Route component={NotFound} />
              ) : (
                <Redirect to={_hostKey ? `/login/${_hostKey}` : '/login'} />
              )}
            </Switch>
            <ToastContainer
              hideProgressBar={true}
              autoClose={duration.timeout5s || 5000}
              transition={Slide}
            />
          </Fragment>
        );
      }}
    </CacheBuster>
  );
};

export default App;
