import BasicDetails from 'pages/portal/company/index/basic-details';
import Tracking from 'pages/portal/company/index/tracking';
import ContactInformation from 'pages/portal/company/index/contact-information';
import Mobile from 'pages/portal/company/index/mobile';
import LeadEmails from 'pages/portal/company/index/lead-emails';
import CRM from 'pages/portal/company/index/crm';
import Facebook from 'pages/portal/company/index/facebook';
import History from 'pages/portal/company/index/history';
import Budget from 'pages/portal/company/index/budget';
import UilBuilding from '@iconscout/react-unicons/icons/uil-suitcase';
import UILSuitcas from '@iconscout/react-unicons/icons/uil-suitcase';
import UILPlug from '@iconscout/react-unicons/icons/uil-plug';
import UILDollar from '@iconscout/react-unicons/icons/uil-dollar-alt';
import HoursCallConnect from 'pages/portal/company/index/call-connect';
import UILSetting from '@iconscout/react-unicons/icons/uil-setting';

const base = {
  general: '/portal/company/:id',
  budget: '/portal/company/:id',
  setting: '/portal/company/:id',
  integrations: '/portal/company/:id'
};

const companyRoutes = [
  {
    name: 'Companys',
    icon: <UilBuilding />,
    exact: true,
    collapsable: true,
    subNav: [
      {
        path: base.general,
        name: 'General',
        icon: <UILSuitcas />,
        links: [
          {
            name: 'Basic Details',
            slug: 'basic-details',
            child: BasicDetails,
            exact: true
          },
          {
            name: 'Contact Information',
            slug: 'contact-information',
            child: ContactInformation,
            exact: true
          },
          {
            name: 'Lead Emails',
            slug: 'lead-emails',
            child: LeadEmails,
            exact: true
          },
          {
            name: 'Hours/Call Connect',
            slug: 'call-connect',
            child: HoursCallConnect,
            exact: true
          }
        ]
      },
      {
        //the name should not be same as links first route name
        name: 'Budget',
        icon: <UILDollar />,
        links: [
          {
            name: 'Pacing, Leads and Traffic',
            slug: 'budget',
            child: Budget,
            exact: true
          },
          {
            name: 'History',
            slug: 'history',
            child: History,
            exact: true
          }
        ]
      },
      {
        //the name should not be same as links first route name
        name: 'Settings',
        icon: <UILSetting />,
        links: [
          {
            name: 'Mobile',
            slug: 'mobile',
            child: Mobile,
            exact: true
          }
        ]
      },
      {
        //the name should not be same as links first route name
        name: 'Integrations',
        icon: <UILPlug />,
        links: [
          {
            //the slug should be the same as route endpoint
            name: 'CRM',
            slug: 'crm',
            child: CRM,
            exact: true
          },
          {
            //the slug should be the same as route endpoint
            name: 'Facebook',
            slug: 'facebook',
            child: Facebook,
            exact: true
          },
          {
            //the slug should be the same as route endpoint
            name: 'Tracking',
            slug: 'tracking',
            child: Tracking,
            exact: true
          }
        ]
      }
    ]
  }
];

export default companyRoutes;
