import React, { useRef, useState } from 'react';
import { Button, Overlay, Form } from 'react-bootstrap';
import { UilGrids, UilFileExport, UilImport } from '@iconscout/react-unicons';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { MenuItem } from '@material-ui/core';
import { JSONToCSVFileConvertor } from 'helpers/generic.methods';
import moment from 'moment';
import './dynamicColumns.scss';

const initialColumns = (data = []) => {
  let obj = {};
  data.forEach(val => {
    obj[val.name] = val.visible ? true : false;
  });
  return obj;
};
const DynamicColumns = ({
  menuOptions,
  handleChange,
  hideColumns = ['actions'],
  selectedFilters,
  isExport = true,
  endPointAPI,
  fileNamePrefix = ''
}) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showExport, setShowExport] = useState(false);
  const [options, setOptions] = useState(initialColumns(menuOptions));
  const target = useRef(null);
  const targetExport = useRef(null);
  const { t } = useTranslation();

  const handleOnChange = optionKey => {
    let temp = { ...options };
    temp[optionKey] = !temp[optionKey];
    setOptions(temp);
  };

  const applyColumns = () => {
    handleChange(options);
    setShow(false);
  };

  const handleSelectAll = check => {
    let temp = { ...options };
    Object.keys(temp).forEach(key => {
      if (!hideColumns.includes(key)) temp[key] = check;
    });
    setOptions(temp);
  };

  const checkSelectAllOrNone = (condition = 'every') => {
    return Object.keys(options)
      .filter(key => !hideColumns.includes(key))
      [condition](val => options[val]);
  };

  const onExport = (isCurrentPage = false) => {
    let payload = { ...selectedFilters };
    if (!isCurrentPage) {
      delete payload['limit'];
    }
    GetDataFromAPI(payload);
  };

  const GetDataFromAPI = payload => {
    try {
      setLoading(true);
      endPointAPI(payload).then(response => {
        JSONToCSVFileConvertor(
          response?.data?.data,
          `${fileNamePrefix}-export-${moment().format('YYYY-MM-DD')}`,
          menuOptions.filter(dc => !!options[dc.name])
        );
        setLoading(false);
      });
    } catch (error) {
      toast.error(error || error.message);
    }
  };

  return (
    <>
      {/* dynamic column */}
      <Button
        className="btn-sm btn-flex"
        variant="lightgray-3"
        ref={target}
        onClick={() => setShow(!show)}
      >
        <UilGrids size="30" />
      </Button>

      <Overlay
        target={target.current}
        show={show}
        placement="bottom"
        onHide={() => setShow(false)}
        rootClose={true}
      >
        {({ placement, arrowProps, show: _show, popper, ...props }) => (
          <div
            {...props}
            style={props.style}
            className="dynamic-col-container popover-style"
          >
            <Form.Check
              custom
              type="checkbox"
              name="checkbox"
              id="selectAllOptions"
              label={t('company.analytics.selectAll')}
              onChange={e => handleSelectAll(e.target.checked)}
              checked={checkSelectAllOrNone('every')}
              className="check-box-all"
            />
            {menuOptions.map((mo, index) =>
              !hideColumns.includes(mo.name) ? (
                <Form.Check
                  key={index}
                  custom
                  type="checkbox"
                  name="checkbox"
                  id={mo.name + index}
                  label={mo.header}
                  onChange={e => handleOnChange(mo.name)}
                  checked={options[mo.name]}
                  className="check-box"
                />
              ) : null
            )}
            <Button
              className="btn-apply-filter"
              variant="primary"
              title={t('company.analytics.filters')}
              disabled={!checkSelectAllOrNone('some')}
              onClick={applyColumns}
            >
              {t('company.analytics.apply')}
            </Button>
          </div>
        )}
      </Overlay>

      {/* End */}

      {/* export options */}

      {isExport ? (
        <Button
          className=" btn-sm btn-flex"
          variant="lightgray-3"
          ref={targetExport}
          onClick={() => setShowExport(!showExport)}
          label="Export"
          disabled={loading}
        >
          {loading ? (
            <i
              className="uil uil-spinner spinner"
              style={{ fontSize: '16px' }}
            ></i>
          ) : (
            <UilFileExport size="30" />
          )}
        </Button>
      ) : null}

      <Overlay
        target={targetExport.current}
        show={showExport}
        placement="bottom"
        onHide={() => setShowExport(false)}
        rootClose={true}
      >
        {({ placement, arrowProps, show: _show, popper, ...props }) => (
          <div
            {...props}
            style={props.style}
            className="export-container cursor-pointer popover-style"
          >
            <MenuItem className="menu-option" onClick={e => onExport(false)}>
              <UilImport size="15" className="mr-1" />
              Export All
            </MenuItem>
            <MenuItem className="menu-option" onClick={e => onExport(true)}>
              <UilFileExport size="15" className="mr-1" />
              Export Page
            </MenuItem>
          </div>
        )}
      </Overlay>
    </>
  );
};

export default DynamicColumns;
