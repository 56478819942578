import FilterWrapper from './filter-wrapper';
import Button from 'react-bootstrap/Button';
import { camelCaseStringToNormalText } from 'helpers/generic.methods';
import FilterPanelInfoTooltip from './filter-panel-info-tooltip';
import { useTranslation } from 'react-i18next';

const UserType = ({
  allowedOptions = {},
  title = '',
  onChangefilters,
  setFunction,
  dataArray,
  toolTipQuickStats,
  toolTipGraphs
}) => {
  const { t } = useTranslation();
  const handleOnChange = (val, ind) => {
    let temp = [...dataArray];
    temp[ind].value = !val;
    setFunction(temp);
    onChangefilters(temp.filter(t => t.value));
  };

  const onFilterClear = (pushBackData = true) => {
    let temp = dataArray.map(t => {
      return { ...t, value: false };
    });
    setFunction(temp);
    onChangefilters([]);
  };

  return (
    <FilterWrapper
      title={title}
      onFilterClear={onFilterClear}
      tooltipLabel={
        <FilterPanelInfoTooltip
          toolTipQuickStats={toolTipQuickStats}
          toolTipGraphs={toolTipGraphs}
        />
      }
    >
      <div className="w-100">
        {dataArray.length ? (
          dataArray.map(
            (device, index) => (
              // allowedOptions[device.name] ? (
              <Button
                key={index}
                className={
                  // 'btn-sm btn-flex'
                  'btn-md btn-flex custom-btn'
                }
                type="button"
                variant={device.value ? 'primary' : 'lightgray-3'}
                onClick={e => {
                  e.preventDefault();
                  handleOnChange(device.value, index);
                }}
              >
                {/* <UILEdit /> */}
                {device.icon || null}
                <span>
                  {camelCaseStringToNormalText(device.name, 'heading')}
                </span>
              </Button>
            )
            // ) : null
          )
        ) : (
          <p>
            <p>{t('company.analytics.noUsersAvailable')}</p>
          </p>
        )}
      </div>
    </FilterWrapper>
  );
};

export default UserType;
