import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { getAllMyReportList } from 'features/report/reportsSlice';
import { Container, Row, Col, Button } from 'react-bootstrap';
import '@inovua/reactdatagrid-community/index.css';
import { getFilterValue, myReportTableConfig } from 'utils/tableConfig';
import HeaderToExistingPortal from 'components/headerToExistingPortal';
import { selectRoles } from 'features/roles/rolesSlice';
import { useTranslation } from 'react-i18next';
import { GridWrapper } from '../../../../components/GridWrapper';
import { UilEye, UilTrashAlt, UilCopy } from '@iconscout/react-unicons';

const MyReports = ({
  setDataLimit,
  setTotalRecords,
  setLoading,
  loading,
  selectedReports,
  setOpenColapse,
  selectedData,
  updateCompaniesAndSaveToLS,
  updateTabAndSaveToLS,
  totalRecords,
  handleDelete,
  handleDuplicate,
  vissibleColumns,
  handleSelect,
  refetch,
  setRefetch,
  setFilters
}) => {
  const ActionButtons = ({ value }) => (
    <>
      <Button
        className="btn-sm btn-flex mr-1"
        variant="lightgray-3"
        // onClick={e => handleView(value)}
        style={{ minHeight: '32px' }}
      >
        <UilEye className="mr-1" />
        View
      </Button>
      <Button
        className="btn-sm btn-flex mr-1"
        onClick={e => handleDuplicate(e, value)}
        variant="lightgray-3"
        style={{ minHeight: '32px' }}
      >
        <UilCopy />
      </Button>
      <Button
        className="btn-sm btn-flex "
        onClick={e => handleDelete(e, value)}
        variant="lightgray-3"
        style={{ minHeight: '32px' }}
      >
        <UilTrashAlt />
      </Button>
    </>
  );

  const { t } = useTranslation();
  const { roles } = useSelector(selectRoles);
  const permissions = (roles && roles.switch) || {};

  const getDateFilterValue = data => {
    let updatedValue;
    if (data.name === 'createdOn' && data.operator === 'before') {
      updatedValue = moment(data.value).local();
    } else if (data.name === 'createdOn' && data.operator === 'after') {
      updatedValue = moment().local();
    } else {
      updatedValue = data.value;
    }
    return updatedValue;
  };

  const loadData = ({ skip, limit, sortInfo, filterValue }) => {
    setLoadingMessage('Loading ...');
    const updatedValue = filterValue
      .filter(v => v.value !== '')
      .map(v => ({
        propertyName: v.name,
        propertyValue1: getFilterValue(v),
        propertyValue2:
          v.name === 'createdOn' &&
          (v.operator === 'before' || v.operator === 'after')
            ? getDateFilterValue(v)
            : '',
        propertyValue3: [],
        filterDataType: 2,
        comparison: 2
      }));
    let payload = {
      start: skip,
      limit: limit,
      filter: JSON.stringify(updatedValue),
      dir: !sortInfo ? 'DESC' : sortInfo.dir === 1 ? 'ASC' : 'DESC',
      sort: sortInfo ? sortInfo.name : 'createdOn'
    };

    setFilters(payload);
    return getAllMyReportList(payload).then(response => {
      if (response.data.totalSize > 20) {
        setDataLimit(skip + response.data.data.length);
      } else {
        setDataLimit(response.data.data.length);
      }
      const totalCount = response.data.totalSize;
      setTotalRecords(totalCount);
      setRefetch(false);
      return { data: response.data.data, count: parseInt(totalCount) };
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dataSource = useCallback(loadData, []);

  const [loadingMessage, setLoadingMessage] = useState('Loading ...');

  const renderColumnContextMenu = useCallback((menuProps, { cellProps }) => {
    menuProps.items = menuProps.items.splice(0, 8);
  }, []);

  return (
    <>
      <Container fluid>
        <Row>
          <Col className={`report-section-wrapper ${loading && 'blux-text'}`}>
            {permissions.allowPortal ? (
              <HeaderToExistingPortal
                msg={t('company.general.oldPortalTag')}
                borderRadius={'0px'}
              />
            ) : null}
            <div className="mobile_view">
              <GridWrapper
                refetch={refetch}
                vissibleColumns={vissibleColumns}
                columns={myReportTableConfig(ActionButtons)}
                gridClass={
                  permissions.allowPortal ? 'myreports-table-container' : ''
                }
                totalRecords={totalRecords}
                dataSource={dataSource}
                setLoading={setLoading}
                loadingMessage={loadingMessage}
                renderColumnContextMenu={renderColumnContextMenu}
                selectedData={selectedData}
                handleSelect={handleSelect}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default MyReports;
