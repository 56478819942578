import { useState, useEffect } from 'react';
import UilPlug from '@iconscout/react-unicons/icons/uil-plug';
import { Row, Col } from 'react-bootstrap';
import Collapseable from 'components/Collapseable';
import { TabComponent, TabNav, TabsContainer } from 'components/TabsComponent';
import Header from './header';
import './style.scss';
import IntegrateWidgetCode from './subSections/integrateWidgetCode';

import {
  getSelectedCompanies,
  setSelectedCompaniesToState
} from 'features/widget-editor/widgetEditorSlice';
import { useSelector, useDispatch } from 'react-redux';
import ScrollBar from 'components/Scrollbars';
import useViewport from 'helpers/viewport-hook';
import storage from 'helpers/storage';
import { useTranslation } from 'react-i18next';

const WidgetEditor = ({ companiesCount, handleCollipseable }) => {
  const [selectedTab, setSelectedTab] = useState(null);
  const selectedCompanies = useSelector(getSelectedCompanies);

  const dispatch = useDispatch();
  const screen = useViewport();
  const { t } = useTranslation();

  useEffect(() => {
    let data = JSON.parse(storage.getItem('companies-widgetSetting')) || [];
    if (!selectedCompanies.length && !data.length && companiesCount > 1) {
      handleCollipseable(true);
    }
  }, [companiesCount]);

  // Append Close button to TabTitle
  const tabTitle = (index, comapny) => {
    return (
      <TabNav
        totalTabs={selectedCompanies.length}
        className="tab-heading"
        index={index}
        id={comapny.id}
        title={comapny.companyName}
        textLimit={12}
        removeTab={companiesCount && companiesCount !== 1 && removeCompany}
      />
    );
  };

  const clearAll = () => {
    console.log('clearAll');
    setSelectedTab(null);
    dispatch(setSelectedCompaniesToState([]));
  };

  // Remove  Tab From Selected Chats
  const removeCompany = (index, id, nextIndex) => {
    let temp = [...selectedCompanies];
    temp.splice(index, 1);
    if (nextIndex || nextIndex == 0)
      setSelectedTab(Number(selectedCompanies[nextIndex].id));
    dispatch(setSelectedCompaniesToState(temp));

    if (nextIndex === 0 && index === 0) {
      setSelectedTab(null);
    }
  };
  const handleTabSelect = (tabKey, ind) => {
    if (selectedTab !== tabKey) {
      setSelectedTab(Number(tabKey));
    }
  };

  return (
    <>
      <Row className="h-100">
        <Col md={12} className={'p-0 widget-editor-container'}>
          <div className="widget-editor-section">
            <Header
              openCompaniesPanel={() => handleCollipseable(true)}
              isMobile={screen.xs}
              showCompaniesButton={companiesCount > 1 ? true : false}
            />
            <div className="widget-editor-body-section mt-3">
              <div className="widget-editor-tabs-container sidebar-wrapper">
                {selectedCompanies.length ? (
                  <TabsContainer
                    activeKey={selectedTab || selectedCompanies?.[0]?.id}
                    clearAll={clearAll}
                    openColapse={true}
                    onTabSelect={e => handleTabSelect(e)}
                    selectedData={selectedCompanies}
                  >
                    {selectedCompanies.map((company, index) => (
                      <TabComponent
                        tabClassName={'tab-ui'}
                        key={company.id}
                        id={`tab-${company.id}`}
                        eventKey={company.id}
                        title={tabTitle(index, company)}
                      >
                        <div className="collapsible-content">
                          <ScrollBar
                            horizontalScroll={false}
                            style={{
                              borderRadius: '0 0 0 24px',
                              height: 'calc(100vh - 180px)'
                            }}
                          >
                            <div className={screen.xs ? 'p-3' : 'p-5'}>
                              <Collapseable
                                name={t('widget.collapse_item1')}
                                description={t('widget.collapse_item1_tag1')}
                                id={`${index + 1}_`}
                                storageKey={'widget-editor-collapse'}
                                icon={<UilPlug />}
                                defaultOpen={true}
                              >
                                <IntegrateWidgetCode
                                  companyKey={company.companyKey}
                                  companyId={company.id}
                                />
                              </Collapseable>
                            </div>
                          </ScrollBar>
                        </div>
                      </TabComponent>
                    ))}
                  </TabsContainer>
                ) : (
                  <span className="d-flex justify-content-center">
                    {t('widget.no_companies_msg')}
                  </span>
                )}
              </div>
            </div>
          </div>
        </Col>

        {/* we will uncomment this column when widget apperance work start */}
        {/* <Col
                md={4}
                className={`widgetPreviewContainer ${
                  openPreviewContainer && 'show-preview-container'
                }`}
              >
                Preview here
                {screen.xs && (
                  <UilAngleLeftB
                    size={30}
                    onClick={() =>
                      setOpenPreviewContainer(!openPreviewContainer)
                    }
                  />
                )}
              </Col> */}
      </Row>
    </>
  );
};

export default WidgetEditor;
