export const en_general = {
  saveTxt: 'Save',
  editTxt: 'Edit',
  uploadAttachments: 'Upload Attachments',
  //Company Menu And Sub Menu Items
  generalTxt: 'General',
  analyticsInsights: 'Analytics Insights',
  detailView: 'Detail View',
  menuItem1: 'Basic Details',
  menuItem2: 'Contact Information',
  menuItem3: 'Lead Emails',
  menuItem4: 'Hours/ Call Connect',
  menuItem5: 'Users',
  budgetTxt: 'Budget',
  menuItem6: 'Pacing, Leads and Traffic',
  menuItem11: 'History',
  settingTxt: 'Settings',
  menuItem7: 'Mobile',
  integrationTxt: 'Integrations',
  menuItem8: 'CRM',
  menuItem9: 'Facebook',
  menuItem10: 'Tracking',
  activeText: 'Active',
  inActiveText: 'Inactive',
  provisioningNote:
    ' To make changes to this data, please go to the existing portal.',
  portallang: 'Portal Language',
  profile: 'Profile',
  changePass: 'Change Password',
  logout: 'Logout',
  messagingTag: 'To view the messaging portal',
  oldPortalTag:
    'For full functionality, visit the existing portal for onboarding.',
  clickBtn: 'Click here',
  portalBtn: 'Existing Portal'
};
export default en_general;
export { default as fr_general } from '../fr-FR-Dictionary/fr-general';
export { default as sp_general } from '../sp-SP-Dictionary/sp-general';
