import { createSlice } from '@reduxjs/toolkit';
import ApiServices from 'api/apexchat.api.services';
import { ChatAPI } from 'api/endpoints';

/**
 * @name @createSlice
 * @description create redux store slice for auth
 **/
export const chatsSlice = createSlice({
  name: 'chats',
  initialState: {
    chats: [],
    chatDetailsRightPanel: {
      show: false,
      activeDetailsTab: 'lead-form',
      activeParentTab: 'user-details'
    }
  },

  reducers: {
    /**
     * @name @login
     * @description login reducer to call login async API with axios instance
     * @requires ApiServices.get method, state and API payload
     **/
    setChatsToState: (state, action) => {
      state.chats = [...state.chats, action.payload.data];
    },

    removeChatsFromState: (state, action) => {
      let chats = [...state.chats];
      let index = chats.findIndex(
        com => (com.id || com.chatId) === action.payload.id
      );
      chats.splice(index, 1);
      state.chats = chats;
    },

    removeAllChatsFromState: (state, action) => {
      state.chats = [];
    },
    toggleChatDetailsRightPane: (state, action) => {
      state.chatDetailsRightPanel = {
        ...state.chatDetailsRightPanel,
        ...action.payload
      };
    }
  }
});

export const {
  setChatsToState,
  removeChatsFromState,
  removeAllChatsFromState,
  toggleChatDetailsRightPane
} = chatsSlice.actions;

/**
 * These function called thunk and allows to perform async logic
 * It can be dispatched like a regular action: `dispatch(data)`
 **/
export const getChatAsync = payload => (dispatch, getState) => {
  let chatAPI = ChatAPI.get + '/' + payload.params.id;
  return ApiServices.getWithParams(chatAPI, payload)
    .then(async res => {
      let { data } = res;
      if (data?.success && data?.data) {
        await dispatch(setChatsToState(data));
        return Promise.resolve(data);
      }
      return Promise.reject(res);
    })
    .catch(error => {
      return Promise.reject(error);
    });
  // }
};
/* called a selector and allows to select values from the state */
export const selectChats = state => {
  return state.chats;
};

export const selectChat = (state, id) => {
  let chats = state.chats.chats;
  if (id && chats.length) {
    let temp = chats.filter(c => c.chatId === id);
    if (temp.length) {
      return { chat: temp[0] };
    } else return { chat: {} };
  } else return { chat: {} };
};

export const getAllChats = payload => {
  let chatAPI = ChatAPI.getAll;
  return ApiServices.getWithParams(chatAPI, { params: payload });
};
export const exportAllChats = payload => {
  let chatAPI = ChatAPI.export;
  return ApiServices.WCF_getWithParams(chatAPI, { params: payload });
};

export const selectChatDetailsRightPane = state => {
  return state.chats.chatDetailsRightPanel;
};

export default chatsSlice.reducer;
