import React from 'react';
import ReactDOM from 'react-dom';
import Root from './Root';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import apexChatApiServices from 'api/apexchat.api.services';
import config from 'config';
// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);
// init axios instance
apexChatApiServices.init();
// Version for GIT Tagging
window.AppVersion = config.version;
if (document.getElementById('root')) {
  ReactDOM.render(
    <BrowserRouter>
      <Root />
    </BrowserRouter>,
    document.getElementById('root')
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
