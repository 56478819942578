import React from 'react';
import './animate-scroll-arrow.scss';

const AnimateScrollArrow = () => {
  return (
    <div className="arrow-container">
      <div className="chevron"></div>
      <div className="chevron"></div>
      <div className="chevron"></div>
    </div>
  );
};

export default AnimateScrollArrow;
