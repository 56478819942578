export const fr_leadDetails = {
  heading1: 'Détails Lead',
  heading2: 'Notes de tête',
  leadTooltip: "Le portail affiche jusqu'à 6 mois de données",
  name: {
    label: 'Nom',
    info: 'Le nom du client.'
  },
  phoneNo: {
    label: 'Pas de téléphone',
    info: 'Le numéro de téléphone du client.'
  },
  email: {
    label: 'Email',
    info: "L'adresse e-mail du client."
  },
  createdOn: {
    label: 'Créé sur',
    info: 'Lorsque le fil a été créé dans notre système.'
  },
  leadType: {
    label: 'Type de',
    info: "Le type de plomb par l'utilisateur CLASSIFIÉS manipulation le chat."
  },
  initiatedBy: {
    label: 'Initié par',
    info: 'Là où le chat a commencé à partir.'
  },
  called: {
    label: 'Appelé',
    info: "Est-ce que l'utilisateur tente de se connecter au visiteur et d'affaires."
  },
  attemptedCalls: {
    label: "Les tentatives d'appel",
    info: 'Nombre de tentatives de connexion appel.'
  },
  completedCalls: {
    label: 'Appels terminés',
    info: "Nombre de tentatives de connexion d'appel terminée."
  },
  keywords: {
    label: 'Mots clés',
    info: 'Fournir les mots clés si le système a pu capturer.'
  },
  flagAsProcessed: {
    label: 'Signaler comme traité',
    info: 'Signaler comme traité'
  },
  flagAsUnProcessed: {
    label: 'Signaler comme non traité',
    info: 'Signaler comme non traité'
  },
  replyToVisitor: {
    label: 'Répondre au visiteur',
    info: 'Répondre au visiteur'
  },
  viewChat: {
    label: 'Voir le chat',
    info: 'Voir le chat'
  },
  region: { label: 'Région', info: 'Région' },
  leadCategory: {
    label: 'Catégorie de prospect',
    info: 'Catégorie de prospect'
  },
  appliedRule: { label: 'Règle appliquée', info: 'Règle appliquée' }
};
export default fr_leadDetails;
