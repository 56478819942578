import Button from 'react-bootstrap/Button';
import UilAngleLeftB from '@iconscout/react-unicons/icons/uil-angle-left-b';
import UilSearch from '@iconscout/react-unicons/icons/uil-search';
import UilSave from '@iconscout/react-unicons/icons/uil-save';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSidebarOpenState,
  setSidebarOpen
} from 'features/widget-editor/widgetEditorSlice';
import { useTranslation } from 'react-i18next';

const Header = ({
  openCompaniesPanel,
  isMobile,
  showCompaniesButton = true
}) => {
  const isSidebarOpen = useSelector(getSidebarOpenState);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const sidebarHandler = isOpen => {
    dispatch(setSidebarOpen(isOpen));
  };

  return (
    <div className="widget-editor-header">
      {isMobile && (
        <UilAngleLeftB
          size={30}
          onClick={() => sidebarHandler(!isSidebarOpen)}
        />
      )}
      <h2 className="m-0 text-primary" style={{ fontSize: isMobile && '25px' }}>
        {t('widget.widget_heading')}
      </h2>
      <div className="d-flex flex-wrap" style={{ gap: '10px' }}>
        {showCompaniesButton ? (
          <Button
            className="btn-md d-flex align-items-center"
            variant="primary"
            title="Companies"
            onClick={openCompaniesPanel}
          >
            <UilSearch className="mr-1" style={{ height: 20, width: 20 }} />
            {t('widget.widget_companies')}
          </Button>
        ) : null}
        {/* <Button
          className="btn-md d-flex align-items-center"
          variant="primary"
          title="Save"
          onClick={() => console.log('save setting')}
        >
          <UilSave className="mr-1" style={{ height: 20, width: 20 }} />
          Save
        </Button> */}
      </div>
    </div>
  );
};

export default Header;
