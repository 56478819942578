const note =
  'Note: les dates de début à 12:00 du matin dans le fuseau horaire sélectionné et se terminent à minuit le même jour. Pour obtenir les résultats pour une journée spécifique, utilisez ce jour-là que * les deux * le début et la date de fin.';
export const fr_reportDetails = {
  heading1: 'Paramètres',
  note: 'Note: les dates de début à 12:00 du matin dans le fuseau horaire sélectionné et se terminent à minuit le même jour. Pour obtenir les résultats pour une journée spécifique, utilisez ce jour-là que * les deux * le début et la date de fin.',
  notes: {
    trafficReportByDate: note,
    trafficSummary: note,
    leads: note,
    billableLeads: note,
    transcripts: note,
    stats: note,
    callsSummary: note,
    leadsDelay: note,
    agentResponseTime: note,
    summaryResponseTime: note,
    reviewRatings: note,
    highVolumeAccounts: note,
    pausedCompanies: `${note}
    Pacing periods are typically 24 hours but can be changed by company, totals are for the company's budgeting period.
    Paused and resumed values reference the last paused date and last resumed date.`,
    oftenOverBudgetCompanies: `${note}
    The "Pause Count" is the number of times the company has been paused because the budget has been hit or exceeded for a given pacing sub-period (usually 24 hours).`,
    nearlyOverBudgetCompanies:
      'Note: Budget percentages are inclusive and refer to the current company pacing cycle only.',
    invalidEmailAddresses:
      'Note: By default, all hosts and companies are included',
    performance: note,
    profileResponse: note,
    activeCompaniesWithLowTraffic:
      'Note: This report automatically covers 6 months of data',
    accountCompaniesWithoutPricing: note,
    nonAccountCompaniesWithoutPricing: note,
    activeCompaniesWithoutInvitationCode: note,
    inactiveCompaniesWithInvitationCode: note,
    companiesWithTrafficPacing:
      'Note: By default, all hosts and companies are included',
    companiesWithBillingAmount: note,
    invitationCodeInstalledReport: note,
    monthlyMinimumReport: note,
    agentResponseTimeByThreshold: note,
    'de-activatedaccounts': note,
    facebookUsage: note
  },

  routes: {
    parameters: 'Paramètres',
    schedule: 'Horaire'
  },
  parameters: {
    name: {
      label: 'Nom',
      info: 'Nommez le rapport avec un nom unique si nécessaire.'
    },
    notificationEmail: {
      label: 'Notification emaill',
      info: 'Envoyer le rapport à plusieurs adresses e-mail en utilisant une virgule.'
    },
    notificationEndPoint: {
      label: 'Point final de notification',
      info: "Adresse Web de notification lorsque le rapport a exécuté avec succès. L'adresse comprendra une URL qui peut être utilisé pour récupérer le rapport qui en résulte."
    },
    emailReport: {
      label: 'Rapport Email en pièce jointe',
      info: 'Si elle est activée, le rapport sera envoyé en pièce jointe avec e-mail de notification.'
    },
    companyKeys: {
      label: "Clés de l'entreprise",
      info: "Utilisez des virgules à plusieurs touches de société distincte. Assurez-vous que l'hôte est pas vérifiée lorsque des rapports individuels en cours d'exécution en fonction des clés de l'entreprise unique ou multiple."
    },
    timeZone: {
      label: 'Fuseau horaire',
      info: 'Sélectionnez votre fuseau horaire approprié.'
    },
    period: {
      label: 'Période',
      info: 'La période de temps sera couru le rapport.'
    },
    groupByCompany: {
      label: 'Groupe par la société',
      info: "Activer s'il y a plusieurs clés qui doivent être regroupés dans le rappor."
    },
    host: {
      label: 'Hôte',
      info: "Sélectionnez l'hôte si le rapport est pour toutes les entreprises"
    }
  },
  heading2: 'Schedule',
  schedule: {
    scheduleType: {
      label: 'Schedule Type'
    },
    schedule: {
      label: 'Horaire'
    },
    time: {
      label: 'Temps'
    },
    monday: {
      label: 'Lundi'
    },
    tuesday: {
      label: 'Mardi'
    },
    wednesday: {
      label: 'Mercredi'
    },
    thursday: {
      label: 'Jeudi'
    },
    friday: {
      label: 'Vendredi'
    },
    saturday: {
      label: 'Samedi'
    },
    sunday: {
      label: 'Dimanche'
    },
    noScheduleFound: {
      label: 'Aucun résultat horaire'
    }
  },
  createReport: 'Create report'
};
export default fr_reportDetails;
