import dropdownEnums, { Enums } from 'helpers/dropdown-enums';
import SimpleSelectBox from 'helpers/ui-controls/ui-components/SimpleSelectBox';

const VisibilityComp = ({ selectedValue, handleChange, roles, form }) => {
  return roles?.script?.edit ? (
    <div className="form-group mb-3">
      <SimpleSelectBox
        dataProperty={selectedValue}
        name="visibility"
        field="visibility"
        options={createOptions(roles)}
        heading={'Select Visibility'}
        handleChange={handleChange}
        showTitle={false}
        error={form.touched.visibility && form.errors.visibility}
        disabled={!roles?.script?.edit}
        customStyle={{
          fontSize: '0.8125rem',
          color: 'var(--black)'
        }}
        labelStyle={{
          marginBottom: '0'
        }}
        errorStyle={{
          marginTop: '0'
        }}
      />
    </div>
  ) : (
    <span>
      <span className="mr-2 font-weight-bold">Visibility:</span>
      <span>Self</span>
    </span>
  );
};

export default VisibilityComp;

const createOptions = roles => {
  let noneOpt = { value: 0, text: 'Select' };
  const options = dropdownEnums.enum_QT_visibility
    .filter(qtEnum => filterOption(qtEnum, roles))
    .map(item => ({
      value: item.value,
      text: item.name === 'Both' ? 'Agents & Operators' : item.name
    }));

  return [noneOpt, ...options];
};
const filterOption = (opt, roles) => {
  if (roles?.script?.edit) {
    return (
      (roles?.isAgent && opt.value !== Enums.QTVisibility.Agents) ||
      (roles?.isOperator && opt.value !== Enums.QTVisibility.Agents)
    );
  } else {
    return opt.value === Enums.QTVisibility.Self;
  }
};
