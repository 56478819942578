import {
  getChatRoomData,
  pushChatRoom
} from 'features/messagingChats/messagingChats';
import { Enums } from 'helpers/dropdown-enums';
import { IncreaseProgress } from 'pages/portal/messaging/ChatSplashScreen/SplashScreen';
import store from 'store';
import RTC from '../rtc';
import { chatChanged } from '../services/chatroom-service';
import { toast } from 'react-toastify';
import { ToastContainer, Slide } from 'react-toastify';
import { logIt } from 'helpers/logger';

const Subscribe_RequestTransferQueue = callbacks => {
  let {
    auth: { profile }
  } = store.getState();

  RTC().Subscribe({
    channels: ['/chat/requestTransfer/' + profile?.UserID],
    callbacks: callbacks
  });
};

export const Init_RequestTransferQueue = () => {
  Subscribe_RequestTransferQueue({
    onSuccess: function (args) {
      IncreaseProgress({
        text: 'Subscribing request transfer queue...',
        count: 10
      });
      logIt('log', 'successfully subscribed to request transfer channel: ');
    },
    onReceive: function (args) {
      logIt('log', 'onReceive request transfer:', args.getData());
      let data = args.getData();
      // room data is incomplete in transferred chat, fetch room data from api
      store.dispatch(getChatRoomData(data.chatId)).then(res => {
        if (res && typeof res !== 'string') {
          var chatroomByType = chatChanged(res?.data);
          logIt(
            'log',
            'request transfer queue chatroomByType---',
            chatroomByType
          );
          chatroomByType.room.data['isTransferred'] = true;

          let chatData = {
            includeInSections: [Enums.RoomType.IncomingChat],
            excludeInSections: [],
            room: chatroomByType.room
          };

          store.dispatch(pushChatRoom(chatData));
        }
      });

      // Toast Functionality if above not work
      // const options = {
      //   onOpen: props => console.log('onOpen'),
      //   onClose: props => console.log('onClose'),
      //   autoClose: data?.timeout || 6000,
      //   closeButton: false,
      //   type: toast.TYPE.INFO,
      //   hideProgressBar: true,
      //   pauseOnHover: true
      // };
      // const toastId = toast(data?.message, options);
      // // update a toast
      // toast.update(toastId, {
      //   type: toast.TYPE.INFO,
      //   render: <ToastContent text={data?.message} />
      // });
    }
  });
};

// const ToastContent = ({ text, onAccept, onIgnore }) => (
//   <div>
//     {text}
//     <button onClick={() => console.log('onAccept')}>Accept</button>
//     <button onClick={() => console.log('onIgnore')}>Ignore</button>
//   </div>
// );

export default Subscribe_RequestTransferQueue;
