import { Enums } from 'helpers/dropdown-enums';

/**
 * @name @mapSendCallConnectData
 * @description transform data as per server requirements
 * @requires object of the form
 * @returns mapped object
 * */
const mapSendCallConnectData = formData => {
  let data = {
    chatId: formData.chatId,
    companyId: formData.companyId,
    // leadType: Enums.LeadType[formData['type']],
    name: formData['name'],
    to: removeSymbolFromNumber(formData['phoneNumber']),
    from: formData['phoneNumberFrom'],
    regionId: formData['regionId'],
    categoryId: formData['categoryId']
  };
  if (formData.type) {
    data['leadType'] = Enums.LeadType[formData['type']];
  }

  return data;
};
export default mapSendCallConnectData;

const removeSymbolFromNumber = number => {
  return number.replace(/[()+-\s]/g, '');
};
