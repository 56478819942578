import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Badge, Button } from 'react-bootstrap';
import moment from 'moment';
import { getAllCompanies } from 'features/companies/companiesSlice';
import storage from 'helpers/storage';
import { useDispatch, useSelector } from 'react-redux';
import '@inovua/reactdatagrid-community/index.css';
import {
  companiesTableConfig,
  getFilterValue,
  getKeysByFilterType,
  widgetCompaniesTableConfig
} from 'utils/tableConfig';
import { toast } from 'react-toastify';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { GridWrapper } from '../../../components/GridWrapper';
import {
  getSelectedCompanies,
  setSelectedCompaniesToState
} from 'features/widget-editor/widgetEditorSlice';
import DynamicColumns from '../report/dynamicColumns';
import UilEye from '@iconscout/react-unicons/icons/uil-eye';
import { useTranslation } from 'react-i18next';
export const dataKey = 'companies-widgetSetting';
// const tabKey = 'companies-widgetSetting-tab';
const useStyles = makeStyles(theme => ({
  companiesContainer: { display: 'block !important' }
}));
const Companies = ({ getCompaniesCount }) => {
  const [selectedData, setSelectedData] = useState({});
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const selectedCompanies = useSelector(getSelectedCompanies);

  const [vissibleColumns, setVissibleColumns] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState(null);
  const classes = useStyles();
  // initialize selected Tab Data after Redirect From other screen
  useEffect(() => {
    let data = JSON.parse(storage.getItem(dataKey)) || [];
    setSelectedData(generateSelected(data));
    dispatch(setSelectedCompaniesToState(data));
  }, []);

  useEffect(() => {
    setSelectedData(generateSelected(selectedCompanies));
  }, [selectedCompanies]);

  // select Company
  const handleSelect = payload => {
    updateCompaniesAndSaveToLS(payload);
  };

  //  update Selected Companies and save to Local Storage
  const updateCompaniesAndSaveToLS = payload => {
    const { data, selected } = payload;
    let temp =
      typeof selected == 'boolean'
        ? data
        : Object.keys(selected).map(key => ({ ...selected[key] }));

    setSelectedData(
      typeof selected == 'boolean' ? generateSelected(data) : selected
    );
    dispatch(setSelectedCompaniesToState(temp));
  };

  const generateSelected = arrayData => {
    return arrayData.reduce(
      (obj, item) => ({
        ...obj,
        [item['id']]: item
      }),
      {}
    );
  };

  const getDateFilterValue = data => {
    let updatedValue;
    if (data.name === 'createdOn' && data.operator === 'before') {
      updatedValue = moment(data.value).local();
    } else if (data.name === 'createdOn' && data.operator === 'after') {
      updatedValue = moment().local();
    } else {
      updatedValue = data.value;
    }
    return updatedValue;
  };

  const loadData = ({ skip, limit, sortInfo, filterValue }) => {
    try {
      setLoadingMessage('Loading ...');
      const updatedValue = filterValue
        .filter(v => v.value !== '')
        .map(v => ({
          propertyName: v.name,
          propertyValue1: getFilterValue(
            v,
            getKeysByFilterType(companiesTableConfig())
          ),
          propertyValue2:
            v.name === 'createdOn' &&
            (v.operator === 'before' || v.operator === 'after')
              ? getDateFilterValue(v)
              : '',
          propertyValue3: [],
          filterDataType: 2,
          comparison: 2
        }));
      let payload = {
        start: skip,
        limit: limit,
        filter: JSON.stringify(updatedValue),
        dir: !sortInfo ? 'DESC' : sortInfo.dir === 1 ? 'ASC' : 'DESC',
        sort: sortInfo ? sortInfo.name : 'createdOn'
      };
      return getAllCompanies(payload).then(response => {
        if (response.data.totalSize > 20) {
          setDataLimit(skip + response.data.data.length);
        } else {
          setDataLimit(response.data.data.length);
        }
        const totalCount = response.data.totalSize;

        setTotalRecords(totalCount);
        if (!updatedValue.length) {
          getCompaniesCount(totalCount);
          if (totalCount === 1) {
            let singleCompany = response.data.data[0];
            updateCompaniesAndSaveToLS({
              selected: { [singleCompany.id]: singleCompany }
            });
          }
        }
        return { data: response.data.data, count: parseInt(totalCount) };
      });
    } catch (error) {
      toast.error(error || error.message);
    }
  };

  const dataSource = useCallback(loadData, []);
  const [dataLimit, setDataLimit] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loadingMessage, setLoadingMessage] = useState('Loading ...');

  const renderColumnContextMenu = useCallback((menuProps, { cellProps }) => {
    menuProps.items = menuProps.items.splice(0, 8);
  }, []);

  const handleColumnsChange = data => {
    setVissibleColumns({ ...data });
  };
  return (
    <>
      <div
        className={`companies-container pr-2 pl-2 ${classes.companiesContainer}`}
      >
        <Row>
          <Col className="m-3">
            <h2>
              {t('widget.widget_companies')}
              <Badge variant="primary p-1 badge-align">
                {loading ? '...' : `${dataLimit} / ${totalRecords}`}
              </Badge>
            </h2>
          </Col>
          <div className="d-flex" style={{ margin: '20px', gap: '10px' }}>
            <DynamicColumns
              handleChange={handleColumnsChange}
              menuOptions={widgetCompaniesTableConfig()}
              selectedFilters={selectedFilters}
              isExport={false}
            />
          </div>
        </Row>
        <Row>
          <Col className={loading && 'blux-text'}>
            <div className="mobile_view">
              <GridWrapper
                checkboxOnlyRowSelect={true}
                checkboxColumn={true}
                customGridStyle={{ minHeight: 'calc(100vh - 100px)' }}
                columns={widgetCompaniesTableConfig()}
                totalRecords={totalRecords}
                dataSource={dataSource}
                setLoading={setLoading}
                loadingMessage={loadingMessage}
                renderColumnContextMenu={renderColumnContextMenu}
                selectedData={selectedData}
                handleSelect={handleSelect}
                vissibleColumns={vissibleColumns}
              />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default React.memo(Companies);
