import React from 'react';
import { UilSync } from '@iconscout/react-unicons';
import { Spinner } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { Card } from 'react-bootstrap';

const Header = ({ title, count, loading, refetch }) => {
  return (
    <Accordion.Toggle as={Card.Header} className="according-text">
      <div className="chat-pool-accord">
        <div className="d-flex justify-content-between align-items-center">
          <span>
            <span className="tab-title">
              {loading && (
                <Spinner animation="border" variant="secondary" size="sm" />
              )}
              &nbsp;{title}&nbsp;
              {''}
              <small className="text-muted">{!loading && `(${count}) `}</small>
            </span>
          </span>

          {!loading && (
            <span>
              <UilSync onClick={refetch} size={15} />
            </span>
          )}
        </div>
      </div>
    </Accordion.Toggle>
  );
};

export default Header;
