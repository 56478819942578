import RTC from '../rtc';
import { callConnectCallbacks } from '../services/chat/callbacks/call_connect_callbacks';

const Subscribe_CallConnectStatusById = options => {
  RTC().Subscribe({
    channels: ['/calls/chat/' + options.chatId],
    callbacks: options.callbacks
  });
};

export const Init_CallConnectStatusById = options => {
  options.callbacks = options.callbacks || {
    onSuccess: args => {
      callConnectCallbacks.onSuccess(args);
    },
    onReceive: args => {
      callConnectCallbacks.onReceive({
        data: args.getData(),
        onStatusArrived: options.onStatusArrived
      });
    },
    onFailure: args => {
      callConnectCallbacks.onFailure(args);
    }
  };
  Subscribe_CallConnectStatusById(options);
};

export default Subscribe_CallConnectStatusById;
