import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import RightArrowIcon from '@material-ui/icons/ArrowForwardIos';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ScrollBar from 'components/Scrollbars';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useViewport from 'helpers/viewport-hook';

const useStyles = makeStyles(theme => ({
  listItemRoot: {
    fontFamily: 'Lato',
    padding: '0 !important'
  },
  listItemText: {
    '& span': {
      fontFamily: 'Lato'
    }
  },
  selectedCompany: {
    backgroundColor: 'var(--lightgray-2) !important'
  },
  disabledCheckbox: {
    pointerEvents: 'none'
  },
  iconButton: {
    '&:hover': {
      backgroundColor: 'transparent !important'
    },
    '& svg': {
      width: '20px'
    }
  }
}));

const CompanyList = ({
  list = [],
  handleChange,
  selectedData,
  loading,
  selectedIndex
}) => {
  const classes = useStyles();
  const screen = useViewport();

  const markChecked = id => {
    return selectedData && id in selectedData && selectedData[id]
      ? true
      : false;
  };

  let tempList = [...list];

  return (
    <div>
      <ScrollBar
        horizontalScroll={false}
        style={{
          borderRadius: '0 0 0 24px',
          height: `calc(100vh - ${screen.sm ? '200px' : '380px'})`,
          // height: `calc(100vh - 380px)`,
          maxHeight: '305px'
        }}
      >
        {!loading &&
          (list.length ? (
            <List component="nav" aria-label="main mailbox folders">
              {tempList
                .sort((a, b) => (markChecked(a.id) ? -1 : 1))
                .map((item, index) => (
                  <ListItem
                    key={item.id}
                    button
                    selected={selectedIndex === item.id}
                    onClick={event => handleChange(item.id)}
                    classes={{
                      root: classes.listItemRoot,
                      selected: classes.selectedCompany
                    }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        checked={markChecked(item.id)}
                        color="primary"
                        className={classes.disabledCheckbox}
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      className={classes.listItemText}
                      primary={item.name}
                    />
                    {selectedIndex === item.id && (
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          disableRipple
                          disableFocusRipple
                          classes={{ root: classes.iconButton }}
                        >
                          <RightArrowIcon size={20} />
                        </IconButton>
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                ))}
            </List>
          ) : (
            <span className="d-flex justify-content-center">
              No company available
            </span>
          ))}
      </ScrollBar>
    </div>
  );
};

export default CompanyList;
