import RTC from '../../rtc';
import { getRealTimeCommunicationChannels } from '../chatroom-service';

const Initiate_Chat = options => {
  RTC().Subscribe({
    meta: {
      chatId: options.chatId,
      companyId: options.companyId
    },
    channels: getRealTimeCommunicationChannels(options.chatId),
    callbacks: options.callbacks
  });
};

export default Initiate_Chat;
