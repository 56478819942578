import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Form } from 'react-bootstrap';
import { UilCopy, UilEnvelopeUpload } from '@iconscout/react-unicons';
import UILInfoCircle from '@iconscout/react-unicons/icons/uil-info-circle';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import storage from 'helpers/storage';
import { useFormik } from 'formik';
import { widgetEditorCode } from 'helpers/schemas/widgetEditorSchemas/initialValues';
import { sendCode } from 'features/widget-editor/widgetEditorSlice';
import { toast } from 'react-toastify';
import CustomAutoCompleteFilter from 'pages/portal/dashboard/filters/custom-auto-complete-filter';
import { useSelector } from 'react-redux';
import { selectRoles } from 'features/roles/rolesSlice';
import renderToolTip from 'helpers/ui-controls/renderToolTip';
import { widgetCodeSchemas } from 'helpers/schemas/widgetEditorSchemas/schemas';
import { success } from 'helpers/variables';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useViewport from 'helpers/viewport-hook';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  inputClass: { width: '100% !important' },
  hint: { fontSize: '13px', marginLeft: '14px' },
  errorClass: ({ isSmall }) => ({
    position: isSmall ? 'static' : 'absolute',
    top: '100%'
  })
}));

const IntegrateWidgetCode = ({ companyKey = '', companyId = '' }) => {
  let user = JSON.parse(storage.getItem('Profile'));
  const screen = useViewport();
  const classes = useStyles({ isSmall: screen.sm });
  const [loading, setLoading] = useState();
  const { roles } = useSelector(selectRoles);
  const { t } = useTranslation();

  const handleFormSubmit = e => {
    let data = {
      developerEmails: e.developerEmails.join(',')
    };
    let widgetCode = document.getElementById(
      `${companyId}-widget-code`
    ).innerHTML;
    if (widgetCode) {
      data['widgetCode'] = widgetCode;
    }
    setLoading(true);
    sendCode(data)
      .then(response => {
        if (response) {
          setLoading(false);
          widgetCodeFormIk.resetForm();
          toast.success(success.sendSuccess('Email'));
        }
      })
      .catch(error => {
        setLoading(false);
        toast.error(error?.response?.data?.Message || error.message);
      });
  };

  const widgetCodeFormIk = useFormik({
    enableReinitialize: true,
    initialValues: widgetEditorCode,
    validationSchema: widgetCodeSchemas,
    onSubmit: handleFormSubmit
  });

  useEffect(() => {
    widgetCodeFormIk.setValues({
      ...widgetCodeFormIk.values
    });
  }, []);

  const onChangeEmail = (data, e) => {
    if (data.length <= 3) {
      widgetCodeFormIk.setValues({
        ...widgetCodeFormIk.values,
        developerEmails: data || []
      });
    } else {
      widgetCodeFormIk.setErrors({
        developerEmails: 'Maximum 3 emails are allowed at a time.'
      });
    }
  };

  const copyWidgetCode = e => {
    let widgetCodeText = document.getElementById(
      `${companyId}-widget-code`
    ).textContent;
    if (widgetCodeText) {
      navigator.clipboard.writeText(widgetCodeText);
    }
  };

  const codeSnippet = () => {
    return (
      <span>
        <span style={{ color: '#2e21ff' }}>&lt;</span>
        <span style={{ color: '#ad6467' }}>script</span>{' '}
        <span style={{ color: '#ff4657' }}>src</span>
        <span style={{ color: '#2e21ff' }}>
          ="
          {user.HostDomain}scripts/invitation.ashx?company=
          {companyKey}"
        </span>
        <span style={{ color: '#ff4657' }}> async</span>
        <span style={{ color: '#2e21ff' }}>=""&gt;</span>
        <span style={{ color: '#2e21ff' }}>&lt; /</span>
        <span style={{ color: '#ad6467' }}>script</span>
        <span style={{ color: '#2e21ff' }}>&gt;</span>
      </span>
    );
  };

  return (
    <>
      <Row className="integrate-code-container">
        <Col>
          <Card className="border-0">
            <Card.Body>
              <Row>
                <Col>
                  <div className="mb-3 msg-textarea-field">
                    <div className="d-flex align-items-center mb-3">
                      <span className="text-primary fw-700 ">
                        {t('widget.widget_script')}
                      </span>
                      <OverlayTrigger
                        placement="auto"
                        overlay={renderToolTip(
                          'You need to add the code snippet below into every page of your website, just before the closing </body> tag.'
                        )}
                      >
                        <UILInfoCircle
                          className="list-icon-after-text ml-1"
                          size={22}
                        />
                      </OverlayTrigger>
                    </div>
                    <Form.Control
                      id={`${companyId}-widget-code`}
                      as="div"
                      name={'widgetCode'}
                      className="mb-3 h-auto"
                    >
                      {codeSnippet()}
                    </Form.Control>
                    <button
                      type="button"
                      className="btn-md d-flex align-items-center btn btn-primary"
                      onClick={copyWidgetCode}
                    >
                      <UilCopy
                        className="mr-1"
                        style={{ height: 20, width: 20 }}
                      />
                      {t('widget.copy_snippet')}
                    </button>
                  </div>
                  {roles.settings.widgetEditorPluginLink.view && (
                    <>
                      <div className="d-flex justify-content-between align-items-center mt-3">
                        <p className="m-0 fw-700">{t('widget.widget_or')}</p>
                        <hr
                          style={{
                            width: '97%'
                          }}
                        />
                      </div>
                      <p className="mx-0 my-2 fw-400">
                        {t('widget.easy_install')}{' '}
                        <a
                          className="text-primary fw-600 wordpress-link"
                          href={
                            'https://www.apexchat.com/faqs/install-apexchat-wordpress-plugin/'
                          }
                          target={'_blank'}
                        >
                          {t('widget.wordpress_plugin')}
                        </a>
                      </p>
                    </>
                  )}
                  <div className="d-flex justify-content-between align-items-center mt-3">
                    <p className="m-0 fw-700"> {t('widget.widget_or')}</p>
                    <hr
                      style={{
                        width: '97%'
                      }}
                    />
                  </div>
                  <Form
                    className="form"
                    onSubmit={widgetCodeFormIk.handleSubmit}
                    onKeyPress={e => {
                      e.key === 'Enter' && e.preventDefault();
                    }}
                    autoComplete="off"
                  >
                    <Row className="my-2">
                      <Col md={12} className="pb-3">
                        <span className="d-block mb-1">
                          {t('widget.send_developer')}
                        </span>
                        <div className="d-flex align-items-center">
                          <CustomAutoCompleteFilter
                            editable={true}
                            setFunction={onChangeEmail}
                            handleTextChange={text =>
                              console.log('textchange', text)
                            }
                            options={[]}
                            loading={false}
                            name={'developerEmails'}
                            selected={
                              widgetCodeFormIk.values['developerEmails']
                            }
                            error={
                              (widgetCodeFormIk.values['developerEmails']
                                .length ||
                                widgetCodeFormIk.touched['developerEmails']) &&
                              widgetCodeFormIk.errors['developerEmails']
                            }
                            handleBlur={widgetCodeFormIk.handleBlur}
                            liStyles={{ alignSelf: 'end', width: '100%' }}
                            variant="outlined"
                            isFilterWrapper={false}
                            isMultiSelectEnable={true}
                            freeSolo={true}
                            placeholder={t('widget.designer_email')}
                            styles={{
                              marginBottom: '0px'
                            }}
                            inputClass={classes.inputClass}
                            errorClass={classes.errorClass}
                          />

                          <OverlayTrigger
                            placement="auto"
                            overlay={renderToolTip(
                              t('widget.widget_error_info')
                            )}
                          >
                            <UILInfoCircle className="list-icon-after-text ml-1" />
                          </OverlayTrigger>
                        </div>
                      </Col>
                      <Col className="d-flex align-items-center mt-2">
                        <button
                          disabled={!widgetCodeFormIk.isValid || loading}
                          type="button"
                          className="btn-md d-flex align-items-center btn btn-primary"
                          onClick={widgetCodeFormIk.handleSubmit}
                        >
                          {loading ? (
                            <i className="uil uil-spinner spinner mr-1"></i>
                          ) : (
                            <UilEnvelopeUpload
                              className="mr-1"
                              style={{ height: 20, width: 20 }}
                            />
                          )}
                          {t('widget.send_instruction')}
                        </button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default IntegrateWidgetCode;
