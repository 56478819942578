import React from 'react';
import { Card } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';
import Accordion from 'react-bootstrap/Accordion';
import Header from './Header';
import ChatListItem from './ChatListItem';
import './index.scss';
import ScrollBar from 'components/Scrollbars';
import { useSelector } from 'react-redux';
import { selectRoles } from 'features/roles/rolesSlice';
import { Enums } from 'helpers/dropdown-enums';

const ChatList = ({
  title,
  chats,
  showActionBtns = false,
  onChatClickHandler,
  onAccept,
  onIgnore,
  onReject,
  eventKey,
  defaultActiveKey,
  roomType
}) => {
  const {
    roles: { isOperator, isAgent }
  } = useSelector(selectRoles);

  return (
    <>
      <Card className="chat-list-container">
        <Header
          title={title}
          chatCount={chats.length}
          eventKey={eventKey}
          defaultActiveKey={defaultActiveKey}
        />
        <Accordion.Collapse eventKey={eventKey}>
          <Card.Body
            style={{
              height: chats.length
                ? chats.length > 1
                  ? '150px'
                  : '90px'
                : '40px'
            }}
          >
            <ListGroup className="chat-list h-100">
              <ScrollBar
                horizontalScroll={false}
                style={{
                  // height: 'calc(100vh - 121px)'
                  height: '100vh'
                  // maxHeight: '300px'
                }}
              >
                {chats.length > 0 &&
                  chats.map(chat => (
                    <ChatListItem
                      isOperator={isOperator}
                      isAgent={isAgent}
                      key={chat.getId()}
                      showActionBtns={showActionBtns}
                      chat={chat}
                      onChatClickHandler={onChatClickHandler}
                      onAccept={onAccept}
                      onIgnore={onIgnore}
                      onReject={onReject}
                      roomType={roomType}
                      eventKey={eventKey} //to see which section chat will be showns incoming/active/all/shelved/unsentleads
                    />
                  ))}
              </ScrollBar>
              {chats.length === 0 && (
                <ListGroup.Item className="non-active-list">
                  <div>
                    <span className="text-center message">
                      {Number(roomType) === Enums.RoomType.IncomingChat
                        ? 'No incoming chat available'
                        : 'No chat available'}
                    </span>
                  </div>
                </ListGroup.Item>
              )}
            </ListGroup>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </>
  );
};

export default ChatList;
