import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const DropDownMenu = ({
  title,
  classes,
  customStyle,
  onOptionSelect,
  disableClick,
  options,
  disabled,
  isOnlyIcon,
  menuClass,
  anchorOrigin,
  transformOrigin
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e, reason) => {
    setAnchorEl(null);
    if (reason === undefined && !disableClick) {
      let selectedValue = e.target.dataset.value;
      onOptionSelect(selectedValue);
    }
  };
  return (
    <>
      {isOnlyIcon ? (
        <IconButton
          aria-label="more"
          aria-controls="customized-menu"
          aria-haspopup="true"
          variant="contained"
          onClick={handleClick}
          className={classes}
          style={customStyle}
          disabled={disabled}
          disableFocusRipple={true}
        >
          {title}
        </IconButton>
      ) : (
        <Button
          aria-controls="customized-menu"
          aria-haspopup="true"
          variant="contained"
          onClick={handleClick}
          className={classes}
          style={customStyle}
          disabled={disabled}
        >
          {title}
        </Button>
      )}

      <Menu
        className={menuClass}
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        getContentAnchorEl={null}
      >
        {options.length &&
          options.map(
            (opt, index) =>
              (opt?.isVisible || !('isVisible' in opt)) && (
                <MenuItem
                  key={index}
                  data-value={opt.key}
                  onClick={opt.disableClick ? null : handleClose}
                >
                  {opt.value}
                </MenuItem>
              )
          )}
      </Menu>
    </>
  );
};

DropDownMenu.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  classes: PropTypes.string,
  customStyle: PropTypes.object,
  onOptionSelect: PropTypes.func,
  disableClick: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
  isOnlyIcon: PropTypes.bool,
  menuClass: PropTypes.string,
  anchorOrigin: PropTypes.object,
  transformOrigin: PropTypes.object
};

DropDownMenu.defaultProps = {
  title: '',
  classes: '',
  customStyle: {},
  disableClick: false,
  options: [],
  disabled: false,
  isOnlyIcon: false,
  menuClass: '',
  anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
  transformOrigin: { vertical: 'top', horizontal: 'left' }
};

export default DropDownMenu;
