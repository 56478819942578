import React, { useState, useCallback, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {
  getAllChatsHistory,
  getSelectedChat
} from 'features/messagingChats/messagingChats';
import { selectRoles } from 'features/roles/rolesSlice';
import { historyTableConfig } from 'utils/tableConfig';
import { historyFilterConfig } from 'utils/filterConfig';
import { GridWrapper } from 'components/GridWrapper';
import '@inovua/reactdatagrid-community/index.css';
import { toast } from 'react-toastify';
import './history.scss';
import Title from './Title';
import { isObjectEmpty } from 'helpers/generic.methods';
const History = ({ onChatClickHandler }) => {
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('Loading ...');
  const [totalRecords, setTotalRecords] = useState(0);
  const [isRefetchEnable, setRefetch] = useState(false);
  const [selectedHistory, setSelectedHistory] = useState({});
  const selectedChat = useSelector(getSelectedChat);

  const renderColumnContextMenu = useCallback((menuProps, { cellProps }) => {
    menuProps.items = menuProps.items.splice(0, 8);
  }, []);

  useEffect(() => {
    if (isObjectEmpty(selectedChat)) {
      setSelectedHistory({});
    } else {
      let selectedHistoryObj = {
        [selectedChat?.getId()]: {
          companyName: { ...selectedChat?.data }
        }
      };
      setSelectedHistory(selectedHistoryObj);
    }
  }, [selectedChat]);

  const loadData = ({ skip, limit, sortInfo, filterValue }) => {
    try {
      setLoadingMessage('Loading ...');
      const updatedValue = filterValue?.length
        ? filterValue[0]?.value.trim()
        : '';
      let payload = {
        start: skip,
        limit: limit,
        searchText: updatedValue,
        dir:
          isRefetchEnable || !sortInfo
            ? ''
            : sortInfo.dir === 1
            ? 'ASC'
            : 'DESC',
        sort: !isRefetchEnable && sortInfo ? sortInfo.name : ''
      };

      return getAllChatsHistory(payload).then(response => {
        const totalCount = response.data.totalSize;

        let historyData = [];
        if (response.data.success && response.data.data.length) {
          response.data.data.map((value, index) => {
            historyData.push({
              id: value.chatId,
              companyName: value
            });
          });
        }
        setTotalRecords(totalCount);
        setRefetch(false);

        return { data: historyData, count: parseInt(totalCount) };
      });
    } catch (error) {
      toast.error(error || error.message);
    }
  };

  const handleSelect = payload => {
    if (payload && payload.data.length) {
      onChatClickHandler(payload.data[0].companyName);
    }
  };

  const dataSource = useCallback(loadData, []);
  const refetch = () => {
    setRefetch(true);
  };
  const {
    roles: { isOperator, isAgent }
  } = useSelector(selectRoles);
  return (
    <>
      <Card
        className="chat-pool-card chat-list-container"
        style={{ height: '100%' }}
      >
        <Title
          title={'History'}
          count={totalRecords}
          loading={loading}
          refetch={refetch}
        />

        <div className="mobile_view history-grid-container">
          <GridWrapper
            columns={historyTableConfig}
            totalRecords={totalRecords}
            dataSource={dataSource}
            setLoading={setLoading}
            loadingMessage={loadingMessage}
            defaultFilterValue={historyFilterConfig}
            selectedData={selectedHistory}
            handleSelect={handleSelect}
            renderColumnContextMenu={renderColumnContextMenu}
            refetch={isRefetchEnable}
            recordsLimit={10}
            customGridStyle={{
              height: 'calc(100% - 48px)',
              minHeight: 'unset',
              marginTop: 'unset',
              overflow: 'scroll'
            }}
            rowHeight={30}
          />
        </div>
      </Card>
    </>
  );
};

export default History;
