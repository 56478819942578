export const fr_crm = {
  heading1: 'CRM',
  heading2: 'Détails de base',
  crmProviders: {
    label: 'Les fournisseurs CRM',
    info:
      'Sélectionnez le CRM de cette liste que vous souhaitez envoyer conduit à.'
  },
  crmUniqueID: {
    label: 'CRM ID unique',
    info:
      "Pour trouver l'ID unique CRM: <br /> <br /> SalesForce: Configuration => Configuration administration => Profil de l'entreprise => Mettre à jour les informations de votre entreprise => Salesforce.com ID de l'organisation <br /> <br /> Infusionsoft : http://kb.infusionsoft.com/index.php?/article/AA-00442/0/How-do-I-enable-the-Infusionsoft-API.html <br /> <br /> Leads360: le paramètre 'client' pour l'URL d'intégration (& client = {cette valeur} &) <br /> <br /> i360: l'adresse email fournie par i360 <br /> <br /> Convert RL: ce n'est pas nécessaire pour les entreprises RL utilisant Convert RL parce que nous avons déjà la clé de l'entreprise; il suffit de mettre un « 0 ». Si c'est en quelque sorte * pas * le cas à l'avenir, parler à Jerod <br /> <br /> dans l'apport juridique: définir ce champ à « 0 » <br /> <br /> dans Smart Advocate: définir ce champ à la cible adresse email Smart Advocate <br/> Ce champ est nécessaire pour l'intégration avec CRMs."
  },
  target_dbid: {
    label: 'DBID cible',
    info:
      "Il s'agit de l'identifiant unique de la base de données cible."
  },
  url: {
    label: 'Url',
    info: "Il s'agit de l'url unique pour l'authentification."
  },
  consumerKey: {
    label: 'La clé du consommateur',
    info: "Il s'agit de l'identifiant client unique."
  },
  consumerSecret: {
    label: 'Secret des consommateurs',
    info: "Il s'agit du code secret unique du client."
  },
  securityToken: {
    label: 'Jeton de sécurité',
    info: "Il s'agit du jeton de sécurité unique."
  },
  username: {
    label: "Nom d'utilisateur",
    info: "C'est le nom d'utilisateur."
  },
  password: {
    label: 'Mot de passe',
    info: "C'est le mot de passe."
  },
  target_domain: {
    label: 'Domaine cible',
    info: "Il s'agit de l'adresse de domaine cible unique."
  },
  app_token: {
    label: "Jeton d'application",
    info: "Il s'agit du jeton d'application."
  },
  intakeSource: {
    label: "Source d'admission",
    info:
      'Le type de source de marketing (applicable uniquement à force de vente).'
  },
  leadTypeForCrm: {
    label: 'Type Pour CRM',
    info: 'Le type de conduit à envoyer au CRM.'
  },
  lidogeneratorId: {
    label: 'ID du générateur de Lido',
    info:
      "Il s'agit d'un numéro d'identification unique qui identifie l'organisation du client Captorra dans laquelle vous postez."
  },
  endPointUrl: {
    label: 'URL du point de terminaison',
    info:
      "Il s'agit de l'URL de point de terminaison unique pour chaque client qui souhaite utiliser Général."
  },
  hAPIKey: {
    label: 'Clé API HubSpot',
    info: "Il s'agit de la clé API HubSpot unique pour chaque client."
  },
  hubSpotUrl: { label: 'URL HubSpot', info: "L'URL pour publier les données." },
  _entityForSales: {
    label: 'Entité de vente',
    info:
      "Entrez le nom de l'entité pour laquelle vous souhaitez recevoir des prospects. Laissez vide si cela ne s'applique pas."
  },
  _entityForService: {
    label: 'Entité de service',
    info:
      "Entrez le nom de l'entité pour laquelle vous souhaitez recevoir le plomb de service. Laissez vide si cela ne s'applique pas."
  },
  _entityForOthers: {
    label: 'Entité pour les autres',
    info:
      "Entrez le nom de l'entité où vous souhaitez recevoir Autre prospect. Laissez vide si cela ne s'applique pas."
  },
  contentType: {
    label: 'Type de contenu',
    info: "Sélectionnez JSON ou URL codée comme type de contenu."
  },
  requestMethod: {
    label: 'Méthode de demande',
    info: 'Sélectionnez GET ou POST comme type de méthode.'
  },
  addHeaders: {
    label: 'Ajouter des en-têtes',
    info:
      "Activez cette option pour ajouter plusieurs en-têtes pour la société spécifique."
  },
  campaignId: {
    label: 'Identifiant de la campagne',
    info: "L'identifiant de la campagne dans laquelle les leads seront créés"
  },
  provider: {
    label: 'Fournisseur',
    info: "Le fournisseur d'intégration pour Leads360 ; quelque chose comme ReachLocal EDU."
  },
  appname: {
    label: "Nom de l'application",
    info:
      "La première partie de l'url de la société cible (http://{name}.infusionsoft.com)."
  },
  actionsequence: {
    label: "ID de séquence d'action",
    info:
      "L'identifiant de la séquence d'action à exécuter lorsque le contact a été créé."
  },
  publicKey: {
    label: 'Clé publique',
    info: 'La clé publique fournie par Legal Intake.'
  },
  privateKey: {
    label: 'Clé privée',
    info: "La clé privée fournie par Legal Intake."
  },
  enableOAuth2: { label: 'Activer OAuth2', info: 'Activer OAuth2.' },
  referrer: {
    label: 'Référent',
    info:
      'Un GUID unique (Globally Unique Identifier) ​​qui identifie le groupe ou le site.'
  },
  serviceUrl: {
    label: 'URL du service',
    info:
      "Obtenez votre URL de service d'organisation dans Paramètres > Personnalisation > Ressources de développement."
  },
  domainName: { label: 'Nom de domaine', info: "Il s'agit d'un domaine sur site." },
  entity: { label: 'Entité', info: "Il s'agit d'un domaine sur site." },
  crmTemplate: {
    label: 'Modèle de CRM',
    info: 'Ici, vous pouvez définir le modèle Lead Xml/Json.'
  },
  sendTemplateAsEmailBody: {
    label: "Envoyer le modèle en tant que corps de l'e-mail",
    info: "Ce drapeau permettra d'envoyer un lead au format Xml/Json comme corps d'email."
  },
  crmEmailSubject: {
    label: 'Sujet du courriel',
    info:
      "La ligne d'objet de l'e-mail, si elle est vide, la valeur par défaut est Lead Email <Content-Type>."
  }
};
export default fr_crm;
