import React, { useContext } from 'react';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import AccordionContext from 'react-bootstrap/AccordionContext';
import storage from 'helpers/storage';

const AccordionButton = ({
  children,
  eventKey,
  callback,
  btnClass,
  storageKey,
  style = {},
  enableStorage = true
}) => {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    callback && callback(eventKey);
    storage.setItem(storageKey, eventKey);
  });

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <a
      href="#"
      tabIndex="0"
      className={`${btnClass}${isCurrentEventKey ? ' active' : ''}`}
      onClick={decoratedOnClick}
      style={style}
    >
      <>{children ? children : null}</>
    </a>
  );
};

export default AccordionButton;
