import React from 'react';

const ActionButtons = ({ sendText, isMobile }) => {
  return (
    <div className="chat-input-actions d-flex align-items-center">
      <span
        className={`${
          isMobile
            ? 'mobile-submit-icon'
            : 'submit-icon btn btn-primary btn-sm px-16 mb-2'
        }`}
        onClick={sendText}
      >
        {/* {sendingText ? (
          <i className="uil uil-spinner spinner ml-2"></i>
        ) : (
          )} */}
        {/* <UilMessage size={30} /> */}
        <i className="uil uil-message"></i>
      </span>
    </div>
  );
};

export default ActionButtons;
