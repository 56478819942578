export const en_leadEmails = {
  heading1: 'Lead Emails',
  leadEmailAddresses: {
    label: 'Lead Email Addresses-(commas to seperate)',
    info:
      'One or multiple emails addresses who will receive each HTML lead. Example: abc@example.com,xyz@example.com'
  },
  chatTranscriptEmailAddresses: {
    label: 'Chat Transcript Email Addresses-(commas to seperate)',
    info:
      'One or multiple emails addresses who will receive each chat transcripts (non-leads).Example: abc@example.com,xyz@example.com'
  },
  classifyChatsAsSpam: {
    label: 'Classify Chats As Spam',
    info: 'If set, the business will receive leads for each chat (non-leads).'
  },
  verified: {
    label: 'Verified',
    info:
      'The lead email addresses who have been sent a verificaiton email to confirm they are recieiving emails. This can only be set by clicking the link in the verification email.'
  },
  verificationEmailSent: {
    label: 'Verification Email Sent',
    info:
      'Whether or not the Lead Email Address has had a verification email sent to it.'
  },
  hasInvalidEmailAddresses: {
    label: 'Has Invalid Email Addresses',
    info:
      'This flag will be enabled if the account has any invalid emails addresses who are not recieiving the HTML leads under the Lead Email Address field.'
  },
  invalidLeadEmailAddresses: {
    label: 'Invalid Lead Email Addresses',
    info:
      'This will display any invalid email addresses that have been flagged by our mail servers.'
  },
  companyType: {
    label: 'Company Type',
    info: 'Set this to enable showing of dealership fields in the dashboard.'
  },
  secureChats: {
    label: 'Secure Chats',
    info: 'If set, forces chats to run over SSL.'
  },
  secureLeads: {
    label: 'Secure Leads',
    info:
      'If set, lead notifications are stripped down and contain a link that requires login.'
  }
};
export default en_leadEmails;
export { default as fr_leadEmails } from '../fr-FR-Dictionary/fr-leadEmails';
export { default as sp_leadEmails } from '../sp-SP-Dictionary/sp-leadEmails';
