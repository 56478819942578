import React, { useState, useEffect, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import Picker from 'emoji-picker-react';
import UilSmile from '@iconscout/react-unicons/icons/uil-smile';

const EmojiPopUp = React.forwardRef((props, ref) => {
  const { onEmojiClick, pickerStyle } = props;
  const [showEmoji, setShowEmoji] = useState(false);

  useImperativeHandle(
    ref,
    () => ({
      showEmoji
    }),
    [showEmoji]
  );

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      let emojiElem = document.getElementsByClassName('emoji-picker-react');
      let emojiBtn = document.getElementById('emoji-picker-btn');
      if (
        showEmoji &&
        emojiElem.length &&
        !emojiElem[0].contains(event.target) &&
        !emojiBtn.contains(event.target)
      ) {
        setShowEmoji(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showEmoji]);

  // show emoji section to select emoji
  const openEmoji = () => {
    setShowEmoji(!showEmoji);
  };

  return (
    <div className="emoji-icon action-icon">
      {/* {showEmoji && ( */}
      <Picker
        onEmojiClick={onEmojiClick}
        disableSearchBar={true}
        native={true}
        // preload={true}
        pickerStyle={{
          visibility: showEmoji ? 'visible' : 'hidden',
          ...pickerStyle
        }}
      />
      {/* )} */}
      <UilSmile id="emoji-picker-btn" onClick={openEmoji} />
    </div>
  );
});

EmojiPopUp.prototype = {
  onEmojiClick: PropTypes.func.isRequired,
  pickerStyle: PropTypes.object
};

EmojiPopUp.defaultProps = {
  pickerStyle: {
    position: 'absolute',
    bottom: '58px',
    left: '0'
  }
};

export default EmojiPopUp;
