import React from 'react';
import { useTranslation } from 'react-i18next';
import SimpleTable from 'helpers/ui-controls/ui-components/SimpleTable';
import { DropzoneArea } from 'material-ui-dropzone';
import { createStyles, makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme =>
  createStyles({
    previewChip: {
      minWidth: 160,
      maxWidth: 210,
      display: 'flex',
      justifyContent: 'space-between',
      border: '1px solid var(--primary)',
      color: 'var(--primary)',
      marginBottom: '8px'
    },
    snackBarTopRight: {
      top: 24,
      right: 24,
      left: 'unset',
      bottom: 'unset'
    },
    customDropzoneParagraph: {
      '&::after': {
        content:
          "'Max file size 2.5 mb | Supported file types png, jpg, doc/docx or pdf'",
        display: 'block',
        fontSize: '1.2rem'
      }
    }
  })
);

const AttachementComponent = ({
  editable,
  isListingEnable = false,
  selectedAttachements = [],
  handleChange,
  handleDelete
}) => {
  const { t } = useTranslation();
  const handleOnChangeAttachements = files => {
    handleChange(files);
  };

  const classes = useStyles();

  return (
    <>
      <div className="mt-2">
        {editable ? (
          <DropzoneArea
            acceptedFiles={['image/jpeg,image/png', 'application/pdf', '.doc']}
            onChange={files => handleOnChangeAttachements(files)}
            showPreviews={true}
            showPreviewsInDropzone={false}
            dropzoneText={`Drag and drop a file here or click`}
            dropzoneParagraphClass={classes.customDropzoneParagraph}
            useChipsForPreview
            alertSnackbarProps={{
              classes: { anchorOriginBottomLeft: classes.snackBarTopRight }
            }}
            maxFileSize={2621440}
            getDropRejectMessage={(file, fileTypes, fileSize) => {
              if (file.size > fileSize) {
                return 'Max file size is 2.5 mb';
              }
              if (!fileTypes.includes(file.type)) {
                return 'Supported File Types are png, jpg, doc/docx or pdf ';
              }
            }}
            previewChipProps={{ classes: { root: classes.previewChip } }}
            previewText="Selected files"
            showAlerts={['error', 'info']}
          />
        ) : null}
      </div>

      {isListingEnable ? (
        <SimpleTable
          editable={editable}
          dataArray={selectedAttachements}
          handleDelete={handleDelete}
        />
      ) : null}
    </>
  );
};

export default AttachementComponent;
