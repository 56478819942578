export const sp_chatDetails = {
  heading: 'Detalles de chat',
  chatTooltip: 'El portal muestra hasta 6 meses de datos',
  createdOn: {
    label: 'Creado',
    info: 'Cuando el chat se inició en nuestro sistema.'
  },
  pickedUpOn: {
    label: 'Captada sobre',
    info: 'Cuando el chat se recogió en.'
  },
  pickedUpBy: {
    label: 'Recogido por',
    info: 'Quien recogió el chat.'
  },
  endedOn: {
    label: 'Finalizado',
    info: 'Cuando terminó el chat en nuestro sistema.'
  },
  leadFlag: {
    label: 'Bandera de plomo',
    info: 'Si la conversación de chat se convirtió en una ventaja válida.'
  },
  ip: {
    label: 'IP',
    info: 'La IP que se ha iniciado el chat.'
  },
  location: {
    label: 'Localización',
    info: 'La ubicación del visitante.'
  },
  referrer: {
    label: 'Referente',
    info: 'La de referencia, donde se inició el chat.'
  },
  chatTranscript: {
    label: 'Transcripción del chat',
    info: 'Transcripción del chat'
  }
};
export default sp_chatDetails;
