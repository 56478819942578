import * as Yup from 'yup';
import { emailRegx } from '../genericSchemas';

/**
 * Setup schema for contact information of the company
 * requires requiredText schema from genericSchemas
 **/

export const widgetCodeSchemas = Yup.object().shape({
  developerEmails: Yup.array('Invalid Emails')
    .of(
      Yup.string().matches(emailRegx, value => {
        //   // added custom error message for array field
        let matches = value.path.match(/\[(.*?)\]/);
        let index = Number(matches[1]);
        return `Email no.${index + 1} is invalid.`;
      })
    )
    .min(1, 'At least 1 email is required.')
    .max(3, 'Maximum 3 emails are allowed at a time.')
    .required('Not be empty.')
});
