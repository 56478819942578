import { setShelvedChatList } from 'features/messagingChats/messagingChats';
import { Enums } from 'helpers/dropdown-enums';
import { logIt } from 'helpers/logger';
import store from 'store';
import RTC from '../rtc';
import { chatChanged } from '../services/chatroom-service';

const Subscribe_Shelved = callbacks => {
  RTC().Subscribe({
    channels: ['/shelvedchats'],
    callbacks: callbacks
  });
};

export const Init_Shelved = () => {
  Subscribe_Shelved({
    onSuccess: function (args) {
      var meta = args.getMeta();
      logIt('log', 'onSuccess::Shelved subscriber ', meta);
      if (meta && meta.length) {
        let incomingList = [];
        let activeList = [];
        let allList = [];
        let shelvedList = [];
        for (let i = 0; i < meta.length; i++) {
          var chatroomByType = chatChanged(meta[i], true);
          if (
            chatroomByType.includeInSections.includes(Enums.RoomType.ActiveChat)
          ) {
            activeList.push(chatroomByType.room);
          }

          if (
            chatroomByType.includeInSections.includes(Enums.RoomType.AllChat)
          ) {
            allList.push(chatroomByType.room);
          }

          if (
            chatroomByType.includeInSections.includes(
              Enums.RoomType.IncomingChat
            )
          ) {
            incomingList.push(chatroomByType.room);
          }

          if (
            chatroomByType.includeInSections.includes(
              Enums.RoomType.ShelvedChat
            )
          ) {
            shelvedList.push(chatroomByType.room);
          }
        }

        store.dispatch(setShelvedChatList(shelvedList));
      }
    },
    onFailure: function (args) {
      logIt('Error', args.getErrorMessage());
    },
    onReceive: function (args) {
      logIt('log', 'shelved on receive--', args);
    }
  });
};

export default Subscribe_Shelved;
