import UilCheck from '@iconscout/react-unicons/icons/uil-check';
import UilTimes from '@iconscout/react-unicons/icons/uil-times';
import React from 'react';

const ActionButtons = ({
  chat,
  onAccept,
  onIgnore = null,
  onReject = null,
  rejectLoading = false
}) => {
  const onAcceptHandler = e => {
    e.stopPropagation();
    onAccept(chat);
  };

  const onIgnoreHandler = e => {
    e.stopPropagation();
    onIgnore(chat);
  };

  const onRejectHandler = e => {
    e.stopPropagation();
    onReject(chat);
  };

  return (
    <div className="action-btn-container text-right">
      {/* TODO */}
      {!!onIgnore && (
        <button
          className="btn btn-danger btn-font-size mr-1 p-0"
          // style={{ width: '45%' }}
          // disabled={true}
          onClick={onIgnoreHandler}
        >
          <UilTimes size={'18'} />
        </button>
      )}
      {!!onReject && (
        <button
          className="btn btn-danger btn-font-size mr-1 p-0"
          // style={{ width: '45%' }}
          disabled={rejectLoading}
          onClick={onRejectHandler}
        >
          {rejectLoading ? (
            <i className="uil uil-spinner spinner"></i>
          ) : (
            <UilTimes size={'18'} />
          )}
        </button>
      )}
      <button
        className="btn btn-success btn-font-size p-0"
        // style={!!onIgnore ? { width: '45%' } : {}}
        onClick={onAcceptHandler}
      >
        <UilCheck size={'18'} />
      </button>
    </div>
  );
};

export default ActionButtons;
