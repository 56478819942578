import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectReport } from 'features/report/reportsSlice';
import { toast } from 'react-toastify';
import { success } from 'helpers/variables';
import {
  companyHeaderSchema,
  reportHeaderSchema
} from 'helpers/schemas/companySchemas/schemas';
import {
  companyHeader,
  reportHeader
} from 'helpers/schemas/companySchemas/initialValues';
import { mapServerValuesToInitialValues } from 'helpers/generic.methods';
import { useFormik } from 'formik';
import Form from 'react-bootstrap/Form';
import DisplayKey from 'helpers/ui-controls/DisplayKey';
import EditableCompanyHeader from 'helpers/ui-controls/EditableCompanyHeader';
import EditSaveButton from 'helpers/ui-controls/ui-components/EditSaveButton';
import { updateCompany } from 'features/companies/companiesSlice';
import { createOrUpdateReport } from 'features/report/reportSlice';
import Button from 'react-bootstrap/Button';
import { UilPlay } from '@iconscout/react-unicons';

const ReportHeader = ({ companyId }) => {
  const { report } = useSelector(e => selectReport(e, companyId));
  const [reportHeaderObj, setReportHeaderObj] = useState(reportHeader);
  const [editable, setEditable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  // const { t } = useTranslation();
  const ReportHeaderFormHandler = formData => {
    //some async task here
    UpdateReport(formData, false);
  };
  const onRun = () => {
    //some async task here
    UpdateReport(report, true);
  };

  const UpdateReport = async (data, run = false) => {
    try {
      setLoading(true);
      let payload = {
        ...data,
        id: report.id
      };
      delete payload['createdOn'];
      delete payload['modifiedOn'];
      const response = await createOrUpdateReport(payload, run);
      setReportHeaderObj(response?.data?.data);
      if (run) {
        toast.success(success.runSuccess('Report'));
      } else {
        toast.success(success.saveSuccess('Report Name'));
      }
      setEditable(false);
      setLoading(false);
    } catch (error) {
      toast.error(success.saveSuccess(error));
      setLoading(false);
    }
  };

  const reportHeaderFormIk = useFormik({
    enableReinitialize: true,
    initialValues: reportHeaderObj,
    validationSchema: reportHeaderSchema,
    onSubmit: ReportHeaderFormHandler
  });
  // const handleOnChange = (element, key, value) => {
  //   CompanyHeaderFormIk.values[key] = value;
  //   CompanyHeaderFormIk.handleChange(element);
  //   setDisabled(value ? false : true);
  // };

  useEffect(() => {
    mapServerValuesToInitialValues(report, reportHeader)
      .then(data => {
        setReportHeaderObj({
          ...reportHeader,
          ...data
        });
      })
      .catch(err => {
        throw new Error(err);
      });
  }, [report]);

  const handleOnChange = (element, key, value) => {
    reportHeaderFormIk.values[key] = value;
    reportHeaderFormIk.handleChange(element);
    // setDisabled(value ? false : true);
  };
  return (
    // <>
    //   <DisplayKey displayKey={report['id']} />
    //   <div className="page-header">
    //     <Form
    //       className="form"
    //       onSubmit={CompanyHeaderFormIk.handleSubmit}
    //       autoComplete="off"
    //     >
    //       <div className="page-header-bottom d-flex align-items-center justify-content-between">
    //         <h1 className="main-head text-primary-2">{report.name}</h1>
    //         {/* <Button className="btn-sm btn-flex" variant="lightgray-3">
    //           <UilPlus />
    //           Open account
    //         </Button> */}
    //       </div>
    //     </Form>
    //   </div>
    // </>
    <>
      <DisplayKey displayKey={report['id']} />
      <div className="page-header">
        {/*  <div className="page-header-top d-flex align-items-center mb-2r">
          <h6 className="company-id mb-0 mr-8">{report.name}</h6>
           {company.active === true ? (
           <Button className="btn-sm btn-flex btn-status" variant="success-3">
             <UilCheckCircle className="mr-1" />
             {t('company.general.activeText')}
           </Button>
         ) : company.active === false ? (
           <Button className="btn-sm btn-flex btn-status" variant="danger-4">
             <UilTimesCircle className="mr-1" />
             {t('company.general.inActiveText')}
           </Button>
         ) : null} 
        </div> */}
        <Form
          className="form"
          onSubmit={reportHeaderFormIk.handleSubmit}
          autoComplete="off"
        >
          <div className="page-header-bottom d-flex align-items-center justify-content-between">
            {/* <h1 className="main-head text-primary-2">{company.companyName}</h1> */}
            <EditableCompanyHeader
              headerDataProperty={report['name']}
              handleChange={handleOnChange}
              error={reportHeaderFormIk.errors['name']}
              editable={editable}
              field="name"
              titleText={report['name']}
            />
            <div className="btn-set">
              <Button
                disabled={loading || editable}
                className={'btn-md btn-flex ml-6r'}
                type="submit"
                variant="primary"
                onClick={onRun}
              >
                <UilPlay />
                <span>
                  Run report
                  {loading && (
                    <i className="uil uil-spinner spinner small ml-2"></i>
                  )}
                </span>
              </Button>
              <EditSaveButton
                editable={editable}
                handleEditableState={setEditable}
                loading={loading}
                headerEditbutton={true}
                disabled={loading}
                // handleOnClick={() => companyHeaderFormHandler()}
              />
              {/* <Button className="btn-sm btn-flex" variant="lightgray-3">
               <UILFileExport />
             </Button> */}
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default ReportHeader;
