import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { makeStyles } from '@material-ui/core/styles';
import ScrollBar from 'components/Scrollbars';
import './style.scss';

export const Front = props => {
  return <div className="front tile">{props.children}</div>;
};

export const Back = props => {
  return <div className="back tile">{props.children}</div>;
};

const useStyle = makeStyles({
  flipperStyles: {
    width: '100%',
    minHeight: '110px',
    maxHeight: '110px',
    height: props => (props.flipped && props.isMobile ? '350px' : '110px')
  }
});
const Flipper = ({ orientation, children, flipperContainerStyles = {} }) => {
  const [flipped, setFlipped] = useState(false);
  const classes = useStyle({ flipped, isMobile });

  return (
    <div
      className={`flipper-container ${orientation} ${classes.flipperStyles}`}
      style={flipperContainerStyles}
    >
      <div className={'flipper' + (flipped ? ' flipped' : '')}>
        {/* <ScrollBar horizontalScroll={false} style={{ maxHeight: '150px' }}> */}
        <div>{children}</div>
        {/* </ScrollBar> */}
      </div>
      <div>
        <span className="flip-button">
          <a onClick={() => setFlipped(!flipped)}>
            {flipped ? 'Back' : 'See more details'}
          </a>
        </span>
      </div>
    </div>
  );
};

Flipper.propTypes = {
  orientation: PropTypes.string,
  flipperContainerStyles: PropTypes.object,
  children: PropTypes.node.isRequired
};

Flipper.defaultProps = {
  orientation: 'horizontal', //vertical
  flipperContainerStyles: {}
};

export default Flipper;
