import React, { useEffect } from 'react';
import Image from 'react-bootstrap/Image';
import { AppMetaProvider } from './helper';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectDynamicMeta,
  setDynamicMetaToState
} from 'features/auth/authSlice';
import Fade from '@material-ui/core/Fade';

const Logo = ({ login = false }) => {
  const dispatch = useDispatch();
  const _dynamicMeta = useSelector(selectDynamicMeta);
  let favicon = document.getElementById('favicon');
  let applyTouchIcon = document.getElementById('apple-touch-icon');

  useEffect(() => {
    if (!_dynamicMeta) {
      favicon.setAttribute('href', '');
      fetchAppMeta();
    }
  }, [_dynamicMeta]);

  var fetchAppMeta = async function () {
    var {
      isWhiteLabeled,
      allowSignup,
      haveMultipleOccurrences,
      hostLogo,
      hostName,
      isDefaultHost,
      signupUrl
    } = await AppMetaProvider().GetDynamicMeta();

    let dynamicMeta = {
      isWhiteLabeled,
      allowSignup,
      haveMultipleOccurrences,
      hostLogo,
      hostName,
      isDefaultHost,
      signupUrl
    };

    dispatch(setDynamicMetaToState(dynamicMeta));
  };

  window.document.title = `Live Chat ${
    _dynamicMeta?.hostName ? '| ' + _dynamicMeta?.hostName : ''
  }`;
  applyTouchIcon.setAttribute('href', _dynamicMeta?.hostLogo);
  favicon.setAttribute('href', _dynamicMeta?.hostLogo);

  return (
    <div className="logo-container" style={{ maxWidth: '170px' }}>
      {!_dynamicMeta ? (
        login && <i className="uil uil-spinner spinner logo-resizing"></i>
      ) : (
        <Fade in timeout={1000}>
          <Image
            className={'img-w w-100 h-auto'}
            style={{ maxHeight: '90px' }}
            src={_dynamicMeta?.hostLogo}
          />
        </Fade>
      )}
    </div>
  );
};

export default Logo;
