import useMediaQuery from '@material-ui/core/useMediaQuery';

function useViewport(props) {
  const breakPoints = {
    xs: useMediaQuery('screen and (max-width:425px)'),
    sm: useMediaQuery('screen and (min-width:426px) and (max-width:1024px)'),
    md: useMediaQuery('screen and (min-width:1025px) and (max-width:1440px)'),
    lg: useMediaQuery('screen and (min-width:1441px)')
  };

  return breakPoints;
}

export default useViewport;
