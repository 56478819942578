import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: 10,
  totalRequest: 0,
  show: false
};

/**
 * @name @createSlice
 * @description create redux store slice for loading
 **/

export const loadingSlice = createSlice({
  name: 'loading',
  initialState: { ...initialState },

  reducers: {
    /**
     * @name @login
     * @description login reducer to call login async API with axios instance
     * @requires ApiServices.get method, state and API payload
     **/

    startLoading: (state = initialState, action) => {
      //  add random number to loading
      let randomeNumber = Math.floor(Math.random() * (80 - 20) + 20);
      if (state.loading + randomeNumber < 90)
        state.loading = state.loading + randomeNumber;
      else state.loading = randomeNumber;
    },

    endLoading: (state = initialState, action) => {
      state.loading = 100;
    },

    showLoading: (state = initialState, action) => {
      state.show = action.payload;
      if (!action.payload) {
        state.loading = initialState.loading;
      }
    }
  }
});

export const { startLoading, endLoading, showLoading } = loadingSlice.actions;

/* called a selector and allows to select values from the state */
export const selectLoading = state => {
  return state.loading;
};

export default loadingSlice.reducer;
