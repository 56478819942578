import React, { useEffect, useState } from 'react';
import './dashboard.scss';
import { getFeaturesUsageStats } from 'features/analytics/analyticSlice';
import DoughnutChart from 'components/Charts/DoughnutChart';
import { toast } from 'react-toastify';
import { camelCaseStringToNormalText } from 'helpers/generic.methods';
import { LoadingComponent } from './loadingListComp';
import { GraphColors } from './filters/constants';

const FeaturesUsageGraph = ({ graphData, loading }) => {
  const [chartData, setChartData] = useState({});
  const [isData, setIsData] = useState(false);

  useEffect(() => {
    getFeaturesUsage();
  }, [graphData]);

  const getFeaturesUsage = () => {
    // try {
    //   let payload = {};
    // TODO: replace data with actual api call function in analyticSlice
    // const response = await getFeaturesUsageStats(payload);

    if (graphData && Object.keys(graphData).length) {
      let total = Object.values(graphData).reduce((a, b) => a + b, 0);
      let colors = Object.keys(graphData)
        .map(key => GraphColors[key])
        .filter(v => v);

      setChartData({
        labels: Object.keys(graphData).map(key => {
          let label = total ? ((graphData[key] / total) * 100).toFixed(2) : 0;

          // return `${camelCaseStringToNormalText(key, 'heading')} : ${label}%`;
          return `${camelCaseStringToNormalText(key, 'heading')}`;
        }),
        datasets: [
          {
            data: Object.keys(graphData).map(key => graphData[key]), // Specify the data values array
            borderColor: colors, // Add custom color border
            backgroundColor: colors, // Add custom color background (Points and Fill)
            borderWidth: 1 // Specify bar border width
          }
        ]
      });
      setIsData(true);
    } else {
      setChartData({});
      setIsData(false);
    }
    // } catch (error) {
    //   toast.error(error || error.message);
    // }
  };
  return (
    <div className="chart-wrapper text-center">
      {!loading && chartData && Object.keys(chartData).length ? (
        <DoughnutChart chartData={chartData} canvasHeight={250} cutout={80} />
      ) : null}
      <LoadingComponent
        loading={loading}
        dataLength={Object.keys(graphData || {}).length}
        isList={false}
      />
    </div>
  );
};

export default FeaturesUsageGraph;
