import { logIt } from 'helpers/logger';
import { encryptText } from 'helpers/messaging/message.methods';

export const chatOnReceiveCallback = function (options) {
  // dispatch
  let data = options.data;
  let channel = options.channel;
  if (channel == '/chatroom/' + options.chatId) {
    logIt('log', 'onMessageArrived', data);
    let mData = encryptText(data);
    options.onMessageArrived(mData, false);
  } else if (channel == '/chatroom/' + options.chatId + '/typing') {
    data.chatId = options.chatId;
    options.onTypingArrived(data, false);
  } else if (channel == '/chatroom/' + options.chatId + '/meta') {
    logIt('log', 'chatOnReceiveCallback::onMetaArrived', options);
    data.chatId = options.chatId;
    options.onMetaArrived(data);
  } else if (channel == '/chatroom/' + options.chatId + '/participants') {
    let chatData = {
      chatId: options.chatId,
      participants: data
    };
    options.onParticipantsChanged(chatData);
  }
  // } else if (channel == '/chatroom/' + id + '/meta') {
  //     me.onMetaArrived(data);
  // } else if (channel == '/calls/chat/' + id) {
  //     me.onCallStatusArrived(data);
  // } else if (channel == '/chatroom/' + id + '/virtualassistant/ner') {
  //     me.onVirtualAssistantNERArrived(data);
  // } else if (channel == '/chatroom/' + id + '/templatedquestions/answer') {
  //     me.onVisitorResponseArrivedForTemplatedQuestions(data);
  // } else if (channel == '/chatroom/' + id + '/live') {
  //     //add chatId to argumnents
  //     if (data != undefined) {
  //         data.chatId = id;
  //     }
  //     me.onLiveArrived(data);
  // }
  // else if (channel == '/chatroom/' + id + '/realtime/text') {
  //     me.onRealtimeTextArrived(data);
  // }
  // else if (channel == '/chatroom/' + id + '/scriptsuggestion') {
  //     me.onScriptSuggestion(data);
  // }
};
export const chatOnSuccessCallback = function (args) {
  const meta = args?.meta;
  // TODO:: OnSuccess need to change participant
  //me.options.participantId = meta.participantId;
  for (var i = 0; i < meta.history.length; i++) {
    // when loading history, its either the ISR or the visitor
    // very specific case in dashboard we need 'Room', and whenever it comes we are sending in roomDetails property
    let mData = encryptText(meta.history[i]);
    args.onMessageArrived(mData, false);
  }
};

export const chatOnPublishCallback = {
  onSuccess: args => {
    args.onMessageArrived(args.data, false);
  },
  onFailure: args => {
    alert(
      "We're sorry, there was a problem sending the message. The chat may no longer be active."
    );
    logIt('Error', args.getErrorMessage());
  }
};
