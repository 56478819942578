import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import Button from 'react-bootstrap/Button';
import { UilEye } from '@iconscout/react-unicons';
import dropdownEnums, { Enums } from 'helpers/dropdown-enums';
import CopyButton from 'CellRenderer/CopyButton';
import Hover from 'CellRenderer/Hover';
import StatusButton from 'CellRenderer/StatusButton';
import TimeStamp from 'CellRenderer/TimeStamp';
import StatusIndicator from 'CellRenderer/StatusIndicator';
import MyReportStatus from 'CellRenderer/MyReportStatus';
import ChatHistory from 'pages/portal/messaging/History/chatHistory';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import moment from 'moment';

const CompaniesColumns = [
  {
    name: 'id',
    header: 'ID',
    minWidth: 100,
    defaultFlex: 1,
    filterDelay: 800,
    enableFilter: true,
    filterType: 'number',
    className: 'hide-filter-button',
    visible: true,
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'companyName',
    header: 'Company Name',
    // defaultFlex: 2,
    minWidth: 180,
    defaultFlex: 2,
    filterDelay: 800,
    enableFilter: true,
    filterType: 'string',
    cellProps: { className: 'copy-button' },
    visible: true,
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'companyKey',
    header: 'Company Key',
    minWidth: 150,
    defaultFlex: 2,
    filterDelay: 800,
    visible: true,
    enableFilter: true,
    filterType: 'string',
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'active',
    header: 'Active',
    minWidth: 120,
    defaultFlex: 1,
    visible: true,
    enableFilter: true,
    className: 'hide-filter-button',
    filterType: 'select',
    type: 'select',
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: 'true',
          label: 'Yes'
        },
        {
          id: 'false',
          label: 'No'
        }
      ]
    },
    render: ({ value }) => <StatusButton value={value} />
  },
  {
    name: 'hostName',
    header: 'Host',
    minWidth: 150,
    defaultFlex: 2,
    filterDelay: 800,
    enableFilter: true,
    filterType: 'string',
    visible: true,
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'hasInvalidEmails',
    header: 'Invalid Emails',
    minWidth: 130,
    //filterDelay: 800,
    enableFilter: true,
    visible: false,
    filterType: 'select',
    headerProps: { className: 'hide-filter-button' },
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: 'true',
          label: 'Yes'
        },
        {
          id: 'false',
          label: 'No'
        }
      ]
    },
    render: ({ value }) => <StatusButton value={value} />
  },
  // {
  //   name: 'version',
  //   header: 'Version',
  //   minWidth: 130,
  //   //filterDelay: 800,
  //   enableFilter: true,
  //   visible: true,
  //   filterType: 'select',
  //   headerProps: { className: 'hide-filter-button' },
  //   filterEditor: SelectFilter,
  //   filterEditorProps: {
  //     placeholder: 'Select',
  //     dataSource: [
  //       {
  //         id: '',
  //         label: 'All'
  //       },
  //       {
  //         id: '0',
  //         label: 'V1'
  //       },
  //       {
  //         id: '1',
  //         label: 'V2'
  //       },
  //       {
  //         id: '2',
  //         label: 'V3'
  //       }
  //     ]
  //   },
  // render: ({ value }) => <StatusButton value={value} />
  // },
  {
    name: 'hasInboundEmails',
    header: 'Inbound Emails',
    minWidth: 130,
    //filterDelay: 800,
    enableFilter: true,
    visible: false,
    filterType: 'select',
    headerProps: { className: 'hide-filter-button' },
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: 'true',
          label: 'Yes'
        },
        {
          id: 'false',
          label: 'No'
        }
      ]
    },
    render: ({ value }) => <StatusButton value={value} />
  },
  {
    name: 'domain',
    header: 'Website',
    minWidth: 150,
    filterDelay: 800,
    enableFilter: true,
    filterType: 'string',
    visible: false,
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'preferInCountryAgents',
    header: 'Geographical Preference',
    minWidth: 130,
    //filterDelay: 800,
    enableFilter: true,
    visible: false,
    filterType: 'select',
    headerProps: { className: 'hide-filter-button' },
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: 'true',
          label: 'Yes'
        },
        {
          id: 'false',
          label: 'No'
        }
      ]
    },
    render: ({ value }) => <StatusButton value={value} />
  },
  {
    name: 'startDate',
    header: 'Start Date',
    minWidth: 150,
    enableFilter: true,
    filterType: 'date',
    visible: false,
    dateFormat: 'DD-MMMM-YYYY',
    cellProps: { className: 'copy-button' },
    filterEditor: DateFilter,
    filterEditorProps: () => {
      return {
        dateFormat: 'string',
        cancelButton: false,
        highlightWeekends: false,
        placeholder: 'Select Date'
      };
    },
    render: ({ value }) => <TimeStamp value={value} />
  },
  {
    name: 'modifiedOn',
    header: 'Modified',
    minWidth: 150,
    enableFilter: true,
    filterType: 'date',
    visible: false,
    dateFormat: 'DD-MMMM-YYYY',
    cellProps: { className: 'copy-button' },
    filterEditor: DateFilter,
    filterEditorProps: () => {
      return {
        dateFormat: 'string',
        cancelButton: false,
        highlightWeekends: false,
        placeholder: 'Select Date'
      };
    },
    render: ({ value }) => <TimeStamp value={value} />
  },
  {
    name: 'createdOn',
    header: 'Created',
    minWidth: 150,
    enableFilter: true,
    filterType: 'date',
    visible: false,
    dateFormat: 'DD-MMMM-YYYY',
    cellProps: { className: 'copy-button' },
    filterEditor: DateFilter,
    filterEditorProps: () => {
      return {
        dateFormat: 'string',
        cancelButton: false,
        highlightWeekends: false,
        placeholder: 'Select Date'
      };
    },
    render: ({ value }) => <TimeStamp value={value} />
  },
  {
    name: 'pacingEnabled',
    header: 'Pacing',
    minWidth: 100,
    enableFilter: true,
    filterType: 'select',
    visible: false,
    headerProps: { className: 'hide-filter-button' },
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: 'true',
          label: 'Enabled'
        },
        {
          id: 'false',
          label: 'Disabled'
        }
      ]
    },
    render: ({ value }) => (
      <StatusButton value={value} variant="enable-status" />
    )
  },
  {
    name: 'budgetPauseEnabled',
    header: 'Paused',
    minWidth: 130,
    //filterDelay: 800,
    enableFilter: true,
    visible: false,
    filterType: 'select',
    headerProps: { className: 'hide-filter-button' },
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: 'true',
          label: 'Yes'
        },
        {
          id: 'false',
          label: 'No'
        }
      ]
    },
    render: ({ value }) => <StatusButton value={value} />
  },
  {
    name: 'verified',
    header: 'Verified',
    minWidth: 100,
    headerProps: { className: 'hide-filter-button' },
    enableFilter: true,
    filterType: 'select',
    visible: false,
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: 'true',
          label: 'Yes'
        },
        {
          id: 'false',
          label: 'No'
        }
      ]
    },
    render: ({ value }) => <StatusButton value={value} />
  },
  {
    name: 'lastLeadSentDate',
    header: 'Last Lead Sent On',
    minWidth: 150,
    dateFormat: 'DD-MMMM-YYYY',
    cellProps: { className: 'copy-button' },
    enableFilter: true,
    filterType: 'date',
    visible: false,
    filterEditor: DateFilter,
    filterEditorProps: () => {
      return {
        dateFormat: 'string',
        cancelButton: false,
        highlightWeekends: false,
        placeholder: 'Select Date'
      };
    },
    render: ({ value }) => <TimeStamp value={value} />
  },
  {
    name: 'leadEmailAddresses',
    header: 'Lead Email Addresses',
    enableFilter: true,
    filterType: 'string',
    visible: false,
    minWidth: 180,
    filterDelay: 800,
    cellProps: { className: 'copy-button' },

    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'requireSecureLeads',
    header: 'Secure Leads',
    minWidth: 130,
    headerProps: { className: 'hide-filter-button' },
    enableFilter: true,
    filterType: 'select',
    visible: false,
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: 'true',
          label: 'Yes'
        },
        {
          id: 'false',
          label: 'No'
        }
      ]
    },
    render: ({ value }) => <StatusButton value={value} />
  },
  {
    name: 'copyEntireTranscriptToLead',
    header: 'Transcript in Notes',
    minWidth: 130,
    //filterDelay: 800,
    enableFilter: true,
    visible: false,
    filterType: 'select',
    headerProps: { className: 'hide-filter-button' },
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: 'true',
          label: 'Yes'
        },
        {
          id: 'false',
          label: 'No'
        }
      ]
    },
    render: ({ value }) => <StatusButton value={value} />
  },
  {
    name: 'includeTranscriptDetails',
    header: 'Transcript in Leads',
    minWidth: 130,
    //filterDelay: 800,
    enableFilter: true,
    visible: false,
    filterType: 'select',
    headerProps: { className: 'hide-filter-button' },
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: 'true',
          label: 'Yes'
        },
        {
          id: 'false',
          label: 'No'
        }
      ]
    },
    render: ({ value }) => <StatusButton value={value} />
  },
  {
    name: 'quickConnectEnabled',
    header: 'Call Connect Enabled',
    minWidth: 180,
    filterEditor: SelectFilter,
    headerProps: { className: 'hide-filter-button' },
    enableFilter: true,
    filterType: 'select',
    visible: false,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: 'true',
          label: 'Yes'
        },
        {
          id: 'false',
          label: 'No'
        }
      ]
    },
    render: ({ value }) => <StatusButton value={value} />
  },
  // {
  //   name: 'priority',
  //   header: 'Priority',
  //   minWidth: 180,
  //   filterEditor: SelectFilter,
  //   headerProps: { className: 'hide-filter-button' },
  //   enableFilter: true,
  //   filterType: 'select',
  // visible: false,
  //   filterEditorProps: {
  //     placeholder: 'Select',
  //     dataSource: [
  //       {
  //         id: '',
  //         label: 'All'
  //       },
  //       {
  //         id: '1',
  //         label: 'Normal'
  //       },
  //       {
  //         id: '2',
  //         label: 'Medium'
  //       },
  //       {
  //         id: '3',
  //         label: 'High'
  //       }
  //     ]
  //   },
  // render: ({ value }) => <StatusButton value={value} />
  // },
  {
    name: 'primaryContactName',
    header: 'Primary Contact Name',
    minWidth: 180,
    filterDelay: 800,
    enableFilter: true,
    filterType: 'string',
    visible: false,
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'primaryContactEmail',
    header: 'Primary Contact Email',
    minWidth: 180,
    filterDelay: 800,
    enableFilter: true,
    filterType: 'string',
    visible: false,
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'primaryContactPhone',
    header: 'Primary Contact Phone Number',
    minWidth: 200,
    filterDelay: 800,
    enableFilter: true,
    filterType: 'string',
    visible: false,
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  // {
  //   name: 'callConnectPhone',
  //   header: 'Call Connect Phone Number',
  //   minWidth: 180,
  //   filterDelay: 800,
  //   enableFilter: true,
  //   filterType: 'string',
  // visible: false,
  //   cellProps: { className: 'copy-button' },
  // render: ({ value }) => <CopyButton value={value} />
  // },
  {
    name: 'canRequestLeadChange',
    header: 'Request Lead Change',
    minWidth: 160,
    //filterDelay: 800,
    enableFilter: true,
    visible: false,
    filterType: 'select',
    headerProps: { className: 'hide-filter-button' },
    filterEditor: SelectFilter,
    filterEditorProps: {
      placeholder: 'Select',
      dataSource: [
        {
          id: '',
          label: 'All'
        },
        {
          id: 'true',
          label: 'Yes'
        },
        {
          id: 'false',
          label: 'No'
        }
      ]
    },
    render: ({ value }) => <StatusButton value={value} />
  }
  // {
  //   name: 'referredBy',
  //   header: 'Referred By',
  //   minWidth: 180,
  //   filterDelay: 800,
  //   enableFilter: true,
  //   filterType: 'string',
  // visible: false,
  //   cellProps: { className: 'copy-button' },
  // render: ({ value }) => <CopyButton value={value} />
  // },
  // {
  //   name: 'salesManager',
  //   header: 'Sales Manager',
  //   minWidth: 180,
  //   filterDelay: 800,
  //   enableFilter: true,
  //   filterType: 'string',
  // visible: false,
  //   cellProps: { className: 'copy-button' },
  //
  // render: ({ value }) => <CopyButton value={value} />
  // },
  // {
  //   name: 'smsEnabled',
  //   header: 'SMS Enabled',
  //   minWidth: 130,
  //   //filterDelay: 800,
  //   enableFilter: true,
  //   visible: true,
  //   filterType: 'select',
  //   headerProps: { className: 'hide-filter-button' },
  //   filterEditor: SelectFilter,
  //   filterEditorProps: {
  //     placeholder: 'Select',
  //     dataSource: [
  //       {
  //         id: '',
  //         label: 'All'
  //       },
  //       {
  //         id: 'true',
  //         label: 'Yes'
  //       },
  //       {
  //         id: 'false',
  //         label: 'No'
  //       }
  //     ]
  //   },
  // render: ({ value }) => <StatusButton value={value} />
  // },
  // {
  //   name: 'exitpopupEnabled',
  //   header: 'Exitpopup Enabled',
  //   minWidth: 130,
  //   //filterDelay: 800,
  //   enableFilter: true,
  //   visible: true,
  //   filterType: 'select',
  //   headerProps: { className: 'hide-filter-button' },
  //   filterEditor: SelectFilter,
  //   filterEditorProps: {
  //     placeholder: 'Select',
  //     dataSource: [
  //       {
  //         id: '',
  //         label: 'All'
  //       },
  //       {
  //         id: 'true',
  //         label: 'Yes'
  //       },
  //       {
  //         id: 'false',
  //         label: 'No'
  //       }
  //     ]
  //   },
  // render: ({ value }) => <StatusButton value={value} />
  // },
  // {
  //   name: 'preChatEnabled',
  //   header: 'Pre-chat Enabled',
  //   minWidth: 130,
  //   //filterDelay: 800,
  //   enableFilter: true,
  //   visible: true,
  //   filterType: 'select',
  //   headerProps: { className: 'hide-filter-button' },
  //   filterEditor: SelectFilter,
  //   filterEditorProps: {
  //     placeholder: 'Select',
  //     dataSource: [
  //       {
  //         id: '',
  //         label: 'All'
  //       },
  //       {
  //         id: 'true',
  //         label: 'Yes'
  //       },
  //       {
  //         id: 'false',
  //         label: 'No'
  //       }
  //     ]
  //   },
  // render: ({ value }) => <StatusButton value={value} />
  // },
  // {
  //   name: 'groups',
  //   header: 'Groups',
  //   minWidth: 180,
  //   filterDelay: 800,
  //   enableFilter: true,
  //   filterType: 'string',
  //   cellProps: { className: 'copy-button' },
  // render: ({ value }) => <CopyButton value={value} />
  // },
];

const setDefaultVissibleCols = (cols, keys) => {
  let updatedCols = cols.map(col =>
    keys.includes(col.name)
      ? { ...col, visible: true }
      : { ...col, visible: false }
  );
  return updatedCols;
};

export const companiesTableConfig = Comp => {
  let columns = [...CompaniesColumns];
  if (Comp)
    columns.push({
      name: 'actions',
      header: '',
      minWidth: 100,
      defaultFlex: 1,
      sortable: false,
      visible: true,
      render: value => {
        return (
          <div className="d-flex">
            {Comp ? <Comp value={value.data} /> : <></>}
          </div>
        );
      }
    });

  return columns;
};
export const widgetCompaniesTableConfig = Comp => {
  let columns = setDefaultVissibleCols(
    [...CompaniesColumns],
    ['id', 'companyName', 'companyKey', 'active']
  );
  if (Comp)
    columns.push({
      name: 'actions',
      header: '',
      minWidth: 100,
      defaultFlex: 1,
      sortable: false,
      visible: true,
      render: value => {
        return (
          <div className="d-flex">
            {Comp ? <Comp value={value.data} /> : <></>}
          </div>
        );
      }
    });
  return columns;
};

export const historyTableConfig = [
  {
    name: 'companyName',
    header: '',
    defaultFlex: 1,
    // defaultWidth: 440,
    filterDelay: 800,
    // minWidth: 280,
    enableFilter: true,
    filterType: 'string',
    defaultVissible: true,
    className: 'hide-filter-button',
    render: ({ value }) => <ChatHistory companyName={value} />
  }
];

export const leadsTableConfig = (isEncrypt = false, Comp) => {
  let columns = [
    {
      name: 'id',
      header: 'ID',
      headerProps: { className: 'hide-filter-button' },
      filterDelay: 800,
      minWidth: 100,
      defaultFlex: 1,
      enableFilter: true,
      filterType: 'number',
      visible: true,
      cellProps: { className: 'copy-button' },
      render: ({ value }) => <CopyButton value={value} />
    },
    {
      name: 'companyName',
      header: 'Company Name',
      minWidth: 180,
      defaultFlex: 1,
      enableFilter: true,
      filterType: 'string',
      visible: true,
      filterDelay: 800,
      cellProps: { className: 'copy-button' },
      render: ({ value }) => <CopyButton value={value} />
    },
    {
      name: 'companyKey',
      header: 'Company Key',
      minWidth: 180,
      defaultFlex: 1,
      enableFilter: true,
      filterType: 'string',
      visible: true,
      filterDelay: 800,
      cellProps: { className: 'copy-button' },
      render: ({ value }) => <CopyButton value={value} />
    },
    {
      name: 'name',
      header: 'Name',
      minWidth: 150,
      defaultFlex: 1,
      enableFilter: true,
      filterType: 'string',
      visible: true,
      filterDelay: 800,
      cellProps: { className: 'copy-button' },
      render: ({ value }) => <CopyButton value={value} />
    },
    {
      name: 'phone',
      header: 'Phone',
      minWidth: 200,
      defaultFlex: 1,
      enableFilter: true,
      filterType: 'string',
      hideColumn: isEncrypt,
      visible: true,
      filterDelay: 800,
      cellProps: { className: 'copy-button' },
      render: ({ value }) => <CopyButton value={value} />
    },
    {
      name: 'email',
      header: 'Email',
      minWidth: 200,
      defaultFlex: 1,
      enableFilter: true,
      filterType: 'string',
      hideColumn: isEncrypt,
      visible: true,
      filterDelay: 800,
      cellProps: { className: 'copy-button' },
      render: ({ value }) => <CopyButton value={value} />
    },
    {
      name: 'processedByCustomer',
      header: 'Processed',
      minWidth: 150,
      defaultFlex: 1,
      enableFilter: true,
      filterType: 'select',
      visible: false,
      headerProps: { className: 'hide-filter-button' },
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: 'Select',
        dataSource: [
          {
            id: '',
            label: 'All'
          },
          {
            id: 'true',
            label: 'Yes'
          },
          {
            id: 'false',
            label: 'No'
          }
        ]
      },
      render: ({ value }) => <span>{value ? 'Yes' : 'No'}</span>
    },
    {
      name: 'domain',
      header: 'Website',
      minWidth: 150,
      defaultFlex: 1,
      filterDelay: 800,
      enableFilter: true,
      filterType: 'string',
      visible: false,
      cellProps: { className: 'copy-button' },
      render: ({ value }) => <CopyButton value={value} />
    },
    {
      name: 'createdOn',
      header: 'Start Date',
      minWidth: 180,
      defaultFlex: 1,
      enableFilter: true,
      filterType: 'date',
      visible: true,
      dateFormat: 'DD-MMMM-YYYY',
      cellProps: { className: 'copy-button' },
      filterEditor: DateFilter,
      filterEditorProps: () => {
        return {
          dateFormat: 'string',
          cancelButton: false,
          highlightWeekends: false,
          placeholder: 'Select Date'
        };
      },
      render: ({ value }) => <TimeStamp value={value} />
    },
    // {
    //   name: 'sentBy',
    //   header: 'Sent By',
    //   minWidth: 150,
    //   filterDelay: 800,
    //   enableFilter: true,
    //   filterType: 'string',
    //   visible: false,
    //   cellProps: { className: 'copy-button' },
    //   render: ({ value }) => <CopyButton value={value} />
    // },
    {
      name: 'leadType',
      header: 'Type',
      minWidth: 120,
      defaultFlex: 1,
      enableFilter: true,
      filterType: 'select',
      visible: true,
      headerProps: { className: 'hide-filter-button' },
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: 'Select',
        dataSource: [
          {
            id: '',
            label: 'All'
          },
          {
            id: '1',
            label: 'Sales'
          },
          {
            id: '2',
            label: 'Service'
          },
          {
            id: '3',
            label: 'Other'
          }
        ]
      },
      render: ({ value }) =>
        dropdownEnums.enum_LeadType.map(
          item => item.value === value && item.name
        )
    },
    // {
    //   name: 'specialInstruction',
    //   header: 'Special Instructions',
    //   minWidth: 150,
    //   filterDelay: 800,
    //   enableFilter: true,
    //   filterType: 'string',
    //   visible: false,
    //   cellProps: { className: 'copy-button' },
    //   render: ({ value }) => <CopyButton value={value} />
    // },
    // {
    //   name: 'initiatedBy',
    //   header: 'Initiated By',
    //   minWidth: 150,
    //   defaultFlex: 1,
    //   enableFilter: true,
    //   filterType: 'select',
    //   visible: true,
    //   headerProps: { className: 'hide-filter-button' },
    //   filterDelay: 800,
    //   filterEditor: SelectFilter,
    //   filterEditorProps: {
    //     placeholder: 'Select',
    //     dataSource: [
    //       { id: '', label: 'All' },
    //       { id: '0', label: 'Unknown' },
    //       { id: '1', label: 'Bar' },
    //       { id: '2', label: 'Dom' },
    //       { id: '3', label: 'Window' },
    //       { id: '4', label: 'Static' },
    //       { id: '5', label: 'Standard' },
    //       { id: '6', label: 'Tab' },
    //       { id: '7', label: 'ExitPopup' },
    //       { id: '8', label: 'SmsStatic' },
    //       { id: '9', label: 'SmsContainer' },
    //       { id: '10', label: 'FacebookMessenger' },
    //       { id: '11', label: 'Video' },
    //       { id: '12', label: 'PreChat' },
    //       { id: '13', label: 'SmsWeb' },
    //       { id: '14', label: 'WebApi' },
    //       { id: '15', label: 'GoogleMyBusiness' }
    //     ]
    //   },
    //   cellProps: { className: 'copy-button' },
    //   render: ({ value }) =>
    //     dropdownEnums.enum_InitiatedBy.map(
    //       item => item.value === value && item.name
    //     )
    // },
    {
      name: 'initiatedByName',
      header: 'Initiated By',
      minWidth: 150,
      filterDelay: 800,
      defaultFlex: 1,
      enableFilter: true,
      filterType: 'string',
      visible: false,
      cellProps: { className: 'copy-button' },
      render: ({ value }) => <CopyButton value={value} />
    },
    // {
    //   name: 'leadCategoryName',
    //   header: 'Category',
    //   minWidth: 150,
    //   filterDelay: 800,
    //   enableFilter: true,
    //   filterType: 'string',
    //   visible: false,
    //   cellProps: { className: 'copy-button' },
    //   render: ({ value }) => <CopyButton value={value} />
    // },
    {
      name: 'hostName',
      header: 'Host',
      minWidth: 200,
      defaultFlex: 2,
      enableFilter: true,
      filterType: 'string',
      visible: true,
      filterDelay: 800,
      cellProps: { className: 'copy-button' },
      render: ({ value }) => <CopyButton value={value} />
    },
    // {
    //   name: 'isLead',
    //   header: 'Referred',
    //   minWidth: 100,
    //   enableFilter: true,
    //   filterType: 'select',
    //   defaultFlex: 2,
    //   visible: true,
    //   className: 'hide-filter-button',
    //   filterEditor: SelectFilter,
    //   filterEditorProps: {
    //     placeholder: 'Select',
    //     dataSource: [
    //       {
    //         id: '',
    //         label: 'All'
    //       },
    //       {
    //         id: 'true',
    //         label: 'Yes'
    //       },
    //       {
    //         id: 'false',
    //         label: 'No'
    //       }
    //     ]
    //   },
    //   render: ({ value }) => {
    //     return <span>{value ? 'Yes' : 'No'}</span>;
    //   }
    // },
    // {
    //   name: 'accepted',
    //   header: 'Accepted',
    //   minWidth: 100,
    //   enableFilter: true,
    //   filterType: 'select',
    //   defaultFlex: 2,
    //   visible: true,
    //   className: 'hide-filter-button',
    //   filterEditor: SelectFilter,
    //   filterEditorProps: {
    //     placeholder: 'Select',
    //     dataSource: [
    //       {
    //         id: '',
    //         label: 'All'
    //       },
    //       {
    //         id: 'true',
    //         label: 'Yes'
    //       },
    //       {
    //         id: 'false',
    //         label: 'No'
    //       }
    //     ]
    //   },
    //   render: ({ value }) => {
    //     return <span>{value ? 'Yes' : 'No'}</span>;
    //   }
    // },
    // {
    //   name: 'denied',
    //   header: 'Denied',
    //   minWidth: 100,
    //   enableFilter: true,
    //   filterType: 'select',
    //   defaultFlex: 2,
    //   visible: true,
    //   className: 'hide-filter-button',
    //   filterEditor: SelectFilter,
    //   filterEditorProps: {
    //     placeholder: 'Select',
    //     dataSource: [
    //       {
    //         id: '',
    //         label: 'All'
    //       },
    //       {
    //         id: 'true',
    //         label: 'Yes'
    //       },
    //       {
    //         id: 'false',
    //         label: 'No'
    //       }
    //     ]
    //   },
    //   render: ({ value }) => {
    //     return <span>{value ? 'Yes' : 'No'}</span>;
    //   }
    // },
    // {
    //   name: 'make',
    //   header: 'Make',
    //   minWidth: 200,
    //   defaultFlex: 2,
    //   enableFilter: true,
    //   filterType: 'string',
    //   visible: true,
    //   filterDelay: 800,
    //   cellProps: { className: 'copy-button' },
    //   render: ({ value }) => <CopyButton value={value} />
    // },
    // {
    //   name: 'model',
    //   header: 'Model',
    //   minWidth: 200,
    //   defaultFlex: 2,
    //   enableFilter: true,
    //   filterType: 'string',
    //   visible: true,
    //   filterDelay: 800,
    //   cellProps: { className: 'copy-button' },
    //   render: ({ value }) => <CopyButton value={value} />
    // },
    {
      name: 'dealershipDepartment',
      header: 'Dealership Department',
      minWidth: 200,
      defaultFlex: 2,
      enableFilter: true,
      filterType: 'string',
      visible: false,
      filterDelay: 800,
      cellProps: { className: 'copy-button' },
      render: ({ value }) => <CopyButton value={value} />
    },
    {
      name: 'isSMS',
      header: 'SMS?',
      minWidth: 100,
      enableFilter: true,
      filterType: 'select',
      defaultFlex: 2,
      visible: false,
      className: 'hide-filter-button',
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: 'Select',
        dataSource: [
          {
            id: '',
            label: 'All'
          },
          {
            id: 'true',
            label: 'Yes'
          },
          {
            id: 'false',
            label: 'No'
          }
        ]
      },
      render: ({ value }) => {
        return <span>{value ? 'Yes' : 'No'}</span>;
      }
    },
    // {
    //   name: 'group',
    //   header: 'Group',
    //   minWidth: 200,
    //   defaultFlex: 2,
    //   enableFilter: true,
    //   filterType: 'string',
    //   visible: true,
    //   filterDelay: 800,
    //   cellProps: { className: 'copy-button' },
    //   render: ({ value }) => <CopyButton value={value} />
    // },
    {
      name: 'hasKeyfacts',
      header: 'Has KeyFacts?',
      minWidth: 150,
      enableFilter: true,
      filterType: 'select',
      defaultFlex: 2,
      visible: false,
      className: 'hide-filter-button',
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: 'Select',
        dataSource: [
          {
            id: '',
            label: 'All'
          },
          {
            id: 'true',
            label: 'Yes'
          },
          {
            id: 'false',
            label: 'No'
          }
        ]
      },
      render: ({ value }) => {
        return <span>{value ? 'Yes' : 'No'}</span>;
      }
    }
    // {
    //   name: 'notes',
    //   header: 'Notes?',
    //   minWidth: 100,
    //   enableFilter: true,
    //   filterType: 'select',
    //   defaultFlex: 2,
    //   visible: true,
    //   className: 'hide-filter-button',
    //   filterEditor: SelectFilter,
    //   filterEditorProps: {
    //     placeholder: 'Select',
    //     dataSource: [
    //       {
    //         id: '',
    //         label: 'All'
    //       },
    //       {
    //         id: 'true',
    //         label: 'Yes'
    //       },
    //       {
    //         id: 'false',
    //         label: 'No'
    //       }
    //     ]
    //   },
    //   render: ({ value }) => {
    //     return <span>{value ? 'Yes' : 'No'}</span>;
    //   }
    // },
  ];
  if (Comp)
    columns.push({
      name: 'actions',
      header: '',
      minWidth: 100,
      defaultFlex: 1,
      sortable: false,
      visible: true,
      render: value => {
        return (
          <div className="d-flex">
            {Comp ? <Comp value={value.data} /> : <></>}
          </div>
        );
      }
    });

  columns = columns.filter(col => !col.hideColumn);
  return columns;
};

export const chatTableConfig = Comp => {
  let columns = [
    {
      name: 'id',
      header: 'ID',
      className: 'hide-filter-button',
      defaultWidth: 100,
      defaultFlex: 2,
      filterDelay: 800,
      enableFilter: true,
      filterType: 'number',
      visible: true,
      cellProps: { className: 'copy-button' },
      render: ({ value }) => <CopyButton value={value} />
    },
    {
      name: 'companyName',
      header: 'Company Name',
      filterDelay: 800,
      defaultWidth: 180,
      defaultFlex: 3,
      enableFilter: true,
      filterType: 'string',
      visible: true,
      cellProps: { className: 'copy-button' },
      render: ({ value }) => <CopyButton value={value} />
    },
    {
      name: 'companyKey',
      header: 'Company Key',
      filterDelay: 800,
      defaultWidth: 150,
      defaultFlex: 3,
      enableFilter: true,
      filterType: 'string',
      visible: true,
      cellProps: { className: 'copy-button' },
      render: ({ value }) => <CopyButton value={value} />
    },
    {
      name: 'createdOn',
      header: 'Created',
      defaultWidth: 180,
      defaultFlex: 3,
      enableFilter: true,
      filterType: 'date',
      visible: true,
      cellProps: { className: 'copy-button' },
      dateFormat: 'DD-MMMM-YYYY',
      filterEditor: DateFilter,
      filterEditorProps: () => {
        return {
          dateFormat: 'string',
          cancelButton: false,
          highlightWeekends: false,
          placeholder: 'Select Date'
        };
      },
      render: ({ value }) => <TimeStamp value={value} />
    },
    {
      name: 'isLead',
      header: 'Lead',
      defaultWidth: 100,
      enableFilter: true,
      filterType: 'select',
      defaultFlex: 2,
      visible: true,
      className: 'hide-filter-button',
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: 'Select',
        dataSource: [
          {
            id: '',
            label: 'All'
          },
          {
            id: 'true',
            label: 'Yes'
          },
          {
            id: 'false',
            label: 'No'
          }
        ]
      },
      render: ({ value }) => {
        return <span>{value ? 'Yes' : 'No'}</span>;
      }
    }
  ];
  if (Comp)
    columns.push({
      name: 'actions',
      header: '',
      minWidth: 100,
      defaultFlex: 1,
      sortable: false,
      visible: true,
      render: value => (
        <div className="d-flex">
          {Comp ? <Comp value={value.data} /> : <></>}
        </div>
      )
    });
  return columns;
};

export const provisionTableConfig = Comp => {
  let columns = [
    {
      name: 'id',
      header: 'ID',
      defaultWidth: 100,
      defaultFlex: 1,
      enableFilter: true,
      filterType: 'number',
      visible: true,
      filterDelay: 800,
      headerProps: { className: 'hide-filter-button' },
      cellProps: { className: 'copy-button' },
      render: ({ value }) => <CopyButton value={value} />
    },
    {
      name: 'companyName',
      header: 'Company Name',
      defaultWidth: 200,
      defaultFlex: 2,
      enableFilter: true,
      filterType: 'string',
      visible: true,
      filterDelay: 800,
      cellProps: { className: 'copy-button' },
      render: ({ value }) => <CopyButton value={value} />
    },
    {
      name: 'hostName',
      header: 'Host',
      defaultWidth: 200,
      defaultFlex: 2,
      enableFilter: true,
      filterType: 'string',
      visible: true,
      filterDelay: 800,
      cellProps: { className: 'copy-button' },
      render: ({ value }) => <CopyButton value={value} />
    },
    {
      name: 'status',
      header: 'Status',
      className: 'hide-filter-button',
      defaultWidth: 200,
      defaultFlex: 2,
      enableFilter: true,
      filterType: 'select',
      visible: true,
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: 'Select',
        dataSource: [
          {
            id: '',
            label: 'All'
          },
          {
            id: '1',
            label: 'Adding Information'
          },
          {
            id: '2',
            label: 'Waiting For Setup'
          },
          {
            id: '3',
            label: 'Need More Information'
          },
          {
            id: '4',
            label: 'Pending Addition To Website'
          },
          {
            id: '5',
            label: 'Completed'
          }
        ]
      },
      render: ({ value }) => <StatusIndicator value={value} />
    },
    {
      name: 'createdOn',
      header: 'Created',
      defaultWidth: 200,
      defaultFlex: 2,
      enableFilter: true,
      filterType: 'date',
      visible: true,
      dateFormat: 'DD-MMMM-YYYY',
      cellProps: { className: 'copy-button' },
      filterEditor: DateFilter,
      filterEditorProps: () => {
        return {
          dateFormat: 'string',
          cancelButton: false,
          highlightWeekends: false,
          placeholder: 'Select Date'
        };
      },
      render: ({ value }) => <TimeStamp value={value} />
    },
    {
      name: 'modifiedOn',
      header: 'Modified',
      defaultWidth: 200,
      defaultFlex: 2,
      enableFilter: true,
      filterType: 'date',
      visible: true,
      dateFormat: 'DD-MMMM-YYYY',
      cellProps: { className: 'copy-button' },
      filterEditor: DateFilter,
      filterEditorProps: () => {
        return {
          dateFormat: 'string',
          cancelButton: false,
          highlightWeekends: false,
          placeholder: 'Select Date'
        };
      },
      render: ({ value }) => <TimeStamp value={value} />
    }
  ];
  if (Comp)
    columns.push({
      name: 'actions',
      header: '',
      minWidth: 100,
      defaultFlex: 1,
      sortable: false,
      visible: true,
      render: value => (
        <div className="d-flex">
          {Comp ? <Comp value={value.data} /> : <></>}
        </div>
      )
    });
  return columns;
};

export const myReportTableConfig = Comp => {
  let columns = [
    {
      name: 'name',
      header: 'Reports',
      defaultFlex: 4,
      filterDelay: 800,
      enableFilter: true,
      filterType: 'string',
      visible: true,
      className: 'hide-filter-button',
      cellProps: { className: 'copy-button' },
      render: ({ value }) => <CopyButton value={value} />
    },
    {
      name: 'createdOn',
      header: 'Created',
      defaultFlex: 2,
      visible: true,
      enableFilter: true,
      filterType: 'date',
      dateFormat: 'DD-MMMM-YYYY',
      cellProps: { className: 'copy-button' },
      filterEditor: DateFilter,
      filterEditorProps: () => {
        return {
          dateFormat: 'string',
          cancelButton: false,
          highlightWeekends: false,
          placeholder: 'Select Date'
        };
      },
      render: ({ value }) => <TimeStamp value={value} />
    }
  ];
  if (Comp)
    columns.push({
      name: 'actions',
      header: '',
      minWidth: 100,
      defaultFlex: 1,
      sortable: false,
      visible: true,
      render: value => (
        <div className="d-flex">
          {Comp ? <Comp value={value.data} /> : <></>}
        </div>
      )
    });
  return columns;
};

export const queuedReportTableConfig = Comp => {
  let columns = [
    {
      name: 'id',
      header: 'ID',
      enableFilter: true,
      filterType: 'number',
      className: 'hide-filter-button',
      defaultFlex: 1,
      minWidth: 180,
      filterDelay: 800,
      cellProps: { className: 'copy-button' },
      visible: true,
      render: ({ value }) => <CopyButton value={value} />
    },
    {
      name: 'reportStatusId',
      header: 'Status',
      className: 'hide-filter-button',
      minWidth: 100,
      defaultFlex: 2,
      visible: true,
      enableFilter: true,
      filterType: 'select',
      filterEditor: SelectFilter,
      filterEditorProps: {
        placeholder: 'Select',
        dataSource: [
          {
            id: '',
            label: 'All'
          },
          {
            id: '0',
            label: 'Queued'
          },
          {
            id: '1',
            label: 'Running'
          },
          {
            id: '2',
            label: 'Completed'
          },
          {
            id: '3',
            label: 'Completed Without Data'
          },
          {
            id: '4',
            label: 'Failed'
          }
        ]
      },
      render: ({ value }) => <MyReportStatus value={value} />
    },
    {
      name: 'name',
      header: 'Name',
      defaultFlex: 3,
      minWidth: 150,
      filterDelay: 800,
      enableFilter: true,
      filterType: 'string',
      cellProps: { className: 'copy-button' },
      visible: true,
      render: ({ value }) => <CopyButton value={value} />
    },
    {
      name: 'parametersJson',
      header: 'Parameters',
      defaultFlex: 3,
      minWidth: 200,
      filterDelay: 800,
      visible: true,
      enableFilter: true,
      filterType: 'string',
      cellProps: { className: 'copy-button' },
      render: ({ value }) => {
        return <Hover value={value} />;
      }
    },
    {
      name: 'createdOn',
      header: 'Created',
      defaultFlex: 2,
      minWidth: 120,
      dateFormat: 'DD-MMMM-YYYY',
      enableFilter: true,
      filterType: 'date',
      cellProps: { className: 'copy-button' },
      filterEditor: DateFilter,
      visible: true,
      filterEditorProps: () => {
        return {
          dateFormat: 'string',
          cancelButton: false,
          highlightWeekends: false,
          placeholder: 'Select Date'
        };
      },
      render: ({ value }) => <TimeStamp value={value} />
    }
  ];
  if (Comp)
    columns.push({
      name: 'actions',
      header: '',
      minWidth: 180,
      defaultFlex: 2,
      sortable: false,
      visible: true,
      render: value => (
        <div className="d-flex">
          {Comp ? <Comp value={value.data} /> : <></>}
        </div>
      )
    });
  return columns;
};

export const viewReportTableConfig = Comp => {
  let columns = [
    {
      name: 'id',
      header: 'ID',
      className: 'hide-filter-button',
      defaultFlex: 1,
      filterDelay: 800,
      cellProps: { className: 'copy-button' },
      render: ({ value }) => <CopyButton value={value} />
    },
    {
      name: 'reportStatusId',
      header: 'Status',
      className: 'hide-filter-button',
      defaultFlex: 2,
      // filterEditor: SelectFilter,
      // filterEditorProps: {
      //   placeholder: 'Select',
      //   dataSource: [
      //     {
      //       id: '',
      //       label: 'All'
      //     },
      //     {
      //       id: '0',
      //       label: 'Queued'
      //     },
      //     {
      //       id: '1',
      //       label: 'Running'
      //     },
      //     {
      //       id: '2',
      //       label: 'Completed'
      //     },
      //     {
      //       id: '3',
      //       label: 'Completed Without Data'
      //     },
      //     {
      //       id: '4',
      //       label: 'Failed'
      //     }
      //   ]
      // },
      render: ({ value }) => <MyReportStatus value={value} />
    },
    {
      name: 'name',
      header: 'Name',
      defaultFlex: 3,
      filterDelay: 800,
      className: 'hide-filter-button',
      cellProps: { className: 'copy-button' },
      render: ({ value }) => <CopyButton value={value} />
    },
    {
      name: 'parametersJson',
      header: 'Parameters',
      defaultFlex: 3,
      filterDelay: 800,
      className: 'hide-filter-button',
      cellProps: { className: 'copy-button' },
      render: ({ value }) => {
        return <Hover value={value} />;
      }
    },
    {
      name: 'createdOn',
      header: 'Created',
      defaultFlex: 2,
      dateFormat: 'DD-MMMM-YYYY',
      cellProps: { className: 'copy-button' },
      // filterEditor: DateFilter,
      // filterEditorProps: () => {
      //   return {
      //     dateFormat: 'string',
      //     cancelButton: false,
      //     highlightWeekends: false,
      //     placeholder: 'Select Date'
      //   };
      // },
      render: ({ value }) => <TimeStamp value={value} />
    }
  ];

  if (Comp) {
    columns.push({
      name: 'actions',
      header: '',
      defaultFlex: 2,
      sortable: false,
      render: value => {
        return (
          <div className="d-flex justify-content-center">
            {Comp ? <Comp value={value.data} /> : <></>}
          </div>
        );
      }
    });
  }

  return columns;
};

export const usersTableConfig = Comp => [
  {
    name: 'id',
    header: 'ID',
    defaultFlex: 4,
    filterDelay: 800,
    defaultVissible: true,
    enableFilter: true,
    filterType: 'number',
    className: 'hide-filter-button',
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'username',
    header: 'User Name',
    defaultFlex: 4,
    filterDelay: 800,
    defaultVissible: true,
    enableFilter: true,
    filterType: 'string',
    className: 'hide-filter-button',
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },
  {
    name: 'displayName',
    header: 'Display Name',
    defaultFlex: 4,
    filterDelay: 800,
    defaultVissible: true,
    enableFilter: true,
    filterType: 'string',
    className: 'hide-filter-button',
    cellProps: { className: 'copy-button' },
    render: ({ value }) => <CopyButton value={value} />
  },

  {
    name: 'actions',
    header: '',
    defaultFlex: 2,
    sortable: false,
    defaultVissible: true,
    render: value => {
      return (
        <div className="d-flex">
          {Comp ? <Comp value={value.data} /> : <></>}
        </div>
      );
    }
  }
];

export const generateFilterTypes = (data = []) => {
  let obj = {};
  data.forEach(col => {
    if (col.enableFilter && col.filterType === 'select') {
      obj[col.name] = {
        ...getFilterOptionsByType(col, true)
      };
    }
  });
  return obj;
};

export const generateFilterColumns = (data = []) => {
  return data
    .filter(val => val.enableFilter)
    .map(col => getFilterOptionsByType(col, false));
};

export const getFilterOptionsByType = (data = {}, config) => {
  let obj = { name: data.name, value: '', type: data.filterType || 'string' };
  switch (obj.type) {
    case 'string':
      obj = {
        ...obj,
        operator: 'contains',
        operators: [
          {
            name: 'contains'
          },
          {
            name: 'startsWith'
          },
          {
            name: 'endsWith'
          }
        ]
      };
      break;
    case 'select':
      obj = { ...obj };
      break;
    case 'date':
      obj = {
        ...obj,
        operator: 'eq',
        operators: [
          {
            name: 'eq'
          },
          {
            name: 'before'
          },
          {
            name: 'after'
          }
        ]
      };
      // obj['type'] = obj.name;
      break;
    case 'number':
      obj = {
        ...obj
      };
      break;
    default:
      obj = {
        ...obj,
        operator: 'contains',
        operators: [
          {
            name: 'contains'
          },
          {
            name: 'startsWith'
          },
          {
            name: 'endsWith'
          }
        ]
      };
  }

  if (config) {
    delete obj['operator'];
    delete obj['value'];
    delete obj['type'];
    return obj;
  } else {
    delete obj['operators'];

    return obj;
  }
};

export const getFilterValue = (data, dateTypeKeysProp = []) => {
  let updatedValue = data.value;
  console.log(data, dateTypeKeysProp);
  let dateTypeKeys = [
    'createdOn',
    'startDate',
    'modifiedOn',
    ...dateTypeKeysProp
  ];
  if (
    dateTypeKeys.includes(data.name) &&
    (data.operator === 'eq' || data.operator === 'after')
  ) {
    updatedValue = moment(data.value, 'DD-MMM-YYYY');
  } else if (data.name === 'createdOn' && data.operator === 'before') {
    updatedValue = '2000-01-01T08:00:00.000Z';
  } else if (data.type === 'number') {
    updatedValue = Number(updatedValue);
  } else if (data.operator === 'contains') {
    updatedValue = `%${updatedValue}%`;
  } else if (data.operator === 'startsWith') {
    updatedValue = `${updatedValue}%`;
  } else if (data.operator === 'endsWith') {
    updatedValue = `%${updatedValue}`;
  }
  return updatedValue;
};

let filterTypeObj = ReactDataGrid.defaultProps.filterTypes;
export const filterTypes = Object.assign({}, filterTypeObj, {
  string: {
    ...filterTypeObj.string,
    operators: [
      {
        name: 'contains'
      },
      {
        name: 'startsWith'
      },
      {
        name: 'endsWith'
      }
    ]
  },
  date: {
    ...filterTypeObj.date,
    operators: [
      {
        name: 'eq'
      },
      {
        name: 'before'
      },
      {
        name: 'after'
      }
    ]
  }
});

export const getKeysByFilterType = (fields = [], type = '') => {
  fields.filter(c => c.filterType === type).map(c => c.name);
};
