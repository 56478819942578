import React from 'react';
import { isMobile } from 'react-device-detect';
import { Button, Card, OverlayTrigger } from 'react-bootstrap';
import UilInfoCircle from '@iconscout/react-unicons/icons/uil-info-circle';
import UilArrowLeft from '@iconscout/react-unicons/icons/uil-arrow-left';

import { formatTime, truncateText } from 'helpers/generic.methods';
import renderToolTip from 'helpers/ui-controls/renderToolTip';
import ChatEndButton from 'components/chat/ChatEndButton';
import ToggleRightPane from 'components/chat/ToggleRightPane';

const ChatDetailHeader = ({
  onBackButtonHandler,
  selectedChat,
  isChatEnded,
  setToggleChatSuggestion,
  setToggleChatDetail,
  setToggleActiveChats
}) => {
  return (
    <Card.Header className="border-bottom-0">
      <div className={`chat-details-header d-flex justify-content-between`}>
        <div className="chat-details-header-left d-flex align-items-center">
          {isMobile && (
            // <span className="chat-back-arrow" onClick={onBackButtonHandler}>
            //   <UilArrowLeft />
            // </span>
            <Button
              variant={'outline-secondary'}
              size="sm"
              className={'chat-back-arrow mr-2'}
              onClick={onBackButtonHandler}
            >
              <i className="uil uil-arrow-left arrow-left-icon"></i>
            </Button>
          )}

          <div className="chat-details-user-info">
            <div className="chat-header-visitor-name">
              {truncateText(
                `${selectedChat?.getCompanyName()}-${selectedChat?.getId()}`,
                isMobile ? 15 : 25
              )}
            </div>

            <div className="chat-header-start-time">
              Chat started at {formatTime(selectedChat?.getData().createdOn)}
            </div>
          </div>
        </div>
        <div className="chat-details-header-right">
          <OverlayTrigger
            placement={'left'}
            overlay={renderToolTip(
              'All the chats can be seen inside Chat section'
            )}
          >
            <UilInfoCircle className="list-icon-after-text mr-2" />
          </OverlayTrigger>
          <ChatEndButton
            selectedChat={selectedChat}
            isDisabled={isChatEnded}
            setToggleChatSuggestion={setToggleChatSuggestion}
            setToggleChatDetail={setToggleChatDetail}
            setToggleActiveChats={setToggleActiveChats}
          />
          <ToggleRightPane
            setToggleChatSuggestion={setToggleChatSuggestion}
            setToggleChatDetail={setToggleChatDetail}
          />
        </div>
        {/* {isMobile && (
                <span
                  className="chat-suggestion-btn"
                  onClick={onSuggestionButtonHandler}
                >
                  <UilInfoCircle />
                </span>
              )} */}
      </div>
    </Card.Header>
  );
};

export default ChatDetailHeader;
