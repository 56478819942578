import React, { useState } from 'react';
import { UilCopy, UilCheckCircle } from '@iconscout/react-unicons';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const CopyButton = ({ value, variant, copySpecificText }) => {
  const [copyValue, setCopyValue] = useState(false);

  const copyText = e => {
    e.stopPropagation();
    setCopyValue(true);
  };

  return (
    <span
      className="copy-button-wrapper"
      onMouseLeave={() => {
        setCopyValue(false);
      }}
    >
      {value ? value : 'N/A'}
      {value &&
        (!copyValue ? (
          <CopyToClipboard text={copySpecificText || value}>
            <button
              title="Copy to clipboard"
              className="copy-button-icon"
              onClick={e => copyText(e)}
            >
              <UilCopy />
            </button>
          </CopyToClipboard>
        ) : (
          <span title="Copied">
            <UilCheckCircle className="copied-text-svg" />
          </span>
        ))}
    </span>
  );
};

export default CopyButton;
