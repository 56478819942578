import React from 'react';
import UilDirection from '@iconscout/react-unicons/icons/uil-direction';
import Button from 'react-bootstrap/Button';
import CardWrapperPopOver from './card-wrapper-popover';

export const CardWrapper = ({
  id,
  children,
  title = 'head',
  clickMoreOptions,
  isCard = true,
  isFilter = false,
  showSubHeading,
  subHeading,
  showTabs = false,
  variant,
  buttons = [],
  selected,
  multiple = false,
  handleChangeButton
}) => {
  const closeCard = () => {
    clickMoreOptions();
  };

  const onMultipleSelect = key => {
    if (selected.includes(key)) {
      handleChangeButton(selected.filter(s => s !== key));
    } else {
      handleChangeButton([...selected, key]);
    }
  };

  const cardHeadersBackground = {
    backgroundColor: '#f4f6f8'
  };

  return (
    <div
      className={`
        ${
          showTabs
            ? 'line-chart-card-container chat-stats-chart'
            : 'line-chart-card-container'
        } ${variant === 'list' ? 'listing-wrapper' : ''}`}
    >
      <div className="card-header p-2" style={cardHeadersBackground}>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex justify-content-between w-100 align-items-center">
            <div>
              <span className="font-weight-bold">{title}</span>
              {showSubHeading && (
                <span className="sub-heading">{subHeading}</span>
              )}
            </div>

            <div className="d-flex align-items-center">
              {buttons.length
                ? buttons.map((bt, index) => (
                    <Button
                      key={index}
                      className="btn-sm card-wrapper-button ml-1"
                      type="button"
                      variant={
                        multiple
                          ? selected.includes(bt.key)
                            ? 'primary added-cancelled-btns'
                            : 'white'
                          : bt.key === selected
                          ? 'primary added-cancelled-btns'
                          : 'white'
                      }
                      onClick={
                        multiple
                          ? e => onMultipleSelect(bt.key)
                          : e => handleChangeButton(bt.key)
                      }
                    >
                      {bt.label}
                    </Button>
                  ))
                : null}
            </div>
          </div>
          <CardWrapperPopOver closeCard={closeCard} />
        </div>
        {/* <span className="full-available-width">
          <span>{title}</span>
          {showSubHeading && <span className="sub-heading">{subHeading}</span>}
          <div className="float-lg-right card-buttons-container">
            {buttons.length
              ? buttons.map((bt, index) => (
                  <span className="ml-2" key={index}>
                    <Button
                      className="btn-sm card-wrapper-button"
                      type="button"
                      variant={
                        multiple
                          ? selected.includes(bt.key)
                            ? 'primary added-cancelled-btns'
                            : 'white'
                          : bt.key === selected
                          ? 'primary added-cancelled-btns'
                          : 'white'
                      }
                      onClick={
                        multiple
                          ? e => onMultipleSelect(bt.key)
                          : e => handleChangeButton(bt.key)
                      }
                    >
                      {bt.label}
                    </Button>
                  </span>
                ))
              : null}
          </div>
        </span> */}
      </div>
      <div className={isCard ? 'graph-card-body' : 'list-card-body'}>
        {children}
      </div>
    </div>
  );
};
