import React from 'react';
import Button from 'react-bootstrap/Button';
import { UilArrowRight } from '@iconscout/react-unicons';
import './styles.scss';
import SSO_Redirection from 'app/redirection-SSO';
import { useTranslation } from 'react-i18next';

const HeaderToExistingPortal = ({
  msg = 'Your message here...',
  borderRadius = '10px 10px 0 0',
  hideBtn
}) => {
  const t = useTranslation().t;
  const redirectHandler = () => {
    // redirection here..
    SSO_Redirection();
  };
  return (
    <div className="container-header-existing-portal" style={{ borderRadius }}>
      <span className="msg">{msg}</span>
      {hideBtn ? (
        ''
      ) : (
        <Button
          className="btn-sm btn-flex ml-2 btn-container"
          variant="outline-warning"
          onClick={redirectHandler}
        >
          <span className="existing-btn-text">
            {t('company.general.portalBtn')}
          </span>
          <span>
            <UilArrowRight className="existing-btn-arrow" />
          </span>
        </Button>
      )}
    </div>
  );
};

export default HeaderToExistingPortal;
