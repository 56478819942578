import React from 'react';
import './style.scss';

const TypingIndicator = ({ msg, typing, size, containerStyles }) => {
  return typing ? (
    <div style={containerStyles}>
      {msg}
      <div data-title=".dot-flashing" className="dot-container">
        <div>
          <div
            className={'dot-flashing-' + size}
            style={{ marginLeft: msg ? '8px' : '0px' }}
          />
        </div>
      </div>
    </div>
  ) : (
    <div></div>
  );
};

export default TypingIndicator;
