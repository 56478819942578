import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import './styles.scss';

const DoughnutChart = ({ chartData = {}, canvasHeight = 200, cutout = 80 }) => {
  const options = {
    responsive: true, // Instruct chart js to respond nicely.
    maintainAspectRatio: true, // Add to prevent default behaviour of full-width/height
    aspectRatio: 2,
    cutout: 70,
    animation: {
      duration: 0
    },
    plugins: {
      legend: {
        position: 'right',
        labels: {
          boxWidth: 10,
          padding: 6
        }
      },
      tooltip: {
        backgroundColor: 'rgba(69, 79, 91, 1)',
        callbacks: {
          label: data => {
            let total = data.dataset.data.reduce((a, b) => a + b, 0);
            let percentage = total
              ? ((data.parsed / total) * 100).toFixed(2)
              : 0;
            return `${data.label} ${data.parsed}: ${percentage}%`;
          }
        }
      }
    }
  };
  return (
    <div className="p-0">
      {chartData && Object.keys(chartData).length ? (
        <Doughnut data={chartData} options={options} />
      ) : null}
    </div>
  );
};

export default DoughnutChart;
