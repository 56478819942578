export const TitleTab = (count, newTitle) => {
  if (count > 0) {
    if (!window.defaultTitle) {
      window.defaultTitle = window.document.title;
    }
    if (!window.executeInterval) {
      window.executeInterval = setInterval(function () {
        window.document.title = newTitle + ' ' + window.defaultTitle;
        setTimeout(() => {
          window.document.title = window.defaultTitle;
        }, 1000);
      }, 1500);
    }
  } else {
    window.document.title = window.defaultTitle;
    if (window.executeInterval) {
      clearInterval(window.executeInterval);
      window.executeInterval = null;
    }
  }
};
