import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Form from 'react-bootstrap/Form';
import UILInfoCircle from '@iconscout/react-unicons/icons/uil-info-circle';
import { toolTipPlacement } from 'helpers/generic.methods';
import renderToolTip from './renderToolTip';

const StaticFeild = ({
  // label of the field
  heading,
  // field name
  field,
  // value of the field
  dataProperty,
  // human readable title
  titleText,
  // text to be shown in tooltip
  helperText,
  //feild for dropdown enum
  dropdownEnum,
  // flag for non editable dropdown
  dropdownFlag
}) => {
  helperText = helperText || titleText;
  return (
    <>
      <li key={field}>
        <Form.Label
          title={titleText}
          htmlFor={field}
          aria-label={heading + ' - ' + titleText}
        >
          {heading}
        </Form.Label>
      </li>
      <li
        key={field + '-field'}
        className={'d-flex px-1r'}
        aria-label={titleText}
      >
        <div className="tooltip-container">
          {dropdownFlag ? (
            <Form.Label
              className="wrap-text"
              style={{
                // maxWidth: dataProperty && helperText ? '98%' : '100%',
                minWidth: '200px',
                width: '200px'
              }}
            >
              {dataProperty === null || dataProperty === 0 || !dataProperty
                ? 'N/A'
                : dropdownEnum.map(enumType => {
                    if (enumType.value === dataProperty) {
                      return enumType.name;
                    }
                    return '';
                  })}
            </Form.Label>
          ) : (
            <Form.Label
              className="wrap-text"
              style={{
                // maxWidth: dataProperty && helperText ? '98%' : '100%',
                minWidth: '200px',
                width: '200px'
              }}
            >
              {dataProperty === '' || dataProperty === null
                ? 'N/A'
                : dataProperty}
            </Form.Label>
          )}
          {helperText && (
            <OverlayTrigger
              placement={toolTipPlacement}
              overlay={renderToolTip(helperText)}
            >
              <UILInfoCircle className="list-icon-after-text" />
            </OverlayTrigger>
          )}
        </div>
      </li>
    </>
  );
};
export default StaticFeild;
