export const fr_widget = {
  // widget editor
  widget_heading: 'Editor de widgets',
  sidebar_item1: 'Configuración de la apariencia',
  item1_child1: 'Editor de widgets',
  widget_companies: 'Compañías',
  no_companies_msg:
    'Actualmente no hay empresas abiertas, haga clic en "Buscar empresas" para cambiar la configuración',
  collapse_item1: 'Integrar widget',
  collapse_item1_tag1:
    'Copie o instale el código del widget para publicar su widget de chat',
  widget_script: 'Guión de widget',
  copy_snippet: 'Copiar fragmento',
  widget_or: 'O',
  easy_install: 'Instalar fácilmente en',
  wordpress_plugin: 'Wordpress a través de un complemento',
  send_developer: 'Enviar a tu desarrolladora',
  designer_email: 'Correo electrónico del diseñador',
  send_instruction: 'Enviar instrucciones',
  widget_error_info:
    'Presiona enter para agregar la dirección de correo electrónico.'
};

export default fr_widget;
