import {
  requiredText,
  email,
  urlText,
  phoneText,
  phoneTextRequired,
  alphaNumeric,
  alphaNumericLength,
  onlyText,
  cityText,
  matchPassword,
  requiredOnlyText,
  nameText,
  emailText,
  emailReqText,
  multipleEmailTextNotReq
} from '../genericSchemas';
import * as Yup from 'yup';

/**
 * Setup schema for contact information of the company
 * requires requiredText schema from genericSchemas
 **/

export const contactInformationSchemas = Yup.object().shape({
  primaryContactName: nameText,
  primaryContactEmail: null,
  primaryContactPhone: phoneText,
  primaryContactAltPhone: phoneText,
  invoiceEmail: multipleEmailTextNotReq,
  address: null,
  city: cityText,
  state: alphaNumeric,
  zip: alphaNumericLength,
  serviceArea: null,
  country: null
});
export const facebookPixelSectionSchema = Yup.object().shape({
  facebookPixelAccount: requiredText
});
export const customTrackingSectionSchema = Yup.object().shape({});
export const googleAnalyticsTrackingSectionSchema = Yup.object().shape({
  googleAnalyticsAccount: requiredText,
  googleAnalyticsDomain: urlText
});

export const facebookSchema = Yup.object().shape({
  // verificationToken: requiredText,
  // pageAccessToken: requiredText,
  // callbackURL: requiredText,
  // getStartedButtonGreetingText: requiredText,
  // facebookChatGreetingText: requiredText,
  // companyFacebookPageName: requiredText,
  // facebookPageID: requiredText
});
export const basicDetailsSchema = Yup.object().shape({
  domain: urlText
});

export const leadEmailSchema = Yup.object().shape({
  leadEmailAddresses: emailReqText,
  chatTranscriptEmailAddresses: emailText
});

export const callConnectSchema = Yup.object().shape({
  officeHoursTimezone: null,
  quickConnectPhoneNumber: phoneText,
  callConnectTranscriptOverride: null,
  callConnectTranscriptEmail: email
});

export const companyHeaderSchema = Yup.object().shape({
  companyName: requiredText
});
export const reportHeaderSchema = Yup.object().shape({
  name: requiredText
});

export const userSchemas = Yup.object().shape({
  userId: requiredText,
  userName: requiredOnlyText,
  displayName: requiredOnlyText,
  email: email,
  confirmPassword: matchPassword,
  lockedOut: null,
  preferMobile: null
});
