import UilSetting from '@iconscout/react-unicons/icons/uil-setting';
import WidgetSetting from 'pages/portal/widget-setting';

const generalRoutes = [
  {
    path: '/portal/settings/widget-editor',
    name: 'settings',
    title: 'Settings',
    component: props => <WidgetSetting {...props} />,
    icon: <UilSetting />,
    exact: true
  }
];

export default generalRoutes;
