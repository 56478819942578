import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { changeTourGuideFlag } from 'helpers/generic.methods';
import storage from 'helpers/storage';
import { useTranslation } from 'react-i18next';
import { camelize, camelCaseStringToNormalText } from 'helpers/generic.methods';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { selectRoles } from 'features/roles/rolesSlice';
import { onTourModalSetTourState } from 'features/introJS-tour/tour-helper';
import './styles.scss';
const TourModal = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const { roles } = useSelector(selectRoles);

  const userDetails = JSON.parse(storage.getItem('Profile'));
  const userDisplayName = userDetails?.DisplayName;

  const userName = t('company.tour.tourWelcome').replace(
    '<user>',
    userDisplayName
  );
  const startTour = () => {
    onTourModalSetTourState(true);
    props.toggelTourHandler(true);
    props.onHide();
    if (roles && roles.analytics && roles.analytics.view) {
      history.push('/analytics');
    }
    changeTourGuideFlag();
  };

  const exitTour = () => {
    changeTourGuideFlag();
    onTourModalSetTourState(false);
    props.toggelTourHandler(false);
    props.onHide();
  };

  const onStartFeature = () => {
    props.startFeatureTour(true);
    props.onHide();
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      {/* <button className="modal-cancel-btn" onClick={props.onHide}>
        <UilTimes />
      </button> */}
      <Modal.Body>
        <h4 style={{ marginTop: '15px' }}>{userName}</h4>
        <p>{t('company.tour.tourWelcomeText')}</p>
        <img src="/undraw_Hello_re_3evm.png" style={{ width: '100%' }} alt="" />
        <div className="d-flex align-items-center justify-content-between tour-modal-footer">
          <Button
            variant="outline-primary"
            className="mx-1 button-padding"
            onClick={onStartFeature}
          >
            {t('company.tour.btn9')}
          </Button>
          <div>
            <Button
              variant="outline-primary"
              className="mx-1 button-padding"
              onClick={exitTour}
            >
              {t('company.tour.btn1')}
            </Button>
            <Button
              variant="primary"
              className="mx-1 button-padding"
              onClick={startTour}
            >
              {t('company.tour.btn2')}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default TourModal;
