import React, { useEffect, useState } from 'react';
import './dashboard.scss';
import { ListGroup } from 'react-bootstrap';
import CountPercentage from './count-percentage';
import UilUser from '@iconscout/react-unicons/icons/uil-user';

import ScrollBar from 'components/Scrollbars';
import { LoadingComponent } from './loadingListComp';
import { TimeIntervalsEnum } from 'helpers/dropdown-enums';
import { useTranslation } from 'react-i18next';
import storage from 'helpers/storage';

const getPercent = (previous, current) => {
  let percentage =
    !previous && !current
      ? 0
      : !previous
      ? 100
      : ((current - previous) / previous) * 100;

  return percentage.toFixed(1);
};

// UIUX-521, Agent Images CTR component
const AgentImagesCTR = ({ data, loading, duration }) => {
  const [agentImagesCTR, setAgentImagesCTR] = useState([]);
  const [totalChats, setTotalChats] = useState(0);
  const profile = JSON.parse(storage.getItem('Profile'));

  useEffect(() => {
    if (data) {
      setTotalChats(data.TotalChats || 0);
      setAgentImagesCTR(data.AgentImagesCTRs || []);
    }
  }, [data]);

  return (
    <ListGroup
      className="analytic-list-container"
      variant="flush"
      style={{ borderRadius: '10px' }}
    >
      <ScrollBar
        horizontalScroll={false}
        className="print-preview-scroll"
        style={{ height: '100vh', marginBottom: 10 }}
      >
        {agentImagesCTR && agentImagesCTR.length
          ? agentImagesCTR.map((dt, index) => (
              <ListGroup.Item
                key={index}
                className="p-0 border-light agent-image-ctr-list"
              >
                <div className="agent-image-ctr d-flex justify-content-between align-items-center ml-1 mr-3">
                  <div className="heading">
                    <span className="pr-2">
                      {/* TODO: Replace the image url with dynmaically loaded one */}
                      {dt.ImageCloudSecureUrl || dt.ImagePath ? (
                        <img
                          style={{ borderRadius: 50 }}
                          src={
                            dt.ImageCloudSecureUrl ||
                            profile.HostDomain + dt.ImagePath
                          }
                          className="avatar-img"
                          alt="user"
                          height="24"
                          width="24"
                        />
                      ) : (
                        <UilUser size={22} />
                      )}
                    </span>
                    {dt.DisplayName}
                  </div>
                  <CountPercentage
                    count={((dt.CurrentCount / totalChats) * 100).toFixed(2)}
                    arrowDirection={
                      getPercent(dt.PreviousCount, dt.CurrentCount) > 0
                        ? 'up'
                        : 'down'
                    }
                    percentage={Math.abs(
                      getPercent(dt.PreviousCount, dt.CurrentCount)
                    )}
                    exactValue={dt.CurrentCount.toFixed(1)}
                    duration={duration}
                    heading={'Chat Count'}
                    calculatedInPercentage={true}
                    showStatistics={duration < TimeIntervalsEnum.SixMonths}
                    hidePercentageSignInTooltip={true}
                  />
                </div>
                {/* <CountPercentage
                  count={
                    totalChats
                      ? ((dt.CurrentCount / totalChats) * 100).toFixed(2)
                      : 0
                  }
                  arrowDirection={
                    getPercent(dt.PreviousCount, dt.CurrentCount) > 0
                      ? 'up'
                      : 'down'
                  }
                  percentage={Math.abs(
                    getPercent(dt.PreviousCount, dt.CurrentCount)
                  )}
                  exactValue={
                    totalChats
                      ? ((dt.CurrentCount / totalChats) * 100).toFixed(2)
                      : 0
                  }
                  duration={duration}
                  heading={t('company.analytics.agentImages')}
                  calculatedInPercentage={true}
                  showStatistics={duration < TimeIntervalsEnum.SixMonths}
                /> */}
              </ListGroup.Item>
            ))
          : null}
        <LoadingComponent
          loading={loading}
          dataLength={agentImagesCTR.length}
        />
      </ScrollBar>
    </ListGroup>
  );
};

export default AgentImagesCTR;
