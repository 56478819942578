import React from 'react';
import { Legend } from 'components/Charts/LineChart';
import { useTranslation } from 'react-i18next';
import { TimeIntervalsEnum } from 'helpers/dropdown-enums';
import { GetDurationByEnum } from '../filters/constants';
import { UilArrowDown, UilArrowUp } from '@iconscout/react-unicons';
import UILInfoCircle from '@iconscout/react-unicons/icons/uil-info-circle';
import { camelize, makeTitleFromKey } from 'helpers/generic.methods';
const QuickStatsLegends = ({ duration }) => {
  const { t } = useTranslation();

  let infoLabel = GetDurationByEnum(duration);
  return (
    <div className="legends-contain d-lg-flex justify-content-end mt-3">
      <Legend
        key={0}
        // label={t('company.analytics.label1')}
        // label={t(`company.analytics.${infoLabel}`)}
        label={t(`company.analytics.${camelize(makeTitleFromKey(infoLabel))}`)}
        color={'var(--primary)'}
        fontSize={'13px'}
      />
      {duration < TimeIntervalsEnum.SixMonths ? (
        <>
          <Legend
            key={1}
            // label={t('company.analytics.label2')}
            label={`${t('company.analytics.label6')} ${t(
              `company.analytics.${camelize(makeTitleFromKey(infoLabel))}`
            )}`}
            color={'#108043'}
            fontSize={'13px'}
            icon={<UilArrowUp color={'#108043'} size={18} />}
            direction={'up'}
          />
          <Legend
            key={2}
            // label={t('company.analytics.label3')}
            label={`${t('company.analytics.label7')} ${t(
              `company.analytics.${camelize(makeTitleFromKey(infoLabel))}`
            )}`}
            color={'#BF0711'}
            fontSize={'13px'}
            icon={<UilArrowDown color={'#BF0711'} size={18} />}
            direction={'down'}
          />
        </>
      ) : (
        <Legend
          key={1}
          label={t('company.analytics.quickStatsLegendTextFor6Months')}
          color={'#108043'}
          fontSize={'13px'}
          icon={<UILInfoCircle size={18} />}
        />
      )}
    </div>
  );
};

export default QuickStatsLegends;
