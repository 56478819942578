import makeStyles from '@material-ui/core/styles/makeStyles';

export const useLoaderStyle = makeStyles(theme => {
  let brandSuccess = '#5cb85c';
  let errorColor = 'red';
  let checkThickness = '2px';
  let checkHeight = loaderSize => loaderSize / 2;
  let checkWidth = loaderSize => checkHeight(loaderSize) / 2;
  let checkLeft = loaderSize => loaderSize / 6 + loaderSize / 12;
  let errorLeft = loaderSize => loaderSize / 4;
  let checkColor = brandSuccess;

  let styles = {
    circleLoader: {
      borderRadius: '50%',
      width: ({ loaderSize }) => `${loaderSize}em`,
      height: ({ loaderSize }) => `${loaderSize}em`,
      '&:after': {
        borderRadius: '50%',
        width: ({ loaderSize }) => `${loaderSize}em`,
        height: ({ loaderSize }) => `${loaderSize}em`
      }
    },

    checkmark: {
      display: 'none',
      boxSizing: 'content-box',
      '&.draw': {
        display: 'block !important',
        '&.draw:after': {
          opacity: '1',
          height: ({ loaderSize }) => `${checkHeight(loaderSize)}em`,
          width: ({ loaderSize }) => `${checkWidth(loaderSize)}em`,
          transformOrigin: 'left top',
          borderRight: `${checkThickness} solid ${checkColor}`,
          borderTop: `${checkThickness} solid ${checkColor}`,
          content: "''",
          left: ({ loaderSize }) => `${checkLeft(loaderSize)}em`,
          top: ({ loaderSize }) => `${checkHeight(loaderSize)}em`,
          position: 'absolute',
          animationDuration: '1.2s',
          animationTimingFunction: 'ease',
          animationName: '$checkmark',
          transform: 'scaleX(-1) rotate(135deg)'
        }
      },

      '&.error': {
        display: 'block !important',
        position: 'absolute',
        left: ({ loaderSize }) => `${errorLeft(loaderSize)}em`,
        top: ({ loaderSize }) => `${errorLeft(loaderSize)}em`,
        width: ({ loaderSize }) => `${checkHeight(loaderSize)}em`,
        height: ({ loaderSize }) => `${checkHeight(loaderSize)}em`,
        '&:before, &:after': {
          position: 'absolute',
          content: "' '",
          height: ({ loaderSize }) => `${checkHeight(loaderSize)}em`,
          width: '2px',
          backgroundColor: '#f00'
        },
        '&:before': {
          transform: 'rotate(45deg)'
        },
        '&:after': {
          transform: 'rotate(-45deg)'
        }
      }
    },
    '@keyframes checkmark': {
      '0%': {
        height: 0,
        width: 0,
        opacity: 1
      },
      '20%': {
        height: 0,
        width: ({ loaderSize }) => `${checkWidth(loaderSize)}em`,
        opacity: 1
      },
      '40%': {
        height: ({ loaderSize }) => `${checkHeight(loaderSize)}em`,
        width: ({ loaderSize }) => `${checkWidth(loaderSize)}em`,
        opacity: 1
      },
      '100%': {
        height: ({ loaderSize }) => `${checkHeight(loaderSize)}em`,
        width: ({ loaderSize }) => `${checkWidth(loaderSize)}em`,
        opacity: 1
      }
    }
  };
  return styles;
});
