import { makeStyles } from '@material-ui/core/styles';
import { AppMetaProvider } from 'components/Sidebar/helper';
import AppStoreImage from 'static/images/app-links/apple-store.png';
import AndroidPlayImage from 'static/images/app-links/google-play.png';

const useStyle = makeStyles(theme => ({
  appIcon: {
    fontSize: '25px',
    [theme.breakpoints.down('md')]: {
      fontSize: '18px'
    }
  }
}));

const MobileAppContent = ({ showText = true, linkStyles = {} }) => {
  const classes = useStyle();
  const { androidAppLink, IOSAppLink } = AppMetaProvider().GetStaticMeta;

  return (
    <div className="mt-3">
      {showText && (
        <div className="text-center p-1" style={{ fontSize: '1.2rem' }}>
          Get the app
        </div>
      )}
      <div
        className={`d-flex justify-content-around align-items-center mt-3 text-center`}
        style={{ ...linkStyles }}
      >
        <span>
          <AppLink link={IOSAppLink} img={AppStoreImage} />
        </span>
        <span>
          <AppLink link={androidAppLink} img={AndroidPlayImage} />
        </span>
      </div>
    </div>
  );
};

const AppLink = ({ link, img }) => {
  const classes = useStyle();
  return (
    <a href={link} target={'_blank'}>
      <img src={img} alt="icon" width={'70%'} />
    </a>
  );
};

export default MobileAppContent;
