export const en_widget = {
  // widget editor
  widget_heading: 'Widget Editor',
  sidebar_item1: 'Appearance Settings',
  item1_child1: 'Widget Editor',
  widget_companies: 'Companies',
  no_companies_msg:
    'Currently no companies are opened, click on “Search Companies” to change settings',
  collapse_item1: 'Integrate Widget',
  collapse_item1_tag1:
    'Copy or install the widget code to publish your chat widget',
  widget_script: 'Widget Script',
  copy_snippet: 'Copy Snippet',
  widget_or: 'OR',
  easy_install: 'Easily Install on',
  wordpress_plugin: 'Wordpress via Plugin',
  send_developer: 'Send to Your Developer',
  designer_email: 'Designer Email',
  send_instruction: 'Send Instructions',
  widget_error_info: 'Press enter to add email address.'
};
export default en_widget;
export { default as fr_widget } from '../fr-FR-WidgetDictionary/fr-Widget';
export { default as sp_widget } from '../sp-SP-WidgetDictionary/sp-Widget';
