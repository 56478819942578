import React, { Fragment, useEffect } from 'react';
import OnRollLogoWhite from 'static/images/OnRollLogo-white.svg';
import ApexchatLogoWhite from 'static/images/logo-light.png';
import ApexchatLogo404 from 'static/images/logo.png';
import OnRollLogo from 'static/images/OnRollLogo.png';
import ApexchatLogo from 'static/images/ApexchatLogo.png';
import config from 'config';
import { getAppMetaAsync } from 'features/auth/authSlice';
import storage from 'helpers/storage';

export const changeThemeColor = theme => {
  if (theme == 'gray') {
    document.documentElement.style.setProperty('--primary', 'var(--th-gray)');
    document.documentElement.style.setProperty(
      '--primary-1',
      'var(--th-gray-1)'
    );
    document.documentElement.style.setProperty(
      '--primary-2',
      'var(--th-gray-2)'
    );
    document.documentElement.style.setProperty(
      '--primary-3',
      'var(--th-gray-3)'
    );
    document.documentElement.style.setProperty(
      '--primary-4',
      'var(--th-gray-4)'
    );
    document.documentElement.style.setProperty(
      '--primary-light',
      'var(--th-light-gray)'
    );
    document.documentElement.style.setProperty(
      '--info-1',
      'var(--th-light-gray)'
    );
    document.documentElement.style.setProperty(
      '--primary-dark',
      'var(--th-dark-gray)'
    );
  } else {
    document.documentElement.style.setProperty('--primary', 'var(--th-blue)');
    document.documentElement.style.setProperty(
      '--primary-1',
      'var(--th-blue-1)'
    );
    document.documentElement.style.setProperty(
      '--primary-2',
      'var(--th-blue-2)'
    );
    document.documentElement.style.setProperty(
      '--primary-3',
      'var(--th-blue-3)'
    );
    document.documentElement.style.setProperty(
      '--primary-4',
      'var(--th-blue-4)'
    );
    document.documentElement.style.setProperty(
      '--primary-light',
      'var(--th-light-blue)'
    );
    document.documentElement.style.setProperty(
      '--info-1',
      'var(--th-light-blue)'
    );
    document.documentElement.style.setProperty(
      '--primary-dark',
      'var(--th-dark-blue)'
    );
  }
};

export const AppMetaProvider = () => {
  const host = window.location.hostname;
  const _subDomainLength = host.split('.')[0].length;
  const _hostDomainSubString = host.substring(_subDomainLength + 1);
  let apexchatOwnerCompanyKeys = config.apexchatOwnerCompanyKeys.split(',');
  let _hostDomain = host == 'localhost' ? 'apexchat.com' : _hostDomainSubString;
  let isApexHostName = config.apexchatURLS.split(',').includes(_hostDomain);
  let _hostKey = JSON.parse(storage.getItem('AppMetaInfo'))?.['host'];

  const _staticMeta = (function () {
    let staticMetaData = {
      isWhiteLabeled: true,
      androidAppLink:
        'https://play.google.com/store/apps/details?id=com.onroll.live',
      IOSAppLink: 'https://apps.apple.com/pk/app/onroll/id1554277859',
      whatsNewIframeLink: config.onRollIframeUrl
    };
    if (isApexHostName) {
      staticMetaData.isWhiteLabeled = false;
      staticMetaData.androidAppLink =
        'https://play.google.com/store/apps/details?id=com.apexchat.live';
      staticMetaData.IOSAppLink =
        'https://apps.apple.com/pk/app/apexconnect/id1385172978';
      staticMetaData.whatsNewIframeLink = config.apexchatIframeUrl;
    }
    return staticMetaData;
  })();

  let _defaultValues = {
    hostName: 'Portal',
    signupUrl: null,
    allowSignup: false,
    hostLogo: OnRollLogo,
    haveMultipleOccurrences: true,
    isDefaultHost: true,
    isWhiteLabeled: true
  };

  let _defaultApexChatValues = {
    hostName: 'Apex Chat',
    signupUrl: null,
    allowSignup: false,
    hostLogo: ApexchatLogo,
    haveMultipleOccurrences: true,
    isDefaultHost: true,
    isWhiteLabeled: false
  };

  const _getCurrentTheme = () => {
    if (isApexHostName) {
      return 'gray';
    }
  };

  const _dynamicMeta = async function () {
    changeThemeColor(_getCurrentTheme());

    const response = await getAppMetaAsync({
      data: {
        hostDomain: _hostDomain,
        hostKey: _hostKey ? _hostKey : ''
      }
    });
    let payload = {
      ..._defaultValues
    };
    if (response?.data?.data) {
      const { data } = response.data;
      if (isApexHostName && data.haveMultipleOccurrences) {
        payload = { ..._defaultApexChatValues };
      } else if (!data.isDefaultHost) {
        if (!data.hostLogo) {
          data.hostLogo = isApexHostName ? ApexchatLogo : OnRollLogo;
        }
        payload = { ...data, isWhiteLabeled: false };
      }
    }
    return payload;
  };

  return {
    GetStaticMeta: _staticMeta,
    GetDynamicMeta: _dynamicMeta,
    GetCurrentTheme: _getCurrentTheme
  };
};
