import { emailRegx, regexValidatePhone, requiredText } from '../genericSchemas';
import * as Yup from 'yup';
import { errors } from 'helpers/variables';

export const leadFormSchema = () =>
  Yup.object().shape(
    {
      name: requiredText,
      phoneNumber: Yup.string()
        .matches(/^[\d-()+\s]+$/, 'Digits only')
        .matches(regexValidatePhone, errors.notValid.phoneNumber)
        .when('email', {
          is: mobile => !mobile || mobile.length === 0,
          then: Yup.string().required('At least one of the fields is required')
        }),
      email: Yup.string()
        .matches(emailRegx, errors.notValid.email)
        .when('phoneNumber', {
          is: email => !email || email.length === 0,
          then: Yup.string().required('At least one of the fields is requireds')
        })
    },
    ['email', 'phoneNumber']
  );

export const callConnectFormSchema = (nameReq = true) =>
  Yup.object().shape(
    {
      name: nameReq ? requiredText : Yup.string(),
      phoneNumber: Yup.string()
        .matches(/^[\d-()+\s]+$/, 'Digits only')
        .matches(regexValidatePhone, errors.notValid.phoneNumber)
        .required('Phone number is required'),
      phoneNumberFrom: Yup.string()
        .matches(/^[\d-()+\s]+$/, 'Digits only')
        .matches(regexValidatePhone, errors.notValid.phoneNumber)
        .required('From Phone number is required')

      // .when('email', {
      //   is: mobile => !mobile || mobile.length === 0,
      //   then: Yup.string().required('At least one of the fields is required')
      // })
    },
    ['phoneNumber']
  );
