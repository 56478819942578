import React, { useState, useEffect } from 'react';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import {
  filterTypes,
  generateFilterColumns,
  generateFilterTypes
} from 'utils/tableConfig';

const gridStyle = {
  minHeight: 'calc(100vh - 100px)',
  marginTop: 10,
  boxShadow: '0px 3px 10px 0 rgba(0, 0, 0, 0.08)',
  overflow: 'hidden'
};

export const GridWrapper = ({
  columns,
  setLoading,
  totalRecords,
  dataSource,
  loadingMessage,
  renderColumnContextMenu,
  selectedData,
  handleSelect,
  gridClass,
  vissibleColumns,
  refetch = false,
  customGridStyle = {},
  rowHeight = 50,
  recordsLimit = 50,
  enableSelection = true,
  activateRowOnFocus = true,
  showActiveRowIndicator = true,
  checkboxOnlyRowSelect = false,
  checkboxColumn = false,
  staticColumns = ['actions']
}) => {
  let CustomfilterType = {
    ...filterTypes,
    ...generateFilterTypes(columns)
  };
  const [limit, setLimit] = useState(recordsLimit);
  const [dynamicColumns, setDynamicColumns] = useState(columns);

  const getVissibleColumns = () => {
    let filteredColumns = columns.map(dc => ({
      ...dc,
      visible: staticColumns.includes(dc.name)
        ? true
        : !!vissibleColumns[dc.name]
    }));
    return filteredColumns;
  };

  useEffect(() => {
    if (vissibleColumns && Object.keys(vissibleColumns).length) {
      setDynamicColumns(getVissibleColumns());
    }
  }, [vissibleColumns]);

  useEffect(() => {
    removeScroll('overflow:hidden');
    return () => removeScroll('');
  }, []);

  const removeScroll = styles => {
    document.getElementsByTagName('body')[0].style.cssText = styles;
  };

  const [gridRef, setGridRef] = useState(null);
  const onReload = () => {
    if (gridRef && gridRef.current) {
      gridRef.current.reload();
    }
  };

  useEffect(() => {
    if (refetch) {
      onReload();
    }
  }, [refetch]);

  return (
    <>
      <ReactDataGrid
        checkboxColumn={checkboxColumn}
        checkboxOnlyRowSelect={checkboxOnlyRowSelect}
        onReady={setGridRef}
        idProperty="id"
        style={{ ...gridStyle, ...customGridStyle }}
        className={gridClass ? gridClass : ''}
        columns={dynamicColumns}
        limit={limit}
        onLimitChange={setLimit}
        pagination={totalRecords > limit ? true : false}
        rowHeight={rowHeight}
        dataSource={dataSource}
        onLoadingChange={setLoading}
        defaultFilterValue={generateFilterColumns(columns)}
        filterTypes={CustomfilterType}
        showHoverRows={false}
        showCellBorders={false}
        showColumnMenuTool={true}
        loadingText={loadingMessage}
        renderColumnContextMenu={renderColumnContextMenu}
        selected={selectedData}
        onSelectionChange={handleSelect}
        enableSelection={enableSelection}
        activateRowOnFocus={activateRowOnFocus}
        showActiveRowIndicator={showActiveRowIndicator}
      />
    </>
  );
};
