import './styles.scss';
import { UilInfoCircle } from '@iconscout/react-unicons';
import { toolTipPlacement } from 'helpers/generic.methods';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './styles.scss';
const FilterWrapper = ({
  title = 'Title',
  clearTitle = 'Clear',
  onFilterClear,
  children,
  tooltipLabel = '',
  tooltipPosition = 'right'
}) => {
  const { t } = useTranslation();
  const handleClick = () => {
    onFilterClear('clear');
  };
  return (
    <>
      <div className="filter-wrapper">
        <div className="filter-header">
          <div className="filter-heading d-flex align-items-center">
            {title}
            {tooltipLabel ? (
              <OverlayTrigger
                // placement={toolTipPlacement}
                placement={'right'}
                overlay={
                  <Tooltip className="filter-panel-tooltip">
                    {tooltipLabel}
                  </Tooltip>
                }
              >
                <UilInfoCircle size={18} className="ml-1" />
              </OverlayTrigger>
            ) : null}
          </div>
          <div className="filter-clear" onClick={handleClick}>
            <span>{t('company.analytics.clear')}</span>
          </div>
        </div>
        <div className="filter-body">{children}</div>
      </div>
      <hr className="m-0" />
    </>
  );
};

export default FilterWrapper;
