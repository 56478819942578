import TruncateWithToolTip from 'components/TruncateWithToolTip';
import { Enums } from 'helpers/dropdown-enums';

const Title = ({
  companyName,
  visitorName,
  isAgent,
  isOperator,
  chatSectionType,
  chatInitiatedPlatform
}) => {
  const agentText = () => {
    return (
      <TruncateWithToolTip
        text={companyName}
        limit={24}
        className="chatname-title"
      />
    );
  };
  const operatorText = () => {
    return (
      <TruncateWithToolTip
        text={visitorName || incomingText()}
        limit={24}
        className="chatname-title"
      />
    );
  };

  const incomingText = () => {
    return chatSectionType === Enums.RoomType.IncomingChat
      ? `New ${chatInitiatedPlatform} Chat`
      : `${chatInitiatedPlatform} Chat`;
  };

  if (isAgent) {
    return agentText();
  } else if (isOperator) {
    return operatorText();
  }
};

export default Title;
