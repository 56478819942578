import LoaderWithCheck from 'components/CircularLoaderWithSuccessOrError';
import useViewport from 'helpers/viewport-hook';
import { Col, Row, Spinner } from 'react-bootstrap';
import MobileLargeScreen from 'static/images/MD-coming-soon-form/responsive-mobile/largeScreen.png';
import MobileAppContent from './mobile-app-content';
import WhatsNewSection from './whats-new-section';

const FormSection = ({
  isGrid,
  alreadySignedUp,
  message,
  loading,
  children
}) => {
  const screen = useViewport();

  return isGrid ? (
    <Col xs={12} md={4} className={`${screen.xs && 'mb-3 mt-3'}`}>
      {screen.xs && (
        <Row className="mb-3 on-mobile-screen-header">
          <Col>
            <h2 style={{ fontSize: '1.4rem' }}>
              {alreadySignedUp
                ? 'Signup Request Received'
                : 'Sign up for the new Messaging Dashboard'}
            </h2>
            {!!message ? <p>{message}</p> : <p>Sign up now.</p>}
            <img
              className="d-block d-md-none mx-auto on-mobile-image"
              src={MobileLargeScreen}
              alt="small-screen-img"
              width={'90%'}
            />
          </Col>
        </Row>
      )}
      <div className={`MD-signup-form-container ${loading && 'h-100'}`}>
        <div
          className={`form-container ${
            (loading || !!message) && 'form-container-loader'
          }`}
        >
          {(loading || !!message) && (
            <div className="spinner-container">
              <LoaderWithCheck
                loading={loading}
                isSuccess={!!message}
                size="lg"
              />
            </div>
          )}
          <>
            {!screen.xs && (
              <Row className="mb-3">
                <Col>
                  {alreadySignedUp ? (
                    <h3>Signup Request Received</h3>
                  ) : (
                    <h3 className="text-capitalize mb-0">
                      Sign up for the new Messaging Dashboard!
                    </h3>
                  )}
                  {!!message ? (
                    <p className="fw-500 fz-20">{message}</p>
                  ) : (
                    <p className="fw-500 fz-20">Sign up now.</p>
                  )}
                </Col>
              </Row>
            )}
            {children}
          </>
        </div>
        <MobileAppContent />
        {screen.xs && <WhatsNewSection />}
      </div>
    </Col>
  ) : null;
};
export default FormSection;
