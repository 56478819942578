export const fr_widget = {
  // widget editor
  widget_heading: 'Éditeur de widgets',
  sidebar_item1: "Paramètres d'apparence",
  item1_child1: 'Éditeur de widgets',
  widget_companies: 'Entreprises',
  no_companies_msg:
    "Actuellement, aucune entreprise n'est ouverte, cliquez sur 'Rechercher des entreprises' pour modifier les paramètres",
  collapse_item1: 'Intégrer le widget',
  collapse_item1_tag1:
    'Copiez ou installez le code du widget pour publier votre widget de chat',
  widget_script: 'Script de widget',
  copy_snippet: "Copier l'extrait",
  widget_or: 'OU',
  easy_install: 'Installez facilement sur',
  wordpress_plugin: 'Wordpress via plugin',
  send_developer: 'Envoyer à votre développeur',
  designer_email: 'Courriel du concepteur',
  send_instruction: 'Envoyer les instructions',
  widget_error_info: 'Appuyez sur Entrée pour ajouter une adresse e-mail.'
};

export default fr_widget;
