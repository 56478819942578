import { pushChatRoom } from 'features/messagingChats/messagingChats';
import { logIt } from 'helpers/logger';
import storage from 'helpers/storage';
import { IncreaseProgress } from 'pages/portal/messaging/ChatSplashScreen/SplashScreen';
import store from 'store';
import RTC from '../rtc';
import { chatChanged } from '../services/chatroom-service';

const Subscribe_GeneralQueue_CompanyID = callbacks => {
  const user = JSON.parse(storage.getItem('Profile'));
  RTC().Subscribe({
    channels: ['/generalqueue/' + user?.CompanyID],
    callbacks: callbacks
  });
};

export const Init_GeneralQueue_CompanyID = () => {
  Subscribe_GeneralQueue_CompanyID({
    onSuccess: function (args) {
      IncreaseProgress({
        text: 'Subscribing general queue for company...',
        count: 10
      });
      logIt('log', 'onSuccess::General Queue CompanyID Subscribe', args);
    },
    onFailure: function (args) {
      logIt('log', 'onFailure::General Queue CompanyID Subscribe', args);
    },
    onReceive: function (args) {
      var data = args.getData();
      var chatroomByType = chatChanged(data);
      logIt(
        'log',
        'genral queue companyID onReceive:: chatroomByType---',
        chatroomByType
      );
      if (
        chatroomByType.includeInSections.length ||
        chatroomByType.excludeInSections.length
      ) {
        // TODO: remove duplication of chat and push to respective chat array by its type
        store.dispatch(pushChatRoom(chatroomByType));
      }
    }
  });
};

export default Subscribe_GeneralQueue_CompanyID;
