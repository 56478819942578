import FilterWrapper from './filter-wrapper';
import FilterPanelInfoTooltip from './filter-panel-info-tooltip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
const useStyles = makeStyles(theme => ({
  root: {
    background: 'white'
  },
  // inputRoot: {
  //   padding: '6px 5px 4px 5px !important'
  // },
  tag: {
    color: '#454F5B !important'
    // padding: '2px 0px'
  }
}));
const CustomFieldFilter = ({
  title = '',
  setFunction,
  onChangefilters,
  loading,
  selected,
  options = [
    { value: 3, text: 'Vendor Max', name: 'vendormax' },
    { value: 2, text: 'Frozen Mountain', name: 'frozenmountain' },
    { value: 13, text: 'Reach Local', name: 'reachlocal' },
    { value: 2054, text: 'Abdul Samad', name: 'Abdul Samad' }
  ],
  isDropdown = true,
  toolTipQuickStats,
  toolTipGraphs
}) => {
  const classes = useStyles();
  const onFilterClear = () => {
    console.log('clear');
    setFunction([]);
    onChangefilters([]);
  };
  const handleChange = (e, data) => {
    setFunction(data);
    onChangefilters(data);
  };

  return (
    <FilterWrapper
      title={title}
      onFilterClear={onFilterClear}
      tooltipLabel={
        <FilterPanelInfoTooltip
          toolTipQuickStats={toolTipQuickStats}
          toolTipGraphs={toolTipGraphs}
        />
      }
    >
      <div className="items-container">
        <Autocomplete
          multiple
          classes={classes}
          limitTags={1}
          options={options}
          getOptionLabel={option => option.key}
          getLimitTagsText={more => (
            <span className="cursor-pointer">{`+${more}`}</span>
          )}
          onChange={(e, data) => handleChange(e, data)}
          value={selected}
          renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
              label=" Select Business Type"
              size="small"
            />
          )}
        />
      </div>
    </FilterWrapper>
  );
};

export default CustomFieldFilter;
