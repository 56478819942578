import useViewport from 'helpers/viewport-hook';
import { Row, Col } from 'react-bootstrap';
import SpecialMessageComponent from './special-message-comp';

const Header = ({ hideHeader, alreadySignedUp, message, showForm, t }) => {
  const screen = useViewport();
  return !hideHeader ? (
    <Row>
      <Col md={12} className="m-3">
        <h2 style={{ fontSize: screen.xs && '1.5rem' }}>
          {alreadySignedUp
            ? 'Signup Request Received'
            : 'Sign up for the new Messaging Dashboard!'}
        </h2>
        <SpecialMessageComponent
          t={t}
          showButton={!showForm}
          message={message}
        />
      </Col>
    </Row>
  ) : null;
};

export default Header;
