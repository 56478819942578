import React from 'react';

const returnHtml = chat => {
  if (chat.text)
    return chat.text.includes('href') ? (
      <span dangerouslySetInnerHTML={{ __html: chat.text }}></span>
    ) : (
      chat.text
    );
  else return '';
};

const ChatItem = ({ chat }) => {
  return (
    <div>
      {chat.participantId < 0 ? (
        <i className="system-chat">
          {chat.participantDisplayName}: {returnHtml(chat)}
        </i>
      ) : chat.participantDisplayName === 'Visitor' ? (
        <span className="visitor-chat">Visitor: {returnHtml(chat)}</span>
      ) : (
        <span className="operator-chat">
          {chat.participantDisplayName}: {returnHtml(chat)}
        </span>
      )}
    </div>
  );
};

export default ChatItem;
