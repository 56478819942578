import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import UilAngleLeft from '@iconscout/react-unicons/icons/uil-angle-left';
import UilAngleRight from '@iconscout/react-unicons/icons/uil-angle-right';

const SidebarAnalytics = ({ open, children }) => {
  return (
    <Navbar
      id="sidebar-analytics"
      className={'sidebar-nav sidebar-nav-' + (open ? 'open active' : 'close')}
    >
      {/* <a href="#" className="navbar-ham-right" onClick={showSidebarHandler}>
        {open ? (
          <UilAngleRight size="28" className="angle-icon" />
        ) : (
          <UilAngleLeft size="28" className="angle-icon" />
        )}
      </a> */}
      <div className="sidebar-wrapper">
        {/* <Scrollbars
          className="scrollbar-wrap"
          style={{ width: '100%', borderRadius: '0 0 0 24px' }}
          renderThumbVertical={() => <div className="scrollbar-rail" />}
        > */}
        {children}
        {/* </Scrollbars> */}
      </div>
    </Navbar>
  );
};

export default SidebarAnalytics;
