import { RTCClient } from './chat-connection';

var rtcClient;

const RTC = () => {
  if (!window.RTC.init) {
    rtcClient = RTCClient();
  }
  const _subscribe = options => {
    let checkingAlreadySubscribedChannels =
      rtcClient.subscribedChannels?.filter(element =>
        options?.channels?.includes(element)
      );
    if (rtcClient?.status && checkingAlreadySubscribedChannels.length === 0) {
      let args = {
        channels: options.channels,
        ...options.callbacks
      };
      if (options.meta) {
        args.meta = options.meta;
      }
      rtcClient.client.Subscribe(args);

      rtcClient.pushSubscribedChannels(options.channels);
    }
  };

  const _publish = options => {
    options.retries = options.retries || 0;
    options.meta = options.meta || {};
    options.channel = options.channel;
    options.data = options.data || {};
    let args = {
      ...options,
      ...options.callbacks
    };

    rtcClient.client.Publish(args);
  };
  const _unsubscribe = channels => {
    rtcClient.client.UnSubscribe({
      channels: channels
    });
  };
  return {
    Subscribe: _subscribe,
    Publish: _publish,
    UnSubscribe: _unsubscribe
  };
};

export default RTC;
