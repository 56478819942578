export const fr_pacingLeadsAndTraffic = {
  heading1: 'Pacing',
  heading2: 'Pistes',
  heading3: 'Circulation',
  campaignPaused: {
    label: 'Campagne mise en veille',
    info: 'Si oui ou non cette campagne est actuellement en pause.'
  },
  recycleBudgetAfterItEnds: {
    label: 'Budget Recyclage Après It Ends',
    info: 'Si oui ou non le redémarrage du budget lui-même après la période totale (jours) a été achevée.'
  },
  resetBudget: {
    label: 'Réinitialiser Budget - 1er de chaque mois',
    info: "La sélection de cette option permet de réinitialiser un budget de l'entreprise automatiquement à la première date de chaque mois."
  },
  totalPeriod: {
    label: 'Période Total (Jours)',
    info: "Le nombre de jours pour exécuter l'outil de budgétisation pour."
  },
  pacingType: {
    label: 'Type de stimulation cardiaque',
    info: 'Le paramètre de baser le budget; Les options sont soit le trafic ou plomb.'
  },
  numberofleads: {
    label: 'Nombre de pistes',
    info: 'Nombre de prospects envoyés au cours du budget actuel.'
  },
  sendNotification: {
    label:
      "Envoyer une notification (sur la première piste lorsqu'un budget démarre)",
    info: 'Cette notification sera envoyée lorsque la première avance est envoyé dans un cycle budgétaire.'
  },
  emailAddresses: {
    label:
      "Adresses email (pour l'envoi d'un message lors de la première avance est envoyé)",
    info: "Premier avertissement budgété de notification de plomb sera envoyé à ces adresses. Si ce champ est laissé vide, la notification sera envoyé au Contact principal pour l'entreprise."
  },
  warningMessageEmailAddresses: {
    label: `Adresses e-mail (pour le message d'avertissement)`,
    info: `Un premier avertissement de notification de prospect budgétisé sera envoyé à ces adresses. Si ce champ est laissé vide, la notification sera envoyée au contact principal de l'entreprise.`
  },
  sendingMessageEmailAddresses: {
    label:
      'Adresses e-mail (pour envoyer un message lorsque le budget est en pause)',
    info: `Un avertissement de budget suspendu sera envoyé à ces adresses. Si ce champ est laissé vide, le message de budget suspendu sera envoyé au contact principal de l'entreprise.`
  },
  hasAWarningBeenSent: {
    label: 'A un avertissement été envoyé (pour la première avance budgété)',
    info: "Un avertissement sera envoyé une seule fois jusqu'à ce que la durée totale du budget est remis à zéro."
  },
  enableSubBudget: {
    label: 'Activer Sous budget (moyenne sur plusieurs jours de stimulation)',
    info: 'Permet à un mini-budget dans le budget défini ci-dessus. Exemple: Si le budget principal est fixé pour un mois, utilisez cette option pour définir une limite quotidienne.'
  },
  totalTrafficAllowed: {
    label: 'Le trafic total admis',
    info: "Le nombre de fois l'invitation de chat sera présenté au cours de la période budgétaire en cours."
  }
};
export default fr_pacingLeadsAndTraffic;
