import React from 'react';
import Button from 'react-bootstrap/Button';
import UILSave from '@iconscout/react-unicons/icons/uil-save';
import UILEdit from '@iconscout/react-unicons/icons/uil-edit-alt';
import { useTranslation } from 'react-i18next';

const EditSaveButton = ({
  editable,
  loading,
  handleEditableState,
  disabled,
  saveText,
  editText,
  readOnlySection,
  handleOnClick,
  headerEditbutton
}) => {
  const t = useTranslation().t;
  return (
    <>
      {editable && !disabled ? (
        <Button
          disabled={disabled}
          className={
            headerEditbutton ? 'btn-sm btn-flex' : 'btn-md btn-flex ml-6r'
          }
          type="submit"
          variant="primary"
          onClick={handleOnClick}
        >
          <UILSave />
          <span>
            {saveText ? saveText : t('company.general.saveTxt')}
            {loading && <i className="uil uil-spinner spinner small ml-2"></i>}
          </span>
        </Button>
      ) : (
        <Button
          className={
            headerEditbutton ? 'btn-sm btn-flex' : 'btn-md btn-flex ml-6r'
          }
          type="button"
          disabled={readOnlySection || disabled}
          variant="lightgray-3"
          onClick={e => {
            e.preventDefault();
            handleEditableState(true);
          }}
        >
          <UILEdit />
          <span>{editText ? editText : t('company.general.editTxt')}</span>
        </Button>
      )}
    </>
  );
};
export default EditSaveButton;
