import React from 'react';
import { Route } from 'react-router-dom';

const HandlePageRoutes = (routes, layout) => {
  return routes.map((route, key) => {
    if (route.path)
      return (
        <Route
          path={route.path}
          component={route.component}
          exact={true}
          key={`${key}-child`}
        />
      );
    else return null;
  });
};

export default HandlePageRoutes;
