// import SidebarRight from 'components/Sidebar/sidebar-right';
import React, { useState, useCallback } from 'react';
import moment from 'moment';
import { getUserById, getUsersByCompany } from 'features/users/userSlice';
import { Row, Col, Button } from 'react-bootstrap';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import './styles.scss';
import { UilEye } from '@iconscout/react-unicons';
import UserModal from '../../modal/UserModal';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import ToggleMenuButton from 'helpers/ui-controls/ui-components/ToggleMenuButton';
import { GridWrapper } from 'components/GridWrapper';
import { getFilterValue, usersTableConfig } from 'utils/tableConfig';
import { usersFilterConfig, usersFilterTypeConfig } from 'utils/filterConfig';

const Users = ({ company, clickHandlerMenu }) => {
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('Loading ...');
  const [totalRecords, setTotalRecords] = useState(0);

  const selectedData = {};
  const { t } = useTranslation();

  const handleShow = id => {
    setShow(true);
    if (id)
      getUserById({
        params: { id }
      }).then(res => {
        if (res.data) {
          const { data } = res.data;
          setSelected(data);
        }
      });
  };
  const handleClose = () => {
    setSelected({});
    setShow(false);
  };

  const ActionButtons = ({ value }) => (
    <>
      <Button className="btn-sm btn-flex mr-1" variant="lightgray-3">
        <UilEye className="mr-1" />
        View
      </Button>
    </>
  );

  const filterTypes = Object.assign(
    {},
    ReactDataGrid.defaultProps.filterTypes,
    usersFilterTypeConfig
  );

  const getDateFilterValue = data => {
    let updatedValue;
    if (data.name === 'createdOn' && data.operator === 'before') {
      updatedValue = moment(data.value).local();
    } else if (data.name === 'createdOn' && data.operator === 'after') {
      updatedValue = moment().local();
    } else {
      updatedValue = data.value;
    }
    return updatedValue;
  };

  const loadData = ({ skip, limit, sortInfo, filterValue }) => {
    try {
      setLoadingMessage('Loading ...');
      const updatedValue = filterValue
        .filter(v => v.value !== '')
        .map(v => ({
          propertyName: v.name,
          propertyValue1: getFilterValue(v),
          propertyValue2:
            v.name === 'createdOn' &&
            (v.operator === 'before' || v.operator === 'after')
              ? getDateFilterValue(v)
              : '',
          propertyValue3: [],
          filterDataType: 2,
          comparison: 2
        }));
      let payload = {
        companyId: company.id,
        start: skip,
        limit: limit,
        filter: JSON.stringify(updatedValue),
        dir: !sortInfo ? 'DESC' : sortInfo.dir === 1 ? 'ASC' : 'DESC',
        sort: sortInfo ? sortInfo.name : 'id'
      };
      return getUsersByCompany(payload).then(response => {
        if (response.data.totalSize > 20) {
          setDataLimit(skip + response.data.data.length);
        } else {
          setDataLimit(response.data.data.length);
        }
        const totalCount = response.data.totalSize;
        setTotalRecords(totalCount);
        return { data: response.data.data, count: parseInt(totalCount) };
      });
    } catch (error) {
      toast.error(error || error.message);
    }
  };

  const handleSelect = payload => {
    const { data } = payload;
    let user = data[0];
    handleShow(user.id);
  };

  const dataSource = useCallback(loadData, []);
  const [dataLimit, setDataLimit] = useState(0);
  const renderColumnContextMenu = useCallback((menuProps, { cellProps }) => {
    menuProps.items = menuProps.items.splice(0, 8);
  }, []);
  return (
    <>
      <div className="users-container">
        <Row>
          <Col className="m-3">
            <ToggleMenuButton
              heading={t('company.general.menuItem5')}
              clickHandlerMenu={clickHandlerMenu}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="mobile_view">
              <GridWrapper
                columns={usersTableConfig(ActionButtons)}
                totalRecords={totalRecords}
                dataSource={dataSource}
                setLoading={setLoading}
                loadingMessage={loadingMessage}
                renderColumnContextMenu={renderColumnContextMenu}
                selectedData={selectedData}
                handleSelect={handleSelect}
                customGridStyle={{
                  minHeight: 'calc(70vh - 100px)'
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
      <UserModal
        title={'User Information'}
        editData={selected}
        eventHideModal={handleClose}
        showModal={show}
      />
    </>
  );
};

export default Users;
