import React, { useEffect, useState } from 'react';
import './styles.scss';
// import { useHistory } from 'react-router-dom';
// import storage from 'helpers/storage';
// import { SubMenuActiveLink } from 'helpers/hoc/NonCollapsableLink';
import UILAngleDown from '@iconscout/react-unicons/icons/uil-angle-down';
import Accordion from 'react-bootstrap/Accordion';
import AccordionButton from './AccordionButton';
import storage from 'helpers/storage';
// import { companySideBarMenu } from './constants';
// import { useTranslation } from 'react-i18next';
// import { useSelector } from 'react-redux';
// import { selectRoles } from 'features/roles/rolesSlice';

const Collapseable = props => {
  const {
    // selectMenu,
    // selectedMenu,
    // mobileFlag,
    children,
    storageKey = 'activeAccordion',
    icon,
    name = '',
    description = '',
    id = '',
    defaultOpen = false
  } = props;
  // const t = useTranslation().t;

  let [show, setShow] = useState(defaultOpen);

  let [activeAccordion, setActiveAccodion] = useState(
    JSON.parse(storage.getItem(storageKey)) || ''
  );

  // TODO
  // const goToPreviousPath = () => {
  //   history.goBack();
  // };

  useEffect(() => {
    let hasActiveAccordion = JSON.parse(storage.getItem(storageKey));
    setActiveAccodion(hasActiveAccordion);
  }, []);

  return (
    <Accordion
      // defaultActiveKey={id}
      activeKey={show ? id : undefined}
      className="collapsible-comp-container"
    >
      <ul className="collapsible">
        <li className={'panel-border'}>
          <AccordionButton
            callback={() => setShow(!show)}
            storageKey={storageKey}
            btnClass="collapse-trigger"
            eventKey={id}
            show={show}
          >
            <span className="d-flex align-items-center">
              {icon}
              <span>
                <div className="menu-name">{name}</div>
                <div style={{ fontSize: '10px' }}>{description}</div>
              </span>
              <UILAngleDown className="collapse-caret" />
            </span>
          </AccordionButton>
          <Accordion.Collapse eventKey={id}>
            <>{children}</>
          </Accordion.Collapse>
        </li>
      </ul>
    </Accordion>
  );
};

export default Collapseable;
