import Form from 'react-bootstrap/Form';

const ToggleSwitch = ({
  label,
  id = 'switch',
  selectedValue,
  handleChange,
  disabled = false,
  classes = '',
  switchClass
}) => {
  return (
    <Form.Group controlId={id} className={`d-flex mb-0 ${classes}`}>
      <Form.Label className="d-flex align-items-center mb-0">
        <span>{label}</span>
      </Form.Label>
      <Form.Check
        type="switch"
        id={id}
        className={switchClass}
        checked={!!selectedValue}
        onChange={handleChange}
        inline={true}
        disabled={disabled}
      />
    </Form.Group>
  );
};

export default ToggleSwitch;
