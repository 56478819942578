import React from 'react';
import { useTranslation } from 'react-i18next';
import { timeZoneData } from 'app/TimeZoneData';
import ToggleToolTip from 'helpers/ui-controls/toggleToolTip';
import ToggleMenuButton from 'helpers/ui-controls/ui-components/ToggleMenuButton';
import TimePicker from 'helpers/ui-controls/ui-components/TimePicker';
import DropDownEditable from 'helpers/ui-controls/DropDownEditable';
import { generateDropDownOptions } from './helper';
import { enum_Dates, enum_hours, enum_Months } from 'helpers/dropdown-enums';

const MonthlyComp = ({
  report = {},
  showHeading = true,
  showNote = true,
  clickHandlerMenu,
  isEdit = true,
  hour = '',
  month = '',
  handleChange,
  editable
}) => {
  const { t } = useTranslation();

  return (
    <>
      <DropDownEditable
        key={'scheduleHour'}
        // heading={t('company.reportDetails.parameters.reportType.label')}
        heading={'Hour'}
        field="scheduleHour"
        dataProperty={hour || 0}
        editable={editable}
        handleChange={handleChange}
        materialFields
        options={generateDropDownOptions(enum_hours)}
      />

      <DropDownEditable
        key={'scheduleDayOfMonth'}
        // heading={t('company.reportDetails.parameters.reportType.label')}
        heading={'Month'}
        field="scheduleDayOfMonth"
        dataProperty={month || 0}
        editable={editable}
        handleChange={handleChange}
        materialFields
        options={generateDropDownOptions(enum_Dates)}
      />
    </>
  );
};

export default MonthlyComp;
