import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { theme } from 'helpers/variables';
import { stopPropogation } from 'features/general/generalSlice';

import RenderSidebarTooltip from 'helpers/ui-controls/renderSidebarTooltip';
import { UilQuestionCircle } from '@iconscout/react-unicons';

const PopMenu = ({ showSideBar, icon, title = '', clickHandler, children }) => {
  const [menuShow, setMenuShow] = useState(false);
  const dispatch = useDispatch();

  const useCloseOnClickedOutside = async ref => {
    await dispatch(stopPropogation(ref)).then(res => {
      if (res) setMenuShow(false);
    });
  };

  // use ref on wrapper element
  const wrapperRef = useRef(null);
  // calling the method to check if the clicked event
  // happened inside / outside the wrapper
  // and pass the wrapper ref
  useCloseOnClickedOutside(wrapperRef);

  const toggleDropdownHandler = e => {
    if (children) {
      setMenuShow(!menuShow);
    } else {
      clickHandler();
    }
  };

  return (
    <div id="introJS-item-setting" className="profile-nav" ref={wrapperRef}>
      <div className="avatar-nav d-block" onClick={toggleDropdownHandler}>
        {/* conditional toggle avtar/usericon */}
        <div className="userIcon d-flex align-items-center userTextPrimary">
          <div className="m-4">
            <RenderSidebarTooltip
              showSidebar={showSideBar}
              title={title}
              icon={icon}
            />
          </div>

          {showSideBar ? <span className=" fw-600 ml-2">{title}</span> : null}
        </div>
      </div>
      {/* toggle the menu on condition check */}
      {menuShow && children && (
        <div className={`sidebar-bottom-menu${showSideBar ? '-open' : ''}`}>
          <ul className="dropdow-list">
            <li className="notification-list-item heading cursor-pointer p-0">
              <div
                className="d-flex justify-content-between px-3 py-2 align-items-center"
                style={{
                  background: 'var(--lightgray-2)',
                  boxShadow: '0px 4px var(--lightgray-5)'
                }}
              >
                <p className="m-0" style={{ fontWeight: 700 }}>
                  {title}
                </p>
                <UilQuestionCircle size="18" className="sidebar-link" />
              </div>
            </li>
            {children}
          </ul>
        </div>
      )}
    </div>
  );
};

export default PopMenu;

export const PopMenuItem = ({
  icon,
  text = '',
  handler,
  style = { color: 'var(--primary)' }
}) => {
  return (
    <li className="notification-list-item cursor-pointer">
      <a style={style} onClick={handler}>
        {icon}
        {text}
      </a>
    </li>
  );
};
