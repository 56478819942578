import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { Card } from 'react-bootstrap';
import { UilAngleUp, UilAngleDown } from '@iconscout/react-unicons';

const Header = ({ title, chatCount, eventKey, defaultActiveKey }) => {
  const [activeId, setActiveId] = useState(defaultActiveKey);

  const toggleActive = id => {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  };

  return (
    <Accordion.Toggle
      as={Card.Header}
      className="according-text"
      eventKey={eventKey}
      onClick={() => toggleActive(eventKey)}
    >
      <span className="tab-title">
        {title} ({chatCount})
      </span>
      {activeId === eventKey ? (
        <UilAngleUp className="collapse-caret" />
      ) : (
        <UilAngleDown className="collapse-caret" />
      )}
    </Accordion.Toggle>
  );
};

export default Header;
