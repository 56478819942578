import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { useLoaderStyle } from './style';

const sizes = {
  sm: '2',
  md: '4',
  lg: '8'
};
const LoaderWithCheck = ({ loading, isSuccess, isError, message, size }) => {
  const classes = useLoaderStyle({ loaderSize: sizes[size] });
  return (
    <div className="text-center">
      <div
        className={`circle-loader ${classes.circleLoader} 
        ${!loading && 'load-complete'} 
        ${isSuccess && 'load-success'} 
        ${isError && 'load-error'} 
      `}
      >
        <div
          className={`${classes.checkmark} ${isSuccess && 'draw'} ${
            isError && 'error'
          }`}
        ></div>
      </div>
      {!!message && <p>{message}</p>}
    </div>
  );
};

LoaderWithCheck.propTypes = {
  loading: PropTypes.bool,
  isSuccess: PropTypes.bool,
  isError: PropTypes.bool,
  message: PropTypes.string,
  size: PropTypes.string
};

LoaderWithCheck.defaultProps = {
  loading: true,
  isSuccess: false,
  isError: false,
  message: '',
  size: 'sm'
};

export default LoaderWithCheck;
