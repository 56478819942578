export const provisionFormInitialValues = {
  companyName: '',
  companyKeyRequest: '',
  status: 1,
  accountManager: '',
  accountManagerEmail: '',
  leadEmails: '',
  billingEmailAddress: '',
  url: '',
  googleAnalyticsCode: '',
  crmName: '',
  crmEmailAddress: '',
  notes: '',
  provisionedCompanyId: 0,
  primaryCategoryId: 0,
  secondaryCategories: [],
  pricing: '',
  hasSetupFee: false,
  hasSmsFee: false,
  hasExitPopUpFee: false,
  hasFacebookFee: false,
  hasSpanishFee: false,
  useCrmEmail: false,
  autoDealerCRMEmail: '',
  freeConsultation: false,
  consultationFee: '',
  areaOfCoverage: '',
  linkToPrivacyPolicy: '',
  scriptTitlePosition: '',
  hipaa: false,
  languages: '',
  callConnect: false,
  quickConnectTranscript: false,
  callConnectPhone: '',
  insuranceCoverageResponse: '',
  specialInstructions: '',
  businessHours: '',
  quickConnectHours: [
    { day: 'Sunday', startTime: '', endTime: '' },
    { day: 'Monday', startTime: '', endTime: '' },
    { day: 'Tuesday', startTime: '', endTime: '' },
    { day: 'Wednesday', startTime: '', endTime: '' },
    { day: 'Thursday', startTime: '', endTime: '' },
    { day: 'Friday', startTime: '', endTime: '' },
    { day: 'Saturday', startTime: '', endTime: '' }
  ],
  officeHours: [
    { day: 'Sunday', startTime: '', endTime: '' },
    { day: 'Monday', startTime: '', endTime: '' },
    { day: 'Tuesday', startTime: '', endTime: '' },
    { day: 'Wednesday', startTime: '', endTime: '' },
    { day: 'Thursday', startTime: '', endTime: '' },
    { day: 'Friday', startTime: '', endTime: '' },
    { day: 'Saturday', startTime: '', endTime: '' }
  ],
  callConnectHours: '',
  invitationBoxTitle: '',
  officeHoursTimezone: 'Dateline Standard Time',
  crmId: 0
};

export const basicDetails = {
  companyName: '',
  companyKeyRequest: '',
  status: 1,
  accountManager: '',
  accountManagerEmail: '',
  leadEmails: '',
  billingEmailAddress: '',
  url: '',
  googleAnalyticsCode: '',
  crmId: 0,
  crmName: '',
  crmEmailAddress: '',
  notes: '',
  provisionedCompanyId: 0,
  primaryCategoryId: 0,
  secondaryCategories: []
};

export const attachements = {
  fileAttachements: []
};

export const pricingDetails = {
  pricing: '',
  hasSetupFee: false,
  hasSmsFee: false,
  hasExitPopUpFee: false,
  hasFacebookFee: false,
  hasSpanishFee: false,
  useCrmEmail: false,
  autoDealerCRMEmail: '',
  freeConsultation: false,
  consultationFee: ''
};

export const businessOperation = {
  areaOfCoverage: '',
  linkToPrivacyPolicy: '',
  scriptTitlePosition: '',
  hipaa: false,
  languages: '',
  callConnect: false,
  quickConnectTranscript: false,
  callConnectPhone: '',
  callConnectHours: '',
  insuranceCoverageResponse: '',
  specialInstructions: '',
  businessHours: '',
  quickConnectHours: '',
  officeHours: '',
  createdOn: '',
  modifiedOn: '',
  officeHoursTimezone: 'Dateline Standard Time'
};
