import React, { useEffect, useState } from 'react';
import './dashboard.scss';
import { getActivationStats } from 'features/analytics/analyticSlice';
import LineChart from 'components/Charts/LineChart';
import {
  getCookie,
  setCookieForSpecificMinutes
} from 'helpers/generic.methods';
const StatsOperatorAgents = () => {
  const [accActivation, setAccActivation] = useState({});
  const [loading, setLoading] = useState(false);
  const [maxValue, setMaxValue] = useState(0);

  const months = [
    '---',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];

  useEffect(() => {
    getAccActivations();
  }, []);

  const getAccActivations = () => {
    let payload = {
      Period: 4
      // EndDateInUTC: '2021-06-02T08:00:09.265Z',
      // StartDateInUTC: '2020-06-02T08:00:09.265Z'
    };
    if (getCookie('ActivationStats')) {
      setAccActivation(JSON.parse(getCookie('ActivationStats')));
    } else {
      setLoading(true);
      getActivationStats(payload).then(res => {
        if (res && res.data) {
          const { data } = res.data;
          if (data.length) {
            let labels = [],
              values = [];
            data.forEach((dt, ind) => {
              labels.push(
                months[dt.Month] +
                `${ind === 0 || dt.Month === 1 ? ` ${dt.Year}` : ''}`
              );
              values.push(dt.Count);
            });
            let obj = {
              labels,
              data: [
                {
                  values,
                  color: '#30CA00',
                  label: 'Operator'
                },
                {
                  values: [12, 19, 3, 5, 2, 3, 6],
                  color: '#B83C7A',
                  label: 'Agents'
                }
              ]
            };
            setMaxValue(Math.max(values));
            setLoading(false);
            setCookieForSpecificMinutes(
              'ActivationStats',
              JSON.stringify(obj),
              5
            );
            setAccActivation(obj);
          }
        }
      });
    }
  };

  return (
    <div className="chart-wrapper text-center">
      {loading && (
        <span className="ml-2 mr-2 mb-5">
          <i className="uil uil-spinner spinner mr-2 fz-24"></i>
          <span className="fz-14 text-darkgray-2">
            Please wait, Data is being fetched...
          </span>
        </span>
      )}
      {Object.keys(accActivation).length ? (
        <LineChart graphData={accActivation} max={maxValue} />
      ) : null}
    </div>
  )
}

export default StatsOperatorAgents;