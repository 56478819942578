import React from 'react';
import { useTranslation } from 'react-i18next';
import dropdownEnums, { Enums, enum_hours } from 'helpers/dropdown-enums';
import Weekly from './WeeklyComp';
import DropDownEditable from 'helpers/ui-controls/DropDownEditable';
import MonthlyComp from './MonthlyComp';
import TimePicker from 'helpers/ui-controls/ui-components/TimePicker';
import ToggleMenuButton from 'helpers/ui-controls/ui-components/ToggleMenuButton';
import SectionHeader from 'helpers/ui-controls/ui-components/SectionHeader';
import { generateDropDownOptions } from './helper';
// import MonthlyOrDaily from './MonthlyOrDaily';

const Schedule = ({
  report,
  handleChange,
  headerSection = false,
  editable,
  setEditable,
  loading,
  showHeading,
  clickHandlerMenu
}) => {
  const { t } = useTranslation();
  const getComponent = (report = {}) => {
    const scheduleType = Number(report.scheduleType);
    // = dropdownEnums.enum_Schedule.find(
    //   item => item.name === report.scheduleType
    // );

    if (scheduleType || scheduleType == 0)
      switch (scheduleType) {
        case Enums.ScheduleType.Monthly:
          return (
            <MonthlyComp
              editable={editable}
              hour={report['scheduleHour'] || 0}
              month={report['scheduleDayOfMonth'] || 0}
              scheduleName={scheduleType.name}
              handleChange={handleChange}
            />
          );
        case Enums.ScheduleType.Weekly:
          return (
            <Weekly
              isEdit={editable}
              hour={report['scheduleHour'] || 0}
              keyName={'scheduleDaysOfWeek'}
              weekDays={report['scheduleDaysOfWeek'] || []}
              scheduleName={scheduleType.name}
              handleChange={handleChange}
            />
          );
        case Enums.ScheduleType.Daily:
          return (
            <DropDownEditable
              key={'scheduleHour'}
              // heading={t('company.reportDetails.parameters.reportType.label')}
              heading={'Hour'}
              field="scheduleHour"
              dataProperty={report['scheduleHour'] || 0}
              editable={editable}
              handleChange={handleChange}
              materialFields
              options={generateDropDownOptions(enum_hours)}
            />
          );
        default:
          return (
            <>
              {/* <div className="page-section mt-0"> */}
              {/* <h4 className="sub-head">
                {t('company.reportDetails.schedule.noScheduleFound.label')}
              </h4> */}
              {/* </div> */}
            </>
          );
      }
    else return null;
  };

  return (
    <>
      <p className="fz-12 text-warning-2 fw-600 p-3 mb-0 warning-box">
        {t('company.reportDetails.note')}
      </p>
      <div className={`${showHeading ? 'page-section mt-0' : 'mt-3'}`}>
        {showHeading && (
          <ToggleMenuButton
            heading={t('company.reportDetails.heading2')}
            clickHandlerMenu={clickHandlerMenu}
          />
        )}
        <section>
          {headerSection ? (
            <SectionHeader
              loading={loading}
              disabled={loading}
              editable={editable}
              handleEditableState={setEditable}
            />
          ) : null}
          <div className="section-body white-background">
            <ul className="editable-fields-lists schedule-fields">
              <DropDownEditable
                heading={t('company.reportDetails.schedule.scheduleType.label')}
                field="scheduleType"
                dataProperty={report['scheduleType']}
                editable={editable}
                handleChange={handleChange}
                // titleText={Enums.ScheduleType[report['scheduleType']] || ''}
                materialFields
                options={dropdownEnums.enum_Schedule}
              />
              {getComponent(report)}
            </ul>
          </div>
        </section>
      </div>
    </>
  );
};

export default Schedule;
