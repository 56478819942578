import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Col, Card } from 'react-bootstrap';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import UserDetails from '../UserDetails';
import Suggestions from '../Suggestions';
import UilArrowLeft from '@iconscout/react-unicons/icons/uil-arrow-left';
import UilArrowRight from '@iconscout/react-unicons/icons/uil-arrow-right';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSelectedChat,
  getSelectedChatAllVisitorMessages
} from 'features/messagingChats/messagingChats';
import EndChatComfirmationModal from '../EndChatConfirmationModal';
import './UserSuggestions.scss';
import {
  selectChatDetailsRightPane,
  toggleChatDetailsRightPane
} from 'features/chat/chatSlice';
import ScrollBar from 'components/Scrollbars';
import { selectRoles } from 'features/roles/rolesSlice';
import {
  getLeadFormUserCredentials,
  setLeadFormReasonBulk
} from 'features/lead/leadSlice';
import { getPlainStringFromHTMLElementAsString } from 'helpers/validate';

const UserSuggestions = ({
  setToggleChatDetail,
  setToggleChatSuggestion,
  defaultSuggestionTab,
  setDefaultMessageText
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  let selectedChat = useSelector(getSelectedChat);
  let togglePanel = useSelector(selectChatDetailsRightPane);
  const { roles } = useSelector(selectRoles);
  const leadFormUserCredentials = useSelector(getLeadFormUserCredentials);
  const getVisitorMessages = useSelector(getSelectedChatAllVisitorMessages);
  const dispatch = useDispatch();
  const onBackButtonHandler = () => {
    setToggleChatDetail(true);
    setToggleChatSuggestion(false);
  };

  const endChat = () => {
    // setLoading(true);
    // setTimeout(() => {
    setToggleChatSuggestion(false);
    setToggleChatDetail(false);
    // setLoading(false);
    // }, 4000);
  };

  const onOpen = () => {
    setOpenModal(true);
  };
  const onClose = () => {
    setOpenModal(false);
  };

  const closeSidePane = () => {
    dispatch(
      toggleChatDetailsRightPane({
        show: false,
        activeParentTab: 'user-details'
      })
    );
  };

  const toggleSidePane = activeTab => {
    console.log('active parent tab', activeTab);
    dispatch(
      toggleChatDetailsRightPane({
        activeParentTab: activeTab
      })
    );
  };

  useEffect(() => {
    if (togglePanel.show && !leadFormUserCredentials.reason) {
      addVisitorMessagesInReason();
    }
  }, [togglePanel.show, leadFormUserCredentials.reason]);

  // add visitor response messages in reason field if reason field is empty and side pane is open
  const addVisitorMessagesInReason = () => {
    let allMessages = getVisitorMessages(selectedChat.getId());
    if (allMessages.length) {
      let reason = '';
      allMessages.map(msg => {
        let tempMsg = getPlainStringFromHTMLElementAsString(msg.text);
        reason = reason ? reason + '\n' + tempMsg : tempMsg;
      });
      if (reason) {
        dispatch(setLeadFormReasonBulk(reason));
      }
    }
  };

  return (
    <>
      <div
        className={`mb-3 chat-details-right-pane ${
          togglePanel.show ? 'show' : 'hide'
        }-panel`}
      >
        <div className="close-panel-wrap position-relative">
          <div className="close-panel" onClick={closeSidePane}>
            {isMobile ? (
              <UilArrowLeft />
            ) : // <UilArrowRight />
            null}
          </div>
        </div>
        <Card className="card-rounded grey-card-panel chat-suggestion-header pr-4r">
          <ScrollBar
            id="suggestion-scroll-div"
            horizontalScroll={false}
            style={{ margin: '1rem 0' }}
          >
            {/* <div className="user-details-wrap">
              <UserDetails selectedChat={selectedChat} endChat={onOpen} />
            </div> */}
            <Tabs
              activeKey={togglePanel?.activeParentTab}
              id="uncontrolled-tab-example"
              className="tab-right-panel mt-3"
              onSelect={toggleSidePane}
            >
              <Tab
                eventKey="user-details"
                title="User Details"
                className="flex-column align-items-center"
              >
                <UserDetails selectedChat={selectedChat} endChat={onOpen} />
              </Tab>
              {roles?.messaging?.showSuggestion && (
                <Tab
                  eventKey="suggestions"
                  title="Suggestions"
                  className="flex-column align-items-center"
                >
                  <Suggestions
                    setDefaultMessageText={setDefaultMessageText}
                    setToggleChatDetail={setToggleChatDetail}
                    setToggleChatSuggestion={setToggleChatSuggestion}
                    selectedChat={selectedChat}
                    isActiveTab={
                      togglePanel?.activeParentTab === 'suggestions' &&
                      togglePanel.show
                        ? true
                        : false
                    }
                  />
                </Tab>
              )}
            </Tabs>
          </ScrollBar>
        </Card>
      </div>

      <EndChatComfirmationModal
        onHide={onClose}
        onSubmit={endChat}
        showModal={openModal}
        loading={loading}
      />
    </>
  );
};

export default UserSuggestions;
