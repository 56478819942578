import Carousel from 'react-material-ui-carousel';
import { Paper } from '@material-ui/core';
import './carousel.scss';
import UilAngleLeft from '@iconscout/react-unicons/icons/uil-angle-left';
import UilAngleRight from '@iconscout/react-unicons/icons/uil-angle-right';
const CustomCarousel = ({ data, ChildComp, getIndexes }) => (
  <div className="carosel-container">
    <Carousel
      navButtonsProps={{
        // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
        style: {
          color: 'var(--darkgray-2)',
          backgroundColor: 'inherit',
          margin: '0',
          padding: '0',
          marginTop: '10px'
        }
      }}
      navButtonsWrapperProps={{
        // Move the buttons to the bottom. Unsetting top here to override default style.
        style: {
          bottom: '-25px',
          top: 'unset'
        }
      }}
      fullHeightHover={false}
      navButtonsAlwaysVisible={true}
      cycleNavigation={false}
      autoPlay={false}
      animation="slide"
      indicatorIconButtonProps={{
        className: 'indicators'
      }}
      activeIndicatorIconButtonProps={{
        className: 'active-indicator'
      }}
      NextIcon={<UilAngleRight size={'40'} />}
      PrevIcon={<UilAngleLeft size={'40'} />}
      onChange={currentIndex => getIndexes(currentIndex)}
    >
      {data.map((dt, innerIndex) => (
        <ChildComp key={innerIndex} data={dt} />
      ))}
    </Carousel>
  </div>
);

export default CustomCarousel;
