import React from 'react';
import { Form } from 'react-bootstrap';
import ScrollBar from 'components/Scrollbars';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  hostListComp: {
    '& div:last-child': {
      zIndex: '1'
    }
  }
}));

const HostListComp = ({
  hostList = [],
  handleHosts,
  selectedHosts = [],
  keyProp = '',
  editable,
  disabled = false
}) => {
  const classes = useStyles();
  const getUpdatedHost = id => {
    let temp = [];
    if (selectedHosts.includes(id)) {
      temp = selectedHosts.filter(tl => tl !== id);
    } else {
      temp = [...selectedHosts];
      temp.push(id);
    }
    return temp;
  };

  const getCheckState = id => {
    let temp = selectedHosts.includes(id) ? true : false;
    return temp;
  };

  return (
    <li key={keyProp}>
      <div className="w-100">
        {!editable && <label className="has-diabled">Hosts</label>}
        <div className={` hosts-container ${!editable && 'pt-1 pb-1'}`}>
          <ul className="list-group host-list-header">
            {editable ? (
              <li
                className="pl-3 list-item-width"
                style={{
                  // borderBottom: editable ? '1px solid grey' : null,
                  borderRadius: '10px 10px 0px 0px'
                }}
              >
                {hostList.length ? (
                  <Form.Check
                    key={'host-select-all'}
                    id={'0'}
                    disabled={!editable || disabled}
                    custom
                    type="checkbox"
                    name="host"
                    label={'Host'}
                    onChange={e =>
                      handleHosts(
                        'host',
                        e.target.checked ? hostList.map(hl => hl.id) : []
                      )
                    }
                    checked={
                      hostList.length &&
                      hostList.length === selectedHosts.length
                    }
                    style={{
                      paddingTop: '10px',
                      paddingBottom: '10px',
                      color: '#454F5B',
                      fontSize: '13px',
                      fontWeight: '600'
                    }}
                  />
                ) : (
                  <label
                    style={{
                      paddingTop: '10px',
                      color: '#454F5B',
                      fontSize: '13px',
                      fontWeight: '600'
                    }}
                  >
                    Host
                  </label>
                )}
              </li>
            ) : null}
          </ul>

          <ScrollBar
            horizontalScroll={false}
            style={{ height: '150px', maxHeight: '200px' }}
            className={classes.hostListComp}
          >
            <ul
              className={` host-container list-group list-group-striped ${
                selectedHosts && selectedHosts.length === 0 ? 'h-100' : ''
              }`}
            >
              {hostList.map((hl, index) =>
                editable ? (
                  <li key={hl.id} className="pl-3 list-item-width">
                    <Form.Check
                      key={index}
                      disabled={!editable || disabled}
                      custom
                      type="checkbox"
                      name="host"
                      id={hl.id}
                      label={hl.name}
                      onChange={e => handleHosts('host', getUpdatedHost(hl.id))}
                      checked={getCheckState(hl.id)}
                      style={{
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        color: '#454F5B',
                        fontSize: '13px',
                        fontWeight: '600'
                      }}
                    />
                  </li>
                ) : getCheckState(hl.id) ? (
                  <li
                    key={hl.id}
                    className="pl-3 w-100"
                    style={{
                      fontSize: 'medium'
                    }}
                  >
                    {hl.name}
                  </li>
                ) : null
              )}
              {!editable && selectedHosts && selectedHosts.length == 0 ? (
                <div className="d-flex  w-100  align-items-center justify-content-center h-100">
                  {'No Data Available'}
                </div>
              ) : null}
            </ul>
          </ScrollBar>
        </div>
      </div>
    </li>
  );
};
export default HostListComp;
