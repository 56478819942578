export const fr_facebookIntegration = {
  heading1: 'Facebook',
  verificationToken: {
    label: 'Vérification Token',
    info: "Un jeton utilisé pour configurer l'application d'intégration de facebook."
  },
  pageAccessToken: {
    label: "Jeton d'accès à la page",
    info: "Un jeton généré par l'application facebook qui donne la permission de communiquer au nom de la page facebook."
  },
  callbackURL: {
    label: 'URL de rappel',
    info: "L'URL du noeud final utilisé pour configurer l'application d'intégration de facebook."
  },
  enableGetStartedButton: {
    label: 'Activer Get Started Bouton',
    info: "Si elle est définie, le bouton apparaît à démarrer que lorsque l'utilisateur interagit avec la page pour la première fois."
  },
  greetingText: {
    label: "Message d'accueil",
    info: "Montre le texte message d'accueil lorsque le bouton Get Started est activé."
  },
  enableGreetingText: {
    label: 'Activer Salutation texte',
    info: 'Si elle est définie, le bouton Commencer également afficher le texte de voeux pour la première fois.'
  },
  facebookChatGreetingText: {
    label: 'Facebook Chat texte Salutation',
    info: 'Ce message apparaît immédiatement après le bouton Commencer est enfoncé.'
  },
  companyFacebookPageName: {
    label: 'Comapany Page Facebook Nom',
    info: "Par exemple: Si url page facebook de l'entreprise est https://www.facebook.com/Gearbestshopping, donc, Gearbestshopping est le nom de la page Facebook."
  },
  facebookPageID: {
    label: 'Page Facebook ID',
    info: "Vous pouvez trouver la page Facebook Id en cliquant droit sur affichage de l'image."
  }
};
export default fr_facebookIntegration;
