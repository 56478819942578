import { useEffect, useState } from 'react';
import {
  Features,
  Devices,
  UserTypes,
  Hours,
  Company,
  SelectionTypeKeys,
  FeaturesOptionSelector,
  InputFieldTypeKeys,
  initializeVissibleCards,
  initialSelectedFilters
} from './constants';
import FeaturesComp from './Features';
import DevicesComp from './devices';
import UserType from './user-type';
import FiltersHeader from './filter-header';
import CustomFieldFilter from './custom-field-filter';
import CardsHideShow from './card-hide-show';
import { getAllCompanies } from 'features/companies/companiesSlice';
import { toast } from 'react-toastify';
import CustomAutoCompleteFilter from './custom-auto-complete-filter';
import Button from 'react-bootstrap/Button';
import {
  getAllowedFilters,
  getBusinessTypes,
  getFilters,
  setAllowedFilters
} from 'features/analytics/analyticSlice';
import { getCookie, setCookie } from 'helpers/generic.methods';
import { useDispatch, useSelector } from 'react-redux';
import { clearAll, isClearAll } from 'features/analytics/analyticSlice';
import { useTranslation } from 'react-i18next';
const generateOptions = data => {
  return [...data].map(dt => ({
    key: `${dt.companyKey}`,
    value: dt.companyKey,
    id: dt.id
  }));
};

const generateBusinessOptions = data => {
  return [...data].map(dt => ({
    key: `${dt.name}`,
    value: dt.id,
    id: dt.id
  }));
};
const Filters = ({
  openColapse,
  onFilterChange,
  closePanel,
  selectedData,
  hiddenCards,
  setHiddenCards,
  roles = {},
  cardsSection,
  isAdmin
}) => {
  const [features, setFeatures] = useState(Features);
  const [allowedOptions, setAllowedOptions] = useState({});

  const [hours, setHours] = useState(Hours);
  const [devices, setDevices] = useState(Devices);
  const [userTypes, setUserTypes] = useState(UserTypes);
  const [businessTypes, setBusinessTypes] = useState([]);
  const [items, setItems] = useState([]);
  const [allFilters, setAllFilters] = useState(initialSelectedFilters);
  const [hideShowCards, setHideShowCards] = useState(hiddenCards);
  const clearAllFilters = useSelector(isClearAll);
  const allowedFilters = useSelector(getAllowedFilters);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const filterOptionsByRole = filterType => {
    switch (filterType) {
      case 'features':
        return Features.filter(dt => (!isAdmin ? allowedOptions[dt.name] : dt));
      case 'userTypes':
        return UserTypes.filter(dt =>
          !isAdmin ? allowedOptions[dt.key || dt.name] : dt
        );
      default:
        return [];
    }
  };

  const setFilters = {
    businessTypes: setBusinessTypes,
    devices: setDevices,
    features: setFeatures,
    hours: setHours,
    items: setItems,
    userTypes: setUserTypes
  };

  let defaultFilterValues = {
    businessTypes: businessTypes,
    devices: Devices,
    features: filterOptionsByRole('features'),
    hours: Hours,
    items: items,
    userTypes: filterOptionsByRole('userTypes')
  };

  //const [items, setItems] = useState([]);
  const [companiesOption, setCompaniesOption] = useState([]);
  const [businessTypesOptions, setBusinessTypesOptions] = useState([]);
  const [loadingOptions, setLoadingOptions] = useState(false);

  const getAllowedFiltersApi = () => {
    try {
      getFilters().then(res => {
        if (res && res.data) {
          const { data } = res.data;
          setData(data);
        }
      });
    } catch (error) {
      toast.error(error || error.message);
    }
  };

  const setData = data => {
    dispatch(setAllowedFilters(data));
    setAllowedOptions(data || {});
    setFeatures(Features.filter(dt => data[dt.name]));
    setUserTypes(UserTypes.filter(dt => data[dt.key || dt.name]));
  };

  const handleChange = (arg, key) => {
    let obj = {
      businessTypes: businessTypes.filter(val => val.value),
      devices: devices.filter(val => val.value),
      features: features.filter(val => val.value),
      hours: hours.filter(val => val.value),
      items: items.filter(val => val.value),
      userTypes: userTypes.filter(val => val.value)
    };
    obj[key] = arg;
    setAllFilters(obj);
  };

  const applyFilter = () => {
    console.log('apply');
    // setHiddenCards(hideShowCards);
    onFilterChange(allFilters, hideShowCards);
  };

  const handleTextChange = text => {
    setLoadingOptions(true);
    try {
      let payload = {
        start: 0,
        limit: 10,
        filter: JSON.stringify([
          {
            propertyName: 'companyKey',
            propertyValue1: `${text}%`,
            propertyValue2: '',
            propertyValue3: [],
            filterDataType: 2,
            comparison: 2
          }
        ]),
        dir: 'ASC',
        sort: 'companyKey'
      };

      getAllCompanies(payload).then(response => {
        if (response && response.data) {
          let data = generateOptions(response.data.data);
          let filteredOptions = [];
          data.forEach(element => {
            if (!alreadySelectedElement(element)) {
              filteredOptions.push(element);
            }
          });
          setCompaniesOption(filteredOptions);
          // updateOptions(filteredOptions);
          setLoadingOptions(false);
        }
      });
    } catch (error) {
      setLoadingOptions(false);
      toast.error(error || error.message);
    }
  };
  const alreadySelectedElement = option => {
    if (items.length && items.find(val => val.key == option.key)) {
      return true;
    }
    return false;
  };
  // get BusinessType Options
  useEffect(() => {
    if (getCookie('analyticsBusinessOptions')) {
      let data = generateBusinessOptions(
        JSON.parse(getCookie('analyticsBusinessOptions'))
      );
      setBusinessTypesOptions(data);
    } else {
      getBussinesTypes();
    }
    if (!isAdmin) {
      if (Object.keys(allowedFilters).length) {
        setData(allowedFilters);
      } else {
        getAllowedFiltersApi();
      }
    }
  }, []);

  const getBussinesTypes = () => {
    try {
      getBusinessTypes().then(response => {
        if (response && response.data) {
          let data = generateBusinessOptions(response.data.data);
          setBusinessTypesOptions(data);
          setCookie(
            'analyticsBusinessOptions',
            JSON.stringify(response.data.data),
            1
          );
        }
      });
    } catch (error) {
      toast.error(error || error.message);
    }
  };

  const handleClearAll = (arg, key) => {
    setFeatures(filterOptionsByRole('features'));
    setHours(Hours);
    setDevices(Devices);
    setUserTypes(filterOptionsByRole('userTypes'));
    setHideShowCards(initializeVissibleCards(false, hiddenCards));
    setBusinessTypes([]);
    setItems([]);
    setAllFilters(initialSelectedFilters);
  };

  useEffect(() => {
    if (clearAllFilters) {
      handleClearAll();
      dispatch(clearAll(false));
    }
  }, [clearAllFilters]);

  useEffect(() => {
    updateSelectedStates();
  }, [openColapse]);

  useEffect(() => {
    updateSelectedStates();
    setAllFilters(selectedData);
  }, [selectedData, hiddenCards]);

  const updateSelectedStates = () => {
    Object.keys(selectedData).forEach((key, index) => {
      let keys = selectedData[key].map(sf => sf.key);

      let feat = defaultFilterValues[key].map(f => {
        if (keys.includes(f.key)) return { ...f, value: true };
        else return { ...f };
      });
      if (SelectionTypeKeys.includes(key)) setFilters[key]([...feat]);
      else setFilters[key](selectedData[key]);
    });
    updateOptions(selectedData.items);
    setHideShowCards(hiddenCards);
  };

  const updateOptions = (data = []) => {
    let rawData = data;
    if (selectedData && selectedData.items && selectedData.items.length) {
      rawData = [...rawData, ...selectedData.items];
    }
    if (rawData && rawData.length) {
      let distinctOptions = rawData.filter(
        (i, index, self) =>
          index ===
          self.findIndex(
            t => t.id === i.id && t.value === i.value && t.key === i.key
          )
      );
      setCompaniesOption(distinctOptions);
    }
  };

  return (
    <>
      <FiltersHeader
        title={t('company.analytics.filters')}
        onFilterClearAll={handleClearAll}
        handleClosePanel={closePanel}
        clearTitle={t('company.analytics.clearAll')}
      />

      {roles.allowFeatures && (
        <FeaturesComp
          allowedOptions={allowedOptions}
          title={t('company.analytics.features')}
          toolTipQuickStats="totalChats,totalBillableLeads,blankChats,activeChats,ctr,lcr"
          toolTipGraphs="featuresUsage,chatStatsBy,chatReferrals,agentCTR,chatsLeads"
          dataArray={features}
          setFunction={setFeatures}
          categoryButtons={true}
          onChangefilters={e => handleChange(e, 'features')}
        />
      )}
      {roles.allowDevices && (
        <DevicesComp
          title={t('company.analytics.devices')}
          toolTipQuickStats="totalChats,totalLeads,totalVisits,totalBillableLeads,blankChats,activeChats,ctr,lcr"
          toolTipGraphs="featuresUsage,chatStatsBy,chatsLeads"
          dataArray={devices}
          setFunction={setDevices}
          onChangefilters={e => handleChange(e, 'devices')}
        />
      )}
      {roles.allowUser && (
        <UserType
          allowedOptions={allowedOptions}
          title={t('company.analytics.user')}
          toolTipQuickStats="totalChats,totalLeads,totalBillableLeads,blankChats,activeChats,ctr,lcr"
          toolTipGraphs="featuresUsage,chatStatsBy,chatsLeads"
          dataArray={userTypes}
          setFunction={setUserTypes}
          onChangefilters={e => handleChange(e, 'userTypes')}
        />
      )}
      {/* <CustomFieldFilter
        title="Company Key"
        dataArray={items}
        setFunction={setItems}
        onChangefilters={e => handleChange(e, 'items')}
      /> */}
      {roles.allowCompanyKey && (
        <CustomAutoCompleteFilter
          title={t('company.analytics.companyKey')}
          toolTipQuickStats="applyToAll"
          toolTipGraphs="featuresUsage,chatStatsBy,chatsLeads,topBottomLeadAccounts"
          onChangefilters={e => handleChange(e, 'items')}
          setFunction={setItems}
          handleTextChange={handleTextChange}
          options={companiesOption}
          loading={loadingOptions}
          selected={items}
        />
      )}
      {/* <AutoCompeleteComp options={companiesOption} /> */}

      {roles.allowBusinessType && (
        // <CustomFieldFilter
        //   title={t('company.analytics.typeOfBusiness')}
        //   toolTipQuickStats="Apply to All"
        //   toolTipGraphs="Apply to All"
        //   dataArray={businessTypes}
        //   setFunction={setBusinessTypes}
        //   options={businessTypesOptions}
        //   onChangefilters={e => handleChange(e, 'businessTypes')}
        // />
        <CustomFieldFilter
          title={t('company.analytics.typeOfBusiness')}
          toolTipQuickStats="applyToAll"
          onChangefilters={e => handleChange(e, 'businessTypes')}
          toolTipGraphs="featuresUsage,chatStatsBy,chatsLeads,topBottomLeadAccounts"
          dataArray={businessTypes}
          setFunction={setBusinessTypes}
          options={businessTypesOptions}
          loading={loadingOptions}
          selected={businessTypes}
        />
      )}
      {/* {roles.allowHours && (
        <FeaturesComp
          title="Hours"
          dataArray={hours}
          setFunction={setHours}
          onChangefilters={e => handleChange(e, 'hours')}
          isHours={true}
        />
      )} */}
      {/* {cardsSection && ( */}
      <CardsHideShow
        onFilterClear={() =>
          setHideShowCards(initializeVissibleCards(false, hiddenCards))
        }
        title={t('company.analytics.ChartsShowHide')}
        data={hideShowCards}
        // allowedCheckBoxes={cardsSection}
        setFunction={setHideShowCards}
      />
      {/* )} */}

      {openColapse && (
        <Button
          className="floating-btn-apply-filter"
          variant="primary"
          title="Filters"
          onClick={applyFilter}
        >
          {t('company.analytics.applyFilters')}
        </Button>
      )}

      <br />
      <br />
    </>
  );
};

export default Filters;
