import React, { useContext } from 'react';
import AccordionContext from 'react-bootstrap/AccordionContext';

const AccordionButton = ({
  children,
  eventKey,
  callback,
  btnClass,
  storageKey,
  style = {},
  show
}) => {
  const currentEventKey = useContext(AccordionContext);

  // const decoratedOnClick = useAccordionToggle(eventKey, () => {
  //   callback && callback(eventKey);
  //   storage.setItem(storageKey, eventKey);
  // });

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <a
      href="#"
      tabIndex="0"
      className={`${btnClass}${show ? ' active' : ''}`}
      onClick={callback}
      style={style}
    >
      <>{children ? children : null}</>
    </a>
  );
};

export default AccordionButton;
