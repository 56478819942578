import React from 'react';
import DisplayKey from 'helpers/ui-controls/DisplayKey';
import { useTranslation } from 'react-i18next';
import FlagOutlinedIcon from '@material-ui/icons/FlagOutlined';
import { Link } from 'react-router-dom';
const LeadHeader = ({
  name,
  id,
  chatId,
  flagAsProcessed,
  handleFlagProcessing
}) => {
  const { t } = useTranslation();
  const _handleFlagProcessing = () => {
    let payload = { leadId: id, processedByCustomer: !flagAsProcessed };
    handleFlagProcessing(payload);
  };
  return (
    <>
      <DisplayKey displayKey={id} />
      <div className="page-header">
        <div className="page-header-bottom d-flex align-items-center justify-content-between">
          <h1 className="main-head text-primary-2">{name}</h1>

          <div className="d-flex">
            <button
              type="button"
              className={
                flagAsProcessed
                  ? 'btn-md btn-flex ml-4r  btn btn-primary'
                  : 'btn-md btn-flex ml-4r introJs-companies-tab-view-0  btn btn-lightgray-3'
              }
              onClick={_handleFlagProcessing}
            >
              <FlagOutlinedIcon fontSize="inherit" />
              <span className="ml-1">
                {flagAsProcessed
                  ? t('company.leadDetails.flagAsUnProcessed.label')
                  : t('company.leadDetails.flagAsProcessed.label')}
              </span>
            </button>
            {!!chatId && (
              <Link
                to={`chat?id=${chatId}`}
                className="btn-md btn-flex ml-4r introJs-companies-tab-view-0  btn btn-primary"
              >
                View Chat
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default LeadHeader;
