export const en_pacingLeadsAndTraffic = {
  heading1: 'Pacing',
  heading2: 'Leads',
  heading3: 'Traffic',
  campaignPaused: {
    label: 'Campaign Paused',
    info: 'Whether or not this campaign is currently paused.'
  },
  recycleBudgetAfterItEnds: {
    label: 'Recycle Budget After It Ends',
    info: 'Whether or not the budget restart itself after the Total Period (Days) has been completed.'
  },
  resetBudget: {
    label: 'Reset Budget - 1st of every month',
    info: 'Selecting this option allows to reset a company budget automatically on the first date of every month.'
  },
  totalPeriod: {
    label: 'Total Period (Days)',
    info: 'The number of days to run the budgeting tool for.'
  },
  pacingType: {
    label: 'Pacing Type',
    info: 'The parameter to base the budget on; Options are either Traffic or Lead.'
  },
  numberofleads: {
    label: 'Number of leads',
    info: 'Number of leads sent out during the current budget.'
  },
  sendNotification: {
    label: 'Send Notification (on first lead when a budget starts)',
    info: 'This notification will be sent when the first lead is sent in a budget cycle.'
  },
  emailAddresses: {
    label: 'Email Addresses (for sending message when first lead is sent)',
    info: 'First Budgeted Lead Notification warning will be sent to these addresses. If this field is left blank, the notification will be sent to the Primary Contact for the company.'
  },
  warningMessageEmailAddresses: {
    label: 'Email Addresses (for warning message)',
    info: 'First Budgeted Lead Notification warning will be sent to these addresses. If this field is left blank, the notification will be sent to the Primary Contact for the company.'
  },
  sendingMessageEmailAddresses: {
    label: 'Email Addresses (for sending message when budget is paused)',
    info: 'Budget paused warning will be sent to these addresses. If this field is left blank, budget paused message will be sent to the Primary Contact for the company.'
  },
  hasAWarningBeenSent: {
    label: 'Has a warning been sent (for first budgeted lead)',
    info: 'A warning will be sent once only until the total budget period is reset.'
  },
  enableSubBudget: {
    label: 'Enable Sub Budget (average over pacing days)',
    info: 'Enables a mini budget within the budget defined above. Example: If main budget is set for a month, use this option to set a daily limit.'
  },
  totalTrafficAllowed: {
    label: 'Total Traffic Allowed',
    info: 'The number of times the chat invitation will be shown during the current budgeting period.'
  }
};
export default en_pacingLeadsAndTraffic;
export { default as fr_pacingLeadsAndTraffic } from '../fr-FR-Dictionary/fr-pacingLeadsAndTraffic';
export { default as sp_pacingLeadsAndTraffic } from '../sp-SP-Dictionary/sp-pacingLeadsAndTraffic';
