import React from 'react';
import { Redirect } from 'react-router-dom';

const HomeRedirector = () => {
  return (
    <>
      <Redirect to={'/home'} />{' '}
    </>
  );
};

export default HomeRedirector;
