/*
 * =====================================================================
 * ---------------------- Notification Navigation ----------------------
 * ------------------- Created by Samiya Batool --------------------
 * ------------------------- Dated: 16-03-2021 -------------------------
 * =====================================================================
 * */
import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import SimpleLabelTextBox from 'helpers/ui-controls/ui-components/SimpleLabelTextbox';
import dropdownEnums from 'helpers/dropdown-enums';
import { camelCaseStringToNormalText } from 'helpers/generic.methods';
import { useTranslation } from 'react-i18next';
import ToggleMenuButton from 'helpers/ui-controls/ui-components/ToggleMenuButton';

const CRM = ({ company, clickHandlerMenu }) => {
  const [companyObj, setCompanyObj] = useState({});
  const { t } = useTranslation();
  const updateData = (key, value) => {
    let _tempCompanyObj = {};
    _tempCompanyObj[key] = value;
    setCompanyObj({ ...companyObj, ..._tempCompanyObj });
  };
  const crmUniqueTT = t('company.crm.crmUniqueID.info').replaceAll(
    '<br />',
    '\n'
  );
  /**
   * Some of the CRM paramerts had disturbed displayName so defined those messed strings here
   */
  const paramDisplayName = {
    LidogeneratorId: 'Lido Generator ID',
    endpointUrl: 'End Point URL',
    passWord: 'Password',
    userName: 'Username',
    HAPIKey: 'HubSpot API Key',
    HubSpotUrl: 'HubSpot URL',
    EntityForSales: 'Entity For Sales',
    EntityForService: 'Entity For Service',
    EntityForOthers: 'Entity For Others',
    contentType: 'Content Type',
    requestMethod: 'Request Method',
    'Add Headers': 'Add Headers',
    'Campaign ID': 'Campaign ID',
    Provider: 'Provider',
    'Application Name': 'Application Name',
    'Action Sequence ID': 'Action Sequence ID',
    'Public Key': 'Public Key',
    'Private Key': 'Private Key',
    leadTypeForCrm: 'lead Type For Crm',
    enableOAuth2: 'Enable OAuth2',
    Referrer: 'Referrer',
    serviceUrl: 'Service Url',
    domainName: 'Domain Name',
    entity: 'Entity'
  };

  return (
    <>
      <Form>
        <div className="page-section">
          <ToggleMenuButton
            heading={t('company.crm.heading1')}
            clickHandlerMenu={clickHandlerMenu}
          />
          <section>
            <header className="section-head d-flex align-items-center justify-content-between">
              <h5 className="fw-600 mb-0">{t('company.crm.heading2')}</h5>
            </header>
            <div className="section-body">
              <ul className="editable-fields-lists">
                <SimpleLabelTextBox
                  heading={t('company.crm.crmProviders.label')}
                  field="crm"
                  dataProperty={company['crm']}
                  titleText={t('company.crm.crmProviders.info')}
                  onChange={updateData}
                />
                <SimpleLabelTextBox
                  heading={t('company.crm.crmUniqueID.label')}
                  field="crmUniqueId"
                  dataProperty={company['crmUniqueId']}
                  titleText={crmUniqueTT}
                  onChange={updateData}
                />
                {company?.crmParameters?.map((element, index) => {
                  return (
                    <SimpleLabelTextBox
                      key={index}
                      heading={
                        //  paramDisplayName[element.displayName]
                        //     ? paramDisplayName[element.displayName]
                        t(`company.crm.${element.codeName}.label`)
                        //  ? t(`company.crm.${element.displayName}.label`)
                        //  : camelCaseStringToNormalText(
                        //      element.displayName,
                        //      'heading'
                        //    )
                      }
                      field={element.codeName}
                      dataProperty={
                        element.codeName !== 'leadTypeForCrm'
                          ? element.value
                          : dropdownEnums.enum_LeadType
                              .map(e => {
                                if (e.value === parseInt(element.value)) {
                                  return e.name;
                                }
                                return null;
                              })
                              .join('')
                      }
                      helperText={t(`company.crm.${element.codeName}.info`)}
                      onChange={updateData}
                    />
                  );
                })}
              </ul>
            </div>
          </section>
        </div>
      </Form>
    </>
  );
};

export default CRM;
