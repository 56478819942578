import { createSlice } from '@reduxjs/toolkit';

/**
 * @name @createSlice
 * @description create redux store slice for auth
 **/
export const rolesSlice = createSlice({
  name: 'roles',
  initialState: {},

  reducers: {
    /**
     * @name @login
     * @description login reducer to call login async API with axios instance
     * @requires ApiServices.get method, state and API payload
     **/
    setRolesToState: (state, action) => {
      state.roles = action.payload;
    }
  }
});

export const { setRolesToState } = rolesSlice.actions;

/**
 * These function called thunk and allows to perform async logic
 * It can be dispatched like a regular action: `dispatch(data)`
 **/

// }
/* called a selector and allows to select values from the state */
export const selectRoles = state => {
  return state.roles;
};

export default rolesSlice.reducer;
