import React, { useState, useEffect } from 'react';
import ParametersComp from 'pages/portal/modal/CreateReport/ParameterComp';
import storage from 'helpers/storage';
import { toast } from 'react-toastify';
import { errors, success } from 'helpers/variables';
import { getAllCompanies } from 'features/companies/companiesSlice';
import { parameterSection } from 'helpers/schemas/reportSchemas/initialValues';
import { useFormik } from 'formik';
import { parameterSchemas } from 'helpers/schemas/reportSchemas/schemas';
import { Form } from 'react-bootstrap';
import { createOrUpdateReport, getAgents } from 'features/report/reportSlice';
import {
  Enums,
  FieldsEnum,
  getObjectKeyByValue,
  GroupByEnums
} from 'helpers/dropdown-enums';
import {
  PeriodOptions,
  RemoveCharAndToCamelCase
} from 'pages/portal/modal/CreateReport/constant';
import {
  getTextByNestedOptions,
  getDisplayDate
} from 'pages/portal/modal/CreateReport/helper';
import { useDispatch, useSelector } from 'react-redux';
import { updateReportToState } from 'features/report/reportsSlice';
import { selectRoles } from 'features/roles/rolesSlice';
const defaultFields = [
  'name',
  'email',
  'timezone',
  'addReportAttachment',
  'webhook',
  'reportId',
  'scheduleType',
  'scheduleHour',
  'scheduleDayOfMonth',
  'scheduleDaysOfWeek'
];
const dateTypes = ['startDate', 'endDate'];

const generateOptions = data => {
  return [...data].map(dt => ({
    key: `${dt.companyKey}`,
    value: dt.companyKey
    // id: dt.id
  }));
};
const ParametersWrapper = ({ report = {}, hostList, setRefetch }) => {
  const [hosts, setHosts] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [loadingOptions, setLoadingOptions] = useState(false);
  const [companiesOption, setCompaniesOption] = useState([]);
  const [hostOptions, setHostOptions] = useState(hostList);
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(false);
  const [agentsOptions, setAgentsOptions] = useState([]);
  const dispatch = useDispatch();
  const user = JSON.parse(storage.getItem('Profile'));
  const {
    roles: { report: reportPermissions }
  } = useSelector(selectRoles);
  const ReportTypes =
    (reportPermissions && reportPermissions.reportTypes) || [];

  const generateParameterJson = data => {
    // allowed fields from template
    let allowedFields = ReportTypes.filter(
      rt => rt.id === Number(report.reportId)
    )[0].allowedFields;

    // filter allowed fields from all fields
    let fields = Object.keys(FieldsEnum)
      .filter(key => allowedFields.includes(FieldsEnum[key]))
      .map(f => RemoveCharAndToCamelCase(f));

    let jsonObject = {};
    if (
      ![
        Enums.ReportType.Invitation_Code_Installed_Report,
        Enums.ReportType.Monthly_Minimum_Report,
        Enums.ReportType.Agent_Response_Time_By_Threshold
      ].includes(Number(report.reportId))
    ) {
      jsonObject['webhook'] = data?.webhook;
    }
    fields.forEach(key => {
      if (!defaultFields.includes(key)) {
        // if (key === 'companyKeys') {
        jsonObject['companyKeys'] = companies.map(com => com.key);
        // } else if (key === 'hosts') {
        jsonObject['hostIds'] = hosts.map(id => id.toString());
        // } else
        if (key === 'period') {
          jsonObject[key] = data[key] || '';
          jsonObject['periodDisplay'] = data['periodDisplay'];
          if (optionsArray().includes(data.period)) {
            jsonObject['periodDisplay'] = getTextByNestedOptions(
              PeriodOptions,
              data[key]
            );
          }
          if (data[key] === 'date_range') {
            jsonObject['period'] = `${getDisplayDate(
              data['periodStart'],
              'YYYY-MM-DD',
              'DD/MM/YYYY'
            )},${getDisplayDate(
              data['periodEnd'],
              'YYYY-MM-DD',
              'DD/MM/YYYY'
            )}`;
            jsonObject['periodDisplay'] = `${getDisplayDate(
              data['periodStart'],
              'YYYY-MM-DD'
            )},${getDisplayDate(data['periodEnd'], 'YYYY-MM-DD')}`;
          }
          if (data.period === 'custom') {
            let oldData = JSON.parse(report.parametersJson);
            jsonObject['periodDisplay'] = oldData.periodDisplay;
            jsonObject['period'] = oldData.period;
          }
        } else if (key === 'group') {
          jsonObject[key] = getObjectKeyByValue(GroupByEnums, data[key]);
        } else if (key === 'pickedUpByUsername') {
          jsonObject[key] =
            agentsOptions.filter(op => op.id === Number(data[key]))[0]
              ?.userName || '';
        } else if (
          dateTypes.includes(key) &&
          data[key] &&
          !data[key].toString().includes('Date')
        ) {
          jsonObject[key] = `/Date(${data[key]})/`;
        } else if (key === 'aggregate') {
          jsonObject[key] = data[key] || 1;
        } else {
          jsonObject[key] = data[key];
        }
      }
    });
    return jsonObject;
  };

  const generatePayload = () => {
    let payload = {
      ...reportFormIk.values
    };
    let tempObj = {};
    tempObj['id'] = report.id;

    tempObj['userId'] = user.UserID;
    tempObj['addReportAttachment'] = payload.addReportAttachment ? 1 : 0;
    tempObj['parametersJson'] = JSON.stringify(generateParameterJson(payload));
    defaultFields.forEach(key => {
      tempObj[key] = payload[key];
    });
    return tempObj;
  };

  const reportFormHandler = () => {
    createReportFunc(false);
  };

  const createReportFunc = (run = false) => {
    setLoading(true);
    let payload = generatePayload();
    try {
      createOrUpdateReport(payload, run).then(response => {
        if (response && response.data) {
          setLoading(false);
          setEditable(false);
          setRefetch(true);
          dispatch(updateReportToState(response.data));
          toast.success(success.saveSuccess('Report'));
        }
      });
    } catch {
      toast.error(errors.createError);
      setLoading(false);
      setEditable(false);
    }
  };

  const handleTextChange = text => {
    setLoadingOptions(true);
    try {
      let payload = {
        start: 0,
        limit: 10,
        filter: JSON.stringify([
          {
            propertyName: 'companyKey',
            propertyValue1: `${text}%`,
            propertyValue2: '',
            propertyValue3: [],
            filterDataType: 2,
            comparison: 2
          }
        ]),
        dir: 'ASC',
        sort: 'companyKey'
      };

      getAllCompanies(payload).then(response => {
        if (response && response.data) {
          let data = generateOptions(response.data.data);
          let filteredOptions = [];
          data.forEach(element => {
            if (!alreadySelectedElement(element)) {
              filteredOptions.push(element);
            }
          });
          setCompaniesOption(filteredOptions);
          // updateOptions(filteredOptions);
          setLoadingOptions(false);
        }
      });
    } catch (error) {
      setLoadingOptions(false);
      toast.error(error || error.message);
    }
  };
  const alreadySelectedElement = option => {
    if (companies.length && companies.find(val => val.key === option.key)) {
      return true;
    }
    return false;
  };
  /** Init Formik */
  const reportFormIk = useFormik({
    enableReinitialize: true,
    initialValues: parameterSection,
    validationSchema: parameterSchemas,
    onSubmit: reportFormHandler
  });

  useEffect(() => {
    setHostOptions(hostList);
  }, [hostList]);

  /**
   * @name @handleOnChange
   * @description do something on field onChange
   * @requires html input element
   * */
  const handleOnChange = (element, key, value) => {
    // reportFormIk.values[key] = value;
    if (element) reportFormIk.handleChange(element);
    reportFormIk.setValues({
      ...reportFormIk.values,
      [key]: value
    });
    if (key === 'companyKeys') {
      setCompanies(value);
    }
    // reportFormIk.handleChange(element);
  };
  const optionsArray = (dataList = PeriodOptions) => {
    let arr = [];
    dataList.forEach(r => {
      (r.nestedOptions || []).forEach(n => {
        arr.push(n.value);
      });
    });
    return arr;
  };

  useEffect(() => {
    if (report) {
      let data = {};
      if (report.parametersJson) {
        data = JSON.parse(report.parametersJson);

        if (data.period) {
          if (data.period.includes('C')) {
            // for custom
            if (!optionsArray().includes(data.period)) {
              data['period'] = 'custom';
            }
          }
          // for Date Range
          else if (!data.period.includes('C')) {
            let dates = data.period.split(',');
            data['periodStart'] = getDisplayDate(
              dates[0],
              'DD/MM/YYYY',
              'YYYY-MM-DD'
            );
            data['periodEnd'] = getDisplayDate(
              dates[1],
              'DD/MM/YYYY',
              'YYYY-MM-DD'
            );
            data['period'] = 'date_range';
          }
        }
        data['group'] = GroupByEnums[data['group']];
      }
      if (
        [
          Enums.ReportType.Transcripts,
          Enums.ReportType.Agent_Response_Time
        ].includes(Number(report.reportId))
      ) {
        getAgentsList({ ...report, ...data });
      } else {
        reportFormIk.setValues({
          ...reportFormIk.values,
          ...report,
          ...data
        });
      }

      if (data && data.companyKeys) {
        setCompanies(
          data.companyKeys.map(val => {
            if (val && typeof val == 'object') {
              return { key: val.key, value: val.key };
            } else {
              return { key: val, value: val };
            }
          })
        );
      }
      if (data && data.hostIds) {
        setHosts(data.hostIds.map(h => Number(h)));
      }
    }
  }, [report]);

  const getAgentsList = allData => {
    try {
      let payload = {
        start: 0,
        limit: 50
      };
      getAgents(payload).then(response => {
        if (response && response.data) {
          const { data } = response.data;
          setAgentsOptions(data);

          if (
            Number(report.reportId) === Enums.ReportType.Transcripts &&
            allData &&
            data.length
          ) {
            let temp = data.filter(
              dt => dt.userName === allData?.pickedUpByUsername
            )[0];
            reportFormIk.setValues({
              ...reportFormIk.values,
              ...allData,
              pickedUpByUsername: temp?.id || ''
            });
          } else {
            reportFormIk.setValues({
              ...reportFormIk.values,
              ...allData
            });
          }
        }
      });
    } catch {
      console.log('error');
    }
  };

  const handleHosts = (key, value) => {
    setHosts(value);
  };

  useEffect(() => {
    if (companies.length && hostList.length) {
      setHosts([]);
    }
  }, [companies]);

  return (
    <>
      <Form
        className="form"
        onSubmit={reportFormIk.handleSubmit}
        onKeyPress={e => {
          e.key === 'Enter' && e.preventDefault();
        }}
        autoComplete="off"
      >
        <ParametersComp
          key={'view'}
          handleHosts={handleHosts}
          selectedHosts={hosts}
          hostList={hostOptions}
          showHeading={true}
          showNote={false}
          form={reportFormIk}
          handleChange={handleOnChange}
          companies={companies}
          setCompanies={setCompanies}
          loadingOptions={loadingOptions}
          companiesOption={companiesOption}
          handleTextChange={handleTextChange}
          keyPrefix="view-report"
          editable={editable}
          loading={loading}
          setEditable={setEditable}
          headerSection={true}
          showHostHeading={false}
          hostTitle={'Hosts'}
          agentsOptions={agentsOptions}
          ReportTypes={ReportTypes}
          rType={Number(report.reportId)}
        />
      </Form>
    </>
  );
};

export default ParametersWrapper;
