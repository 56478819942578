import { Enums, enum_InitiatedBy } from 'helpers/dropdown-enums';
import { camelCaseStringToNormalText } from 'helpers/generic.methods';
import { getPlainStringFromHTMLElementAsString } from 'helpers/validate';
import store from 'store';

/**
 * @name @getSelectedText
 * @description get highlighted text
 * @requires string => optional and toTitleCase function
 * @returns highlighted text
 * */
export const getHighlightedText = (str = '') => {
  let highlightedText = '';
  let hasSelection = false;

  if (window.getSelection) {
    highlightedText = window.getSelection()?.toString();
  } else if (document.getSelection) {
    highlightedText = document.getSelection()?.toString();
  } else if (document.selection) {
    highlightedText = document.selection.createRange().text;
  }
  if (!highlightedText?.trim()) {
    highlightedText = str;
  } else {
    hasSelection = true;
    highlightedText = highlightedText.trim();
  }
  return toTitleCase(highlightedText);
};

/**
 * @name @toTitleCase
 * @description string to title case
 * @requires phrase string
 * @returns title case text
 * */
export const toTitleCase = phrase => {
  return phrase
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

/**
 * @name @copyToClipboard
 * @description copy string to clipboard
 * @requires phrase string
 * @returns N/A
 * */

export const copyToClipboard = phrase => {
  if (typeof phrase === 'string') {
    navigator.clipboard.writeText(phrase).then(
      function () {
        console.log('Async: Copying to clipboard was successful!');
      },
      function (err) {
        console.error('Async: Could not copy text: ', err);
      }
    );
  }
};

/**
 * @name @getCSSClassAccordingToMessage
 * @description CSS class as per the message type
 * @requires message object
 * @returns class name
 * */
export const getCSSClassAccordingToMessage = (msg, role = '') => {
  let _cssClass = '';
  let _type = getMessageSenderType(msg.userId, role);
  if (
    msg.userId >
      0 /*This includes normal agent message and simulated message*/ ||
    msg.userId === Enums.ChatMessageType.Prechat ||
    msg.userId === Enums.ChatMessageType.Predefined ||
    msg.userId === Enums.ChatMessageType.PokeMessage
  ) {
    _cssClass = 'chat_message_left';
  } else if (
    msg.userId < 0 ||
    msg.userId === Enums.ChatMessageType.Bot ||
    msg.userId === Enums.ChatMessageType.System ||
    msg.userId === Enums.ChatMessageType.NonVisitorSystem
  ) {
    _cssClass = 'chat_message_center';
  } else if (msg.userId === Enums.ChatMessageType.Visitor) {
    _cssClass = 'chat_message_right';
  } else {
    _cssClass = 'chat_message_right';
  }
  return {
    cssClass: _cssClass,
    type: _type
  };
};

/**
 * @name @getMessageSenderType
 * @description Resolve sender type as per the ID
 * @requires userId
 * @returns sender type
 * */
export const getMessageSenderType = (userId, role = '') => {
  if (userId === Enums.ChatMessageType.Bot) {
    return 'BOT';
  } else if (
    userId === Enums.ChatMessageType.Simulated ||
    userId === Enums.ChatMessageType.System ||
    userId === Enums.ChatMessageType.NonVisitorSystem
  ) {
    return 'SYSTEM';
  } else if (userId === Enums.ChatMessageType.Prechat) {
    return 'PRECHAT';
  } else if (userId === Enums.ChatMessageType.Predefined) {
    return 'PRE-DEFINED';
  } else if (userId === Enums.ChatMessageType.PokeMessage) {
    return 'POKE';
  } else if (userId > 0) {
    return role;
  }
  return false;
};

/**
 * @name @isCallConnectEnable
 * @description enable/disable call connect button
 * @requires selectedChat
 * @returns true/false
 * */
export const isCallConnectEnable = selectedChat => {
  let enabled = true;
  if (
    !selectedChat.data.quickConnectEnabled ||
    !selectedChat.data.quickConnectAvailable ||
    !selectedChat.data.quickConnectPhoneNumber
  ) {
    enabled = false;
  }
  return enabled;
};

/**
 * @name @renderComponent
 * @description render role base messaging component
 * @requires components
 * @requires componentType
 * @returns component
 * */

export const renderMessagingComponent = (
  MDWrapper,
  SignUpForm,
  SpecialMessageComp,
  componentType
) => {
  switch (componentType) {
    case Enums.MessagingComponentType.MDWrapper:
      return MDWrapper;
    case Enums.MessagingComponentType.SignUpForm:
      return SignUpForm;
    case Enums.MessagingComponentType.SpecialMessageComp:
      return SpecialMessageComp;
    default:
      break;
  }
};

/**
 * @name @encryptText
 * @description chat text messages will be encrypted for vendormax users those are not admins
 * @requires m (message data)
 * @returns m (updated message data)
 * */

export const encryptText = m => {
  let {
    roles: { roles }
  } = store.getState();
  if (roles.enableEncryption && m.maskedText) {
    //Replace original message with the masked one.
    m.text = m.maskedText;
  }

  return m;
};

/**
 * @name @parseScriptText
 * @description remove html part from selected suggestion script text
 * @requires scriptText (text)
 * @returns text (parsedText)
 * */
export const parseScriptText = scriptText => {
  let parsedText = getPlainStringFromHTMLElementAsString(scriptText);
  let text = parsedText;
  if (parsedText.split('::')[1]) {
    text = parsedText.split('::')[1].trim();
  }
  return text;
};

export const getSuggestionIcon = QT => {
  let chatQuestionType = Enums.ChatQuestionType;
  let iconName = Object.keys(chatQuestionType)[QT];
  if (iconName == 'None') return;
  let getIcon = require.context(
    '../../static/images/user-suggestions-icons',
    true
  );
  let icon = getIcon(`./${iconName}.svg`).default;
  return icon;
};

export const getSuggestionsByRole = data => {
  let {
    roles: { roles },
    auth: { profile }
  } = store.getState();
  let roleBaseQuestions = data?.questions
    ?.map(question => {
      let modifiedAnswers =
        question?.answers?.filter(
          answer =>
            answer.visibility === Enums.QTVisibility.Both ||
            (answer.visibility === Enums.QTVisibility.Agents &&
              roles.isAgent) ||
            (answer.visibility === Enums.QTVisibility.Operators &&
              roles.isOperator) ||
            (answer.visibility === Enums.QTVisibility.Self &&
              answer?.createdBy === profile?.Username) // username can be same in different companies
        ) || [];
      let modifiedQuestion = { ...question, answers: modifiedAnswers };
      // commented below line to show folder which have no answer in add statement modal
      // return modifiedAnswers.length ? modifiedQuestion : null;
      return modifiedQuestion;
    })
    .filter(Boolean);
  let modifiedData = { ...data, questions: roleBaseQuestions };
  return modifiedData;
};

/**
 * @name @chatInitiatedByPlatformObj
 * @description create initiatedBy object w.r.t platform i.e web/sms, social is also consider as web
 * @returns object
 * */
export const chatInitiatedByPlatformObj = () => {
  let chatInitiatedPlatformType = enum_InitiatedBy.reduce((acc, curr) => {
    let text = curr.selectorType === 'social' ? curr.name : curr.selectorType;
    let textMode = ['sms', 'GMB'].includes(text) ? 'uppercase' : 'sentence';
    text = camelCaseStringToNormalText(text, textMode, true);
    acc[curr.value] = text;
    return acc;
  }, {});
  return { ...chatInitiatedPlatformType, 0: 'Web' };
};
