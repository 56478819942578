import { useState, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  getLeadCategoriesByCompanyAsync,
  getRegionsByCompanyAsync
} from 'features/lead/leadSlice';

const LeadService = (m = null, id = '') => {
  const dispatch = useDispatch();
  const [leadCategories, setLeadCategories] = useState([]);
  const [regions, setRegions] = useState([]);

  useEffect(() => {
    if (m?.data?.rulesEnabled) {
      fetchLeadCategories();
      fetchRegions();
    }
  }, [m?.getId()]);

  const fetchLeadCategories = () => {
    dispatch(getLeadCategoriesByCompanyAsync(m?.getCompanyId())).then(res => {
      if (res) {
        setLeadCategories(res);
      }
    });
  };

  const fetchRegions = () => {
    dispatch(getRegionsByCompanyAsync(m?.getCompanyId())).then(res => {
      if (res) {
        setRegions(res);
      }
    });
  };

  return {
    rulesEnabled: m?.data?.rulesEnabled,
    leadCategories,
    regions
  };
};

export default LeadService;
