import React from 'react';
import Button from 'react-bootstrap/Button';
import {
  UilCheckCircle,
  UilClock,
  UilQuestionCircle,
  UilHourglass,
  UilInfoCircle
} from '@iconscout/react-unicons';

const MyReportStatus = ({ value }) => {
  const getButtonValue = statusId => {
    if (statusId === 0) {
      return (
        <span>
          <UilClock /> Queued
        </span>
      );
    } else if (statusId === 1) {
      return (
        <span>
          <UilHourglass /> Running
        </span>
      );
    } else if (statusId === 2) {
      return (
        <span>
          <UilCheckCircle /> Completed
        </span>
      );
    } else if (statusId === 3) {
      return (
        <span>
          <UilQuestionCircle /> Completed Without Data
        </span>
      );
    } else if (statusId === 4) {
      return (
        <span>
          <UilInfoCircle /> Failed
        </span>
      );
    }
  };
  const getClass = statusCode => {
    if (statusCode === 0) {
      return 'warning-3';
    } else if (statusCode === 1) {
      return 'info-1';
    } else if (statusCode === 2) {
      return 'success-3';
    } else if (statusCode === 3) {
      return 'success-7';
    } else if (statusCode === 4) {
      return 'danger-4';
    }
  };

  return (
    <Button className="btn-sm btn-flex btn-status" variant={getClass(value)}>
      {getButtonValue(value)}
    </Button>
  );
};

export default MyReportStatus;
