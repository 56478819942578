import React from 'react';
import { Steps } from 'intro.js-react';
//import RoleEngine from 'app/role-engine';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';

const Tour = ({
  toggleTour,
  stepData,
  initialStep,
  onStart,
  onChange,
  onExit,
  onComplete,
  exitOnOverlayClick = false
}) => {
  const { t } = useTranslation();
  const initStep = initialStep || 0;

  const opt = {
    exitOnOverlayClick: exitOnOverlayClick || false,
    prevLabel: t('company.tour.btn3'),
    doneLabel: t('company.tour.btn4'),
    nextLabel: t('company.tour.btn5'),
    disableInteraction: true,
    scrollToElement: isMobile ? true : false
  };
  return (
    <>
      <Steps
        enabled={toggleTour}
        steps={stepData}
        initialStep={initStep}
        options={opt}
        onStart={onStart}
        onExit={onExit}
        onComplete={onComplete}
        onChange={onChange}
      />
    </>
  );
};

export default Tour;
