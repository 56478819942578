import React from 'react';
import { useTranslation } from 'react-i18next';
import { timeZoneData } from 'app/TimeZoneData';
import ToggleToolTip from 'helpers/ui-controls/toggleToolTip';
import ToggleMenuButton from 'helpers/ui-controls/ui-components/ToggleMenuButton';
import TimePicker from 'helpers/ui-controls/ui-components/TimePicker';
import dropdownEnums, { Enums, enum_hours } from 'helpers/dropdown-enums';
import { camelCaseStringToNormalText } from 'helpers/generic.methods';
import DropDownEditable from 'helpers/ui-controls/DropDownEditable';
import { generateDropDownOptions } from './helper';

const WeeklyComp = ({
  report = {},
  showHeading = true,
  showNote = true,
  clickHandlerMenu,
  isEdit,
  handleChange,
  keyName = '',
  hour = '',
  weekDays = []
}) => {
  const { t } = useTranslation();

  const handleDays = (e, value) => {
    let temp = [...weekDays];
    if (temp.includes(Number(value))) {
      temp.splice(temp.indexOf(value), 1);
    } else {
      temp.splice(value - 1, 0, value);
    }

    handleChange(e, keyName, temp);
  };

  return (
    <>
      <DropDownEditable
        key={'scheduleHour'}
        // heading={t('company.reportDetails.parameters.reportType.label')}
        heading={'Hour'}
        field="scheduleHour"
        dataProperty={hour || 0}
        editable={isEdit}
        handleChange={handleChange}
        materialFields
        options={generateDropDownOptions(enum_hours)}
      />

      {dropdownEnums.enum_WeekDays.map(day => (
        <ToggleToolTip
          key={day.name}
          className="custom-switch-btn"
          heading={t(`company.reportDetails.schedule.${day.name}.label`)}
          toolTipFor={day.name}
          field={day.name}
          flag={weekDays.includes(Enums.WeekDays[day.name])}
          // titleText={t('company.reportDetails.schedule.sunday.label')}
          editable={isEdit}
          handleChange={e => handleDays(e, day.value)}
        />
      ))}
      {/* <ToggleToolTip
        className="custom-switch-btn"
        heading={t('company.reportDetails.schedule.sunday.label')}
        toolTipFor="sunday"
        field="sunday"
        flag={weekDays.includes(Enums.WeekDays['Sunday'])}
        // titleText={t('company.reportDetails.schedule.sunday.label')}
        editable={isEdit}
        handleChange={handleChange}
      />
      <ToggleToolTip
        className="custom-switch-btn"
        heading={t('company.reportDetails.schedule.monday.label')}
        toolTipFor="monday"
        field="monday"
        flag={weekDays.includes(Enums.WeekDays[field])}
        // titleText={t('company.reportDetails.schedule.monday.label')}
        editable={isEdit}
        handleChange={handleChange}
      />
      <ToggleToolTip
        className="custom-switch-btn"
        heading={t('company.reportDetails.schedule.tuesday.label')}
        toolTipFor="tuesday"
        field="tuesday"
        flag={weekDays.includes(Enums.WeekDays[field])}
        // titleText={t('company.reportDetails.schedule.tuesday.label')}
        editable={isEdit}
        handleChange={handleChange}
      />
      <ToggleToolTip
        className="custom-switch-btn"
        heading={t('company.reportDetails.schedule.wednesday.label')}
        toolTipFor="wednesday"
        field="wednesday"
        flag={report['wednesday']}
        // titleText={t('company.reportDetails.schedule.wednesday.label')}
        editable={isEdit}
        handleChange={handleChange}
      />
      <ToggleToolTip
        className="custom-switch-btn"
        heading={t('company.reportDetails.schedule.thursday.label')}
        toolTipFor="thursday"
        field="thursday"
        flag={report['thursday']}
        // titleText={t('company.reportDetails.schedule.thursday.label')}
        editable={isEdit}
        handleChange={handleChange}
      />
      <ToggleToolTip
        className="custom-switch-btn"
        heading={t('company.reportDetails.schedule.friday.label')}
        toolTipFor="friday"
        field="friday"
        flag={report['friday']}
        // titleText={t('company.reportDetails.schedule.friday.label')}
        editable={isEdit}
        handleChange={handleChange}
      />
      <ToggleToolTip
        className="custom-switch-btn"
        heading={t('company.reportDetails.schedule.saturday.label')}
        toolTipFor="saturday"
        field="saturday"
        flag={report['saturday']}
        // titleText={t('company.reportDetails.schedule.saturday.label')}
        editable={isEdit}
        handleChange={handleChange}
      /> */}
    </>
  );
};

export default WeeklyComp;
