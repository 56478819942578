import { Button, Modal, Form } from 'react-bootstrap';
import React, { useState } from 'react';
import 'static/scss/change-password.scss';
import UILKey from '@iconscout/react-unicons/icons/uil-key-skeleton';
import { useFormik } from 'formik';
import * as changePasswordSchema from 'helpers/schemas/authSchemas';
import { useDispatch } from 'react-redux';
import { changePasswordAsync } from 'features/auth/authSlice';
import { errors, success } from 'helpers/variables';
import { toast } from 'react-toastify';
import { setErrorClass } from 'helpers/generic.methods';
import UILTime from '@iconscout/react-unicons/icons/uil-times';
import UILEyeShow from '@iconscout/react-unicons/icons/uil-eye-slash';
import UILEye from '@iconscout/react-unicons/icons/uil-eye';
import UILSpinner from '@iconscout/react-unicons/icons/uil-spinner';

const ChangePassword = ({
  showModal,
  eventHideModal,
  requirePasswordReset
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [fieldErrorOnSubmit, setFieldErrorOnSubmit] = useState(false);
  const [oldPasswordShow, setOldPasswordShow] = useState(false);
  const [newPasswordShow, setNewPasswordShow] = useState(false);
  const [confirmNewPasswordShow, setConfirmNewPasswordShow] = useState(false);

  /**
   * @name @changePasswordFormHandler
   * @description Used to connect with store and call change password api then toggle error
   * @requires  changeFormData from the form fields
   * */
  const changePasswordFormHandler = async (changeFormData, { resetForm }) => {
    setLoading(true);
    await dispatch(changePasswordAsync(changeFormData))
      .then(() => {
        toast.success(success.changePassword);
        eventHideModal(); // to close the modal when password is successfully changed
        resetForm({});
        setLoading(false);
      })
      .catch(() => {
        toast.error(errors.changepassword);
        setFieldErrorOnSubmit(true);
        resetForm({});
        setLoading(false);
      });
  };

  /** Init Formik */
  const changePasswordFormIk = useFormik({
    initialValues: changePasswordSchema.changePasswordInitialValues,
    validationSchema: changePasswordSchema.changePasswordSchema,
    onSubmit: changePasswordFormHandler
  });

  /**
   * @name @handleOnChange
   * @description do something on field onChange
   * @requires html input element
   * */
  const handleOnChange = e => {
    changePasswordFormIk.handleChange(e);
    setFieldErrorOnSubmit(false);
  };
  /**
   * hide and show password
   */
  const toggleOldPassword = () => {
    setOldPasswordShow(oldPasswordShow ? false : true);
  };
  const toggleNewPassword = () => {
    setNewPasswordShow(newPasswordShow ? false : true);
  };
  const toggleConfirmNewPassword = () => {
    setConfirmNewPasswordShow(confirmNewPasswordShow ? false : true);
  };

  /**
   * to reset the form fields when close the modal with close icon
   */
  const handleOnModalClose = fromIkObject => {
    fromIkObject.handleReset();
    eventHideModal();
  };
  return (
    <Modal
      show={showModal}
      onHide={eventHideModal}
      size="md"
      keyboard={false}
      id="passwordModal"
      dialogClassName="passwordModal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <Modal.Title id="contained-modal-title-vcenter">
          Change password
          <a
            href="#"
            className="float-right"
            style={{ color: 'var(--darkgray-2)' }}
            onClick={e => handleOnModalClose(changePasswordFormIk)}
          >
            <UILTime />
          </a>
        </Modal.Title>
        {requirePasswordReset ? (
          <div className="warning-msg">
            As you previously forgot the password, The change password is
            mandatory, closing this window will redirect you to the login page
          </div>
        ) : null}
        <form
          className="form"
          onSubmit={changePasswordFormIk.handleSubmit}
          autoComplete="off"
        >
          <Form.Group
            controlId="oldPassword"
            className={setErrorClass(
              [changePasswordFormIk, fieldErrorOnSubmit],
              'oldPassword'
            )}
          >
            <Form.Control
              className="lined with-label"
              type={oldPasswordShow ? 'text' : 'password'}
              label="oldPassword"
              name="oldPassword"
              onChange={e => handleOnChange(e)}
              value={changePasswordFormIk.values.oldPassword}
            />
            <Form.Label className="label-movable">Old password</Form.Label>
            <i className="passEye" onClick={toggleOldPassword}>
              {oldPasswordShow ? (
                <UILEye size="12" />
              ) : (
                <UILEyeShow size="12" />
              )}
            </i>
            <p
              className="text-red-1 field-error"
              style={{
                height: '25px'
              }}
            >
              {changePasswordFormIk.errors.oldPassword &&
                changePasswordFormIk.touched.oldPassword &&
                errors.fieldError('password')}
              {fieldErrorOnSubmit && errors.fieldCorrection('password')}
            </p>
          </Form.Group>
          <Form.Group
            controlId="newPassword"
            className={`${setErrorClass(
              [changePasswordFormIk, fieldErrorOnSubmit],
              'newPassword'
            )} ${
              changePasswordFormIk.errors.newPassword &&
              changePasswordFormIk.touched.newPassword &&
              'is-invalid'
            }`}
          >
            <Form.Control
              className="lined with-label"
              type={newPasswordShow ? 'text' : 'password'}
              label="newPassword"
              name="newPassword"
              onChange={e => handleOnChange(e)}
              value={changePasswordFormIk.values.newPassword}
            />
            <Form.Label className="label-movable">New password</Form.Label>
            <i className="passEye" onClick={toggleNewPassword}>
              {newPasswordShow ? (
                <UILEye size="12" />
              ) : (
                <UILEyeShow size="12" />
              )}
            </i>
            <p
              className="text-red-1 field-error"
              style={{
                height: '25px',
                whiteSpace: 'unset'
              }}
            >
              {changePasswordFormIk.errors.newPassword &&
                changePasswordFormIk.touched.newPassword &&
                changePasswordFormIk.errors.newPassword}
              {fieldErrorOnSubmit && changePasswordFormIk.errors.newPassword}
            </p>
          </Form.Group>
          <Form.Group
            controlId="confirmNewPassword"
            className={`${setErrorClass(
              [changePasswordFormIk, fieldErrorOnSubmit],
              'confirmNewPassword'
            )} ${
              changePasswordFormIk.errors.confirmNewPassword &&
              changePasswordFormIk.touched.confirmNewPassword &&
              'is-invalid'
            }`}
          >
            <Form.Control
              className="lined with-label"
              type={confirmNewPasswordShow ? 'text' : 'password'}
              label="confirmNewPassword"
              name="confirmNewPassword"
              onChange={e => handleOnChange(e)}
              value={changePasswordFormIk.values.confirmNewPassword}
            />
            <Form.Label className="label-movable">
              Confirm new password
            </Form.Label>
            <i className="passEye" onClick={toggleConfirmNewPassword}>
              {confirmNewPasswordShow ? (
                <UILEye size="12" />
              ) : (
                <UILEyeShow size="12" />
              )}
            </i>
            <p className="text-red-1 field-error">
              {changePasswordFormIk.errors.confirmNewPassword &&
                changePasswordFormIk.touched.confirmNewPassword &&
                changePasswordFormIk.errors.confirmNewPassword}
              {fieldErrorOnSubmit &&
                changePasswordFormIk.errors.confirmNewPassword}
            </p>
          </Form.Group>
          <Button
            disabled={loading || !changePasswordFormIk.dirty}
            type="submit"
            variant="primary"
            className="passBtn btn-md btn-flex float-right"
            onClick={() => setFieldErrorOnSubmit(false)}
          >
            <UILKey />
            <span>Change Password</span>
            {loading && <UILSpinner className="spinner" />}
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ChangePassword;
