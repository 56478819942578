import React, { useState, useEffect } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Form from 'react-bootstrap/Form';
import UILInfoCircle from '@iconscout/react-unicons/icons/uil-info-circle';
import { timeZoneData } from 'app/TimeZoneData';
import FieldError from './ui-components/FieldError';
import UilAngleDown from '@iconscout/react-unicons/icons/uil-angle-down';
import 'static/scss/time-zone.scss';
import { toolTipPlacement } from 'helpers/generic.methods';
import renderToolTip from './renderToolTip';

/*
 ** Component for TimeZone field select, edit and save
 */
const TimeZoneField = ({
  // label of the field
  heading,
  // field name
  field,
  // value of the field
  dataProperty,
  // human readable title
  titleText,
  // FormIK error for the specific field
  error,
  // custom error message text
  errorMessage,
  // text to be shown in tooltip
  helperText,
  // boolean: false to make field read-only
  editable,
  // read-only field never editable
  readOnly,
  // action trigger on change
  handleChange,
  styles = {},
  liClassName = ''
}) => {
  const [valueTZ, setValueTZ] = useState(dataProperty); //TZ - Time Zone
  const [selectOpen, setSelectOpen] = useState(false);
  helperText = helperText || titleText;

  const handleOnChange = element => {
    handleOnClick(true);
    setValueTZ(element.target.value);
    handleChange(element, field, element.target.value);
  };

  const handleOnClick = value => {
    setSelectOpen(value);
  };

  useEffect(() => {
    setValueTZ(dataProperty);
  }, [dataProperty]);
  return (
    <>
      <li className={liClassName}>
        <div
          className={`form-group timeZone material-dropdown select ${
            !editable ? 'has-diabled' : ''
          }`}
          style={{ width: '100%', ...styles }}
        >
          <Form.Label
            title={titleText}
            htmlFor={field}
            style={{ fontSize: '0.75rem', marginBottom: '0rem' }}
          >
            {heading}
          </Form.Label>
          <Form.Control
            as="select"
            value={valueTZ}
            className={`select ${error ? 'is-invalid' : ''}${
              editable ? '' : 'read-only'
            }${' text-darkgray-1'} material-select`}
            title={valueTZ}
            readOnly={!editable || readOnly}
            onChange={e => handleOnChange(e)}
            onClick={e => handleOnClick(!selectOpen)}
            id={field}
            name={field}
            aria-label={titleText}
          >
            {timeZoneData.map(option => (
              <option
                className="text-darkgray-1"
                key={option.id}
                value={option.id}
              >
                {option.displayName.length > 30
                  ? (option.displayName =
                      option.displayName.substring(0, 30) + '...')
                  : option.displayName}
              </option>
            ))}
          </Form.Control>
          <span className={`select-arrow ${selectOpen ? 'toggled' : ''}`}>
            <UilAngleDown size="24" />
          </span>
        </div>
        {error && (
          <FieldError
            titleText={titleText}
            error={error}
            errorMessage={errorMessage}
          />
        )}
        {helperText && (
          <OverlayTrigger
            placement={toolTipPlacement}
            overlay={renderToolTip(helperText)}
          >
            <UILInfoCircle className="list-icon-after-text" />
          </OverlayTrigger>
        )}
      </li>
    </>
  );
};
export default TimeZoneField;
