import React, { useEffect, useState } from 'react';
import './dashboard.scss';
import { ListGroup } from 'react-bootstrap';
import Scrollbars from 'react-custom-scrollbars';
import CountPercentage from './count-percentage';
import { getTopReveneueStats } from 'features/analytics/analyticSlice';
import { APIStatus, TimeIntervalsEnum } from 'helpers/dropdown-enums';
import { toast } from 'react-toastify';
import ScrollBar from 'components/Scrollbars';
import { LoadingComponent } from './loadingListComp';

const TopRevenue = ({ graphData, isStatus = undefined, loading, duration }) => {
  const [topRevenuePerClient, setTopRevenuePerClient] = useState([]);
  useEffect(() => {
    getTopRevenueData();
  }, [graphData]);

  const getTopRevenueData = () => {
    // try {
    //   let payload = {
    //     pageSize: 10
    //   };
    //   const response = await getTopReveneueStats(payload);
    //   if (response.data && response.status === APIStatus.OK) {
    //     const { data } = response.data;
    setTopRevenuePerClient(
      graphData || []
      // [
      //   {
      //     CompanyName: 'company',
      //     CompanyId: 1,
      //     Count: 123,
      //     PercentageChange: 20
      //   }
      // ]
    );
    // setNoData(graphData.length ? false);
    //   }
    //   if (response.status === APIStatus.NoContent || response.data === null) {
    //     setNoData(true);
    //   }
    // } catch (error) {
    //   setNoData(true);
    //   toast.error(error || error.message);
    // }
  };

  return (
    <>
      <ListGroup
        className="analytic-list-container "
        variant="flush"
        style={{ borderRadius: '10px' }}
      >
        <ScrollBar
          horizontalScroll={false}
          className="print-preview-scroll"
          style={{ height: '100vh', marginBottom: 10 }}
        >
          {topRevenuePerClient.map((dt, index) => (
            <ListGroup.Item
              key={index}
              className="analytics-list list-item-padding"
            >
              <div className="heading">{dt.CompanyName}</div>
              <CountPercentage
                isStatus={isStatus}
                count={dt.Count}
                arrowDirection={dt.Trend === 1 ? 'up' : 'down'}
                percentage={
                  dt.PercentageChange ? dt.PercentageChange.toFixed(1) : ''
                }
                exactValue={dt.Count ? dt.Count.toFixed(1) : ''}
                heading={'Lead Count'}
                duration={duration}
                showStatistics={duration < TimeIntervalsEnum.SixMonths}
              />
            </ListGroup.Item>
          ))}
          <LoadingComponent
            loading={loading}
            dataLength={topRevenuePerClient.length}
          />
          {/* {!topRevenuePerClient.length ? (
            <ListGroup.Item>
              <div className="heading text-center">No data Available</div>
            </ListGroup.Item>
          ) : null} */}
        </ScrollBar>
      </ListGroup>
      {/* <ListGroup className="print-preiew" variant="flush">
        <ScrollBar horizontalScroll={false}>
          {topRevenuePerClient.map((dt, index) =>
            noData ? (
              <ListGroup.Item key={index}>
                <div className="heading">No data Available</div>
              </ListGroup.Item>
            ) : (
              <ListGroup.Item key={dt.CompanyId} className="analytics-list">
                <div className="heading">{dt.CompanyName}</div>
                <CountPercentage
                  count={dt.Count}
                  arrowDirection={dt.Trend === 1 ? 'up' : 'down'}
                  percentage={dt.PercentageChange.toFixed(1)}
                  exactValue={dt.Count.toFixed(1)}
                  heading={'Lead Count'}
                />
              </ListGroup.Item>
            )
          )}
        </ScrollBar>
      </ListGroup> */}
    </>
  );
};

export default TopRevenue;
