import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { UilSignout } from '@iconscout/react-unicons';
import PropTypes from 'prop-types';
import CircularLoader from 'components/CircularLoader';
import './style.scss';

const ButtonWithConfirmLoader = ({
  title,
  loading,
  isDisabled,
  onConfirm,
  buttonIcon,
  loaderDuration,
  loaderMode,
  classes,
  variant,
  confirmVariant,
  confirmButtonType,
  showConfirmButton
}) => {
  const [confirm, setConfirm] = useState(false);
  const [stopLoader, setStopLoader] = useState(false);

  useEffect(() => {
    if (loading) {
      setStopLoader(true);
    }

    if (!loading && stopLoader) {
      setStopLoader(false);
      setConfirm(false);
    }
  }, [loading]);

  const endChatHandler = () => {
    setConfirm(true);
  };

  const closeConfirmButton = () => {
    setConfirm(false);
    setStopLoader(false);
  };

  return showConfirmButton && confirm && !stopLoader ? (
    <span className={`confirm-container ${classes}`}>
      {/* <span className="top-text">Are you sure?</span> */}
      <Button
        variant={confirmVariant}
        size="sm"
        className={`btn-end-chat d-flex align-items-center`}
        onClick={onConfirm}
        disabled={loading}
        type={confirmButtonType}
      >
        {loading ? (
          <i className="uil uil-spinner spinner mr-1"></i>
        ) : (
          <CircularLoader
            start={confirm}
            duration={loaderDuration}
            callBack={closeConfirmButton}
            mode={loaderMode}
          />
        )}
        Yes, Confirm!
      </Button>
    </span>
  ) : (
    <Button
      variant={variant}
      size="sm"
      className={`btn-end-chat ${classes}`}
      onClick={showConfirmButton ? endChatHandler : onConfirm}
      disabled={loading || isDisabled}
    >
      {loading ? (
        <i className="uil uil-spinner spinner mr-1"></i>
      ) : (
        // <UilSignout />
        // [buttonIcon]
        React.isValidElement(buttonIcon) &&
        React.cloneElement(buttonIcon, { key: Math.random() })
      )}
      {title}
    </Button>
  );
};

ButtonWithConfirmLoader.propTypes = {
  title: PropTypes.string,
  loading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  buttonIcon: PropTypes.node,
  loaderDuration: PropTypes.number,
  loaderMode: PropTypes.string,
  variant: PropTypes.string,
  confirmVariant: PropTypes.string,
  confirmButtonType: PropTypes.string,
  showConfirmButton: PropTypes.bool
};

ButtonWithConfirmLoader.defaultProps = {
  title: 'Save',
  loading: false,
  isDisabled: false,
  loaderDuration: 3000,
  loaderMode: 'determinate',
  variant: 'outline-danger',
  confirmVariant: 'outline-success',
  confirmButtonType: 'button',
  showConfirmButton: true
  // onConfirm:PropTypes.func.isRequired,
  // buttonIcon:PropTypes.node,
};

export default ButtonWithConfirmLoader;
