// import $ from 'jquery';
// import 'jquery-ui-dist/jquery-ui'
// import {useEffect} from 'react';
import './bottomPaper.scss';
import { UilAngleDown } from '@iconscout/react-unicons';

const BottomPaper = ({ open, closeBotomSheet, children }) => {
  return (
    <>
      <div
        id="boxRef"
        className={`hide-box ${open ? 'show-hidden-div' : 'hide-div'}`}
      >
        <span type="button" className="close-button" onClick={closeBotomSheet}>
          <UilAngleDown />
        </span>
        {children}
      </div>
    </>
  );
};

export default BottomPaper;
