const note =
  'Nota: Las fechas de comienzo a las 12:00 de la mañana en la zona horaria seleccionada y terminan a la medianoche del mismo día. Para obtener los resultados para un día específico, utilice ese día como * ambos * la fecha de inicio y fin.';
export const sp_reportDetails = {
  heading1: 'Parámetros',
  note: 'Nota: Las fechas de comienzo a las 12:00 de la mañana en la zona horaria seleccionada y terminan a la medianoche del mismo día. Para obtener los resultados para un día específico, utilice ese día como * ambos * la fecha de inicio y fin.',
  notes: {
    trafficReportByDate: note,
    trafficSummary: note,
    leads: note,
    billableLeads: note,
    transcripts: note,
    stats: note,
    callsSummary: note,
    leadsDelay: note,
    agentResponseTime: note,
    summaryResponseTime: note,
    reviewRatings: note,
    highVolumeAccounts: note,
    pausedCompanies: `${note}
    Pacing periods are typically 24 hours but can be changed by company, totals are for the company's budgeting period.
    Paused and resumed values reference the last paused date and last resumed date.`,
    oftenOverBudgetCompanies: `${note}
    The "Pause Count" is the number of times the company has been paused because the budget has been hit or exceeded for a given pacing sub-period (usually 24 hours).`,
    nearlyOverBudgetCompanies:
      'Note: Budget percentages are inclusive and refer to the current company pacing cycle only.',
    invalidEmailAddresses:
      'Note: By default, all hosts and companies are included',
    performance: note,
    profileResponse: note,
    activeCompaniesWithLowTraffic:
      'Note: This report automatically covers 6 months of data',
    accountCompaniesWithoutPricing: note,
    nonAccountCompaniesWithoutPricing: note,
    activeCompaniesWithoutInvitationCode: note,
    inactiveCompaniesWithInvitationCode: note,
    companiesWithTrafficPacing:
      'Note: By default, all hosts and companies are included',
    companiesWithBillingAmount: note,
    invitationCodeInstalledReport: note,
    monthlyMinimumReport: note,
    agentResponseTimeByThreshold: note,
    'de-activatedaccounts': note,
    facebookUsage: note
  },
  routes: {
    parameters: 'Parámetros',
    schedule: 'Calendario'
  },
  parameters: {
    name: {
      label: 'Nombre',
      info: 'Nombre del informe con un nombre único, si es necesario.'
    },
    notificationEmail: {
      label: 'Correo electrónico de notificación',
      info: 'Enviar el informe a múltiples direcciones de correo electrónico mediante el uso de una coma.'
    },
    notificationEndPoint: {
      label: 'Punto final Notificación',
      info: 'Dirección web para notificar cuando el informe ha ejecutado con éxito. La dirección incluirá una URL que se puede utilizar para recuperar el informe resultante.'
    },
    emailReport: {
      label: 'Informe de correo electrónico como archivo adjunto',
      info: 'Si está activado, el informe será enviado como archivos adjuntos de correo electrónico con la notificación.'
    },
    companyKeys: {
      label: 'Claves de la compañía',
      info: 'Uso coma para varias claves compañía separada. Asegúrese de acogida no se comprueba cuando se ejecutan informes individuales sobre la base de claves únicas o múltiples de la compañía.'
    },
    timeZone: {
      label: 'Zona horaria',
      info: 'Seleccione su zona horaria apropiada.'
    },
    period: {
      label: 'Período',
      info: 'El período de tiempo que el informe se postuló para.'
    },
    groupByCompany: {
      label: 'Grupo por la empresa',
      info: 'Habilitar si hay varias claves que deben ser agrupados dentro del informe.'
    },
    host: {
      label: 'Anfitrión',
      info: 'Seleccione el host si el informe es para todas las empresas.'
    }
  },
  heading2: 'Schedule',
  schedule: {
    scheduleType: {
      label: 'Schedule Type'
    },
    schedule: {
      label: 'Calendario'
    },
    time: {
      label: 'Hora'
    },
    monday: {
      label: 'Lunes'
    },
    tuesday: {
      label: 'Martes'
    },
    wednesday: {
      label: 'Miércoles'
    },
    thursday: {
      label: 'Jueves'
    },
    friday: {
      label: 'Viernes'
    },
    saturday: {
      label: 'Sábado'
    },
    sunday: {
      label: 'Domingo'
    },
    noScheduleFound: {
      label: 'No hay horario encontrado'
    }
  },
  createReport: 'Create report'
};
export default sp_reportDetails;
