/* eslint-disable no-use-before-define */
import React from 'react';
import * as _ from 'lodash.debounce';
// import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import './auto-complete-styles.scss';
import { Chip } from '@material-ui/core';

const AutoCompeleteComp = ({
  options,
  handleChange,
  handleTextChange,
  loading = false,
  selected = [],
  variant = 'outlined',
  title = 'Select Company Key',
  styles = {},
  editable = true,
  isMultiSelectEnable,
  handleBlur = null,
  name,
  error,
  freeSolo = false,
  placeholder = 'Select one or more...',

  //for textarea prop
  multiline = false,
  maxRows,
  minRows,
  inputClass = '',
  errorClass = ''
}) => {
  const handleChangeInput = (e, value) => {
    if (value) handleTextChange(value);
  };
  const useStyles = makeStyles(theme => ({
    root: {
      // marginTop: '14px',
      fontFamily: 'Lato',
      background: 'white',
      color: error
        ? 'var(--danger-1) !important'
        : 'var(--darkgray-5) !important',
      '&:focus': {
        border: '1px solid purple'
      }
    },
    inputRoot: {
      fontFamily: 'Lato',
      color: 'var(--darkgray-2) !important'
    },
    tag: {
      opacity: '1 !important',
      color: `var(--darkgray-${editable ? '1' : '2'}) !important`
    }
  }));

  const classes = useStyles();

  return (
    <div className="form-group" style={styles}>
      <Autocomplete
        freeSolo={freeSolo}
        open={freeSolo ? false : undefined}
        onBlur={handleBlur}
        disabled={!editable}
        readOnly={!editable}
        classes={classes}
        multiple={isMultiSelectEnable}
        limitTags={editable ? 5 : undefined}
        id={name || 'tags-outlined'}
        options={options}
        name={name}
        loading={loading}
        getOptionLabel={option => option.key || ''}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            let props = { ...getTagProps({ index }) };
            return (
              <Chip
                label={option?.key || option}
                {...props}
                onDelete={editable ? props.onDelete : null}
                className={`${props.className} custom-chip`}
              />
            );
          })
        }
        getLimitTagsText={more => (
          <span className="cursor-pointer">{`+${more}`}</span>
        )}
        onChange={(e, data) =>
          handleChange(data, {
            ...e,
            target: { ...e.target, name, value: data }
          })
        }
        onInputChange={_(handleChangeInput, 1500)}
        value={selected || []}
        // inputValue={inputValue || undefined}
        filterSelectedOptions
        renderInput={params => {
          return (
            <TextField
              {...params}
              variant={variant}
              error={!!error}
              helperText={
                <span
                  className={errorClass}
                  style={{ color: 'var(--danger-1)' }}
                >
                  {error || ''}
                </span>
              }
              // FormHelperTextProps={}
              label={title}
              name={name}
              InputLabelProps={{
                classes: {
                  root: classes.root
                }
              }}
              inputProps={{
                ...params.inputProps,
                style: { borderBottom: '1px solid yellow !important' },
                className: `${params.inputProps.className} ${inputClass}`,
                name
              }}
              placeholder={editable ? placeholder : ''}
              size="small"
              //for textarea prop
              multiline={multiline}
              maxRows={maxRows}
              minRows={minRows}
            />
          );
        }}
      />
    </div>
  );
};

export default AutoCompeleteComp;
