import moment from 'moment';
/**
 * basic details initial values
 **/
export const basicDetails = {
  domain: ''
};
/**
 * Company information initial values
 **/
export const contactInformation = {
  primaryContactName: '',
  primaryContactEmail: '',
  primaryContactPhone: '',
  primaryContactAltPhone: '',
  address: '',
  city: '',
  state: '',
  zip: '',
  serviceArea: '',
  country: ''
};
export const facebookPixelSection = {
  facebookPixelTracking: false,
  facebookPixelAccount: ''
};
export const customTrackingSection = {
  customTrackingEnabled: false,
  chatCodeForCustomAnalytics: '',
  leadCodeForCustomAnalytics: ''
};
export const googleAnalyticsTrackingSection = {
  googleAnalyticsTrackingEnabled: false,
  googleAnalyticsAccount: '',
  googleAnalyticsDomain: ''
};

export const facebookIntegrationSection = {
  verificationToken: '',
  pageAccessToken: '',
  callbackURL: '',
  getStartedButtonGreetingText: '',
  facebookChatGreetingText: '',
  companyFacebookPageName: '',
  facebookPageID: '',
  enableGreetingText: false,
  getStartedButton: false
};

export const leadEmailSection = {
  leadEmailAddresses: '',
  chatTranscriptEmailAddresses: ''
};

export const callConnectSection = {
  officeHoursTimezone: '',
  quickConnectPhoneNumber: '',
  callConnectTranscriptOverride: '',
  callConnectTranscriptEmail: ''
};

export const companyHeader = {
  companyName: ''
};

// user Initial Values Formik

export const parameterSection = {
  name: '',
  email: '',
  timezone: 'West Asia Standard Time',
  userId: '',
  endPoint: '',
  period: 'C/C/C,C/C/C',
  emailReport: false,
  description: '',
  isDeleted: false,
  addReportAttachment: false,
  groupByCompany: false,
  scheduleType: 0,
  scheduleHour: 0,
  scheduleDaysOfWeek: [],
  scheduleDayOfMonth: 0,
  periodStart: '',
  periodEnd: '',
  webhook: '',
  group: 3,
  limit: '',
  aggregate: 1,
  minimum: 0,
  maximum: 0,
  companyKeys: [],
  startDate: '',
  endDate: '',
  agentOperatorFilter: 2,
  agentId: 0
};
