/**
 * basic details initial values
 **/
export const basicDetails = {
  domain: ''
};
/**
 * Company information initial values
 **/
export const contactInformation = {
  primaryContactName: '',
  primaryContactEmail: '',
  primaryContactPhone: '',
  primaryContactAltPhone: '',
  invoiceEmail: '',
  address: '',
  city: '',
  state: '',
  zip: '',
  serviceArea: '',
  country: ''
};
export const facebookPixelSection = {
  facebookPixelTracking: false,
  facebookPixelAccount: ''
};
export const customTrackingSection = {
  customTrackingEnabled: false,
  chatCodeForCustomAnalytics: '',
  leadCodeForCustomAnalytics: ''
};
export const googleAnalyticsTrackingSection = {
  googleAnalyticsTrackingEnabled: false,
  googleAnalyticsAccount: '',
  googleAnalyticsDomain: ''
};

export const facebookIntegrationSection = {
  verificationToken: '',
  pageAccessToken: '',
  callbackURL: '',
  getStartedButtonGreetingText: '',
  facebookChatGreetingText: '',
  companyFacebookPageName: '',
  facebookPageID: '',
  enableGreetingText: false,
  getStartedButton: false
};

export const leadEmailSection = {
  leadEmailAddresses: '',
  chatTranscriptEmailAddresses: ''
};

export const callConnectSection = {
  officeHoursTimezone: '',
  quickConnectPhoneNumber: '',
  callConnectTranscriptOverride: '',
  callConnectTranscriptEmail: ''
};

export const companyHeader = {
  companyName: ''
};
export const reportHeader = {
  name: ''
};

// user Initial Values Formik

export const usersSection = {
  userId: '',
  userName: '',
  displayName: '',
  email: '',
  confirmPassword: '',
  lockedOut: false,
  preferMobile: false
};
