const note =
  'Note: Dates start at 12:00 in the morning in the selected timezone and end at midnight the same day. To get the results for a specific day, use that day as *both* the start and end date.';
export const en_reportDetails = {
  heading1: 'Parameters',
  note: note,
  notes: {
    trafficReportByDate: note,
    trafficSummary: note,
    leads: note,
    billableLeads: note,
    transcripts: note,
    stats: note,
    callsSummary: note,
    leadsDelay: note,
    agentResponseTime: note,
    summaryResponseTime: note,
    reviewRatings: note,
    highVolumeAccounts: note,
    pausedCompanies: `${note}
    Pacing periods are typically 24 hours but can be changed by company, totals are for the company's budgeting period.
    Paused and resumed values reference the last paused date and last resumed date.`,
    oftenOverBudgetCompanies: `${note}
    The "Pause Count" is the number of times the company has been paused because the budget has been hit or exceeded for a given pacing sub-period (usually 24 hours).`,
    nearlyOverBudgetCompanies:
      'Note: Budget percentages are inclusive and refer to the current company pacing cycle only.',
    invalidEmailAddresses:
      'Note: By default, all hosts and companies are included',
    performance: note,
    profileResponse: note,
    activeCompaniesWithLowTraffic:
      'Note: This report automatically covers 6 months of data',
    accountCompaniesWithoutPricing: note,
    nonAccountCompaniesWithoutPricing: note,
    activeCompaniesWithoutInvitationCode: note,
    inactiveCompaniesWithInvitationCode: note,
    companiesWithTrafficPacing:
      'Note: By default, all hosts and companies are included',
    companiesWithBillingAmount: note,
    invitationCodeInstalledReport: note,
    monthlyMinimumReport: note,
    agentResponseTimeByThreshold: note,
    'de-activatedaccounts': note,
    facebookUsage: note
  },
  routes: {
    parameters: 'Parameters',
    schedule: 'Schedule'
  },
  parameters: {
    name: {
      label: 'Name',
      info: 'Name the report with a unique name if required.'
    },
    notificationEmail: {
      label: 'Notification Email',
      info: 'Send the report to multiple email addresses by using a comma.'
    },
    notificationEndPoint: {
      label: 'Notification Endpoint',
      info: 'Web address to notify when the report has executed successfully. The address will include a URL that can be used to retrieve the resulting report.'
    },
    emailReport: {
      label: 'Email Report as Attachment',
      info: 'If enabled, the report will be sent as attachments with notification email.'
    },
    companyKeys: {
      label: 'Company Key(s)',
      info: 'Use comma to separate multiple company keys. Make sure Host is NOT checked when running individual reports based on single or multiple Company keys.'
    },
    timeZone: {
      label: 'Time Zone',
      info: 'Select your appropriate Time Zone.'
    },
    period: {
      label: 'Period',
      info: 'The period of time the report will be ran for.'
    },
    groupByCompany: {
      label: 'Group By Company',
      info: 'Enable if there are multiple keys that need to be grouped within the report.'
    },
    host: {
      label: 'Host',
      info: 'Select the host if the report is for all companies.'
    },
    reportType: {
      label: 'Report Type',
      info: ''
    }
  },
  heading2: 'Schedule',
  schedule: {
    scheduleType: {
      label: 'Schedule Type'
    },
    schedule: {
      label: 'Schedule'
    },
    time: {
      label: 'Time'
    },
    dayOfMonth: {
      label: 'Day'
    },
    monday: {
      label: 'Monday'
    },
    tuesday: {
      label: 'Tuesday'
    },
    wednesday: {
      label: 'Wednesday'
    },
    thursday: {
      label: 'Thursday'
    },
    friday: {
      label: 'Friday'
    },
    saturday: {
      label: 'Saturday'
    },
    sunday: {
      label: 'Sunday'
    },
    noScheduleFound: {
      label: 'No schedule found'
    }
  },
  createReport: 'Create report'
};
export default en_reportDetails;
export { default as fr_reportDetails } from '../fr-FR-ReportDictionary/fr-reportDetails';
export { default as sp_reportDetails } from '../sp-SP-ReportDictionary/sp-reportDetails';
