import {
  pushChatRoom,
  pushLoadingProgress,
  setAllChatList
} from 'features/messagingChats/messagingChats';
import { Enums } from 'helpers/dropdown-enums';
import { logIt } from 'helpers/logger';
import { IncreaseProgress } from 'pages/portal/messaging/ChatSplashScreen/SplashScreen';
import store from 'store';
import RTC from '../rtc';
import { chatChanged } from '../services/chatroom-service';

const Subscribe_DefaultQueue = callbacks => {
  RTC().Subscribe({
    channels: ['/defaultqueue'],
    callbacks: callbacks
  });
};

export const Init_DefaultQueue = () => {
  Subscribe_DefaultQueue({
    onSuccess: function (args) {
      IncreaseProgress({
        text: 'Subscribing default queue...',
        count: 10
      });
      logIt('successfully subscribed to default channel: ', 'log');
    },
    onReceive: function (args) {
      var data = args.getData();
      var chatroomByType = chatChanged(data);
      logIt(`default queue chatroomByType--- ${chatroomByType}`, 'log');
      if (
        chatroomByType.includeInSections.length ||
        chatroomByType.excludeInSections.length
      ) {
        // TODO: remove duplication of chat and push to respective chat array by its type
        store.dispatch(pushChatRoom(chatroomByType));
      }
    }
  });
};

export default Subscribe_DefaultQueue;
