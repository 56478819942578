import React from 'react';
import Button from 'react-bootstrap/Button';
import {
  UilCheckCircle,
  UilClock,
  UilPlusCircle,
  UilQuestionCircle,
  UilHourglass
} from '@iconscout/react-unicons';

const StatusIndicator = ({ value }) => {
  const getButtonValue = statusId => {
    if (statusId === 1) {
      return (
        <span>
          <UilPlusCircle /> Adding Information
        </span>
      );
    } else if (statusId === 2) {
      return (
        <span>
          <UilClock /> Waiting For Setup
        </span>
      );
    } else if (statusId === 3) {
      return (
        <span>
          <UilQuestionCircle /> Need More Information
        </span>
      );
    } else if (statusId === 4) {
      return (
        <span>
          <UilHourglass /> Pending Addition To Website
        </span>
      );
    } else if (statusId === 5) {
      return (
        <span>
          <UilCheckCircle /> Completed
        </span>
      );
    }
  };
  const getClass = statusCode => {
    if (statusCode === 1) {
      return 'info-1';
    } else if (statusCode === 2) {
      return 'warning-3';
    } else if (statusCode === 3) {
      return 'danger-4';
    } else if (statusCode === 4) {
      return 'darkgray-7';
    } else if (statusCode === 5) {
      return 'success-3';
    }
  };

  return (
    <Button className="btn-sm btn-flex btn-status" variant={getClass(value)}>
      {getButtonValue(value)}
    </Button>
  );
};

export default StatusIndicator;
