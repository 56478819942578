import React from 'react';
import { Badge, OverlayTrigger } from 'react-bootstrap';

import {
  UilChatBubbleUser,
  UilUserSquare,
  UilCommentAltSlash,
  UilCommentAltVerify,
  UilClockThree
} from '@iconscout/react-unicons';
import { formatTime, truncateText } from 'helpers/generic.methods';
import renderToolTip from 'helpers/ui-controls/renderToolTip';

const ChatcompanyName = ({ companyName = null }) => {
  return (
    <>
      {companyName ? (
        <div className="d-flex my-2 chat-companyName-row-container">
          <div style={{ minWidth: '120px' }}>
            <span className="d-flex align-items-center">
              {/* Language */}
              <OverlayTrigger
                placement={'right'}
                overlay={renderToolTip('Language')}
              >
                <Badge className="badge badge-primary d-flex align-items-center py-1">
                  <span className="mx-2 badge-font text-uppercase">
                    {companyName.threeLetterISOLanguageName}
                  </span>
                </Badge>
              </OverlayTrigger>

              {/* chat id */}
              <OverlayTrigger
                placement={'right'}
                overlay={renderToolTip(`Chat ID: ${companyName.chatId}`)}
              >
                <span className="my-0 mx-2" style={{ minWidth: '60px' }}>
                  {truncateText(companyName.chatId, 7)}
                </span>
              </OverlayTrigger>

              {/* company name */}
              <OverlayTrigger
                placement={'auto'}
                overlay={renderToolTip(companyName.companyName)}
              >
                <span className="my-0 mx-2" style={{ minWidth: '87px' }}>
                  {truncateText(companyName.companyName, 11)}
                </span>
              </OverlayTrigger>

              {/* time */}
              <OverlayTrigger
                placement={'left'}
                overlay={renderToolTip(
                  `Created on: ${formatTime(
                    companyName.createdOn,
                    'MM/DD/YY hh:mm:ss'
                  )}`
                )}
              >
                <span
                  className="my-0 mx-2 badge-font"
                  style={{ minWidth: '87px' }}
                >
                  {truncateText(
                    formatTime(companyName.createdOn, 'MM/DD/YY hh:mm:ss'),
                    11
                  )}
                </span>
              </OverlayTrigger>
            </span>
          </div>
        </div>
      ) : (
        'no history found'
      )}
    </>
  );
};

export default ChatcompanyName;
