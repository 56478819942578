import SubSidebar from 'components/SubSidebar/index-v2';

const menuOpions = [
  {
    id: 'appearance-settings',
    name: 'widget.sidebar_item1',
    subItems: [
      {
        id: 'chat-widget',
        name: 'widget.item1_child1',
        slug: 'chat-widget',
        child: <h1>Widget Editor</h1>,
        path: '/',
        exact: true
      }
      // {
      //   id: 'language',
      //   name: 'Language',
      //   slug: 'language',
      //   child: <h1>Language</h1>,
      //   path: '/language',
      //   exact: true
      // }
    ]
  }
  // {
  //   id: 'billing-payments',
  //   name: 'Billing & Payments',
  //   path: '/billing-&-payments',
  //   subItems: []
  // }
];

const WidgetSettingOptions = ({ selectedMenu, handleSelect }) => {
  return (
    <SubSidebar
      mainClass="mt-3 font-size-class"
      selectedMenu={selectedMenu}
      selectMenu={handleSelect}
      //   matchedRoute={match}
      // routes={menuOpions}
      sideBarMenu={menuOpions}
      isRouted={true}
      activeAccordionKey={'appearance-settings'}
      storageKey={'active-widget-editor'}
    />
  );
};

export default WidgetSettingOptions;
