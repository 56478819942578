import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { verifyJwt, login, logout } from 'features/auth/authSlice';
import './styles.scss';
import storage from 'helpers/storage';

const SSO = props => {
  const jwt = props.match.params.id;
  const dispatch = useDispatch();
  const history = useHistory();
  const profile = storage.getItem('Profile');
  //1. Verify jwt token with API
  const getJwtResponse = async () => {
    try {
      if (jwt === 'logout') {
        setTimeout(() => {
          history.push('/login');
        }, 1500);
        return;
      }
      const response = await verifyJwt(jwt);
      if (response?.data?.data?.IsAuthenticated) {
        const payload = {
          ...response.data.data,
          JWT: jwt
        };
        dispatch(login(payload));
        history.push('/home');
      } else {
        history.push('/login');
        toast.error('Your login is expired');
      }
    } catch (error) {
      toast.error('Your login is expired');
      history.push('/login');
    }
  };

  useEffect(() => {
    dispatch(logout());
    getJwtResponse();
  }, [props, profile]);
  //2. After verification get the api response i.e the user profile store the Auth and profile in locat storage.

  //3. redirect to dashboard route.
  return (
    <div className="d-flex justify-content-center align-items-center sso-no-data">
      <div className="verify-container">
        <i className="uil uil-spinner spinner ml-2 spinner-size"></i>
        <br />
        Please wait.. verification is in process...
      </div>
    </div>
  );
};

export default SSO;
