import React, { useEffect, useState } from 'react';
import NestedSelect from 'helpers/ui-controls/NestedSelect';
import TimePicker from 'helpers/ui-controls/ui-components/TimePicker';
import SimpleLabelTextBox from 'helpers/ui-controls/ui-components/SimpleLabelTextbox';

const PeriodComp = ({ keyID, report, handleChange, editable }) => {
  const [data, setData] = useState({});

  // useEffect(() => {
  //   setData(report);
  // }, [report]);

  return (
    <React.Fragment key={keyID}>
      <li key={keyID} style={{ alignSelf: 'flex-start' }}>
        <NestedSelect
          editable={editable}
          handleChangeValue={handleChange}
          key={`${keyID}-nested`}
          field={'period'}
          value={report['period']}
          label="Period"
          // label={t('company.reportDetails.parameters.period.label')}
        />
      </li>
      {report['period'] === 'date_range' ? (
        <React.Fragment key={'date_range'}>
          <li key={`date_range-${keyID}`}>
            <TimePicker
              key={keyID}
              customStyles={{ marginBottom: '-5px' }}
              field={'periodStart'}
              type="date"
              disabled={!editable}
              dataProperty={report['periodStart']}
              heading={'From'}
              handleChange={handleChange}
            />
          </li>
          <li key={`date_range-${keyID + 1}`}>
            <TimePicker
              key={keyID}
              customStyles={{ marginBottom: '-5px' }}
              field={'periodEnd'}
              type="date"
              disabled={!editable}
              dataProperty={report['periodEnd']}
              heading={'To'}
              handleChange={handleChange}
            />
          </li>
        </React.Fragment>
      ) : null}
      {report['period'] === 'custom' ? (
        <React.Fragment key={'custom'}>
          <SimpleLabelTextBox
            key={`custom-${keyID}`}
            heading={'From'}
            field="webhook"
            dataProperty={
              report['periodDisplay']
                ? report['periodDisplay'].split('-')[0]
                : ''
            }
            editable={false}
            handleChange={handleChange}
          />
          <SimpleLabelTextBox
            key={`custom-${keyID + 1}`}
            heading={'To'}
            field="webhook"
            dataProperty={
              report['periodDisplay']
                ? report['periodDisplay'].split('-')[1]
                : ''
            }
            editable={false}
            handleChange={handleChange}
          />
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

export default PeriodComp;
