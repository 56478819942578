import React from 'react';
import UILCheckCircle from '@iconscout/react-unicons/icons/uil-check-circle';
import UILTimesCircle from '@iconscout/react-unicons/icons/uil-times-circle';
const ToggleText = ({ flag }) => {
  return (
    <div
      className={`flag-wrapper ${
        flag ? 'text-success' : 'text-danger'
      } align-items-center d-flex px-1r`}
    >
      {flag ? (
        <>
          <UILCheckCircle className="list-icon-before-text" />
          <span className="mr-1">Enabled</span>
        </>
      ) : (
        <>
          <UILTimesCircle className="list-icon-before-text" />

          <span>Disabled</span>
        </>
      )}
    </div>
  );
};
export default ToggleText;
