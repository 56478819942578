export const fr_contactInformation = {
  heading1: 'Coordonnées',
  heading2: 'Détails principaux',
  primaryContactName: {
    label: 'Nom',
    info: 'Le nom du contact principal qui sera le principal point de contact pour le compte.'
  },
  primaryContactEmail: {
    label: 'Email',
    info: 'Le contact de messagerie principal sera le principal point de contact pour le compte.'
  },
  primaryContactPhone: {
    label: 'Téléphoner',
    info: 'Le contact téléphonique primaire sera le principal point de contact pour le compte.'
  },
  primaryContactAltPhone: {
    label: 'Autre numéro de téléphone',
    info: 'Un autre numéro de téléphone qui peut être contacté pour le compte'
  },
  address: {
    label: 'Adresse',
    info: "L'adresse principale de l'entreprise."
  },
  city: {
    label: 'Ville',
    info: "La ville où se trouve l'entreprise."
  },
  state: {
    label: 'État',
    info: "L'état dans lequel se trouve l'entreprise"
  },
  zip: {
    label: 'Zipper',
    info: "Le code postal où l'entreprise est situé."
  },
  serviceArea: {
    label: 'Zone de service',
    info: "La zone de service et la couverture de l'entreprise. (Ex: dans le monde entier, Nationwide, État, villes, etc.)"
  },
  country: {
    label: 'Pays',
    info: "Le pays où l'entreprise est situé."
  },
  invoiceEmail: {
    label: 'Adresse e-mail du contact de facturation',
    info: `Les destinataires de l'e-mail qui recevront les informations de facturation.`
  }
};
export default fr_contactInformation;
