export const sp_facebookIntegration = {
  heading1: 'Facebook',
  verificationToken: {
    label: 'Clave de verificación',
    info: 'Testigo utilizado para configurar la aplicación de la integración de Facebook.'
  },
  pageAccessToken: {
    label: 'Token de acceso a la página',
    info: 'Un token generado por la aplicación de Facebook que da permiso para comunicarse en nombre de la página de facebook.'
  },
  callbackURL: {
    label: 'Devolución de llamada URL',
    info: 'La URL de punto final utiliza para configurar la aplicación integración de Facebook.'
  },
  enableGetStartedButton: {
    label: 'Habilitar el botón Empezar',
    info: 'Si se establece, el botón Comienza sólo aparecerá cuando el usuario interactúa con la página por primera vez.'
  },
  greetingText: {
    label: 'Un mensaje de saludo',
    info: 'Muestra el texto del saludo cuando se activa el botón Get Started.'
  },
  enableGreetingText: {
    label: 'Habilitar el texto del saludo',
    info: 'Si se establece, el botón Comienza también mostrará el texto del saludo por primera vez.'
  },
  facebookChatGreetingText: {
    label: 'Facebook Chat saludo de texto',
    info: 'Este mensaje aparecerá inmediatamente después de pulsar el botón Get Started.'
  },
  companyFacebookPageName: {
    label: 'Comapany página de Facebook Nombre',
    info: 'Por ejemplo: si la página empresa facebook url es https://www.facebook.com/Gearbestshopping, por lo que, Gearbestshopping es el nombre de la página de Facebook.'
  },
  facebookPageID: {
    label: 'Facebook ID de página',
    info: 'Puede encontrar la página de Facebook Id haciendo clic derecho sobre imagen para mostrar.'
  }
};
export default sp_facebookIntegration;
