import FilterWrapper from './filter-wrapper';
import Button from 'react-bootstrap/Button';
import FilterPanelInfoTooltip from './filter-panel-info-tooltip';

const DevicesComp = ({
  title = '',
  onChangefilters,
  setFunction,
  dataArray,
  toolTipQuickStats,
  toolTipGraphs
}) => {
  const handleOnChange = (e, ind) => {
    let temp = dataArray.map((t, index) => {
      let obj = { ...t };
      if (index === ind) obj.value = e;
      return obj;
    });
    setFunction(temp);
    onChangefilters(temp.filter(t => t.value));
  };

  const onFilterClear = () => {
    let temp = dataArray.map(t => {
      return { ...t, value: false };
    });
    setFunction(temp);
    onChangefilters([]);
  };

  return (
    <FilterWrapper
      title={title}
      onFilterClear={onFilterClear}
      tooltipLabel={
        <FilterPanelInfoTooltip
          toolTipQuickStats={toolTipQuickStats}
          toolTipGraphs={toolTipGraphs}
        />
      }
    >
      <div className="w-100">
        <div>
          {dataArray.map((device, index) => (
            <Button
              key={index}
              className={
                // 'btn-sm btn-flex'
                'btn-md btn-flex custom-btn'
              }
              type="button"
              variant={device.value ? 'primary' : 'lightgray-3'}
              onClick={e => {
                e.preventDefault();
                handleOnChange(!device.value, index);
              }}
            >
              {/* <UILEdit /> */}
              {device.icon}
              <span>{device.key}</span>
            </Button>
          ))}
        </div>
      </div>
    </FilterWrapper>
  );
};

export default DevicesComp;
