import { Button, Modal, Form } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import './styles.scss';
import { TabComponent, TabsContainer } from 'components/TabsComponent';
import { parameterSection } from 'helpers/schemas/reportSchemas/initialValues';
import { parameterSchemas } from 'helpers/schemas/reportSchemas/schemas';
import UilSpinner from '@iconscout/react-unicons/icons/uil-spinner';
import Schedule from './scheduleComp';
import ParametersComp from './ParameterComp';
import { createOrUpdateReport, getAgents } from 'features/report/reportSlice';
import { errors, success } from 'helpers/variables';
import {
  Enums,
  FieldsEnum,
  getObjectKeyByValue,
  GroupByEnums
} from 'helpers/dropdown-enums';
import storage from 'helpers/storage';
import { getAllCompanies } from 'features/companies/companiesSlice';
import ScrollBar from 'components/Scrollbars';
import { PeriodOptions, RemoveCharAndToCamelCase } from './constant';
import { getDisplayDate, getTextByNestedOptions } from './helper';
import { useSelector } from 'react-redux';
import { selectRoles } from 'features/roles/rolesSlice';
import moment from 'moment';
const defaultFields = [
  'name',
  'email',
  'timezone',
  'addReportAttachment',
  'webhook',
  'reportId',
  'scheduleType',
  'scheduleHour',
  'scheduleDayOfMonth',
  'scheduleDaysOfWeek'
];

const dateTypes = ['startDate', 'endDate'];

const generateOptions = data => {
  return [...data].map(dt => ({
    key: `${dt.companyKey}`,
    value: dt.companyKey
  }));
};
const CreateReportModal = ({
  showModal,
  eventHideModal,
  title,
  editData,
  onSubmitAndRun,
  hostList
}) => {
  const [selectedTab, setSelectedTab] = useState(1);
  const [hosts, setHosts] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [loadingOptions, setLoadingOptions] = useState(false);
  const [companiesOption, setCompaniesOption] = useState([]);
  const [hostOptions, setHostOptions] = useState(hostList);
  const [agentsOptions, setAgentsOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [runLoading, setRunLoading] = useState(false);
  const [rType, setRType] = useState(1);
  const user = JSON.parse(storage.getItem('Profile'));
  const {
    roles: { report }
  } = useSelector(selectRoles);
  const ReportTypes = (report && report.reportTypes) || [];

  const generateParameterJson = data => {
    // allowed fields from template

    let allowedFields = ReportTypes.filter(rt => rt.id === Number(rType))[0]
      .allowedFields;

    // filter allowed fields from all fields
    let fields = Object.keys(FieldsEnum)
      .filter(key => allowedFields.includes(FieldsEnum[key]))
      .map(f => RemoveCharAndToCamelCase(f));

    let jsonObject = {};
    if (
      ![
        Enums.ReportType.Invitation_Code_Installed_Report,
        Enums.ReportType.Monthly_Minimum_Report,
        Enums.ReportType.Agent_Response_Time_By_Threshold
      ].includes(rType)
    ) {
      jsonObject['webhook'] = data?.webhook;
    }

    fields.forEach(key => {
      if (!defaultFields.includes(key)) {
        jsonObject['companyKeys'] = companies.map(com => com.key);
        jsonObject['hostIds'] = hosts.map(id => id.toString());
        if (key === 'period') {
          jsonObject[key] = data[key] || '';
          jsonObject['periodDisplay'] = getTextByNestedOptions(
            PeriodOptions,
            data[key]
          );
          if (data[key] === 'date_range') {
            jsonObject['period'] = `${getDisplayDate(
              data['periodStart'],
              'YYYY-MM-DD',
              'DD/MM/YYYY'
            )},${getDisplayDate(
              data['periodEnd'],
              'YYYY-MM-DD',
              'DD/MM/YYYY'
            )}`;
            jsonObject['periodDisplay'] = `${getDisplayDate(
              data['periodStart'],
              'YYYY-MM-DD'
            )},${getDisplayDate(data['periodEnd'], 'YYYY-MM-DD')}`;
          }
        } else if (key === 'group') {
          jsonObject[key] = getObjectKeyByValue(GroupByEnums, data[key] || 3);
        } else if (key === 'pickedUpByUsername') {
          jsonObject[key] =
            agentsOptions.filter(op => op.id === Number(data[key]))[0]
              ?.userName || '';
        } else if (dateTypes.includes(key)) {
          jsonObject[key] = `/Date(${data[key] || moment().valueOf()})/`;
        } else if (key === 'aggregate') {
          jsonObject[key] = data[key] || 1;
        } else {
          jsonObject[key] = data[key];
        }
      }
    });
    return jsonObject;
  };

  const generatePayload = () => {
    let payload = {
      ...reportFormIk.values
    };

    let tempObj = {};
    tempObj['userId'] = user.UserID;
    tempObj['addReportAttachment'] = payload.addReportAttachment ? 1 : 0;
    tempObj['parametersJson'] = JSON.stringify(generateParameterJson(payload));
    defaultFields.forEach(key => {
      if (key === 'reportId') {
        tempObj[key] = rType;
      } else {
        tempObj[key] = payload[key];
      }
    });
    return tempObj;
  };

  const getAgentsList = () => {
    try {
      let payload = {
        start: 0,
        limit: 50
      };
      getAgents(payload).then(response => {
        if (response && response.data) {
          const { data } = response.data;
          setAgentsOptions(data);
        }
      });
    } catch {
      console.log('error');
    }
  };

  const reportFormHandler = run => {
    if (run) {
      setRunLoading(true);
    } else {
      setLoading(true);
    }
    let payload = generatePayload();
    try {
      createOrUpdateReport(payload, run).then(response => {
        if (response && response.data) {
          setLoading(false);
          setRunLoading(false);
          if (run) {
            onSubmitAndRun(payload);
          }
          toast.success(success.saveSuccess('Report'));
          // eventHideModal();
          handleOnModalClose(true);
        }
      });
    } catch {
      toast.error(errors.createError);
      setLoading(false);
      setRunLoading(false);
    }
  };
  const handleTextChange = text => {
    setLoadingOptions(true);
    try {
      let payload = {
        start: 0,
        limit: 10,
        filter: JSON.stringify([
          {
            propertyName: 'companyKey',
            propertyValue1: `${text}%`,
            propertyValue2: '',
            propertyValue3: [],
            filterDataType: 2,
            comparison: 2
          }
        ]),
        dir: 'ASC',
        sort: 'companyKey'
      };

      getAllCompanies(payload).then(response => {
        if (response && response.data) {
          let data = generateOptions(response.data.data);
          let filteredOptions = [];
          data.forEach(element => {
            if (!alreadySelectedElement(element)) {
              filteredOptions.push(element);
            }
          });
          setCompaniesOption(filteredOptions);
          // updateOptions(filteredOptions);
          setLoadingOptions(false);
        }
      });
    } catch (error) {
      setLoadingOptions(false);
      toast.error(error || error.message);
    }
  };
  const alreadySelectedElement = option => {
    if (companies.length && companies.find(val => val.key === option.key)) {
      return true;
    }
    return false;
  };

  const handleRunReport = () => {
    let formValid = reportFormIk.validateForm();
    formValid.then(errors => {
      if (errors && !Object.keys(errors).length) {
        reportFormHandler(true);
      }
    });
  };
  const handleSubmitReport = () => {
    reportFormHandler(false);
  };

  /** Init Formik */
  const reportFormIk = useFormik({
    enableReinitialize: true,
    initialValues: Object.assign({}, parameterSection),
    validationSchema: parameterSchemas(rType),
    onSubmit: handleSubmitReport
  });

  useEffect(() => {
    setHostOptions(hostList);
  }, [hostList]);

  useEffect(() => {
    if (
      [
        Enums.ReportType.Transcripts,
        Enums.ReportType.Agent_Response_Time
      ].includes(Number(rType))
    ) {
      getAgentsList();
    }

    reportFormIk.resetForm();
  }, [rType]);

  const handleOnChange = (element, key, value) => {
    // reportFormIk.values[key] = value;
    if (element) reportFormIk.handleChange(element);
    reportFormIk.setValues({
      ...reportFormIk.values,
      [key]: value
    });
    if (key === 'companyKeys') {
      setCompanies(value);
    }
    if (key === 'reportId') {
      setRType(value);
    }
    // if (key === 'agentOperatorFilter' && value == 1) {
    //   setCompanies([{ key: 'vendormax', value: 'vendormax' }]);
    //   let options = [...companiesOption];
    //   if (!companiesOption.map(v => v.value).includes('vendormax')) {
    //     options.push({ key: 'vendormax', value: 'vendormax' });
    //   }
    //   setCompaniesOption(options);
    // }
  };

  const handleHosts = (key, value) => {
    setHosts(value);
  };

  /**
   * to reset the form fields when close the modal with close icon
   */
  const handleOnModalClose = (refetchData = false) => {
    reportFormIk.handleReset();
    setRType(1);
    setSelectedTab(1);
    setHosts([]);
    setCompanies([]);
    eventHideModal(refetchData);
    setLoading(false);
    setRunLoading(false);
  };

  // Select Tab Handler
  const handleTabSelect = tabKey => {
    if (selectedTab !== tabKey) {
      setSelectedTab(tabKey);
    }
  };

  useEffect(() => {
    if (companies.length && hostList.length) {
      setHosts([]);
    }
  }, [companies]);

  return (
    <Modal
      show={showModal}
      onHide={handleOnModalClose}
      size={'xl'}
      keyboard={false}
      id="createReportModal"
      scrollable
      dialogClassName="createReportModal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Form
        className="form form-class  p-0"
        onSubmit={reportFormIk.handleSubmit}
        autoComplete="off"
      >
        <div className="create-report-modal-header-container">
          <Modal.Header className="create-report-modal-header">
            {title}
          </Modal.Header>
        </div>

        <Modal.Body className="create-report-modal-body">
          <div className={'navbar-expand'}>
            <TabsContainer
              onTabSelect={e => handleTabSelect(e)}
              activeKey={selectedTab}
            >
              <TabComponent
                tabClassName={'tab-ui'}
                key={1}
                id={`tab-${1}`}
                eventKey={1}
                title={
                  <div
                    className={`tab-styles ${
                      selectedTab != 1 ? 'text-darkgray-2' : ''
                    } `}
                  >
                    Parameters
                  </div>
                }
              >
                <ScrollBar horizontalScroll={false} style={{ height: '50vh' }}>
                  <div className="white-background">
                    <ParametersComp
                      key={'create'}
                      handleHosts={handleHosts}
                      selectedHosts={hosts}
                      hostList={hostOptions}
                      agentsOptions={agentsOptions}
                      showHeading={false}
                      showNote={false}
                      isCreate={true}
                      form={reportFormIk}
                      handleChange={handleOnChange}
                      companies={companies}
                      setCompanies={setCompanies}
                      loadingOptions={loadingOptions}
                      companiesOption={companiesOption}
                      handleTextChange={handleTextChange}
                      keyPrefix="create-report"
                      ReportTypes={ReportTypes}
                      setRType={setRType}
                      rType={rType}
                    />
                  </div>
                </ScrollBar>
              </TabComponent>
              <TabComponent
                tabClassName={'tab-ui'}
                key={2}
                id={`tab-${2}`}
                eventKey={2}
                title={
                  <div
                    className={`tab-styles ${
                      selectedTab != 2 ? 'text-darkgray-2' : ''
                    } `}
                  >
                    Schedule
                  </div>
                }
              >
                <ScrollBar horizontalScroll={false} style={{ height: '50vh' }}>
                  <Schedule
                    report={reportFormIk.values}
                    handleChange={handleOnChange}
                    editable={true}
                  />
                </ScrollBar>
              </TabComponent>
            </TabsContainer>
          </div>
        </Modal.Body>
        <Modal.Footer className="create-report-modal-footer d-flex justify-content-between">
          <Button
            type="button"
            variant="outline-primary"
            className="passBtn btn-md btn-flex float-right"
            onClick={() => handleOnModalClose()}
          >
            <span>Close</span>
          </Button>
          <div>
            <Button
              disabled={loading || runLoading}
              type="button"
              variant="primary"
              className="passBtn btn-md btn-flex float-right ml-2"
              // onClick={reportFormIk.handleSubmit}
              onClick={handleRunReport}
            >
              <span>Run Report</span>
              {runLoading && <i className="uil uil-spinner spinner ml-2"></i>}
            </Button>
            <Button
              disabled={loading || runLoading}
              type="submit"
              variant="primary"
              className="passBtn btn-md btn-flex float-right"
            >
              <span>Save Report</span>
              {loading && <i className="uil uil-spinner spinner ml-2"></i>}
            </Button>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default CreateReportModal;
