import React, { useState, useEffect } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Table from 'react-bootstrap/Table';
import { UilDownloadAlt, UilEye, UilTrashAlt } from '@iconscout/react-unicons';
import FieldError from './FieldError';
import { toolTipPlacement } from 'helpers/generic.methods';
import '../styles.scss';
import renderToolTip from '../renderToolTip';
const SimpleTable = ({ editable, dataArray = [], handleDelete }) => {
  return (
    <>
      {dataArray.length ? (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>File Name</th>
            </tr>
          </thead>
          <tbody>
            {dataArray.map((val, index) => {
              return (
                <tr key={index}>
                  <td className="d-flex justify-content-between align-items-center">
                    <p className="m-0 text-darkgray">{val.fileName}</p>

                    <div className="btn-group">
                      <OverlayTrigger
                        placement="auto"
                        overlay={renderToolTip('View')}
                      >
                        <a
                          href={val.downloadLink}
                          target="_blank"
                          className="list-icon-before-text text-darkgray"
                        >
                          <UilEye />
                        </a>
                      </OverlayTrigger>
                      {editable && (
                        <OverlayTrigger
                          placement="auto"
                          overlay={renderToolTip('Delete')}
                        >
                          <a
                            href="#"
                            className="list-icon-before-text ml-2 text-danger"
                            onClick={e => handleDelete(index)}
                          >
                            <UilTrashAlt />
                          </a>
                        </OverlayTrigger>
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        !editable && <p>No Records</p>
      )}
    </>
  );
};

export default SimpleTable;
