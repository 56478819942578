import {
  requiredText,
  phoneTextRequired,
  requiredOnlyText,
  requiredEmail,
  regexValidatePhone
} from '../genericSchemas';
import * as Yup from 'yup';
import { errors } from 'helpers/variables';

// MD SignUp Initial Values Formik
export const MDSignUpForm = {
  name: '',
  companyName: '',
  email: '',
  phone: ''
};

export const MDSignUpSchemas = Yup.object().shape({
  name: requiredText,
  // companyName: requiredText,
  email: requiredEmail,
  phone: Yup.string()
    .matches(regexValidatePhone, errors.notValid.phoneNumber)
    .matches(/^[\d-()+\s]+$/, 'Digits only')
    .required('Phone number is required')
});
