export const CrmData = [
  { domain: null, value: 0, isDeleted: false, name: '- None -' },
  {
    domain: 'https://acculynx.com/',
    value: 3,
    isDeleted: false,
    name: 'Acculynx'
  },
  {
    domain: 'https://www.activecampaign.com/ca/',
    value: 12,
    isDeleted: false,
    name: 'Active Campaign'
  },
  {
    domain: 'https://www.alertcommunications.com/',
    value: 14,
    isDeleted: false,
    name: 'Alert Communications'
  },
  {
    domain: 'https://capsulecrm.com/',
    value: 5,
    isDeleted: false,
    name: 'Capsule CRM'
  },
  {
    domain: 'https://www.captorra.com/',
    value: 19,
    isDeleted: false,
    name: 'Captorra'
  },
  {
    domain: 'https://www.clio.com/grow/',
    value: 13,
    isDeleted: false,
    name: 'Clio Grow'
  },
  {
    domain: 'https://www.hubspot.com/',
    value: 16,
    isDeleted: false,
    name: 'HubSpot'
  },
  {
    domain: 'https://www.improveit360.com/',
    value: 1,
    isDeleted: false,
    name: 'improveit360'
  },
  {
    domain: 'https://keap.com/',
    value: 24,
    isDeleted: false,
    name: 'Infusionsoft'
  },
  {
    domain: 'https://www.insightly.com/',
    value: 22,
    isDeleted: false,
    name: 'Insightly'
  },
  {
    domain: 'https://intake.law/',
    value: 7,
    isDeleted: false,
    name: 'Intake Conversion Experts'
  },
  {
    domain: 'https://www.lawruler.com/',
    value: 11,
    isDeleted: false,
    name: 'LawRuler'
  },
  {
    domain: 'https://www.leaddocket.com/',
    value: 15,
    isDeleted: false,
    name: 'Lead Docket'
  },
  {
    domain: 'https://www.leadperfection.com/',
    value: 25,
    isDeleted: false,
    name: 'Lead Perfection'
  },
  {
    domain: 'https://www.leadspedia.com/',
    value: 8,
    isDeleted: false,
    name: 'LeadsPedia'
  },
  {
    domain: 'https://www.legalintake.com/',
    value: 17,
    isDeleted: false,
    name: 'Legal Intake Professionals'
  },
  {
    domain: 'https://lexicata.com/',
    value: 20,
    isDeleted: false,
    name: 'Lexicata'
  },
  {
    domain: 'https://www.litify.com/',
    value: 6,
    isDeleted: false,
    name: 'Litify'
  },
  {
    domain: 'https://www.marketsharp.com/',
    value: 9,
    isDeleted: false,
    name: 'MarketSharp'
  },
  {
    domain: 'https://www.salesforce.com/',
    value: 21,
    isDeleted: false,
    name: 'Salesforce'
  },
  {
    domain: 'https://www.servicetitan.com/',
    value: 2,
    isDeleted: false,
    name: 'ServiceTitan'
  },
  {
    domain: 'https://www.smartadvocate.com/',
    value: 18,
    isDeleted: false,
    name: 'Smart Advocates'
  },
  {
    domain: 'https://www.velocify.com/',
    value: 23,
    isDeleted: false,
    name: 'Velocify'
  },
  {
    domain: 'https://www.whatconverts.com/',
    value: 10,
    isDeleted: false,
    name: 'WhatConverts'
  },
  {
    domain: 'https://zapier.com/',
    value: 26,
    isDeleted: false,
    name: 'Zapier'
  },
  { domain: 'https://www.zoho.com/', value: 4, isDeleted: false, name: 'ZOHO' },
  { domain: null, value: -1, isDeleted: false, name: 'Other' }
];
