import { createSlice } from '@reduxjs/toolkit';
import ApiServices from 'api/apexchat.api.services';
import { MyReportAPI, ReportAPI, UserAPI } from 'api/endpoints';

/**
 * @name @createSlice
 * @description create redux store slice for auth
 **/
export const reportSlice = createSlice({
  name: 'report',
  initialState: {
    myReport: []
  },

  reducers: {
    /**
     * @name @login
     * @description login reducer to call login async API with axios instance
     * @requires ApiServices.get method, state and API payload
     **/
  }
});

/**
 * These function called thunk and allows to perform async logic
 * It can be dispatched like a regular action: `dispatch(data)`
 **/

/* called a selector and allows to select values from the state */

export const getAllMyReportList = payload => {
  let myReportAPI = MyReportAPI.getAll;
  return ApiServices.getWithParams(myReportAPI, { params: payload });
};
export const getReportTypeList = payload => {
  let myReportAPI = ReportAPI.getList;
  return ApiServices.getWithParams(myReportAPI, { params: payload });
};
export const getAgents = payload => {
  let myUserAPI = UserAPI.getAgents;
  return ApiServices.getWithParams(myUserAPI, { params: payload });
};
export const createOrUpdateReport = (payload, run = false) => {
  let myReportAPI =
    ReportAPI.post + `${payload.id || 0}?doRunImmediately=${run}`;
  return ApiServices.report_post(myReportAPI, payload);
};

export const updateQuReport = (payload, run) => {
  let myReportAPI = ReportAPI.updateQueuedReport;
  return ApiServices.post(myReportAPI, payload);
};

export default reportSlice.reducer;
