import { Badge, OverlayTrigger } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import renderToolTip from 'helpers/ui-controls/renderToolTip';

const useStyle = makeStyles(theme => ({
  badgeStyle: {
    fontSize: '11px',
    backgroundColor: 'var(--white) !important',
    padding: '1px 6px 1px 0px !important',
    borderColor: 'var(--primary-3)'
  }
}));

const BadgeWithIcon = ({
  text = '',
  toolTipText = '',
  icon = '',
  showIcon = false,
  badgeClass = ''
}) => {
  const classes = useStyle();
  return (
    <OverlayTrigger
      show={toolTipText ? undefined : false}
      placement={'auto'}
      overlay={renderToolTip(toolTipText)}
    >
      <Badge
        pill
        className={`bg-primary-3 border-1 ml-2 ${classes.badgeStyle} ${badgeClass}
      `}
      >
        <span className="d-flex align-items-center">
          {showIcon && icon}
          <span
            style={{
              padding: !showIcon ? '2px 0px 2px 5px' : '0'
            }}
          >
            {text}
          </span>
        </span>
      </Badge>
    </OverlayTrigger>
  );
};

export default BadgeWithIcon;
