import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    zIndex: '1039 !important'
  },
  backdrop: {
    position: 'absolute',
    borderBottomLeftRadius: '25px',
    borderTopLeftRadius: '25px'
  },
  appBar: {
    position: 'relative'
  },
  toolBar: {
    padding: '0px 16px'
  },
  title: {
    // marginLeft: theme.spacing(2),
    flex: 1
  },
  scrollPaper: {
    alignItems: 'start'
  },
  paperScrollPaper: {
    width: '100%',
    margin: '0'
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide direction={props.direction} ref={ref} {...props} timeout={900} />
  );
});

const ModalInSpecificContainer = ({
  heading,
  open,
  showCloseIcon,
  showSaveBtn,
  transitionDirection,
  children
}) => {
  const classes = useStyles();

  return (
    <Dialog
      // classes={classes}
      fullWidth
      open={open}
      onClose={() => console.log('onClose')}
      TransitionComponent={Transition}
      TransitionProps={{ direction: transitionDirection }}
      disablePortal
      className={classes.root}
      classes={{
        root: classes.root,
        scrollPaper: classes.scrollPaper,
        paperScrollPaper: classes.paperScrollPaper
      }}
      BackdropProps={{
        classes: { root: classes.backdrop }
      }}
      style={{ position: 'absolute' }}
    >
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          {showCloseIcon && (
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => console.log('onClose')}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          )}
          <Typography variant="h6" className={classes.title}>
            {heading}
          </Typography>
          {showSaveBtn && (
            <Button
              //   autoFocus
              color="inherit"
              onClick={() => console.log('on save')}
            >
              save
            </Button>
          )}
        </Toolbar>
      </AppBar>
      {children}
    </Dialog>
  );
};

ModalInSpecificContainer.propTypes = {
  heading: PropTypes.string,
  open: PropTypes.bool,
  showCloseIcon: PropTypes.bool,
  showSaveBtn: PropTypes.bool,
  transitionDirection: PropTypes.string,
  children: PropTypes.node.isRequired
};

ModalInSpecificContainer.defaultProps = {
  heading: 'Some heading here',
  open: false,
  showCloseIcon: true,
  showSaveBtn: true,
  transitionDirection: 'down' //as mentioned in Material Ui top/down/left/right
};

export default ModalInSpecificContainer;
