import React, { useState, useEffect } from 'react';
import 'static/scss/sub-sidebar.scss';
// import { useHistory } from 'react-router-dom';
import storage from 'helpers/storage';
import { SubMenuActiveLink } from 'helpers/hoc/NonCollapsableLink';
import UILAngleDown from '@iconscout/react-unicons/icons/uil-angle-down';
import Accordion from 'react-bootstrap/Accordion';
import AccordionButton from './AccordionButton';
import { useTranslation } from 'react-i18next';

const SubSidebar = props => {
  const {
    selectMenu,
    selectedMenu,
    sideBarMenu,
    mainClass = '',
    isRouted = false,
    activeAccordionKey = 'general',
    storageKey = 'activeAccordion'
  } = props;
  const t = useTranslation().t;

  // let history = useHistory();
  let [activeAccordion, setActiveAccodion] = useState(
    JSON.parse(storage.getItem(storageKey)) || activeAccordionKey
  );

  // TODO
  // const goToPreviousPath = () => {
  //   history.goBack();
  // };

  useEffect(() => {
    let hasActiveAccordion = JSON.parse(storage.getItem(storageKey));
    setActiveAccodion(hasActiveAccordion);
  }, []);

  return (
    <aside className={mainClass}>
      <div className="collapsible-container">
        <Accordion defaultActiveKey={activeAccordion}>
          {sideBarMenu.map((menuItem, key) => (
            <ul className="collapsible" key={menuItem.id}>
              <li>
                {menuItem.subItems.length ? (
                  <>
                    <AccordionButton
                      btnClass="collapse-trigger"
                      eventKey={menuItem.id}
                      storageKey={storageKey}
                      style={{ whiteSpace: 'normal' }}
                    >
                      <>
                        {menuItem.icon}
                        <span className="menu-name">{t(menuItem.name)}</span>
                        <UILAngleDown className="collapse-caret" />
                      </>
                    </AccordionButton>
                    <Accordion.Collapse eventKey={menuItem.id}>
                      <>
                        <ul className="collapsible-nav">
                          {menuItem.subItems.map((subItem, subItemKey) => (
                            <li className="menu-name" key={subItem.id}>
                              <SubMenuActiveLink
                                onClick={() => selectMenu(subItem.id)}
                                href={isRouted ? subItem?.path : '#'}
                                title={t(subItem.name)}
                                activeMenu={selectedMenu === subItem.id}
                              >
                                <>{t(subItem.name)}</>
                              </SubMenuActiveLink>
                            </li>
                          ))}
                        </ul>
                      </>
                    </Accordion.Collapse>
                  </>
                ) : (
                  <SubMenuActiveLink
                    onClick={() => selectMenu(menuItem.id)}
                    href={isRouted ? menuItem?.path : '#'}
                    title={t(menuItem.name)}
                    activeMenu={selectedMenu === menuItem.id}
                  >
                    <>{t(menuItem.name)}</>
                  </SubMenuActiveLink>
                )}
              </li>
            </ul>
          ))}
        </Accordion>
      </div>
    </aside>
  );
};

export default SubSidebar;
