export const fr_hoursCallConnect = {
  heading1: "Heure d'appel/connexion",
  heading2: 'Appeler connecter',
  heading3: 'Appel heures de connexion',
  heading4: 'Heures de bureau',
  timeZone: {
    label: 'Fuseau horaire',
    info: "Le fuseau horaire de l'entreprise et le numéro de connexion appel."
  },
  quickConnectPhoneNumber: {
    label: 'Numéro de téléphone',
    info:
      "L'appel de connexion est le numéro sont le système reliera le visiteur durant ou après les heures d'ouverture."
  },
  transcriptOverride: {
    label: 'Override Transcription',
    info:
      'Si elle est définie, le « Call Connect Transcript » sera livré seulement à « Call Connect Transcript emails »'
  },
  transcriptEmail: {
    label: 'Email Transcription',
    info: 'Insérer les adresses email pour envoyer Call Connect Transcript à'
  },
  useCallConnectNumberforCallerID: {
    label:
      "L'utilisation des numéros d'appel de connexion pour l'identification de l'appelant",
    info:
      "Si elle est définie, utilise le numéro Call Connect pour l'identification de l'appelant; cela exige que le nombre soit validé en Twilio)."
  },
  companyNumberFirst: {
    label: "Numéro d'entreprise d'abord",
    info:
      "Si elle est définie, Call Connect appellera automatiquement le numéro d'entreprise d'abord."
  },
  override: {
    label: 'Override',
    info:
      "Si elle est définie, un numéro sera sélectionné dans le pool de niveau de l'entreprise de numéros Twilio."
  },
  officeHourMonitoring: {
    label: 'Suivi des heures de bureau',
    info:
      "Si elle est activée, et les heures de bureau sont fixés, l'invitation ne baisse pendant ou après les heures de bureau, selon le cas; si les heures de bureau ne sont pas définies, le bureau est supposé être ouvert et l'invitation baissera de toute façon; si désactivé, les heures de bureau seront ignorés et l'invitation seront affichés simplement toujours. Ceci est encore soumis au drapeau « surveillance activé »."
  },
  smsChatNumber: {
    label: 'Sms numéro de chat',
    info:
      'Un numéro de SMS unique, acheté pour le compte pour permettre le texte à chat SMS.'
  },
  visitorgreeting: {
    label: 'Accueil des visiteurs',
    info:
      'Message à dire au visiteur sur le téléphone lors de la connexion via un appel de connexion [laisser vide pour utiliser le paramètre hôte]; vous pouvez utiliser "<# = companyName #>" et "<# = hostTitle #>" dans ce modèle.'
  },
  visitorgreetingdelay: {
    label: "Visiteur retard message d'accueil",
    info:
      "Nombre de secondes d'attente avant visiteur est diffusée. Beaucoup de temps, une partie de cette salutation est coupée car il commence à jouer avant que le visiteur commence à écouter."
  },
  businessgreeting: {
    label: 'Salutation bussiness',
    info:
      "Message à dire à l'entreprise sur le téléphone lors de la connexion via connexion d'appel [blanc autorisation d'utiliser le paramètre hôte]; vous pouvez utiliser <# = companyName #> et <# = hostTitle #> dans ce modèle."
  },
  businessgreetingdelay: {
    label: "Bussiness retard message d'accueil",
    info:
      "Nombre de secondes d'attente avant de connecter l'appel à l'entreprise. Ceci est utile lorsque l'appel est ramassé par une machine avant de transférer à un être humain. Ce délai dépendra de combien de temps le message automatisé. Le but d'avoir ce retard est de livrer l'entreprise saluant à un être humain et non à l'agent d'appel automatisé."
  },
  monday: {
    label: 'Lundi'
  },
  tuesday: {
    label: 'Mardi'
  },
  wednesday: {
    label: 'Mercredi'
  },
  thursday: {
    label: 'Jeudi'
  },
  friday: {
    label: 'Vendredi'
  },
  saturday: {
    label: 'Samedi'
  },
  sunday: {
    label: 'Dimanche'
  },
  copyToAll: {
    label: 'Copie à tous'
  }
};
export default fr_hoursCallConnect;
