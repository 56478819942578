export const sp_basicDetails = {
  heading1: 'Detalles básicos',
  heading2: 'Detalles primarios',
  heading3: 'Ayuda de idioma',
  companyId: {
    label: 'ID de la compañía',
    info:
      'El ID de la empresa es una serie numérica de referencia para cuando se creó la cuenta en nuestro sistema'
  },
  hostName: {
    label: 'Host',
    info: 'El nombre del distribuidor la cuenta está bajo.'
  },
  relationship: {
    label: 'Relación',
    info: 'Esto indica el tipo de cuenta (socio o cliente).'
  },
  CDN: {
    label: 'CDN',
    info:
      'Esta opción le permitió cargar el código de chat desde un CDN en lugar de nuestros servidores.'
  },
  website: {
    label: 'Sitio web',
    info: 'El nombre de dominio asociado a esta cuenta'
  },
  languages: {
    label: 'Idiomas (Language)',
    info: 'Idiomas admitidos por esta empresa'
  }
};
export default sp_basicDetails;
