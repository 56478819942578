import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import store from 'store';
import App from 'app/App';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { getAppInsights } from './TelemetryService';
import TelemetryProvider from './telemetry-provider';
import config from 'config';
const Root = () => {
  let appInsights = null;
  if (!config.instrumentationConnectionString) {
    console.warn('Instrument key is not defined in the config file.');
  }
  //The <Provider /> makes the Redux store available to any nested components
  // that have been wrapped in the connect() function
  return (
    <TelemetryProvider
      instrumentationKey={config.instrumentationConnectionString}
      after={() => {
        appInsights = getAppInsights();
      }}
    >
      <Provider store={store}>
        <Router>
          <Route path="/" component={App} />
        </Router>
      </Provider>
    </TelemetryProvider>
  );
};

export default Root;
