import {
  pushLoadingProgress,
  removeFromIncomingSection,
  setActiveChatList,
  setAllChatList,
  setIncomingChatList
} from 'features/messagingChats/messagingChats';
import { Enums } from 'helpers/dropdown-enums';
import { logIt } from 'helpers/logger';
import { IncreaseProgress } from 'pages/portal/messaging/ChatSplashScreen/SplashScreen';
import store from 'store';
import RTC from '../rtc';
import { chatChanged } from '../services/chatroom-service';

const Subscribe_GeneralQueue = callbacks => {
  RTC().Subscribe({
    channels: ['/generalqueue'],
    callbacks: callbacks
  });
};

export const Init_GeneralQueue = () => {
  Subscribe_GeneralQueue({
    onSuccess: function (args) {
      IncreaseProgress({
        text: 'Subscribing general queue...',
        count: 10
      });
      logIt('log', 'onSuccess::General Queue Subscribe', args);
      var meta = args.getMeta();
      logIt('log', 'general queue meta', meta);
      if (meta && meta.length) {
        let incomingList = [];
        let activeList = [];
        let allList = [];

        let removedIncomingList = [];
        let removedActiveList = [];
        let removedAllList = [];
        for (let i = 0; i < meta.length; i++) {
          var chatroomByType = chatChanged(meta[i], true);
          let { room, includeInSections, excludeInSections } = chatroomByType;
          if (
            includeInSections.includes(Enums.RoomType.ActiveChat) &&
            !activeList.some(item => item.getId() === room.getId())
          ) {
            activeList.push(room);
          }

          if (
            includeInSections.includes(Enums.RoomType.AllChat) &&
            !allList.some(item => item.getId() === room.getId())
          ) {
            allList.push(room);
          }

          if (
            includeInSections.includes(Enums.RoomType.IncomingChat) &&
            !incomingList.some(item => item.getId() === room.getId())
          ) {
            incomingList.push(room);
          }

          // if ((excludeInSections.includes(Enums.RoomType.ActiveChat))) {
          //   removedActiveList.push(room);
          // }

          // if ((excludeInSections.includes(Enums.RoomType.AllChat))) {
          //   removedAllList.push(room);
          // }

          if (excludeInSections.includes(Enums.RoomType.IncomingChat)) {
            store.dispatch(removeFromIncomingSection(room.getId()));
            // removedIncomingList.push(room);
          }
        }

        store.dispatch(setAllChatList(allList));
        store.dispatch(setActiveChatList(activeList));
        store.dispatch(setIncomingChatList(incomingList));
      }
    },
    onFailure: function (args) {
      logIt('log', 'onFailure::General Queue Subscribe', args);
    },
    onReceive: function (arg) {
      logIt('log', 'onReceive::', arg.getData().text);
    }
  });
};

export default Subscribe_GeneralQueue;
