const connectionService = {
  ConnectionStatus() {
    var me = this;
    var _template = {};
    var _retryCount = 0;
    me._timeOutObject = [];
    var _lastStatus;
    var _config;
    var _status;
    var _getHtml = function (isOnline) {
      return isOnline
        ? _template.connectHtmlTemplate
        : _template.disconnectHtmlTemplate;
    };
    var _init = function (config) {
      _config = config;
      _template.connectHtmlTemplate = config.connectHtmlTemplate;
      _template.disconnectHtmlTemplate = config.disconnectHtmlTemplate;
      me._timeOutObject[config.uniqueKey] = setInterval(function () {
        let connection = _checkConnection(config);
        var html = _getHtml(_status);
        // On Success callback of dynamic template render
        if (config.onSuccess) {
          config.onSuccess(html, _status);
        }
      }, config.interval);
      return config.uniqueKey;
    };
    var _xmlhttp = null;
    var _updateStatus = function (status, msg) {
      _status = status;
      if (status !== _lastStatus) {
        status ? _config.onReconnect() : _config.onFailure();
      }
      _lastStatus = status;
      _retryCount = 0;
    };
    var _stateChange = function (args, config) {
      // if xmlhttp shows "loaded"
      if (_xmlhttp.readyState === 4) {
        try {
          // if "OK"
          if (_xmlhttp.status === 200) {
            // "Internet is connected.."
            _updateStatus(true, 'Internet is connected');
            return;
          } else {
            _updateStatus(false, 'Internet is NOT connected..');
          }
        } catch (err) {
          _updateStatus(false, 'Internet is NOT connected..');
        }
      }
    };
    var _checkConnection = function (config) {
      _retryCount++;
      // code for Mozilla, Chrome etc.
      if (!_xmlhttp && window.XMLHttpRequest) {
        _xmlhttp = new XMLHttpRequest();
      }
      //   // code for IE
      //   else if (!_xmlhttp && window.ActiveXObject) {
      //     _xmlhttp = new ActiveXObject('Microsoft.XMLHTTP');
      //   }
      if (_xmlhttp != null) {
        _xmlhttp.onreadystatechange = _stateChange.bind(this, config);
        _xmlhttp.open('GET', _config.url, true);
        try {
          _xmlhttp.send(null);
        } catch (err) {
          _updateStatus(false, err);
        }
      } else {
        console.warn(
          'Your browser does not support XMLHTTP, Please use Chrome/Mozilla/Safari/Opera/Edge, etc.'
        );
      }

      if (_retryCount >= _config.retryCount) {
        _updateStatus(false, 'Network Timeouts...');
        _clearAllInstances();
        setTimeout(function () {
          _init(config);
        }, 10000); // If network fail, wait for 10 seconds then try again
        return;
      }
    };
    _status = _checkConnection;
    var _clearInterval = function (uniqueKey) {
      window.clearInterval(me._timeOutObject[uniqueKey]);
    };
    var _clearAllInstances = function () {
      var instances = Object.keys(me._timeOutObject);
      for (var i = 0; i < instances.length; i++) {
        window.clearInterval(me._timeOutObject[instances[i]]);
      }
    };
    return {
      GetStatus: _status, // ApexChat.ConnectionStatus.GetStatus() / returns true/false depending on internet connection..
      Init: _init, // ApexChat.ConnectionStatus.Init :: Takes 3 argument connectHtmlTemplate, disconnectHtmlTemplate, Success Callback
      Clear: _clearInterval, // ApexChat.ConnectionStatus.Clear :: clear the interval
      ClearAll: _clearAllInstances
    };
  }
};
export default connectionService.ConnectionStatus();
