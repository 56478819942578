import SimpleLabelTextBox from 'helpers/ui-controls/ui-components/SimpleLabelTextbox';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

const SignUpForm = ({ form, loading, handleOnChange, disabled }) => {
  return (
    <div className={`form-fields ${disabled ? 'form-disabled' : ''}`}>
      <Form className="form" onSubmit={form.handleSubmit} autoComplete="off">
        <SimpleLabelTextBox
          heading={'Name'}
          field="name"
          dataProperty={form.values['name']}
          error={form.touched.name && form.errors['name']}
          // titleText={'Full Name'}
          editable={true}
          readOnly={false}
          handleBlur={form.handleBlur}
          handleChange={handleOnChange}
        />
        {/* <SimpleLabelTextBox
          heading={'Company Name'}
          field="companyName"
          dataProperty={form.values['companyName']}
          error={form.touched.companyName && form.errors['companyName']}
          // titleText={'Full Name'}
          editable={true}
          readOnly={false}
          handleChange={handleOnChange}
        /> */}
        <SimpleLabelTextBox
          heading={'Business Email'}
          field="email"
          dataProperty={form.values['email']}
          // titleText={'Business Email'}
          error={form.touched.email && form.errors['email']}
          editable={true}
          handleChange={handleOnChange}
          handleBlur={form.handleBlur}
        />
        <SimpleLabelTextBox
          heading={'Phone Number'}
          field="phone"
          dataProperty={form.values['phone']}
          // titleText={'Business Email'}
          error={form.touched.phone && form.errors['phone']}
          editable={true}
          handleChange={handleOnChange}
          handleBlur={form.handleBlur}
        />

        <span className="d-block text-right">
          <Button
            disabled={disabled}
            className="btn signup-btn"
            type="submit"
            variant="primary"
          >
            <span>
              Sign Up
              {loading && (
                <i className="uil uil-spinner spinner small ml-2"></i>
              )}
            </span>
          </Button>
        </span>
      </Form>
    </div>
  );
};

export default SignUpForm;
