import React, { useState, useEffect } from 'react';
import UserInfo from './UserInfo';
import LeadForm from './LeadSection/LeadForm';
import { Tabs } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import { toast } from 'react-toastify';
import { errors, success } from 'helpers/variables';
import { sendCallConnect, sendLead } from 'features/lead/leadSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectChatDetailsRightPane,
  toggleChatDetailsRightPane
} from 'features/chat/chatSlice';
import {
  updateUnsentLeadStatus,
  setCallConnectStatus,
  getSelectedChatCallStatus,
  setChatInfoDataInChats,
  getChatInfoById
} from 'features/messagingChats/messagingChats';
import { Init_CallConnectStatusById } from 'app/communication/listeners/listener_call_connect_status_by_id';
import { isCallConnectEnable } from 'helpers/messaging/message.methods';
import './UserDetails.scss';
import { Enums } from 'helpers/dropdown-enums';
import LeadService from './LeadSection/lead-service';

const UserDetails = ({ selectedChat, endChat }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);
  let callConnectionStatus = useSelector(
    getSelectedChatCallStatus(selectedChat?.getId())
  );
  let togglePanel = useSelector(selectChatDetailsRightPane);
  const { data: chatInfo } = useSelector(getChatInfoById(selectedChat.getId()));
  const { rulesEnabled, leadCategories, regions } = LeadService(selectedChat);

  useEffect(() => {
    setTimeout(() => {
      if (chatInfo?.sendingLead) {
        dispatch(
          setChatInfoDataInChats({
            chatId: selectedChat.getId(),
            chatInfoData: { sendingLead: false }
          })
        );
      }
    }, 30000);
  }, [chatInfo?.sendingLead]);

  const leadFromHandler = data => {
    setLoading(true);
    dispatch(sendLead(data))
      .then(res => {
        dispatch(
          setChatInfoDataInChats({
            chatId: res.data.chatId,
            chatInfoData: {
              leadId: res.data.id,
              sendingLead: true,
              leadSubmitMessage: 'Chat lead has been submitted'
            }
          })
        );
        setLoading(false);
      })
      .catch(err => {
        toast.error(err ? err : errors.failure);
        setLoading(false);
      });
  };
  const callConnectFormHandler = data => {
    setLoading(true);
    Init_CallConnectStatusById({
      chatId: selectedChat.getId(),
      onStatusArrived: args => {
        onCallConnectStatusChange(args);
      }
    });
    dispatch(sendCallConnect(data))
      .then(() => {
        // toast.success(success.callConnectSuccess);
        setLoading(false);
      })
      .catch(err => {
        toast.error(err ? err : errors.failure);
        setLoading(false);
      });
  };

  const onCallConnectStatusChange = args => {
    let { chatID, status, prettyError } = args;
    dispatch(setCallConnectStatus({ chatId: chatID, status }));
    console.warn('callConnectStatus failed with prettyError:', prettyError);
    if (status === Enums.CallConnectStatus.Failed) {
      toast.error(prettyError || 'Call connect failed');
    }
  };

  const toggleSidePane = activeTab => {
    dispatch(
      toggleChatDetailsRightPane({
        activeDetailsTab: activeTab,
        activeParentTab: 'user-details'
      })
    );
  };

  const unsentleadHandler = data => {
    setSending(true);
    dispatch(updateUnsentLeadStatus(data))
      .then(res => {
        if (res === true) {
          toast.success(success.updateLead(data.chatId));
        } else {
          toast.error(
            res && !res.includes('Network Error') ? res : errors.networkError
          );
        }
        setSending(false);
      })
      .catch(err => {
        toast.error(err ? err : errors.failure);
        setSending(false);
      });
  };

  return (
    <>
      {Object.keys(selectedChat)?.length && (
        <UserInfo
          selectedChat={selectedChat}
          endChat={endChat}
          participants={chatInfo?.participants || []}
        />
      )}
      <div className="userdetails-lead-tabs">
        <Tabs
          activeKey={togglePanel.activeDetailsTab}
          id="uncontrolled-user-details-tabs"
          className="tab-right-panel mt-3"
          onSelect={toggleSidePane}
        >
          <Tab
            eventKey="lead-form"
            title="Lead Form"
            className="flex-column align-items-center"
          >
            <LeadForm
              title="Lead"
              id="lead-form"
              hideFields={{ phoneFrom: true }}
              fromHandler={leadFromHandler}
              loading={loading}
              onClickUnsentLeadHandler={unsentleadHandler}
              sending={sending}
              rulesEnabled={rulesEnabled}
              leadCategories={leadCategories}
              regions={regions}
            />
          </Tab>
          <Tab
            eventKey="call-connect"
            title="Call Connect"
            className={`flex-column align-items-center`}
            disabled={!isCallConnectEnable(selectedChat)}
            tabClassName={!isCallConnectEnable(selectedChat) && 'disabled-tab'}
          >
            <LeadForm
              title="Call Connect"
              id="call-connect"
              hideFields={{ email: true, reason: true, type: !rulesEnabled }}
              fromHandler={e => callConnectFormHandler(e)}
              loading={loading}
              callStatus={callConnectionStatus}
              rulesEnabled={rulesEnabled}
              leadCategories={leadCategories}
              regions={regions}
            />
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

export default UserDetails;
