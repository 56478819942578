import { errors } from 'helpers/variables';
import * as Yup from 'yup';
import { requiredText, requiredTextWithNoZero } from '../genericSchemas';

/**
 * Setup schema for statement
 * requires requiredText schema from genericSchemas
 **/

export const statementFormSchema = Yup.object().shape({
  statement: Yup.string().trim().required(errors.empty),
  visibility: requiredTextWithNoZero('Please select visibility'),
  folderIdArray: Yup.array().min(1, 'Please select at least 1 folder.')
});
