import StatusButton from 'CellRenderer/StatusButton';
import { formatTime } from 'helpers/generic.methods';
import { getReportViewColumnByRole } from 'app/role-engine';

export const ViewReportColumns = reportType => [
  {
    columnName: 'dateText',
    header: 'Date',
    type: 'date',
    allowedReports: [1],
    hidden: false
  },
  {
    columnName: 'visits',
    header: 'Visits',
    type: 'string',
    allowedReports: [1, 2, 18, 19],
    hidden: false
  },
  {
    columnName: 'invitedVisitors',
    header: 'Invited Visitors',
    type: 'string',
    allowedReports: [1, 2, 19],
    hidden: false
  },
  {
    columnName: 'clickRate',
    header: 'Click Rate %',
    type: 'percentage',
    allowedReports: [1, 2, 18, 19],
    isCalculated: [1, 2, 19],
    hidden: isRoleBased('clickRate', reportType, [1, 2, 19])
    // rolebase (1,2,19)
  },
  {
    columnName: 'chats',
    header: 'Chats',
    type: 'string',
    allowedReports: [1, 2, 19, 31],
    hidden: false
  },
  {
    columnName: 'blankChats',
    header: 'Blank Chats',
    type: 'string',
    allowedReports: [1, 2, 18, 19],
    hidden: isRoleBased('blankChats', reportType, [1, 2, 19])
    //rolebase (1,2,19)
  },
  {
    columnName: 'leadConversion',
    header: 'Lead Conversion %',
    type: 'string',
    allowedReports: [1, 2, 19],
    hidden: getReportViewColumnByRole('leadConversion', reportType)
    // rolebase (1,2,19)
  },
  {
    columnName: 'leads',
    header: 'Leads',
    type: 'string',
    allowedReports: [1, 2, 19, 31],
    hidden: false
  },
  {
    columnName: 'billableLeads',
    header: 'Billable Leads',
    type: 'string',
    allowedReports: [1, 2, 4, 19, 31],
    hidden: false
  },

  {
    columnName: 'phoneVisits',
    header: 'Phone Visitors',
    type: 'string',
    allowedReports: [1, 2, 18, 19],
    hidden: false
  },
  {
    columnName: 'tabletVisits',
    header: 'Tablet Visitors',
    type: 'string',
    allowedReports: [1, 2, 18, 19],
    hidden: false
  },

  {
    columnName: 'phoneChats',
    header: 'Phone Chats',
    type: 'string',
    allowedReports: [1, 2, 18, 19],
    hidden: false
  },
  {
    columnName: 'tabletChats',
    header: 'Tablet Chats',
    type: 'string',
    allowedReports: [1, 2, 18, 19],
    hidden: false
  },
  {
    columnName: 'conversionToSales',
    header: 'Conversion To Sales %',
    type: 'string',
    allowedReports: [1, 2, 19],
    hidden: getReportViewColumnByRole('conversionToSales', reportType)
    //rolebase (1,2,19)
  },

  {
    columnName: 'phoneLeads',
    header: 'Phone Leads',
    type: 'string',
    allowedReports: [1, 2, 18, 19],
    hidden: false
  },
  {
    columnName: 'tabletLeads',
    header: 'Tablet Leads',
    type: 'string',
    allowedReports: [1, 2, 18, 19],
    hidden: false
  },
  {
    columnName: 'companyKey',
    header: 'Company Key',
    type: 'string',
    allowedReports: [
      1, 2, 3, 4, 5, 7, 12, 13, 14, 15, 16, 18, 19, 20, 21, 22, 23, 24, 25, 26,
      27, 29, 30, 31
    ],
    hidden: isRoleBased('companyKey', reportType, [2, 5, 19])
    //rolebase (2,5,19)
  },

  {
    columnName: 'companyName',
    header: 'Company Name',
    type: 'string',
    allowedReports: [
      1, 2, 3, 4, 5, 7, 8, 12, 13, 14, 15, 16, 19, 22, 23, 24, 25, 26, 31
    ],
    hidden: isRoleBased('companyName', reportType, [2, 5, 19])
    //rolebase (2,5,19)
  },
  {
    columnName: 'smsChats',
    header: 'Sms Chats',
    type: 'string',
    allowedReports: [1, 2, 18, 19],
    hidden: getReportViewColumnByRole('smsChats', reportType)
    //rolebase (1,2,18,19)
  },
  {
    //show this column if it is administrator or column exist
    columnName: 'smsLeads',
    header: 'Sms Leads',
    type: 'string',
    allowedReports: [1, 2, 18, 19],
    hidden: getReportViewColumnByRole('smsLeads', reportType)
    //rolebase (1,2,18,19)
  },
  {
    //show this column if it is administrator or column exist
    columnName: 'smsBillableLeads',
    header: 'Sms Billable Leads',
    type: 'string',
    allowedReports: [1, 2, 4, 18, 19],
    hidden: getReportViewColumnByRole('smsBillableLeads', reportType)
    //rolebase (1,2,4,18,19)
  },
  {
    columnName: 'exitPopupChats',
    header: 'Exitpopup Chats',
    type: 'string',
    allowedReports: [1, 2, 18, 19],
    hidden: getReportViewColumnByRole('exitPopupChats', reportType)
    //rolebase (1,2,18,19)
  },
  {
    columnName: 'exitPopupLeads',
    header: 'Exitpopup Leads',
    type: 'string',
    allowedReports: [1, 2, 18, 19],
    hidden: getReportViewColumnByRole('exitPopupLeads', reportType)
    //rolebase (1,2,18,19)
  },
  {
    columnName: 'exitPopupBillableLeads',
    header: 'Exitpopup Billable Leads',
    type: 'string',
    emptyString: 'Unknown',
    allowedReports: [1, 2, 4, 18, 19],
    hidden: getReportViewColumnByRole('exitPopupBillableLeads', reportType)
    //rolebase (1,2,4,18,19)
  },

  {
    columnName: 'companyCreatedOn',
    header: 'Created',
    type: 'dateTime',
    allowedReports: [2, 19, 20, 21],
    hidden: false
  },
  {
    columnName: 'active',
    header: 'Active',
    type: 'check',
    allowedReports: [2, 16, 19, 20, 21, 24],
    hidden: false
  },
  {
    header: 'Service Leads',
    columnName: 'serviceLeads',
    type: 'string',
    allowedReports: [2, 19],
    hidden: false
  },
  {
    header: 'Other Leads',
    columnName: 'otherLeads',
    type: 'string',
    allowedReports: [2, 19],
    hidden: false
  },

  {
    columnName: 'leadId',
    header: 'Lead',
    type: 'string',
    shouldDisplayCheckForReports: [5],
    allowedReports: [3, 5],
    hidden: false
  },
  {
    columnName: 'chatId',
    header: 'Chat ID',
    type: 'string',
    allowedReports: [3, 5, 8, 9],
    hidden: false
  },
  {
    columnName: 'leadName',
    header: 'Lead Name',
    type: 'string',
    allowedReports: [3],
    hidden: false
  },
  {
    columnName: 'leadTypeName',
    header: 'Lead Type',
    type: 'string',
    allowedReports: [3, 5],
    hidden: false
  },
  {
    columnName: 'leadPhone',
    header: 'Lead Phone',
    type: 'string',
    allowedReports: [3],
    hidden: false
  },
  {
    columnName: 'leadEmail',
    header: 'Email',
    type: 'string',
    allowedReports: [3],
    hidden: false
  },
  {
    columnName: 'leadNotes',
    header: 'Notes',
    type: 'string',
    allowedReports: [3],
    hidden: false
  },
  {
    columnName: 'createdOn',
    header: 'Chat Created',
    type: 'dateTime',
    allowedReports: [3, 5, 6, 8, 22, 23, 26],
    hidden: false
  },
  {
    columnName: 'firstName',
    header: 'First Name',
    type: 'string',
    allowedReports: [3],
    hidden: false
  },
  {
    columnName: 'lastName',
    header: 'Last Name',
    type: 'string',
    allowedReports: [3],
    hidden: false
  },
  {
    columnName: 'isMobile',
    header: 'Mobile?',
    type: 'check',
    allowedReports: [3],
    hidden: false
  },

  {
    columnName: 'change',
    header: 'Change Lead Type',
    type: 'string',
    allowedReports: [3],
    hidden: getReportViewColumnByRole('change', reportType)
    //rolebase (3)
  },

  {
    columnName: 'referrer',
    header: 'Referrer',
    type: 'string',
    allowedReports: [3, 5, 18],
    hidden: isRoleBased('referrer', reportType, [3, 5])
    //rolebase (3,5)
  },

  {
    columnName: 'originalReferrer',
    header: 'Original Referrer',
    type: 'string',
    allowedReports: [3, 5, 18],
    hidden: isRoleBased('originalReferrer', reportType, [3, 5])
    //rolebase (3,5)
  },

  {
    columnName: 'landingReferrer',
    header: 'Landing Referrer',
    type: 'string',
    allowedReports: [3, 5, 18],
    hidden: isRoleBased('landingReferrer', reportType, [3, 5])
    //rolebase (3,5)
  },

  {
    columnName: 'keywords',
    header: 'Keywords',
    type: 'string',
    allowedReports: [3, 18],
    hidden: isRoleBased('keywords', reportType, [3])
    //rolebase (3)
  },

  {
    columnName: 'category',
    header: 'Category',
    type: 'string',
    allowedReports: [3],
    hidden: false
  },
  {
    columnName: 'region',
    header: 'Region',
    type: 'string',
    allowedReports: [3],
    hidden: false
  },
  {
    columnName: 'visitorId',
    header: 'Visitor ID',
    type: 'string',
    allowedReports: [3, 5],
    hidden: false
  },
  {
    //show this column if it is administrator or column exist
    columnName: 'smsLead',
    header: 'Sms Lead',
    type: 'boolean',
    allowedReports: [3],
    hidden: getReportViewColumnByRole('smsLead', reportType)
    //rolebase (3)
  },
  {
    columnName: 'exitPopupLead',
    header: 'ExitPopup Lead',
    type: 'boolean',
    allowedReports: [3],
    hidden: getReportViewColumnByRole('exitPopupLead', reportType)
    //rolebase (3)
  },

  {
    columnName: 'attemptedCalls',
    header: 'Attempted Calls',
    type: 'string',
    allowedReports: [3],
    hidden: getReportViewColumnByRole('attemptedCalls', reportType)
    //rolebase (3)
  },

  {
    columnName: 'completedCalls',
    header: 'Completed Calls',
    type: 'string',
    allowedReports: [3],
    hidden: getReportViewColumnByRole('completedCalls', reportType)
    //rolebase (3)
  },

  {
    columnName: 'denied',
    header: 'Denied',
    type: 'string',
    allowedReports: [3],
    hidden: false
  },

  {
    columnName: 'deniedDate',
    header: 'Denied Date',
    type: 'date',
    allowedReports: [3],
    hidden: false
  },
  {
    columnName: 'location',
    header: 'Location',
    type: 'string',
    allowedReports: [3, 5],
    hidden: false
  },

  {
    columnName: 'startDate',
    header: 'Date',
    type: 'string',
    allowedReports: [4, 18],
    hidden: false
  },
  {
    columnName: 'agentLeads',
    header: 'Sent By Agents',
    type: 'string',
    allowedReports: [4],
    hidden: false
  },
  {
    columnName: 'operatorLeads',
    header: 'Sent By Operators',
    type: 'string',
    allowedReports: [4],
    hidden: false
  },

  {
    columnName: 'pickedUpOn',
    header: 'Picked Up On',
    type: 'dateTime',
    allowedReports: [5],
    hidden: false
  },

  {
    columnName: 'endedOn',
    header: 'Ended On',
    type: 'dateTime',
    allowedReports: [5, 8],
    hidden: false
  },

  {
    columnName: 'agentChatCountAtAcceptance',
    header: 'Chat Count',
    type: 'string',
    allowedReports: [5],
    hidden: getReportViewColumnByRole('agentChatCountAtAcceptance', reportType)
    //rolebase (5)
  },

  {
    columnName: 'totalIncomingChats',
    header: 'Incoming Chats',
    type: 'string',
    allowedReports: [6],
    hidden: false
  },

  {
    columnName: 'totalActiveChats',
    header: 'Active Chats',
    type: 'string',
    allowedReports: [6],
    hidden: false
  },
  {
    columnName: 'totalOnlineAndActiveAgents',
    header: 'Online & Active Agents',
    type: 'string',
    allowedReports: [6],
    hidden: false
  },
  {
    columnName: 'totalOnlineAndInactiveAgents',
    header: 'Online & Inactive Agents',
    type: 'string',
    allowedReports: [6],
    hidden: false
  },
  {
    columnName: 'chatsAssignedToInactiveAgents',
    header: 'Assigned to Inactive',
    type: 'string',
    allowedReports: [6],
    hidden: false
  },
  {
    columnName: 'chatsAssignedToActiveAgents',
    header: 'Assigned to Active',
    type: 'string',
    allowedReports: [6],
    hidden: false
  },
  {
    columnName: 'inactiveLoad',
    header: 'Inactive Load',
    type: 'decimal',
    allowedReports: [6],
    hidden: false
  },
  {
    columnName: 'activeLoad',
    header: 'Active Load',
    type: 'decimal',
    allowedReports: [6],
    hidden: false
  },

  {
    columnName: 'chatCount',
    header: 'Chat Count',
    type: 'string',
    allowedReports: [7, 18],
    hidden: false
  },
  {
    columnName: 'leadCount',
    header: 'Lead Count',
    type: 'string',
    allowedReports: [7, 12, 18],
    hidden: false
  },
  {
    columnName: 'attemptedCallCount',
    header: 'Attempted Calls Count',
    type: 'string',
    allowedReports: [7],
    hidden: false
  },
  {
    columnName: 'completedCallCount',
    header: 'Completed Calls Count',
    type: 'string',
    allowedReports: [7],
    hidden: false
  },

  {
    columnName: 'id',
    header: 'Lead ID',
    type: 'string',
    allowedReports: [8, 16, 26],
    hidden: false
  },
  {
    columnName: 'displayName',
    header: 'Display Name',
    type: 'string',
    allowedReports: [8, 17, 18],
    hidden: false
  },
  {
    columnName: 'userName',
    header: 'User Name',
    type: 'string',
    allowedReports: [8, 10],
    hidden: false
  },

  {
    columnName: 'lastMessageSent',
    header: 'Last Message',
    type: 'dateTime',
    allowedReports: [8],
    hidden: false
  },
  {
    columnName: 'emailSentTimeStamp',
    header: 'Email Sent',
    type: 'dateTime',
    allowedReports: [8],
    hidden: false
  },
  {
    columnName: 'diffTimeStampEndedOn',
    header: 'Delay (Minutes), Ended On',
    type: 'string',
    allowedReports: [8],
    hidden: false
  },
  {
    columnName: 'diffTimeStampLastMsgSent',
    header: 'Delay (Minutes), Last Message',
    type: 'string',
    allowedReports: [8],
    hidden: false
  },

  {
    columnName: 'agentId',
    header: 'Agent ID',
    type: 'string',
    allowedReports: [9],
    hidden: false
  },
  {
    columnName: 'agentName',
    header: 'Agent Name',
    type: 'string',
    allowedReports: [9, 10, 11],
    hidden: false
  },
  {
    columnName: 'startTime',
    header: 'Start Time',
    type: 'dateTime',
    allowedReports: [9],
    hidden: false
  },
  {
    columnName: 'endTime',
    header: 'End Time',
    type: 'dateTime',
    allowedReports: [9],
    hidden: false
  },
  {
    columnName: 'averageResponse',
    header: 'Average Response in Seconds',
    type: 'string',
    allowedReports: [9, 10],
    hidden: false
  },

  {
    columnName: 'maxResponse',
    header: 'Maximum Response in Seconds',
    type: 'string',
    allowedReports: [10],
    hidden: false
  },

  {
    columnName: 'averageRating',
    header: 'Average Rating',
    type: 'string',
    allowedReports: [11],
    hidden: false
  },
  {
    columnName: 'numberOfReviews',
    header: 'Number of Reviews',
    type: 'string',
    allowedReports: [11],
    hidden: false
  },
  {
    columnName: 'details',
    header: 'Details',
    type: 'string',
    allowedReports: [11],
    hidden: false
  },

  {
    columnName: 'budgetPausedTimestamp',
    header: 'Paused On',
    type: 'dateTime',
    allowedReports: [13, 24],
    hidden: false
  },
  {
    columnName: 'subPeriodBudgetSpentInLeads',
    header: 'Current Period Spent',
    type: 'string',
    allowedReports: [13],
    hidden: false
  },
  {
    columnName: 'subPeriodBudgetAvailableInLeads',
    header: 'Current Period Available',
    type: 'string',
    allowedReports: [13],
    hidden: false
  },
  {
    columnName: 'totalBudgetSpentInLeads',
    header: 'Total Spent',
    type: 'string',
    allowedReports: [13],
    hidden: false
  },
  {
    columnName: 'totalBudgetAvailableInLeads',
    header: 'Total Available',
    type: 'string',
    allowedReports: [13],
    hidden: false
  },

  {
    columnName: 'budgetPauseCount',
    header: 'Pause Count',
    type: 'string',
    allowedReports: [14],
    hidden: false
  },

  {
    columnName: 'totalLeads',
    header: 'Total Leads',
    type: 'string',
    allowedReports: [15, 17],
    hidden: false
  },
  {
    columnName: 'usedLeads',
    header: 'Sent Leads',
    type: 'string',
    allowedReports: [15],
    hidden: false
  },
  {
    columnName: 'percentUsed',
    header: 'Percent',
    type: 'decimal',
    allowedReports: [15],
    hidden: false
  },

  {
    columnName: 'hasInvalidEmails',
    header: 'Has Invalid Emails',
    type: 'check',
    allowedReports: [16],
    hidden: false
  },
  {
    columnName: 'hasInboundEmails',
    header: 'Has Inbound Emails',
    type: 'check',
    allowedReports: [16],
    hidden: false
  },
  {
    columnName: 'invalidEmails',
    header: 'Invalid Emails',
    type: 'string',
    allowedReports: [16],
    hidden: false
  },

  {
    columnName: 'username',
    header: 'Username',
    type: 'string',
    allowedReports: [17, 28],
    hidden: false
  },
  {
    columnName: 'totalChats',
    header: 'Total Chats',
    type: 'string',
    allowedReports: [17],
    hidden: false
  },
  {
    columnName: 'nonBlankChats',
    header: 'Non Blank Chats',
    type: 'string',
    allowedReports: [17],
    hidden: false
  },
  {
    columnName: 'salesLeads',
    header: 'Sales Leads',
    type: 'string',
    allowedReports: [17],
    hidden: false
  },
  {
    columnName: 'chatsToLeads',
    header: 'Chats To Leads',
    type: 'percentage',
    allowedReports: [17],
    hidden: false
  },
  {
    columnName: 'leadsToSalesLeads',
    header: 'Leads to Sales Leads',
    type: 'percentage',
    allowedReports: [17],
    hidden: false
  },
  {
    columnName: 'chatsToSalesLeads',
    header: 'Chats to Sales Leads',
    type: 'percentage',
    allowedReports: [17],
    hidden: false
  },
  {
    columnName: 'percentBlankChats',
    header: 'Percent Blank Chats',
    type: 'percentage',
    allowedReports: [17],
    hidden: false
  },

  {
    columnName: 'billableLeadCount',
    header: 'Billable Leads',
    type: 'string',
    allowedReports: [18],
    hidden: false
  },

  {
    columnName: 'phoneBillableLeads',
    header: 'Phone Billable Leads',
    type: 'string',
    allowedReports: [18],
    hidden: false
  },
  {
    columnName: 'tabletBillableLeads',
    header: 'Tablet Billable Leads',
    type: 'string',
    allowedReports: [18],
    hidden: false
  },
  {
    columnName: 'desktopVisits',
    header: 'Desktop Visits',
    type: 'string',
    allowedReports: [18],
    hidden: false
  },
  {
    columnName: 'desktopChats',
    header: 'Desktop Chats',
    type: 'string',
    allowedReports: [18],
    hidden: false
  },
  {
    columnName: 'desktopLeads',
    header: 'Desktop Leads',
    type: 'string',
    allowedReports: [18],
    hidden: false
  },
  {
    columnName: 'desktopBillableLeads',
    header: 'Desktop Billable Leads',
    type: 'string',
    allowedReports: [18],
    hidden: false
  },

  {
    columnName: 'skinName',
    header: 'Skin',
    type: 'string',
    allowedReports: [18],
    hidden: false
  },
  {
    columnName: 'theme',
    header: 'Theme',
    type: 'string',
    allowedReports: [18],
    hidden: false
  },
  {
    columnName: 'initialLoad',
    header: 'Initial Load',
    type: 'string',
    allowedReports: [18],
    hidden: false
  },
  {
    columnName: 'mode',
    header: 'Display Mode',
    type: 'string',
    allowedReports: [18],
    hidden: false
  },
  {
    columnName: 'invitationLocation',
    header: 'Location',
    type: 'string',
    allowedReports: [18],
    hidden: false
  },

  {
    columnName: 'invitationType',
    header: 'Invitation Type',
    type: 'string',
    allowedReports: [18],
    hidden: false
  },
  {
    columnName: 'conversion',
    header: 'Conversion',
    type: 'percentage',
    allowedReports: [18],
    hidden: false
  },
  {
    columnName: 'conversionToSale',
    header: 'Conversion To Sales',
    type: 'percentage',
    allowedReports: [18],
    hidden: false
  },

  {
    columnName: 'operatorImageUrl',
    header: 'Invitation Image',
    type: 'image',
    allowedReports: [18],
    hidden: false
  },
  {
    columnName: 'aliasImageUrl',
    header: 'Invitation Image',
    type: 'image',
    allowedReports: [18],
    hidden: false
  },

  {
    columnName: 'hostName',
    header: 'Host Name',
    type: 'string',
    allowedReports: [20, 21, 27],
    hidden: false
  },
  {
    columnName: 'hostLeadPricing',
    header: 'Host Lead Pricing',
    type: 'string',
    allowedReports: [20, 21],
    hidden: false
  },
  {
    columnName: 'companyLeadPricing',
    header: 'Company Lead Pricing',
    type: 'string',
    allowedReports: [20, 21],
    hidden: false
  },
  {
    columnName: 'hostMonthlyMinimum',
    header: 'Host Monthly Minimum',
    type: 'string',
    allowedReports: [20, 21],
    hidden: false
  },
  {
    columnName: 'companyMonthlyMinimum',
    header: 'Company Monthly Minimum',
    type: 'string',
    allowedReports: [20, 21],
    hidden: false
  },
  {
    columnName: 'hostBillingAmount',
    header: 'Host Billing Amount',
    type: 'string',
    allowedReports: [20, 21],
    hidden: false
  },
  {
    columnName: 'companyBillingAmount',
    header: 'Company Billing Amount',
    type: 'string',
    allowedReports: [],
    hidden: false
  },

  {
    columnName: 'hostMonthlyBillingAmount',
    header: 'Host Monthly Billing Amount',
    type: 'string',
    allowedReports: [],
    hidden: false
  },
  {
    columnName: 'companyMonthlyBillingAmount',
    header: 'Company Monthly Billing Amount',
    type: 'string',
    allowedReports: [20, 21],
    hidden: false
  },

  {
    columnName: 'modifiedOn',
    header: 'Modified On',
    type: 'dateTime',
    allowedReports: [22, 23],
    hidden: false
  },

  {
    columnName: 'budgetTrafficUsage',
    header: 'Traffic Usage',
    type: 'string-percentage',
    allowedReports: [24],
    hidden: false
  },
  {
    columnName: 'budgetPeriodUsage',
    header: 'Period Usage',
    type: 'string-percentage',
    allowedReports: [24],
    hidden: false
  },
  {
    columnName: 'budgetTrafficRunRate',
    header: 'Run Rate',
    type: 'string-percentage',
    allowedReports: [24],
    hidden: false
  },

  {
    columnName: 'budgetAmountTotalTraffic',
    header: 'Total Traffic Allowed',
    type: 'string',
    allowedReports: [24],
    hidden: false
  },

  {
    columnName: 'budgetResumedTimestamp',
    header: 'Budget Resumed',
    type: 'dateTime',
    allowedReports: [24],
    hidden: false
  },

  {
    columnName: 'budgetPauseEnabled',
    header: 'Budget Pause Enabled',
    type: 'check',
    allowedReports: [24],
    hidden: false
  },

  {
    columnName: 'periodRunningTrafficCount',
    header: 'Visits',
    type: 'string',
    allowedReports: [24],
    hidden: false
  },

  {
    columnName: 'periodRunningLeadCount',
    header: 'Leads',
    type: 'string',
    allowedReports: [24],
    hidden: false
  },

  {
    columnName: 'trafficToBillableLeadsRatio',
    header: 'Traffic To Billable Leads Ratio',
    type: 'string-percentage',
    allowedReports: [24],
    hidden: false
  },
  {
    columnName: 'adjustedConversionPercent',
    header: 'Adjusted Conversion Percent',
    type: 'string-percentage',
    allowedReports: [24],
    hidden: false
  },

  {
    columnName: 'monthlyBillingAmount',
    header: 'Billing Amount',
    type: 'string',
    allowedReports: [25],
    hidden: false
  },

  {
    columnName: 'domain',
    header: 'URL',
    type: 'string',
    allowedReports: [26],
    hidden: false
  },

  {
    columnName: 'accountName',
    header: 'Account',
    type: 'string',
    allowedReports: [27],
    hidden: false
  },

  {
    columnName: 'monthlyMinimum',
    header: 'Monthly Minimum',
    type: 'string',
    allowedReports: [27],
    hidden: false
  },

  {
    columnName: 'userID',
    header: 'User ID',
    type: 'string',
    allowedReports: [28],
    hidden: false
  },
  {
    columnName: 'aboveThreshold',
    header: 'Above Threshold',
    type: 'string',
    allowedReports: [28],
    hidden: false
  },
  {
    columnName: 'belowOrAtThreshold',
    header: 'Below Or At Threshold',
    type: 'string',
    allowedReports: [28],
    hidden: false
  },
  {
    columnName: 'atOrUnderThresholdPercent',
    header: 'Percent At Or Under Threshold',
    type: 'string',
    allowedReports: [28],
    hidden: false
  },
  {
    columnName: 'total',
    header: 'Total',
    type: 'string',
    allowedReports: [28],
    hidden: false
  },

  {
    columnName: 'host',
    header: 'Host',
    type: 'string',
    allowedReports: [29, 30, 31],
    hidden: false
  },
  {
    columnName: 'cancelationReason',
    header: 'Cancelation Reason',
    type: 'string',
    allowedReports: [29, 30],
    hidden: false
  },
  {
    columnName: 'disabledMonitoringBy',
    header: 'Disabled Monitoring By',
    type: 'string',
    allowedReports: [29, 30],
    hidden: false
  },
  {
    columnName: 'dateDeactivated',
    header: 'Date Deactivated',
    type: 'dateTime',
    allowedReports: [29, 30],
    hidden: false
  }

  //******************* End *************************//
];
export const getReportTypeColumns = type => {
  console.log('getReportTypeColumns :: type', type);
  return ViewReportColumns(type)
    .filter(column => column.allowedReports.includes(type) && !column.hidden)
    .map(filteredCol => {
      let col = {
        ...filteredCol,
        name: filteredCol.columnName,
        // header: camelCaseStringToNormalText(key, 'heading'),
        className: 'hide-filter-button',
        defaultWidth: filteredCol.columnName.length * 10 + 50,
        filterDelay: 800,
        // cellProps: { className: 'copy-button' },
        render: ({ value, ...rest }) => {
          if (filteredCol?.shouldDisplayCheckForReports) {
            //for report type 5 (transcript) leadId column should be rendered as boolean yes/nno type
            filteredCol = booleanHanlder(filteredCol, type);
          }

          if (filteredCol?.isCalculated) {
            // added custom type for clickRate percentage if it is pre calculated by server
            filteredCol = percentageHandler(filteredCol, type);
          }

          return reportValueRenderer(
            filteredCol.type,
            value === null && 'emptyString' in filteredCol
              ? filteredCol.emptyString
              : value
          );
        }
      };
      return col;
    });
};

export const reportValueRenderer = (type, value) => {
  switch (type) {
    case 'dateTime':
      return value ? formatTime(value, 'MM-DD-YYYY h:mm:ss a') : '';
    case 'date':
      return value ? formatTime(value, 'MM-DD-YYYY') : '';
    case 'string':
      return value;
    case 'decimal':
      return value.toFixed(2);
    case 'string-percentage':
      return `${value.toFixed(2)}%`;
    case 'percentage':
      return `${parseInt(value * 100)}%`;
    case 'check':
    case 'boolean':
      return <StatusButton value={value} />;
    case 'image':
      return <img src={value} alt="image here" width={'45px'} />;
    default:
      return value;
  }
};

const isRoleBased = (columnName, type, roleBasedTypes = []) => {
  if (roleBasedTypes.length) {
    return roleBasedTypes?.includes(type)
      ? getReportViewColumnByRole(columnName, type)
      : false;
  }
  return getReportViewColumnByRole(columnName, type);
};

const booleanHanlder = (col, type) => {
  let isCheckType = col?.shouldDisplayCheckForReports
    ? col?.shouldDisplayCheckForReports.includes(type)
    : false;
  col.type = isCheckType ? 'boolean' : col.type;
  return col;
};

const percentageHandler = (col, type) => {
  let isCustomType = col?.isCalculated
    ? col?.isCalculated?.includes(type)
    : false;
  col.type = isCustomType ? 'string' : col.type;
  return col;
};
