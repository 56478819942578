import { TimeIntervalsEnum } from 'helpers/dropdown-enums';

export const getPermitCheckBox = data => {
  let obj = {};
  if (data)
    Object.keys(data)
      .filter(f => data[f])
      .forEach(m => {
        obj[m] = true;
      });
  return obj;
};

export const FeatureUsageConstants = {
  Web: [
    'Bar',
    'Dom', // UIUX-781
    'Window', // UIUX-781
    'Static',
    'Standard',
    'Tab',
    'ExitPopup',
    'PreChat',
    'Unknown',
    'WebApi'
  ],
  Sms: ['SmsStatic', 'SmsWeb', 'SmsContainer'],
  Unknown: ['Unknown']
};

export const getUniqueListBy = (arr, key, selected) => {
  let mapData = new Map(
    arr.map(item => {
      return selected.includes(item[key]) ? [item[key], item] : [];
    })
  );
  let newData = mapData.values();
  return [...newData]
    .filter(Boolean)
    .sort((first, second) => first.widgetId - second.widgetId);
};

export const calculateCTR = (totalChats, totalVisits, duration) => {
  // if selectedWidgets include CTR and TotalChats and TotalVisits and there data too
  if (
    // selectedWidgets.includes(5) && // 5 = Click Through Rate (CTR)
    // selectedWidgets.includes(2) && // 2 = Total Chats
    // selectedWidgets.includes(1) && // 1 = Total Visits
    totalVisits &&
    totalChats
  ) {
    let clickThroughRate = {
      current: 0,
      previous: 0,
      widgetId: 5,
      widgetName: 'ClickThroughRate',
      arrowDirection: '',
      hasInfo: true,
      info: `(${totalChats.current} Chats ÷ ${totalVisits.current} Visits) x 100`
    };
    var isPreviousZero =
      duration === TimeIntervalsEnum.SixMonths
        ? false
        : isNaN(totalChats.previous / totalVisits.previous);

    clickThroughRate.current = totalVisits.current
      ? (totalChats.current / totalVisits.current) * 100
      : 0;
    clickThroughRate.previous =
      isPreviousZero && totalVisits.previous > 0
        ? 100
        : totalVisits.previous
        ? (totalChats.previous / totalVisits.previous) * 100
        : 0;
    clickThroughRate.arrowDirection =
      totalChats.current > totalChats.previous ? 'up' : 'down';
    return clickThroughRate;
  }
  return null;
};

export const calculateLTR = (selectedWidgets, totalChats, totalLeads) => {
  // if selectedWidgets include LTR and TotalChats and TotalLeads and there data too
  if (
    selectedWidgets.includes(6) && // 6 = Lead Conversion Rate (LTR)
    selectedWidgets.includes(2) && // 2 = Total Chats
    selectedWidgets.includes(3) && // 3 = Total Leads
    totalChats &&
    totalLeads
  ) {
    let leadConversionRate = {
      current: 0,
      previous: 0,
      widgetId: 6,
      widgetName: 'LeadConversionRate',
      arrowDirection: ''
    };
    var isPreviousZero = isNaN(totalLeads.previous / totalChats.previous);
    leadConversionRate.current = totalChats.current
      ? (totalLeads.current / totalChats.current) * 100
      : 0;
    leadConversionRate.previous =
      isPreviousZero && totalLeads.previous > 0
        ? 100
        : totalChats.previous
        ? (totalLeads.previous / totalChats.previous) * 100
        : 0;
    leadConversionRate.arrowDirection =
      totalLeads.current > totalLeads.previous ? 'up' : 'down';
    return leadConversionRate;
  }
  return null;
};

export const removeZeroValues = (data = {}) => {
  let temp = {};
  Object.keys(data).forEach(dt => {
    if (data[dt]) {
      temp[dt] = data[dt];
    }
  });
  return temp;
};
