import React, { useState, useEffect, useRef } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';

const CircularLoader = ({ mode, duration, callBack, start }) => {
  const [count, setCount] = useState(0);
  const timer = useRef(null);

  useEffect(() => {
    if (start && mode === 'determinate') {
      startProgress();
    }

    return () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
    };
  }, [start]);

  const startProgress = () => {
    let intervalCount = 0;
    setCount(0);
    let counter = duration ? 100 / (duration / 100) : 0;
    timer.current = setInterval(() => {
      intervalCount = intervalCount + 100;
      setCount(prev => prev + counter); //3.34
      if (intervalCount === duration + 200) {
        clearInterval(timer.current);
        timer.current = null;
        if (callBack) {
          callBack();
        }
      }
    }, 100);
  };

  return (
    <CircularProgress
      variant={mode}
      value={count}
      className="circular-loader"
    />
  );
};

CircularLoader.propTypes = {
  duration: PropTypes.number,
  callBack: PropTypes.func,
  start: PropTypes.bool,
  mode: PropTypes.string
};

CircularLoader.defaultProps = {
  mode: 'determinate', // mode have two types determinate/indeterminate
  duration: 0,
  start: false
};

export default CircularLoader;
