import React from 'react';

/*
 *Component for displaying IDs (chat id, company id) on different screens
 */
const DisplayKey = ({ displayKey }) => {
  return (
    <>
      <div className="displayKey float-right d-flex px-4r">
        <span className="fw-600">ID: {displayKey}</span>
      </div>
    </>
  );
};
export default DisplayKey;
