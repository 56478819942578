import moment from 'moment';

export const generateDropDownOptions = (data = []) => {
  if (data && data.length)
    return [...data].map(dt => ({
      value: dt.id,
      name: dt.name || dt.displayName
    }));
  else return [];
};

export const getTextByNestedOptions = (options = [], value) => {
  let list = [];
  options
    .filter(o => o.nestedOptions)
    .forEach(op => {
      list.push(...op.nestedOptions);
    });
  let filtered = list.filter(l => l.value === value);

  if (filtered.length) {
    return filtered[0].name;
  } else {
    return '';
  }
};

export const getDisplayDate = (
  date = '',
  fromFormat = 'DD-MM-YYYY',
  toFormat = 'MMM D, YYYY'
) => {
  return moment(date, fromFormat).format(toFormat);
};
