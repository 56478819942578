import './styles.scss';
import { UilTimes } from '@iconscout/react-unicons';
const FiltersHeader = ({
  title = 'Title',
  clearTitle = 'Clear',
  onFilterClearAll,
  handleClosePanel
}) => {
  const handleClick = () => {
    onFilterClearAll('clear All');
  };
  return (
    <>
      <div className="filters-header">
        <div className="filter-heading">{title}</div>
        <div className="filter-clear">
          <span onClick={handleClick}>{clearTitle}</span>
          <span onClick={handleClosePanel} className="ml-8">
            <UilTimes size={30} />
          </span>
        </div>
      </div>
      <hr className="m-0" />
    </>
  );
};

export default FiltersHeader;
