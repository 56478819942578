import React, { useState, useEffect } from 'react';
import SplashScreen, {
  IncreaseProgress
} from './ChatSplashScreen/SplashScreen';
import { InitializeDashboardConnections } from 'app/communication/listeners/dashboard_initiate_listeners';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSplashScreenMeta,
  getUnsentLeadListAsync,
  removeSelectedChat
} from 'features/messagingChats/messagingChats';
import { getUserAsync } from 'features/users/userSlice';
import { selectLogin, setProfileToState } from 'features/auth/authSlice';
import storage from 'helpers/storage';

const ChatWrapper = ({ chatOperations, children }) => {
  const [loading, setLoading] = useState(true);
  const user = useSelector(selectLogin);
  let splashScreenMeta = useSelector(getSplashScreenMeta);
  let dispatch = useDispatch();
  const { init } = InitializeDashboardConnections();
  useEffect(() => {
    if (chatOperations.status) {
      init();
      if (splashScreenMeta.count > 100) {
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      }
    }
  }, [init, splashScreenMeta.count]);

  useEffect(() => {
    if (chatOperations.status) {
      getUserStatus();
      fetchUnsentLeads();
    }

    return () => {
      // dispatch(removeSelectedChat());
    };
  }, []);

  // get current user status
  const getUserStatus = () => {
    let userStatus = dispatch(
      getUserAsync({
        params: { id: user.profile.UserID }
      })
    );
    if (userStatus) {
      userStatus.then(res => {
        IncreaseProgress({
          text: 'Getting user status...',
          count: 5
        });
        let profile = JSON.parse(storage.getItem('Profile'));
        profile['Active'] = res.data.active;
        storage.setItem('Profile', profile);
        dispatch(setProfileToState());
      });
    }
  };

  // get all unsent leads list on component load
  const fetchUnsentLeads = () => {
    let sentLeads = dispatch(getUnsentLeadListAsync());
    if (sentLeads) {
      sentLeads.then(res => {
        IncreaseProgress({
          text: 'Getting user lead details...',
          count: 5
        });
      });
    }
  };

  return (loading || !chatOperations.status) && !window.RTC.hasLoaded ? (
    <SplashScreen chatOperations={chatOperations} />
  ) : (
    [children]
  );
};

export default ChatWrapper;
