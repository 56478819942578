import { selectHosts } from 'features/hosts/hostSlice';
import { camelCaseStringToNormalText } from 'helpers/generic.methods';
import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useSelector } from 'react-redux';

const hiddenFields = ['period'];
const Hover = ({ value }) => {
  let parameters = {};
  const { hosts } = useSelector(selectHosts);
  let hostList = [];

  if (value) {
    parameters = JSON.parse(value);
  }

  if (parameters && parameters.hostIds && hosts) {
    hostList = hosts.filter(h => parameters.hostIds.includes(h.id.toString()));
  }

  const mapParameters = (key, data) => {
    switch (key) {
      case 'period':
        return data?.periodDisplay || '-';
      case 'companyKeys':
        return data?.companyKeys?.toString()?.replace(/,/g, ', ') || '-';
      case 'hostIds':
        return hostList.length ? hostList.map(hl => hl.name).join(', ') : '-';
      default:
        return data[key].toString() || '-';
    }
  };

  const renderTooltip = props => (
    <Tooltip {...props}>
      {Object.keys(parameters).length ? (
        Object.keys(parameters).map((key, index) =>
          key !== 'periodDisplay' && !key.includes('ext-comp') ? (
            <div className={'text-left'} key={index}>
              <span>{camelCaseStringToNormalText(key, 'heading')}:</span>
              {mapParameters(key, parameters)}
            </div>
          ) : null
        )
      ) : (
        <div className={'text-left'}>
          <span>No Data Available</span>
        </div>
      )}
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="auto"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
      // show={value ? undefined : false}
    >
      <span>Hover to view</span>
    </OverlayTrigger>
  );
};

export default Hover;
