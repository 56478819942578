import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle
} from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { selectLangauge } from 'features/general/generalSlice';
import './tour.scss';
import {
  camelCaseStringToNormalText,
  camelize,
  getUserFromViewedUser,
  RandomNumber,
  updateViewedUser
} from 'helpers/generic.methods';
import CustomCarousel from 'components/CustomCarousel';
import { useTranslation } from 'react-i18next';
import storage from 'helpers/storage';
import { semverGreaterThan } from 'CacheBuster';
import { filterTourBasedOnPermission } from './helper';
import UilAngleLeft from '@iconscout/react-unicons/icons/uil-angle-left';
import { AppMetaProvider } from 'components/Sidebar/helper';

const FeatureTourModal = forwardRef((props, ref) => {
  const [releases, setReleases] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [dataArray, setDataArray] = useState([]);
  const [startTour, setStartTour] = useState(false);
  const language = useSelector(selectLangauge);
  const { t } = useTranslation();

  const userDetails = JSON.parse(storage.getItem('Profile'));
  const userDisplayName = userDetails?.DisplayName;
  const userName = t('company.tour.featureTourWelcome').replace(
    '<user>',
    userDisplayName
  );

  useImperativeHandle(ref, () => ({ dataArray }), [dataArray]);

  const exitTour = () => {
    props.onHide(false);
    setSelectedIndex(0);
    setTimeout(() => {
      setStartTour(false);
    }, 500);
  };

  useEffect(() => {
    fetchReleases();
  }, []);

  const getIndexes = (current, previous) => {
    setSelectedIndex(current);
  };

  const fetchReleases = () => {
    let { lastSeenVersion, isLogginBefore } = getUserFromViewedUser();
    fetch(`/tour/releases.json?id=${RandomNumber()}`)
      .then(response => response.json())
      .then(data => {
        if (data) {
          let lastSeenIndex = releases.findIndex(
            val => val === lastSeenVersion
          );
          let filtered = lastSeenVersion
            ? data.releases.filter((rl, index) => index > lastSeenIndex)
            : [global.AppInfo.version];
          setReleases(filtered);
          // auto open modal
          if (isLogginBefore) {
            if (
              semverGreaterThan(
                global.AppInfo.version,
                lastSeenVersion || '0.0.0'
              )
            ) {
              setTimeout(() => {
                props.onHide(true);
              }, 1000);
            }
          }
        }
      })
      .catch(err => {
        setReleases([global.AppInfo.version]);
        console.log(err);
      });
  };
  useEffect(() => {
    if (releases.length) {
      fetchTourJSON();
    }
  }, [releases]);

  const fetchTourJSON = () => {
    setDataArray([]);
    let data = props.currentRelease ? [props.currentRelease] : releases;
    data.forEach(release => {
      fetch(`/tour/${release}/tour.json?id=${RandomNumber()}`)
        .then(response => response.json())
        .then(data => {
          let filteredData = filterTourBasedOnPermission(data);
          console.log('filteredData', filteredData);
          setDataArray(prev => [
            ...prev,
            ...generateArray(release, filteredData)
          ]);
        })
        .catch(err => {
          console.log('fetch tour json', err);
        });
    });
    updateViewedUser({ lastSeenVersion: global.AppInfo.version });
  };

  const generateArray = (release, data) => {
    let temp = [];
    if (data?.['features']) {
      temp.push(
        ...data?.['features'].map(dt => ({
          ...dt,
          release
        }))
      );
    }
    return temp;
  };

  const getImageURL = (path = '') => {
    let theme = AppMetaProvider().GetCurrentTheme() || 'blue';
    let updatedPath = path.replace('<#=theme#>', theme);
    return updatedPath;
  };

  return (
    <div className="feature-modal-container">
      <Modal
        show={props.show}
        onHide={exitTour}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        scrollable={true}
        className={'feature-modal-container'}
      >
        <Modal.Body style={{ padding: '0px' }}>
          <div className="main-modal-body">
            <div className="">
              {startTour && dataArray.length ? (
                <div className="">
                  <div className="d-flex justify-content-between align-items-center px-3 pt-3">
                    <div className="">
                      <span className="badge badge-success custom-badge">
                        Version: {dataArray[selectedIndex]?.release || ''}
                      </span>
                    </div>
                    <label className="m-0 cursor-pointer" onClick={exitTour}>
                      {t('company.tour.btn7')}
                    </label>
                  </div>
                  <div className="slider-container">
                    <CustomCarousel
                      getIndexes={getIndexes}
                      data={dataArray}
                      ChildComp={({ data }) => {
                        return (
                          <>
                            <img
                              className="w-100"
                              src={getImageURL(data.image)}
                              style={{ borderRadius: '5px' }}
                              alt=""
                            />
                            <div
                              className="mt-3 d-flex align-items-center justify-content-center"
                              style={{ height: '80px' }}
                            >
                              <div className="text-center">
                                <div className="info-container">
                                  {data[language].heading}
                                </div>
                                {data[language].text.map((val, ind) => (
                                  <p key={ind} className="mb-0">
                                    {val}
                                  </p>
                                ))}
                              </div>
                            </div>
                          </>
                        );
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div className="whats-new-section ">
                  <div className="d-flex whats-new-container align-items-center">
                    <div className="main-container">
                      <div className="info-container">
                        {t('company.tour.whatsNew')}
                      </div>
                      <ul className="bullet-text">
                        {dataArray?.map((val, ind) => (
                          <li key={ind}>{val[language].heading}</li>
                        ))}
                      </ul>
                    </div>
                    <div className="title-image-container">
                      <div className="bg-image"></div>
                    </div>
                  </div>
                  <div className="text-container">
                    <div className="heading">{userName}</div>
                    <p className="info mb-0">
                      {t('company.tour.featureTourWelcomeText')}
                    </p>
                    <div className="disclaimer">
                      {t('company.tour.featureTourDisclaimer')}
                    </div>
                  </div>
                </div>
              )}
            </div>
            {!startTour ? (
              <div
                className={`d-flex align-items-center justify-content-end`}
                style={{ padding: '10px 25px 20px 25px ' }}
              >
                <div>
                  <Button
                    variant="outline-primary"
                    className="mx-1 button-padding"
                    onClick={exitTour}
                  >
                    {t('company.tour.btn6')}
                  </Button>
                  {dataArray.length ? (
                    <Button
                      variant="primary"
                      className="mx-1 button-padding"
                      // onClick={() => updateSelectedIndex(0)}
                      onClick={() => setStartTour(true)}
                    >
                      {t('company.tour.btn2')}
                    </Button>
                  ) : null}
                </div>
              </div>
            ) : selectedIndex == dataArray.length - 1 ? (
              <div className="done-btn">
                <Button
                  variant="primary"
                  className="mx-1 button-padding"
                  onClick={exitTour}
                >
                  {t('company.tour.btn8')}
                </Button>
              </div>
            ) : selectedIndex == 0 && startTour ? (
              <div className="back-btn cursor-pointer">
                <UilAngleLeft onClick={() => setStartTour(false)} size={'40'} />
              </div>
            ) : null}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
});

export default FeatureTourModal;
