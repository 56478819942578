import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import Image from 'react-bootstrap/Image';
import { selectLogin } from 'features/auth/authSlice';
import { useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import './styles.scss';
import Logo from 'components/Sidebar/Logo';
import { AppMetaProvider } from 'components/Sidebar/helper';
const NotFound = ({
  errorExpression = 'Ooops!!',
  errorCode = 404,
  message = 'THAT PAGE DOESN’T EXIST OR IS UNAVAILABLE.',
  goBack = false
}) => {
  const isAuthenticated = useSelector(selectLogin).isAuthenticated;
  const history = useHistory();
  const { isWhiteLabeled } = AppMetaProvider().GetStaticMeta;
  return (
    <div className="container text-center">
      <div className="row d-flex full-height align-items-center justify-content-center">
        <div>
          <div
            className={`${
              isWhiteLabeled ? 'logo-onroll' : 'logo-apex'
            } d-flex justify-content-center logo404`}
          >
            <Link to="/">
              <Logo />
            </Link>
          </div>
          <h4 className="mb-0 fw-600 mt-64">{errorExpression}</h4>
          <h1 className="fz-200 text-danger-1 fw-300 my-24">{errorCode}</h1>
          <h5 className="fw-500 mb-lg-64 mb-48">{message}</h5>
          {goBack ? (
            <Button className="btn btn-primary btn-lg" onClick={history.goBack}>
              Go Back
            </Button>
          ) : (
            <Link
              className="btn btn-primary btn-lg"
              to={isAuthenticated ? '/home' : '/login'}
            >
              Back to {isAuthenticated ? 'Home' : 'Login'}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default NotFound;
