import React from 'react';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import ModalInSpecificContainer from 'components/ModalInSpecificContainer';

const RestrictionModal = ({ heading, status, message }) => {
  return (
    <ModalInSpecificContainer
      heading={heading}
      open={status}
      showCloseIcon={false}
      showSaveBtn={false}
    >
      <List>
        <ListItem button>
          <ListItemText primary={message} secondary="" />
        </ListItem>
      </List>
    </ModalInSpecificContainer>
  );
};

export default RestrictionModal;
