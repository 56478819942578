import React, { useEffect, useState } from 'react';
import GoogleAnalyticsTracking from 'components/company/sections/Tracking-GoogleAnalytics';
import FacebookPixelTracking from 'components/company/sections/Tracking-FacebookPixelTracking';
import CustomTracking from 'components/company/sections/Tracking-CustomTracking';
import { renderCompanyTrackingTypes } from 'components/company/sections/tracking-logic';
import { useTranslation } from 'react-i18next';
import ToggleMenuButton from 'helpers/ui-controls/ui-components/ToggleMenuButton';

const Tracking = ({ company, permissionEdit, clickHandlerMenu }) => {
  const [trackingFlags, setTrackingFlags] = useState({});
  const [companyTrackingState, setCompanyTrackingState] = useState(company);
  // The flags serverSideGoogleTracking/clientSideGoogleTracking they become True on some logical conditions
  const { t } = useTranslation();
  const handleCompanyReflection = _company => {
    setCompanyTrackingState(_company);
  };

  useEffect(() => {
    const trackingInfo = renderCompanyTrackingTypes(companyTrackingState);
    setTrackingFlags(trackingInfo);
  }, [companyTrackingState]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      <div className="page-section">
        <ToggleMenuButton
          heading={t('company.trackingIntegration.heading1')}
          clickHandlerMenu={clickHandlerMenu}
        />
        <GoogleAnalyticsTracking
          permissionEdit={permissionEdit}
          company={companyTrackingState}
          trackingFlags={trackingFlags}
          reflectObject={handleCompanyReflection}
        />
      </div>
      <div className="page-section">
        <FacebookPixelTracking
          permissionEdit={permissionEdit}
          company={companyTrackingState}
          trackingFlags={trackingFlags}
          reflectObject={handleCompanyReflection}
        />
      </div>
      <div className="page-section">
        <CustomTracking
          permissionEdit={permissionEdit}
          company={companyTrackingState}
          trackingFlags={trackingFlags}
        />
      </div>
      <hr />
    </>
  );
};

export default Tracking;
