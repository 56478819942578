import React from 'react';
import { useTranslation } from 'react-i18next';
import { timeZoneData } from 'app/TimeZoneData';
import ToggleToolTip from 'helpers/ui-controls/toggleToolTip';
import ToggleMenuButton from 'helpers/ui-controls/ui-components/ToggleMenuButton';
import SimpleLabelTextBox from 'helpers/ui-controls/ui-components/SimpleLabelTextbox';
import DropDownEditable from 'helpers/ui-controls/DropDownEditable';
import { Enums, FieldsEnum } from 'helpers/dropdown-enums';
import TimeZoneField from 'helpers/ui-controls/TimeZoneField';
import TimePicker from 'helpers/ui-controls/ui-components/TimePicker';
import HostListComp from './HostListComp';
import CustomAutoCompleteFilter from 'pages/portal/dashboard/filters/custom-auto-complete-filter';
import PeriodComp from './PeriodComp';
import SectionHeader from 'helpers/ui-controls/ui-components/SectionHeader';
import { makeStyles, TextField } from '@material-ui/core';
import { generateDropDownOptions } from './helper';
import moment from 'moment';
import {
  AgentOperatorOption,
  RemoveCharAndToCamelCase,
  ReportNotes
} from './constant';

const styles = {
  root: {
    fontFamily: 'Lato',
    color: 'var(--darkgray-1)',
    background: 'white'
  },
  inputRoot: {
    fontFamily: 'Lato',
    color: 'var(--darkgray-2) !important'
  }
};
const useStyle = makeStyles(theme => ({
  root: {
    // marginRight: 8,
    color: 'var(--darkgray-1)',
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'var(--darkgray-2)' // (default alpha is 0.38)
    }
  }
}));

const ParametersComp = ({
  form = {},
  showHeading = true,
  showNote = true,
  clickHandlerMenu,
  handleChange,
  hostList = [],
  selectedHosts = [],
  handleHosts,
  companies,
  setCompanies,
  loadingOptions,
  companiesOption,
  agentsOptions = [],
  handleTextChange,
  isCreate = false,
  keyPrefix = '',
  editable = true,
  setEditable,
  loading,
  headerSection = false,
  ReportTypes = [],
  rType,
  setRtype
}) => {
  const classes = useStyle();
  const { t } = useTranslation();
  // generate fields by field id
  const { values: report, errors } = form;
  const getFieldByEnum = (id, key, templateData = {}) => {
    // console.log(templateData);
    switch (id) {
      case FieldsEnum.Name:
        return (
          <SimpleLabelTextBox
            key={keyPrefix + key}
            heading={t('company.reportDetails.parameters.name.label')}
            field="name"
            dataProperty={report['name']}
            editable={editable}
            handleChange={handleChange}
            handleBlur={form.handleBlur}
            error={form.touched.name && errors['name']}
          />
        );
      case FieldsEnum.Email:
        return (
          <SimpleLabelTextBox
            key={keyPrefix + key}
            heading={t(
              'company.reportDetails.parameters.notificationEmail.label'
            )}
            field="email"
            dataProperty={report['email']}
            editable={editable}
            handleChange={handleChange}
            handleBlur={form.handleBlur}
            error={form.touched.email && errors['email']}
          />
        );
      case FieldsEnum.Webhook:
        return (
          <SimpleLabelTextBox
            key={keyPrefix + key}
            heading={t(
              'company.reportDetails.parameters.notificationEndPoint.label'
            )}
            field="webhook"
            dataProperty={report['webhook']}
            editable={editable}
            handleChange={handleChange}
          />
        );
      case FieldsEnum.Time_zone:
        return (
          <TimeZoneField
            key={keyPrefix + key}
            heading={t('company.reportDetails.parameters.timeZone.label')}
            field="timezone"
            dataProperty={
              report['timezone']
                ? timeZoneData.find(item => item.id === report['timezone']).id
                : ''
            }
            editable={editable}
            handleChange={handleChange}
          />
        );
      case FieldsEnum.Period:
        return (
          <PeriodComp
            editable={editable}
            key={keyPrefix + key}
            keyID={keyPrefix + key}
            report={report}
            handleChange={handleChange}
          />
        );
      case FieldsEnum.Add_Report_Attachment:
        return (
          <ToggleToolTip
            key={keyPrefix + key}
            heading={t('company.reportDetails.parameters.emailReport.label')}
            className="custom-switch-btn"
            field="addReportAttachment"
            flag={report['addReportAttachment']}
            editable={editable}
            handleChange={handleChange}
          />
        );
      case FieldsEnum.Group_By_Company:
        return (
          <ToggleToolTip
            key={keyPrefix + key}
            heading={t('company.reportDetails.parameters.groupByCompany.label')}
            className="custom-switch-btn"
            field="groupByCompany"
            flag={report['groupByCompany']}
            editable={editable}
            handleChange={handleChange}
          />
        );
      case FieldsEnum.Company_Keys:
        return (
          <CustomAutoCompleteFilter
            editable={editable}
            liStyles={{
              alignSelf: 'flex-start'
            }}
            name={'companyKeys'}
            title={t('company.analytics.companyKey')}
            setFunction={(data, e) => handleChange(e, 'companyKeys', data)}
            // setFunction={setCompanies}
            handleTextChange={handleTextChange}
            options={companiesOption}
            loading={loadingOptions}
            selected={companies}
            isFilterWrapper={false}
            variant="standard"
            key={keyPrefix + key + rType}
            handleBlur={form.handleBlur}
            error={form.touched.companyKeys && errors['companyKeys']}
          />
        );
      case FieldsEnum.Hosts:
        return (
          <HostListComp
            editable={editable}
            disabled={companies.length}
            keyProp={keyPrefix + key}
            handleHosts={handleHosts}
            selectedHosts={selectedHosts}
            hostList={hostList}
            key={keyPrefix + key}
          />
        );
      case FieldsEnum.Aggregate:
        return (
          <DropDownEditable
            key={keyPrefix + key}
            // heading={t('company.reportDetails.parameters.reportType.label')}
            heading={'Aggregate'}
            field="aggregate"
            dataProperty={report['aggregate'] || ''}
            editable={editable}
            handleChange={handleChange}
            materialFields
            options={generateDropDownOptions([
              { id: 1, name: 'Average' },
              { id: 2, name: 'Max' }
            ])}
          />
        );
      case FieldsEnum.Group:
        return (
          <DropDownEditable
            key={keyPrefix + key}
            // heading={t('company.reportDetails.parameters.reportType.label')}
            heading={'Group By'}
            field="group"
            dataProperty={report['group'] || '-'}
            editable={editable}
            handleChange={handleChange}
            materialFields
            options={generateDropDownOptions(
              templateData.id === Enums.ReportType.Stats
                ? [
                    { id: 0, name: 'None' },
                    { id: 3, name: 'Day' },
                    { id: 2, name: 'Hour' },
                    { id: 1, name: 'Minute' }
                  ]
                : templateData.id === Enums.ReportType.Profile_Response
                ? [
                    // { id: 0, name: 'None' },
                    { id: 2, name: 'Hour' },
                    { id: 3, name: 'Day' },
                    { id: 4, name: 'Month' }
                  ]
                : templateData.id === Enums.ReportType.Billable_Leads
                ? [
                    { id: 0, name: 'None' },
                    { id: 3, name: 'Day' },
                    { id: 4, name: 'Month' },
                    { id: 5, name: 'Year' }
                  ]
                : []
            )}
          />
        );

      case FieldsEnum.Agent_Id:
        return (
          <DropDownEditable
            key={keyPrefix + key}
            // heading={t('company.reportDetails.parameters.reportType.label')}
            heading={'Agent Name'}
            field="agentId"
            dataProperty={report['agentId'] || '-'}
            editable={editable}
            handleChange={handleChange}
            materialFields
            options={generateDropDownOptions(agentsOptions)}
          />
        );
      case FieldsEnum.Picked_Up_By_Username:
        return (
          <DropDownEditable
            key={keyPrefix + key}
            // heading={t('company.reportDetails.parameters.reportType.label')}
            heading={'Agent Name'}
            field="pickedUpByUsername"
            dataProperty={report['pickedUpByUsername'] || '-'}
            editable={editable}
            handleChange={handleChange}
            materialFields
            options={generateDropDownOptions(agentsOptions)}
          />
        );
      case FieldsEnum.Start_Date:
        return (
          <li key={keyPrefix + key}>
            {templateData.id ===
            Enums.ReportType.Invitation_Code_Installed_Report ? (
              <TimePicker
                key={keyPrefix + key}
                field={'startDate'}
                type="date"
                showEmpty={false}
                disabled={!editable}
                dataProperty={moment(report['startDate']).format('YYYY-MM-DD')}
                heading={'Start Date'}
                handleChange={(e, key, value) =>
                  handleChange(e, key, moment(value).valueOf())
                }
                handleBlur={form.handleBlur}
                error={form.touched.startDate && errors['startDate']}
              />
            ) : (
              <TextField
                className="form-group"
                // style={{ marginBottom: '2rem' }}
                classes={classes}
                id="datetime-local"
                label={<span style={styles.root}>Start Date</span>}
                type="datetime-local"
                disabled={!editable}
                value={
                  report['startDate']
                    ? getFormatedValue(report['startDate'])
                    : ''
                }
                onChange={e =>
                  handleChange(e, 'startDate', moment(e.target.value).valueOf())
                }
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
              />
            )}
          </li>
        );
      case FieldsEnum.End_Date:
        return (
          <li key={keyPrefix + key}>
            {templateData.id ===
            Enums.ReportType.Invitation_Code_Installed_Report ? (
              <TimePicker
                key={keyPrefix + key}
                field={'endDate'}
                type="date"
                disabled={!editable}
                dataProperty={
                  report['startDate']
                    ? moment(report['startDate']).format('YYYY-MM-DD')
                    : ''
                }
                heading={'End Date'}
                handleChange={(e, key, value) =>
                  handleChange(e, key, moment(value).valueOf())
                }
              />
            ) : (
              <TextField
                className="form-group"
                // style={{ marginBottom: '2rem' }}
                classes={classes}
                fullWidth
                id="datetime-local"
                label={<span style={styles.root}>End Date</span>}
                type="datetime-local"
                disabled={!editable}
                // defaultValue="2017-05-24T10:30"
                value={
                  report['endDate'] ? getFormatedValue(report['endDate']) : ''
                }
                // onChange={e => handleChange(e, 'endDate', e.target.value)}
                onChange={e =>
                  handleChange(e, 'endDate', moment(e.target.value).valueOf())
                }
                InputLabelProps={{
                  shrink: true
                }}
              />
            )}
          </li>
        );
      case FieldsEnum.Agent_Operator_Filter:
        return (
          <DropDownEditable
            key={keyPrefix + key}
            // heading={t('company.reportDetails.parameters.reportType.label')}
            heading={'Select Agent or Operator'}
            field="agentOperatorFilter"
            dataProperty={report['agentOperatorFilter'] || '-'}
            editable={editable}
            handleChange={handleChange}
            materialFields
            options={generateDropDownOptions(AgentOperatorOption)}
          />
        );
      case FieldsEnum.Minimum:
        return (
          <SimpleLabelTextBox
            key={keyPrefix + key}
            heading={'Min Percentage'}
            field="minimum"
            dataProperty={report['minimum']}
            editable={editable}
            handleChange={handleChange}
            handleBlur={form.handleBlur}
            error={form.touched.minimum && errors['minimum']}
            type="number"
          />
        );
      case FieldsEnum.Maximum:
        return (
          <SimpleLabelTextBox
            key={keyPrefix + key}
            heading={'Max Percentage'}
            field="maximum"
            dataProperty={report['maximum']}
            editable={editable}
            handleChange={handleChange}
            handleBlur={form.handleBlur}
            error={form.touched.maximum && errors['maximum']}
            type="number"
          />
        );
      case FieldsEnum.Limit:
        return (
          <SimpleLabelTextBox
            key={keyPrefix + key}
            heading={'Pause Count'}
            field="limit"
            dataProperty={report['limit']}
            editable={editable}
            handleChange={handleChange}
            handleBlur={form.handleBlur}
            error={form.touched.limit && errors['limit']}
            // type="number"
          />
        );
      case FieldsEnum.Threshold:
        return (
          <SimpleLabelTextBox
            key={keyPrefix + key}
            heading={'Threshold'}
            field="threshold"
            dataProperty={report['threshold']}
            editable={editable}
            handleChange={handleChange}
            error={errors['threshold']}
            type="number"
          />
        );
      default:
        return {};
    }
  };

  const getFormatedValue = date => {
    let tempDate = moment(date).format('YYYY-MM-DDTHH:mm');
    return tempDate;
  };

  const fieldsRender = ReportTypes.filter(rt => rt.id === Number(rType))[0];

  return (
    <>
      <p className="fz-12 text-warning-2 fw-600 p-3 mb-0 warning-box">
        {t(
          `company.reportDetails.notes.${RemoveCharAndToCamelCase(
            fieldsRender?.name,
            ' '
          )}`
        )}
        {/* {  t(ReportNotes[RemoveCharAndToCamelCase(fieldsRender?.name, ' ')])} */}
      </p>
      <div className={`${showHeading ? 'page-section mt-0' : 'mt-3'}`}>
        {showHeading && (
          <ToggleMenuButton
            heading={t('company.reportDetails.heading1')}
            clickHandlerMenu={clickHandlerMenu}
          />
        )}
        <section>
          {headerSection ? (
            <SectionHeader
              loading={loading}
              disabled={loading}
              editable={editable}
              handleEditableState={setEditable}
            />
          ) : null}
          <div className="section-body">
            <ul className="editable-fields-lists">
              <DropDownEditable
                key={keyPrefix + 0}
                heading={t('company.reportDetails.parameters.reportType.label')}
                field="reportId"
                dataProperty={rType}
                editable={isCreate}
                handleChange={handleChange}
                materialFields
                options={generateDropDownOptions(ReportTypes)}
              />

              {/* render fields according to template */}
              {fieldsRender && Object.keys(fieldsRender).length
                ? fieldsRender.allowedFields.map((field, index) =>
                    getFieldByEnum(field, index + 1, fieldsRender)
                  )
                : null}
            </ul>
          </div>
        </section>
      </div>
    </>
  );
};

export default ParametersComp;
