import moment from 'moment';
import React from 'react';
import { Form } from 'react-bootstrap';
import './styles.scss';

const TimePicker = ({
  heading = 'time picker',
  disabled = false,
  dataProperty,
  label = 'time',
  type = 'time',
  id,
  field,
  handleChange,
  format = 'dd/mm/yyyy',
  isFormat = false,
  showEmpty = true,
  customStyles = {},
  handleBlur,
  error = ''
}) => (
  <div
    className={`form-group ${dataProperty ? 'has-value' : ''} ${
      error ? 'is-invalid' : ''
    }`}
  >
    <Form.Control
      as="input"
      disabled={disabled}
      type={type}
      value={
        dataProperty !== ':undefined'
          ? dataProperty
            ? isFormat
              ? moment(new Date(dataProperty)).format('YYYY-MM-DD')
              : dataProperty
            : showEmpty
            ? ''
            : undefined
          : '--:--'
      }
      onChange={e => {
        handleChange(
          e,
          field,
          isFormat ? moment(e.target.value).format(format) : e.target.value
        );
      }}
      id={id}
      name={field}
      aria-label={label}
      style={customStyles}
      onBlur={handleBlur}
      className="lined with-label time-picker-styles"
    />
    <Form.Label className="label-movable">{heading}</Form.Label>
    <p style={{ position: 'absolute' }} className="text-red-1 field-error">
      {error}
    </p>
  </div>
);

export default TimePicker;
