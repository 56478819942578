export const ChatsType = [
  {
    key: 'INC',
    title: 'Incoming Chats',
    chats: 'incomingChatList',
    showActionBtns: true,
    defaultActiveKey: '0',
    eventKey: '0',
    roomType: 3
  },
  {
    key: 'ACT',
    title: 'Active Chats',
    chats: 'activeChatList',
    showActionBtns: false,
    defaultActiveKey: '1',
    eventKey: '1',
    roomType: 1
  },
  {
    key: 'ALL',
    title: 'All Chats',
    chats: 'allChatList',
    showActionBtns: false,
    defaultActiveKey: '0',
    eventKey: '2',
    roomType: 2
  },
  {
    key: 'SHL',
    title: 'Shelved Chats',
    chats: 'shelvedChatList',
    showActionBtns: false,
    defaultActiveKey: '3',
    eventKey: '3',
    roomType: 4
  }
  // {
  //   key: 'UL',
  //   title: 'Unsent Leads',
  //   chats: 'unsentLeadList',
  //   showActionBtns: false,
  //   defaultActiveKey: '0',
  //   eventKey: '4'
  // }
];

export const UnsentChatList = {
  key: 'UL',
  title: 'Unsent Leads',
  chats: 'unsentLeadList',
  showActionBtns: false,
  defaultActiveKey: '0',
  eventKey: '4',
  roomType: 5
};
