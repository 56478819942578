export const en_crm = {
  heading1: 'CRM',
  heading2: 'Basic details',
  crmProviders: {
    label: 'CRM Providers',
    info: 'Select the CRM from this list that you would like to send leads to.'
  },
  crmUniqueID: {
    label: 'CRM Unique ID',
    info:
      'To find the CRM Unique ID:<br /><br />in SalesForce: Setup=>Administration Setup=>Company Profile=>Update your company information=>Salesforce.com Organization ID<br /><br />in InfusionSoft: http://kb.infusionsoft.com/index.php?/article/AA-00442/0/How-do-I-enable-the-Infusionsoft-API.html <br /><br />in Leads360: The "Client" parameter for the integration url (&Client={this value}&)<br /><br />in i360: The email address provided by i360<br /><br />in RL Convert: This is not necessary for RL companies using RL Convert because we already have the company key; just put a "0" here. If somehow that is *not* the case in the future, talk to Jerod<br /><br />in Legal Intake: Set this field to "0"<br /><br />in Smart Advocate: Set this field to the target Smart Advocate email address<br /><br />This field is required for integration with CRMs.'
  },
  target_dbid: {
    label: 'Target DBID',
    info: 'This is the unique target database id.'
  },
  url: {
    label: 'Url',
    info: 'This is the unique url for authentication.'
  },
  consumerKey: {
    label: 'Consumer Key',
    info: 'This is the unique client id.'
  },
  consumerSecret: {
    label: 'Consumer Secret',
    info: 'This is the unique client secret code.'
  },
  securityToken: {
    label: 'Security Token',
    info: 'This is the unique security token.'
  },
  username: {
    label: 'User Name',
    info: 'This is the username.'
  },
  password: {
    label: 'Password',
    info: 'This is the password.'
  },
  target_domain: {
    label: 'Target Domain',
    info: 'This is the unique target domain address.'
  },
  app_token: {
    label: 'Application Token',
    info: 'This is the application token.'
  },
  intakeSource: {
    label: 'Intake Source',
    info: 'This value will be variable based on the site the chat comes from.'
  },
  leadTypeForCrm: {
    label: 'Lead Type For CRM',
    info: 'The type of leads to send to the CRM.'
  },
  lidogeneratorId: {
    label: 'Lido Generator ID',
    info:
      'This is a unique ID number that identifies the Captorra client’s organization to which you are posting.'
  },
  endPointUrl: {
    label: 'End Point URL',
    info:
      'This is the unique end point Url  for each client who wants to use General.'
  },
  hAPIKey: {
    label: 'HubSpot API Key',
    info: 'This is the unique HubSpot API key for every client.'
  },
  hubSpotUrl: { label: 'HubSpot URL', info: 'The Url to post data.' },
  _entityForSales: {
    label: 'Entity For Sales',
    info:
      'Enter the entity name where you want to receive Sales lead. Leave blank if it does not apply.'
  },
  _entityForService: {
    label: 'Entity For Service',
    info:
      'Enter the entity name where you want to receive Service lead. Leave blank if it does not apply.'
  },
  _entityForOthers: {
    label: 'Entity For Others',
    info:
      'Enter the entity name where you want to receive Other lead. Leave blank if it does not apply.'
  },
  contentType: {
    label: 'Content Type',
    info: 'Select JSON or URL Encoded as Content Type.'
  },
  requestMethod: {
    label: 'Request Method',
    info: 'Select GET or POST as a Method Type.'
  },
  addHeaders: {
    label: 'Add Headers',
    info:
      'Enable this option to add multiple headers for the specific company.'
  },
  campaignId: {
    label: 'Campaign ID',
    info: 'The id of the campaign in which the leads will be created'
  },
  provider: {
    label: 'Provider',
    info: 'The integration provider for Leads360; something like ReachLocalEDU'
  },
  appname: {
    label: 'Application Name',
    info:
      'The first part of the url for the target company (http://{name}.infusionsoft.com).'
  },
  actionsequence: {
    label: 'Action Sequence ID',
    info:
      'The id of the action sequence to run when the contact has been created.'
  },
  publicKey: {
    label: 'Public Key',
    info: 'The public key provided by Legal Intake'
  },
  privateKey: {
    label: 'Private Key',
    info: 'The private key provided by Legal Intake'
  },
  enableOAuth2: { label: 'Enable OAuth2', info: 'Enable OAuth2' },
  referrer: {
    label: 'Referrer',
    info:
      'A single GUID (Globally Unique Identifier) that identifies the group or site.'
  },
  serviceUrl: {
    label: 'Service Url',
    info:
      'Get your Organization Service Url in Settings > Customization > Development Resources.'
  },
  domainName: { label: 'Domain Name', info: 'This is an on-premises domain' },
  entity: { label: 'Entity', info: 'This is an on-premises domain' },
  crmTemplate: {
    label: 'Crm Template',
    info: 'Here you can set the Lead Xml/Json Template'
  },
  sendTemplateAsEmailBody: {
    label: 'Send Template As Email Body',
    info: 'This flag will allow to send an Xml/Json format lead as email body'
  },
  crmEmailSubject: {
    label: 'Email Subject',
    info:
      'The subject line for Email, if blank the default value is Lead Email <Content-Type>.'
  }
};
export default en_crm;
export { default as fr_crm } from '../fr-FR-Dictionary/fr-crm';
export { default as sp_crm } from '../sp-SP-Dictionary/sp-crm';
