import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const EndChatComfirmationModal = ({ showModal, onHide, onSubmit, loading }) => {
  return (
    <Modal
      show={showModal}
      onHide={onHide}
      size="md"
      keyboard={false}
      id="endChatModal"
      dialogClassName="endChatModal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <Modal.Title id="contained-modal-title-vcenter">
          End Chat Confirmation Modal
        </Modal.Title>
        <div>
          <p>Are you want to end this chat?</p>
        </div>
      </Modal.Body>
      <Modal.Footer className="user-modal-footer">
        <Button
          type="button"
          variant="outline-primary"
          className="passBtn btn-md btn-flex float-right"
          onClick={onSubmit}
          disabled={loading}
        >
          <span>Yes</span>
          {loading && <i className="uil uil-spinner spinner ml-2"></i>}
        </Button>
        <Button
          type="button"
          variant="outline-primary"
          className="passBtn btn-md btn-flex float-right"
          onClick={onHide}
        >
          <span>No</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EndChatComfirmationModal;
