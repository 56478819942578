import React, { useEffect, useRef, useState } from 'react';
import { Button, Popover, Overlay, Form } from 'react-bootstrap';
import { UilPlus, UilFilter } from '@iconscout/react-unicons';
import { camelize } from 'helpers/generic.methods';
import './dashboard.scss';
import { jsonParser } from 'helpers/generic.methods';
import { element } from 'prop-types';
import { quickStatsOptions } from './filters/constants';
import { useTranslation } from 'react-i18next';
export const quickstatsOptions = Object.freeze([
  { id: 1, key: 'Total Visits', value: false },
  { id: 2, key: 'Total Chats', value: false },
  { id: 3, key: 'Total Leads', value: false },
  { id: 4, key: 'Total Billable Leads', value: false },
  { id: 5, key: 'Click Through Rate', value: false },
  { id: 6, key: 'Lead Conversion Rate', value: false },
  { id: 7, key: 'Blank Chats', value: false },
  { id: 8, key: 'Active Call Connect', value: false },
  { id: 9, key: 'Call Connect Attempts', value: false },
  { id: 10, key: 'Call Connect Duration', value: false },
  { id: 11, key: 'Active Chats', value: false }
]);
const QuickPopOver = ({ onChangefilters, selected }) => {
  const [show, setShow] = useState(false);
  const [selectAllOptions, setSelectAllOptions] = useState(false);
  const target = useRef(null);
  const [options, setOptions] = useState(quickStatsOptions);

  useEffect(() => {
    let opt = options.map(op => {
      return { ...op, value: selected.includes(op.id) };
    });
    if (opt.every(element => element.value)) {
      setSelectAllOptions(true);
    }
    setOptions(opt);
  }, [selected]);
  const handleOnChangeSelectAllOption = e => {
    setSelectAllOptions(e.target.checked);
    let allOptions = options;
    allOptions.forEach(element => {
      element.value = e.target.checked;
    });
    setOptions(allOptions);
  };

  const { t } = useTranslation();

  const handleQuickStatsParentOption = (e, optionKey) => {
    let temp = [...options];

    if (optionKey == 'Lead Conversion Rate') {
      temp.map((t, index) => {
        if (
          t.key == 'Lead Conversion Rate' ||
          t.key == 'Total Chats' ||
          t.key == 'Total Leads'
        ) {
          t.value = e.target.checked;
        }
      });
    }
    if (optionKey == 'Click Through Rate') {
      temp.map((t, index) => {
        if (
          t.key == 'Click Through Rate' ||
          t.key == 'Total Chats' ||
          t.key == 'Total Visits'
        ) {
          t.value = e.target.checked;
        }
      });
    }
    setOptions(temp);
    if (temp.every(element => element.value)) {
      setSelectAllOptions(true);
    } else {
      setSelectAllOptions(false);
    }
  };
  const handleQuickStatsChildOption = (e, optionKey) => {
    let temp = [...options];
    if (optionKey == 'Total Chats') {
      temp.map(t => {
        if (
          t.key == 'Lead Conversion Rate' ||
          t.key == 'Click Through Rate' ||
          t.key == 'Total Chats'
        ) {
          t.value = e.target.checked;
        }
      });
    }
    if (optionKey == 'Total Visits') {
      temp.map(t => {
        if (t.key == 'Click Through Rate' || t.key == 'Total Visits') {
          t.value = e.target.checked;
        }
      });
    }
    if (optionKey == 'Total Leads') {
      temp.map(t => {
        if (t.key == 'Lead Conversion Rate' || t.key == 'Total Leads') {
          t.value = e.target.checked;
        }
      });
    }
    setOptions(temp);
    if (temp.every(element => element.value)) {
      setSelectAllOptions(true);
    } else {
      setSelectAllOptions(false);
    }
  };
  const handleOnChange = (e, ind, optionKey) => {
    let parentOptions = ['Click Through Rate', 'Lead Conversion Rate'];
    let childOptions = ['Total Visits', 'Total Chats', 'Total Leads'];
    if (parentOptions.find(val => val == optionKey) && e.target.checked) {
      handleQuickStatsParentOption(e, optionKey);
      return;
    }
    if (childOptions.find(val => val == optionKey) && !e.target.checked) {
      handleQuickStatsChildOption(e, optionKey);
      return;
    }
    let temp = options.map((t, index) => {
      let obj = { ...t };
      if (index === ind) obj.value = e.target.checked;
      return obj;
    });
    setOptions(temp);
    if (temp.every(element => element.value)) {
      setSelectAllOptions(true);
    } else {
      setSelectAllOptions(false);
    }
    // onChangefilters(temp.filter(t => t.value));
  };

  //   const onFilterClear = (pushBackData = true) => {
  //     let temp = features.map(t => {
  //       return { ...t, value: false };
  //     });
  //     setOptions(temp);
  //     if (pushBackData) onChangefilters([]);
  //   };
  const handleShow = val => {
    setShow(val);
  };

  const applyFilter = () => {
    onChangefilters(options.filter(t => t.value));
    setShow(false);
  };

  return (
    <>
      <Button
        className="btn-addmore"
        variant="primary"
        ref={target}
        onClick={handleShow.bind(this, !show)}
      >
        <UilPlus style={{ height: 15, width: 15 }} />
        {t('company.analytics.addMore')}
      </Button>
      <Overlay
        target={target.current}
        show={show}
        placement="bottom"
        onHide={handleShow.bind(this, false)}
        rootClose={true}
      >
        {({ placement, arrowProps, show: _show, popper, ...props }) => (
          <div
            {...props}
            style={{
              backgroundColor: 'white',
              padding: '2px 10px',
              border: '1px solid lightgrey',
              color: 'white',
              borderRadius: 5,
              ...props.style
            }}
          >
            <Form.Check
              custom
              type="checkbox"
              name="selectAllOptions"
              id="selectAllOptions"
              label={t('company.analytics.selectAll')}
              onChange={e => handleOnChangeSelectAllOption(e)}
              checked={selectAllOptions}
              style={{
                paddingTop: '10px',
                color: '#454F5B',
                fontSize: '13px',
                fontWeight: '600',
                borderBottom: '1px solid #454f5b',
                paddingBottom: '5px'
              }}
            />
            {options.map((option, index) => (
              <Form.Check
                key={index}
                custom
                type="checkbox"
                name="checkbox"
                id={option.key + index}
                label={t(`company.analytics.${camelize(option.key)}`)}
                onChange={e => handleOnChange(e, index, option.key)}
                checked={option.value}
                style={{
                  paddingTop: '10px',
                  color: '#454F5B',
                  fontSize: '13px',
                  fontWeight: '600'
                }}
              />
            ))}
            <Button
              className="btn-apply-filter"
              variant="primary"
              title={t('company.analytics.filters')}
              onClick={applyFilter}
            >
              {t('company.analytics.apply')}
            </Button>
          </div>
        )}
      </Overlay>
    </>
  );
};

export default QuickPopOver;
