import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import useViewport from 'helpers/viewport-hook';
import UilAngleRightB from '@iconscout/react-unicons/icons/uil-angle-right-b';
import UilAngleLeftB from '@iconscout/react-unicons/icons/uil-angle-left-b';
import './style.scss';
import WidgetSettingOptions from './widget-options';
import WidgetEditor from './widget-editor';
import { MemoryRouter, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSidebarOpenState,
  setSidebarOpen
} from 'features/widget-editor/widgetEditorSlice';
import SidebarRight from 'components/Sidebar/sidebar-right';
import Companies from './companies';
const WidgetSetting = props => {
  const [selectedMenu, setSelectedMenu] = useState('chat-widget');
  const screen = useViewport();
  const isSidebarOpen = useSelector(getSidebarOpenState);
  const dispatch = useDispatch();
  const [openColapse, setOpenColapse] = useState(false);
  const [companiesCount, setCompaniesCount] = useState(null);
  // const [selectedCompanies, setSelectedCompanies] = useState([]);
  const handleSelect = menuKey => {
    setSelectedMenu(menuKey);
    if (screen.xs) {
      sidebarHandler(!isSidebarOpen);
    }
  };

  const sidebarHandler = isOpen => {
    dispatch(setSidebarOpen(isOpen));
  };

  return (
    <>
      <Container fluid className="p-3 widget-setting-container">
        <MemoryRouter>
          <Row
            className={screen.xs && 'mobile-view'}
            style={{ height: 'calc(100vh - 32px)', margin: '0' }}
          >
            <Col
              md={3}
              className={`p-0 widget-options-container ${
                isSidebarOpen && 'show-opt-container'
              }`}
            >
              <h2 className="ml-3 mt-3 text-primary">Settings</h2>
              <WidgetSettingOptions
                selectedMenu={selectedMenu}
                handleSelect={handleSelect}
              />
            </Col>
            <Col md={9} className="widget-editor-container">
              {/* <Switch>{HandlePageRoutes(widgetEditorRoutes)}</Switch> */}
              <WidgetEditor
                companiesCount={companiesCount}
                handleCollipseable={setOpenColapse}
              />
            </Col>
          </Row>
        </MemoryRouter>
      </Container>
      <SidebarRight
        handleOpen={status => {
          setOpenColapse(status);
        }}
        hideButtons={true}
        open={openColapse}
        defaultWidth={screen.xs ? 90 : 50}
      >
        <Companies getCompaniesCount={count => setCompaniesCount(count)} />
      </SidebarRight>
    </>
  );
};

export default WidgetSetting;

const widgetEditorRoutes = [
  {
    path: '/',
    name: 'appearance-settings',
    title: 'Appearance Settings',
    component: props => <WidgetEditor {...props} />,
    // icon: <UilSetting />,
    exact: true
  }
];
