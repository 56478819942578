import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import SimpleLabelTextBox from 'helpers/ui-controls/ui-components/SimpleLabelTextbox';
import SectionHeader from 'helpers/ui-controls/ui-components/SectionHeader';
import { facebookSchema } from 'helpers/schemas/companySchemas/schemas';
import { success } from 'helpers/variables';
import { facebookIntegrationSection } from 'helpers/schemas/companySchemas/initialValues';
import { mapServerValuesToInitialValues } from 'helpers/generic.methods';
import { useTranslation } from 'react-i18next';
import ToggleToolTip from 'helpers/ui-controls/toggleToolTip';
import ToggleMenuButton from 'helpers/ui-controls/ui-components/ToggleMenuButton';
import storage from 'helpers/storage';
import { Enums } from 'helpers/dropdown-enums';
import {
  updateCompany,
  updateCompanyToState
} from 'features/companies/companiesSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectRoles } from 'features/roles/rolesSlice';

const Facebook = ({ company, permissionEdit, clickHandlerMenu }) => {
  const [companyFacebook, setCompanyFacebook] = useState({});
  const [facebook_chk, setFacebook_chk] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(false);
  const [facebookObj, setFacebookObj] = useState(facebookIntegrationSection);

  const { roles } = useSelector(selectRoles);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const facebookFormHandler = async formData => {
    try {
      setLoading(true);
      let payload = {
        id: company.id,
        companyKey: company.companyKey,
        enableFacebookIntegration: facebook_chk,
        facebook: {
          ...formData,
          id: company.id,
          companyKey: company.companyKey
        }
      };
      const response = await updateCompany(payload);
      dispatch(
        updateCompanyToState({ id: company.id, data: response?.data?.data })
      );
      toast.success(success.saveSuccess('Facebook'));
      setEditable(false);
      setLoading(false);
    } catch (error) {
      toast.error(error || error.message);
      setLoading(false);
    }
  };

  /** Init Formik */
  const FacebookFormIk = useFormik({
    enableReinitialize: true,
    initialValues: facebookObj,
    validationSchema: facebookSchema,
    onSubmit: facebookFormHandler
  });
  /**
   * @name @handleOnChange
   * @description do something on field onChange
   * @requires html input element
   * */
  const handleOnChange = (element, key, value) => {
    setCompanyFacebook({ ...companyFacebook, [key]: value });
    FacebookFormIk.values[key] = value;
    FacebookFormIk.handleChange(element);
  };
  /**
   * @handleGoogleAnalyticsTrackingSwitch: callback function needs to send to headerComponent
   */
  const handleFacebookSwitch = checked => {
    setFacebook_chk(checked);
  };

  useEffect(() => {
    handleFacebookSwitch(!!company.enableFacebookIntegration);
    setCompanyFacebook(company.facebook);
    mapServerValuesToInitialValues(company.facebook, facebookIntegrationSection)
      .then(data => {
        setFacebookObj({
          ...facebookIntegrationSection,
          ...data
        });
      })
      .catch(err => {
        throw new Error(err);
      });
  }, [company]);

  return (
    <>
      <div className="page-section">
        <Form
          className="form"
          onSubmit={FacebookFormIk.handleSubmit}
          autoComplete="off"
        >
          <ToggleMenuButton
            heading={t('company.facebookIntegration.heading1')}
            clickHandlerMenu={clickHandlerMenu}
          />
          <section>
            <SectionHeader
              pageHeading={t('company.facebookIntegration.heading1')}
              field="facebook"
              loading={loading}
              // disabled={loading || !FacebookFormIk.dirty}
              // disabled={loading || !permissionEdit}
              disabled={loading}
              editable={editable}
              error={FacebookFormIk.errors['facebook']}
              handleEditableState={setEditable}
              hasHeaderProperty={true}
              headerProperty={facebook_chk}
              headerPropertyOnChange={handleFacebookSwitch}
              handleChange={handleOnChange}
              // readOnlySection={true}
              // Bug-572 turned off the edit button in facebook and custom tracking
              // hardTurnOff={true}
            />
            <div className="section-body">
              {!!facebook_chk && (
                <ul
                  className="editable-fields-lists"
                  // style={!!facebook_chk ? {} : { display: 'none' }}
                >
                  <SimpleLabelTextBox
                    heading={t(
                      'company.facebookIntegration.verificationToken.label'
                    )}
                    field="verificationToken"
                    dataProperty={
                      companyFacebook
                        ? companyFacebook['verificationToken']
                        : ''
                    }
                    titleText={t(
                      'company.facebookIntegration.verificationToken.info'
                    )}
                    error={FacebookFormIk.errors['verificationToken']}
                    editable={roles.isAdminCompany && editable}
                    handleChange={handleOnChange}
                    // for now making false as UI is not ready.
                    multiline={true}
                  />
                  <SimpleLabelTextBox
                    heading={t(
                      'company.facebookIntegration.pageAccessToken.label'
                    )}
                    field="pageAccessToken"
                    dataProperty={
                      companyFacebook ? companyFacebook['pageAccessToken'] : ''
                    }
                    titleText={t(
                      'company.facebookIntegration.pageAccessToken.info'
                    )}
                    error={FacebookFormIk.errors['pageAccessToken']}
                    handleChange={handleOnChange}
                    editable={editable && roles.isAdminCompany}
                    // for now making false as UI is not ready.
                    multiline={true}
                  />
                  <SimpleLabelTextBox
                    heading={t('company.facebookIntegration.callbackURL.label')}
                    field="callbackURL"
                    dataProperty={
                      companyFacebook ? companyFacebook['callbackURL'] : ''
                    }
                    titleText={t(
                      'company.facebookIntegration.callbackURL.info'
                    )}
                    error={FacebookFormIk.errors['callbackURL']}
                    // editable={editable}
                    handleChange={handleOnChange}
                    disabled={true}
                    // for now making false as UI is not ready.
                    multiline={true}
                  />
                  {roles.isAdminCompany ? (
                    <>
                      <ToggleToolTip
                        heading={t(
                          'company.facebookIntegration.enableGetStartedButton.label'
                        )}
                        toolTipFor="getStartedButton"
                        flag={
                          companyFacebook
                            ? companyFacebook.getStartedButton
                            : false
                        }
                        titleText={t(
                          'company.facebookIntegration.enableGetStartedButton.info'
                        )}
                        field={'getStartedButton'}
                        handleChange={handleOnChange}
                        editable={editable}
                      />
                      <SimpleLabelTextBox
                        heading={t(
                          'company.facebookIntegration.greetingText.label'
                        )}
                        field="getStartedButtonGreetingText"
                        dataProperty={
                          companyFacebook
                            ? companyFacebook['getStartedButtonGreetingText']
                            : ''
                        }
                        titleText={t(
                          'company.facebookIntegration.greetingText.info'
                        )}
                        error={
                          FacebookFormIk.errors['getStartedButtonGreetingText']
                        }
                        editable={editable}
                        handleChange={handleOnChange}
                        // for now making false as UI is not ready.
                        multiline={false}
                      />
                      <ToggleToolTip
                        heading={t(
                          'company.facebookIntegration.enableGreetingText.label'
                        )}
                        toolTipFor="enableGreetingText"
                        flag={
                          companyFacebook
                            ? companyFacebook.enableGreetingText
                            : ''
                        }
                        titleText={t(
                          'company.facebookIntegration.enableGreetingText.info'
                        )}
                        editable={editable}
                        field={'enableGreetingText'}
                        handleChange={handleOnChange}
                      />
                      <SimpleLabelTextBox
                        heading={t(
                          'company.facebookIntegration.facebookChatGreetingText.label'
                        )}
                        field="facebookChatGreetingText"
                        dataProperty={
                          companyFacebook
                            ? companyFacebook['facebookChatGreetingText']
                            : ''
                        }
                        titleText={t(
                          'company.facebookIntegration.facebookChatGreetingText.info'
                        )}
                        error={
                          FacebookFormIk.errors['facebookChatGreetingText']
                        }
                        editable={editable}
                        handleChange={handleOnChange}
                        // for now making false as UI is not ready.
                        multiline={false}
                      />
                    </>
                  ) : null}
                  <SimpleLabelTextBox
                    heading={t(
                      'company.facebookIntegration.companyFacebookPageName.label'
                    )}
                    field="companyFacebookPageName"
                    dataProperty={
                      companyFacebook
                        ? companyFacebook['companyFacebookPageName']
                        : ''
                    }
                    titleText={t(
                      'company.facebookIntegration.companyFacebookPageName.info'
                    )}
                    error={FacebookFormIk.errors['companyFacebookPageName']}
                    editable={roles.isAdminCompany && editable}
                    // disabled={true}
                    handleChange={handleOnChange}
                    // for now making false as UI is not ready.
                    multiline={false}
                  />
                  <SimpleLabelTextBox
                    heading={t(
                      'company.facebookIntegration.facebookPageID.label'
                    )}
                    field="facebookPageID"
                    dataProperty={
                      companyFacebook ? companyFacebook['facebookPageID'] : ''
                    }
                    titleText={t(
                      'company.facebookIntegration.facebookPageID.info'
                    )}
                    error={FacebookFormIk.errors['facebookPageID']}
                    // editable={editable}
                    editable={roles.isAdminCompany && editable}
                    handleChange={handleOnChange}
                    // for now making false as UI is not ready.
                    multiline={false}
                  />
                </ul>
              )}
            </div>
          </section>
        </Form>
      </div>
    </>
  );
};

export default Facebook;
