import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NestedMenuItem from 'material-ui-nested-menu-item';
import { useEffect } from 'react';
import './styles.scss';
import { PeriodOptions } from 'pages/portal/modal/CreateReport/constant';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: '0px',
    width: '100%',
    verticalAlign: 'middle',
    marginTop: '8px'
  },
  selectEmpty: {
    // marginTop: theme.spacing(2)
  },
  paper: {
    width: '120px !important'
  },
  itemRoot: {
    margin: '0px'

    // display: 'flex',
    // justifyContent: 'space-between'
  },
  root: {
    color: 'var(--darkgray-2) !important',
    fontSize: '1rem',
    fontFamily: 'lato !important',
    marginTop: '18px !important'
  },
  rootEditable: {
    color: 'var(--darkgray-4) !important',
    fontSize: '1rem',
    marginTop: '18px !important',
    fontFamily: 'lato !important'
  }
}));

// Supported values:
//   C       : Current month
//   FY      : First month of year
//   LY      : Last month of year
//   C-3     : 3 months ago
//   11      : Specific month (1-12)

// Supported values:
//   C       : Current day
//   FW      : First day of week
//   LW      : Last day of week
//   FM      : First day of month
//   LM      : Last day of month
//   C-3     : 3 days ago
//   11      : Specific day (1-31)
// Date / Month / Year

const NestedSelect = ({
  options = PeriodOptions,
  label = 'Label',
  handleChangeValue,
  field,
  value = '',
  editable = true
}) => {
  const classes = useStyles();
  const [tempValue, setTempValue] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);

  const handleChange = event => {
    setOpen(false);
    setTempValue(event.target.value);
    handleChangeValue(event, field, event.target.value);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleItemClick = (event, value) => {
    event.preventDefault();
    setOpen(false);
    setTempValue(value);
    handleChangeValue(event, field, value);
  };

  useEffect(() => {
    setTempValue(value);
  }, [value]);

  const handleModal = () => {
    setOpenModal(!openModal);
  };

  return (
    // <div>
    <>
      <FormControl
        className={`${classes.formControl}`}
        style={{ marginTop: '12px !important' }}
      >
        <InputLabel
          id="demo-simple-select-label"
          className="lato-label form-label"
        >
          {label}
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="nested-select"
          autoWidth
          className={editable ? classes.rootEditable : classes.root}
          name={field}
          value={tempValue}
          onChange={handleChange}
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
          disabled={!editable}
          //   classes={classes}
          MenuProps={{
            classes: { paper: classes.paper },
            anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
            getContentAnchorEl: null
          }}
        >
          {options.map((op, ind) => {
            let opt = [];
            if (op.nestedOptions && op.nestedOptions.length) {
              opt.push(
                <NestedMenuItem
                  key={ind}
                  label={op.name}
                  parentMenuOpen={open}
                  value={op.value}
                  classes={{ root: classes.itemRoot }}
                >
                  {op.nestedOptions.map((no, index) => {
                    opt.push(
                      <MenuItem
                        classes={{ root: classes.paper }}
                        key={`${ind}${index}`}
                        value={no.value.toString()}
                        name={no.name}
                        style={{ display: 'none' }}
                      >
                        {`${no.name}`}
                      </MenuItem>
                    );
                    return (
                      <MenuItem
                        // classes={{ root: classes.paper }}
                        key={`${ind}${index}`}
                        value={no.value.toString()}
                        onClick={e => handleItemClick(e, no.value)}
                      >
                        {`${no.name}`}
                      </MenuItem>
                    );
                  })}
                </NestedMenuItem>
              );
            } else {
              opt.push(
                <MenuItem
                  key={ind}
                  value={op.value.toString()}
                  disabled={op.value.toString() == 'custom'}
                  //   classes={{ root: classes.paper }}
                >
                  {op.name}
                </MenuItem>
              );
            }
            return opt;
          })}
        </Select>
      </FormControl>
      {/* <Dialog open={open} onClose={handleModal}>
        <DialogTitle>Fill the form</DialogTitle>
        <DialogContent>
          <div>
            <TimePicker
              field={'startDate'}
              type="date"
              dataProperty={report['startDate']}
              heading={'Start Date'}
              // heading={t('company.reportDetails.parameters.period.label')}
              handleChange={handleChange}
            />
            <TimePicker
              field={'startDate'}
              type="date"
              dataProperty={report['startDate']}
              heading={'Start Date'}
              // heading={t('company.reportDetails.parameters.period.label')}
              handleChange={handleChange}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            variant="outline-primary"
            className="passBtn btn-md btn-flex float-right"
            onClick={handleModal}
          >
            <span>Close</span>
          </Button>
        </DialogActions>
      </Dialog> */}
    </>
    // </div>
  );
};
export default NestedSelect;
