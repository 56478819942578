import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import UilCopy from '@iconscout/react-unicons/icons/uil-copy';
import {
  convertToMeridianTime,
  convertToNonMeridianTime,
  camelize
} from 'helpers/generic.methods';
import { useTranslation } from 'react-i18next';
import UILTimesCircle from '@iconscout/react-unicons/icons/uil-times-circle';
const SimpleLabelTextBox = ({
  id,
  // field name
  field,
  // value of the field
  dataArray,
  // human readable title
  titleText,
  handleHoursChange,
  disabled
}) => {
  const [dataArrayObj, setDataArrayObj] = useState([]);
  const { t } = useTranslation();
  const handleOnChange = (e, entity, index) => {
    e.preventDefault();
    let _tempData = JSON.parse(JSON.stringify(dataArrayObj));
    _tempData[index][entity] = e.target.value || dataArrayObj[index][entity];
    // cloning
    var stateData = JSON.parse(
      JSON.stringify(conversionToNonMeridian(_tempData))
    );

    // converting to non-maridian time as textboxes only supports 24h time
    setDataArrayObj(conversionToNonMeridian(stateData));

    // Sending to API as a meridian format 12h (11:00 PM)
    handleHoursChange(field, conversionToMeridian(_tempData));
  };
  const handleCopyToAll = () => {
    // JSON.parse(JSON.stringify(dataArrayObj)) cloning..
    let _tempData = JSON.parse(JSON.stringify(dataArrayObj));
    // copying to all textboxes
    dataArrayObj.forEach((element, index) => {
      _tempData[index].startTime = _tempData[0].startTime;
      _tempData[index].endTime = _tempData[0].endTime;
    });
    // cloning
    var stateData = JSON.parse(
      JSON.stringify(conversionToNonMeridian(_tempData))
    );
    // converting to non-maridian time as textboxes only supports 24h time
    setDataArrayObj(conversionToNonMeridian(stateData));
    // Sending to API as a meridian format 12h (11:00 PM)
    handleHoursChange(field, conversionToMeridian(_tempData));
  };
  const conversionToNonMeridian = array => {
    array.forEach((element, index) => {
      array[index].startTime = convertToNonMeridianTime(element.startTime);
      array[index].endTime = convertToNonMeridianTime(element.endTime);
    });
    return array;
  };
  const conversionToMeridian = array => {
    array.forEach((element, index) => {
      array[index].startTime = convertToMeridianTime(element.startTime);
      array[index].endTime = convertToMeridianTime(element.endTime);
    });
    return array;
  };
  const clearInputValues = index => {
    let _tempData = JSON.parse(JSON.stringify(dataArrayObj));
    _tempData[index]['startTime'] = ':undefined';
    _tempData[index]['endTime'] = ':undefined';
    // cloning
    var stateData = JSON.parse(
      JSON.stringify(conversionToNonMeridian(_tempData))
    );

    setDataArrayObj(conversionToNonMeridian(stateData));

    // Sending to API as a meridian format 12h (11:00 PM)
    handleHoursChange(field, conversionToMeridian(_tempData));
  };
  useEffect(() => {
    if (dataArray) {
      // cloning
      let _tempData = JSON.parse(JSON.stringify(dataArray));
      // converting to non-meridian time so our textbox can display data, they dont support merdian time
      _tempData = conversionToNonMeridian(_tempData);
      setDataArrayObj(_tempData);
    }
  }, [dataArray]);
  return (
    <>
      {dataArrayObj &&
        dataArrayObj.map((days, index) => {
          return (
            <ul
              key={'connect-hours-' + index}
              className="editable-fields-list"
              title={titleText}
            >
              <li>
                {t(`company.hoursCallConnect.${camelize(days.day)}.label`)}
              </li>
              <li className="range">
                <Form.Control
                  as="input"
                  disabled={disabled}
                  type="time"
                  value={
                    days.startTime !== ':undefined'
                      ? days.startTime
                      : '--:-- --'
                  }
                  onChange={e => handleOnChange(e, 'startTime', index)}
                  id={days.startTime + '_' + id + '_' + index}
                  name={'name_' + days.startTime + '_' + index}
                  aria-label={days.day + ' on ' + days.startTime}
                  className="mr-3r"
                />
                <span className="text-darkgray-2">to</span>
                <Form.Control
                  as="input"
                  disabled={disabled}
                  type="time"
                  value={
                    days.endTime !== ':undefined' ? days.endTime : '--:-- --'
                  }
                  onChange={e => handleOnChange(e, 'endTime', index)}
                  id={'id_startTime_' + id + '_' + index}
                  name={'name_endTime_' + index}
                  aria-label={days.day + ' on ' + days.endTime}
                  className="ml-3r"
                />
                {!disabled && (
                  <UILTimesCircle
                    className="list-icon-before-text ml-2 cursor-pointer text-danger"
                    onClick={e => clearInputValues(index)}
                  />
                )}
                {!disabled && index === 0 ? (
                  <span
                    className="text-primary-2 ml-3r cursor-pointer"
                    onClick={handleCopyToAll}
                  >
                    <UilCopy className=" list-icon-before-text" />
                    {t('company.hoursCallConnect.copyToAll.label')}
                  </span>
                ) : (
                  ''
                )}
              </li>
            </ul>
          );
        })}
    </>
  );
};
export default SimpleLabelTextBox;
