import ApiServices from 'api/apexchat.api.services';
import { MDSignUpAPI } from 'api/endpoints';

export const PostMDSignUp = payload => {
  return ApiServices.WCF_post(MDSignUpAPI, payload)
    .then(async response => {
      if (response?.data?.data) {
        return Promise.resolve(response.data.data);
      }
      return Promise.reject(response);
    })
    .catch(async err => {
      return Promise.reject(err);
    });
};
