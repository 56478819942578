export const sp_leadDetails = {
  heading1: 'Detalles de plomo',
  heading2: 'Notas de plomo',
  leadTooltip: 'El portal muestra hasta 6 meses de datos',
  name: {
    label: 'Nombre',
    info: 'El nombre del cliente.'
  },
  phoneNo: {
    label: 'Telefono no',
    info: 'El número de teléfono del cliente.'
  },
  email: {
    label: 'Correo electrónico',
    info: 'La dirección de correo electrónico del cliente.'
  },
  createdOn: {
    label: 'Creado en',
    info: 'Cuando el plomo se creó en nuestro sistema.'
  },
  leadType: {
    label: 'Tipos de plomo',
    info: 'El tipo de plomo classifed por el usuario manejar el chat.'
  },
  initiatedBy: {
    label: 'Iniciado por',
    info: 'Cuando el chat se inició desde.'
  },
  called: {
    label: 'Llamada',
    info: 'El usuario intenta conectarse al visitante y de negocios.'
  },
  attemptedCalls: {
    label: 'Los intentos de llamadas',
    info: 'Número de intentos de conexión de llamada.'
  },
  completedCalls: {
    label: 'Llamadas completadas',
    info: 'Número de llamada completada intentos de conexión.'
  },
  keywords: {
    label: 'Palabras clave',
    info: 'Proporcionar las palabras clave si el sistema fue capaz de capturarlo.'
  },
  flagAsProcessed: {
    label: 'Marcar como procesa',
    info: 'Marcar como procesa'
  },
  flagAsUnProcessed: {
    label: 'Marcar como no procesada',
    info: 'Marcar como no procesada'
  },
  replyToVisitor: {
    label: 'Responder a los visitantes',
    info: 'Responder a los visitantes'
  },
  viewChat: {
    label: 'Vista de chat',
    info: 'Vista de chat'
  },
  region: { label: 'Región', info: 'Región' },
  leadCategory: { label: 'Categoría de plomo', info: 'Categoría de plomo' },
  appliedRule: { label: 'regla aplicada', info: 'regla aplicada' }
};
export default sp_leadDetails;
