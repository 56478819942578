import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import UilAngleDown from '@iconscout/react-unicons/icons/uil-angle-down';
import FieldError from './FieldError';
import storage from 'helpers/storage';
import makeStyles from '@material-ui/core/styles/makeStyles';
import angleDownIcon from 'static/images/line-angle-down-icon.svg';
import angleUpIcon from 'static/images/line-angle-up-icon.svg';

const useStyles = makeStyles(theme => ({
  dropdownArrow: {
    paddingLeft: '10px',
    height: '40px',
    WebkitAppearance: 'none',
    appearance: 'none',
    MozAppearance: 'none',
    backgroundImage: `url(${angleDownIcon})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '10px 10px',
    backgroundPosition: 'calc(100% - 10px)'
  }
}));

const SimpleSelectBox = ({
  // label of the field
  heading,
  // field name
  field,
  // value of the field
  dataProperty,
  // human readable title
  titleText,
  // FormIK error for the specific field
  error,
  // custom error message text
  errorMessage,
  // boolean: false to make field read-only
  editable,
  // read-only field never editable
  readOnly,
  // action trigger on change
  handleChange,
  // for Dropdown
  options,
  // for full width
  fullWidth = false,
  customStyle = {},
  // disabled dropdown
  disabled = false,
  // label styling
  labelStyle = {},
  // label styling
  errorStyle = {},
  // show title on hover
  showTitle = true
}) => {
  const [value, setValue] = useState(dataProperty);
  const [selectOpen, setSelectOpen] = useState(false);
  const classes = useStyles();
  // const selectedLanguage = JSON.parse(storage.getItem('language'));
  const handleOnChange = element => {
    handleOnClick(true);
    setValue(element.target.value);
    handleChange(element, field, element.target.value);
  };
  const handleOnClick = value => {
    setSelectOpen(value);
  };
  useEffect(() => {
    setValue(dataProperty);
  }, [dataProperty]);
  return (
    <>
      <div className={'select-wrapper mt-1' + fullWidth && 'w-100'}>
        {heading && (
          <Form.Label
            title={titleText}
            htmlFor={field}
            style={{ ...labelStyle }}
          >
            {heading}
          </Form.Label>
        )}
        {/* ${editable ? '' : 'read-only'} */}
        <Form.Control
          as="select"
          className={`${error && 'is-invalid'} ${classes.dropdownArrow}`}
          style={customStyle}
          value={value}
          title={showTitle ? value : undefined}
          disabled={disabled}
          // readOnly={!editable || readOnly}
          onChange={e => handleOnChange(e)}
          onClick={e => handleOnClick(!selectOpen)}
          id={field}
          name={field}
          aria-label={titleText}
        >
          {options.map(option => (
            <option key={option.value} value={option.value}>
              {option.text}
            </option>
          ))}
        </Form.Control>
        {/* <span className={`select-arrow ${selectOpen ? 'toggled' : ''}`}>
          <UilAngleDown size="24" />
        </span> */}
        {error && (
          // <FieldError
          //   titleText={titleText}
          //   error={error}
          //   errorMessage={errorMessage}
          // />
          <p
            style={{ position: 'absolute', ...errorStyle }}
            className="text-red-1 field-error"
          >
            {error}
          </p>
        )}
      </div>
    </>
  );
};
export default SimpleSelectBox;
