import React, { useState, useEffect } from 'react';
import DisplayKey from 'helpers/ui-controls/DisplayKey';
import { Link } from 'react-router-dom';
const ChatHeader = ({ chat, companyId }) => {
  const [chatData, setChatData] = useState({});

  useEffect(() => {
    // mapServerValuesToInitialValues(company, companyHeader)
    //   .then(data => {
    //     setCompanyHeaderObj({
    //       ...companyHeaderObj,
    //       ...data
    //     });
    //   })
    //   .catch(err => {
    //     throw new Error(err);
    //   });
    setChatData(chat);
  }, [chat]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <DisplayKey displayKey={companyId} />
      <div className="page-header">
        <div className="page-header-bottom d-flex align-items-center justify-content-between">
          <h1 className="main-head text-primary-2">{chatData.companyName}</h1>
          {!!chatData.leadId && (
            <Link
              to={`leads?id=${chatData.leadId}`}
              className="btn-md btn-flex ml-6r introJs-companies-tab-view-0 btn-float-right btn btn-primary"
            >
              View lead
            </Link>
          )}
        </div>
      </div>
    </>
  );
};

export default ChatHeader;
