import Companies from '../../pages/portal/companies/index';
import BasicDetails from '../../pages/portal/company/index/basic-details';
import Tracking from '../../pages/portal/company/index/tracking';
import ContactInformation from '../../pages/portal/company/index/contact-information';
import Mobile from '../../pages/portal/company/index/mobile';
import LeadEmails from '../../pages/portal/company/index/lead-emails';
import CRM from '../../pages/portal/company/index/crm';
import Facebook from '../../pages/portal/company/index/facebook';
import Budget from '../../pages/portal/company/index/budget';
import UILSuitcas from '@iconscout/react-unicons/icons/uil-suitcase';
import UILPlug from '@iconscout/react-unicons/icons/uil-plug';
import UILDollar from '@iconscout/react-unicons/icons/uil-dollar-alt';
import HoursCallConnect from '../../pages/portal/company/index/call-connect';
import UILSetting from '@iconscout/react-unicons/icons/uil-setting';
import Users from 'pages/portal/company/index/users';

const base = {
  general: '/portal/companies/:id',
  budget: '/portal/companies/:id',
  setting: '/portal/companies/:id',
  integrations: '/portal/companies/:id'
};

export const companySideBarMenu = [
  {
    // path: base.general,
    id: 'general',
    name: 'company.general.generalTxt',
    icon: <UILSuitcas />,
    subItems: [
      {
        id: 'general-basic-details',
        path: base.general + '/basic-details',
        name: 'company.general.menuItem1',
        slug: 'basic-details',
        // component: Companies,
        child: props => <BasicDetails {...props} />,
        exact: true
      },
      {
        id: 'general-contact-information',
        path: base.general + '/contact-information',
        name: 'company.general.menuItem2',
        slug: 'contact-information',
        // component: Companies,
        child: props => <ContactInformation {...props} />,
        exact: true
      },
      {
        id: 'general-lead-emails',
        path: base.general + '/lead-emails',
        name: 'company.general.menuItem3',
        slug: 'lead-emails',
        // component: Companies,
        child: props => <LeadEmails {...props} />,
        exact: true
      },
      {
        id: 'general-call-connect',
        path: base.general + '/call-connect',
        name: 'company.general.menuItem4',
        slug: 'call-connect',
        // component: Companies,
        child: props => <HoursCallConnect {...props} />,
        exact: true
      },
      {
        id: 'general-users',
        path: base.general + '/users',
        name: 'company.general.menuItem5',
        slug: 'users',
        // component: Companies,
        child: props => <Users {...props} />,
        exact: true
      }
    ]
  },
  {
    // path: base.budget,
    //the name should not be same as links first route name
    id: 'budget',
    name: 'company.general.budgetTxt',
    icon: <UILDollar />,
    subItems: [
      {
        id: 'budget-budget',
        path: base.budget + '/budget',
        name: 'company.general.menuItem6',
        slug: 'budget',
        component: Companies,
        child: props => <Budget {...props} />,
        exact: true
      }
      // History is not included in MVP
      // {
      //   id: 'budget-history',
      //   path: base.budget + '/history',
      //   slug: 'history',
      //   name: 'company.general.menuItem11',
      //   component: Companies,
      //   child: props => <History {...props} />,
      //   exact: true
      // }
    ]
  },
  {
    // path: base.setting,
    //the name should not be same as links first route name
    id: 'settings',
    name: 'company.general.settingTxt',
    icon: <UILSetting />,
    subItems: [
      {
        id: 'settings-mobile',
        path: base.setting + '/mobile',
        name: 'company.general.menuItem7',
        slug: 'mobile',
        component: Companies,
        child: props => <Mobile {...props} />,
        exact: true
      }
    ]
  },
  {
    // path: base.integrations,
    //the name should not be same as links first route name
    id: 'integrations',
    name: 'company.general.integrationTxt',
    icon: <UILPlug />,
    subItems: [
      {
        id: 'integrations-crm',
        path: base.integrations + '/crm',
        //the slug should be the same as route endpoint
        slug: 'crm',
        name: 'company.general.menuItem8',
        component: Companies,
        child: props => <CRM {...props} />,
        exact: true
      },
      {
        id: 'integrations-facebook',
        path: base.integrations + '/facebook',
        //the slug should be the same as route endpoint
        slug: 'facebook',
        name: 'company.general.menuItem9',
        component: Companies,
        child: props => <Facebook {...props} />,
        exact: true
      },
      {
        id: 'integrations-tracking',
        path: base.integrations + '/tracking',
        //the slug should be the same as route endpoint
        slug: 'tracking',
        name: 'company.general.menuItem10',
        component: Companies,
        child: props => <Tracking {...props} />,
        exact: true
      }
    ]
  }
];
